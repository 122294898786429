import React, { useContext, useRef, useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Checkbox,
    Panel,
    DefaultButton,
    PanelType,
    Modal,
    IconButton,
    ContextualMenu,
    IContextualMenuItem,
    Pivot,
    PivotItem,
    TooltipHost,
    DirectionalHint,
    ThemeProvider,
    SpinnerSize,
    Spinner,
    DocumentCard,
    DocumentCardTitle,
    Stack,
    DocumentCardPreview,
    DocumentCardType
} from "@fluentui/react";

import RModal from "react-modal";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

import { FiUser } from "react-icons/fi";
import { MdArrowBackIos } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { LuPanelRightClose } from "react-icons/lu";
import { TbGridDots } from "react-icons/tb";
import { HiOutlineViewList } from "react-icons/hi";
import kognifyLogo from "../../assets/kognifyLogo.png";

import readNDJSONStream from "ndjson-readablestream";
import { AuthenticationResult } from "@azure/msal-browser";

import styles from "./Chat.module.css";
import {
    chatApi,
    ChatAppResponse,
    qGetChatLog,
    qHistGetByKey,
    qHistGetRecent,
    qGetSuggestedQuestions,
    qHistGetLatest,
    qCodeChk,
    qUSp,
    qLi,
    qGetTiers,
    qHistGetList,
    qHistAdd,
    qGetCompFlatList,
    qGetGroupsMemberships,
    qGetCompanyUsers,
    qGetAllUsers,
    qGetGroups,
    qGetGroupLastModifiedFileDate,
    qAddMember,
    qHistEditName,
    qHistDelete,
    qUploadCCAConvo
} from "../../api";

import { Answer, AnswerError, AnswerLoading, AnswerHi, AnswerDocs } from "../../components/Answer";
import NoSpacesCard from "../../components/NoSpacesCard/NoSpacesCard";
import { QuestionInput } from "../../components/QuestionInput";
import { QuestionInputXplrr } from "../../components/QuestionInputXplrr";
import { UserChatMessage } from "../../components/UserChatMessage";
import { SettingsButton } from "../../components/SettingsButton";
import SpacePanel from "../../components/SpacePanel/SpacePanel";
import AddSpaceModal from "../../components/AddSpaceModal/AddSpaceModal";
import InviteUserCallout from "../../components/InviteUserCallout/InviteUserCallout";
import { ListItemButton } from "../../components/ListItemButton";
import SpaceEditForm from "../../components/SpaceEditForm/SpaceEditForm";
import AccountInfo from "../../components/AccountInfo/AccountInfo";
import { useMsal } from "./../../MsalContext";
import { loginRequest } from "../../authConfig";
import customTheme from "./../../theme/customTheme";
import CompEditForm from "../../components/CompEditForm/CompEditForm";
import { DetailsListDocumentsExample } from "../../components/detailedList"; // Files List
import XplrrPDF from "../../components/XplrrPDF/XplrrPDF";
import FieldSelectionForInvoices from "../../components/FieldSelection/FieldSelectionForInvoices";
import SpeechOutput from "./../../components/SpeechOutput/SpeechOutput";
import XplrrPDFSideByChat from "../../components/XplrrPDFSideByChat/XplrrPDFSideByChat";
import { DocFieldSettings } from "../../components/DocFieldSelection/DocFieldSettings";
import { CallAssistantSettings } from "../../components/DocFieldSelection/CallAssistantSettings";
import UserList from "../../components/UserList/UserList";
import CompList from "../../components/CompList/CompList";
import GroupCardsList from "../../components/GroupCardsList/GroupCardsList";
import SpaceUserListV2 from "../../components/SpaceUserListV2/SpaceUserListV2";
import AnalyticsConvoList from "../../components/AnalyticsConvoList/AnalyticsConvoList";
import XplrrPDFDiff from "../../components/XplrrPDF/XplrrPDFDiff";

import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from "lodash/debounce";
import {
    gfile,
    ggroup,
    gselectedgroup,
    gcompflat,
    ispdfmodalopen,
    gXplrrCitationChangeCallback,
    gChatlogItem,
    gConf,
    gnewgroup,
    gsuggestedquestions,
    gtiers,
    grecentconvosaccrossspaces,
    gfeedback,
    ispdfdiffmodalopen,
    gdiffresponse,
    qsentenceforaudio,
    emptyaudiosentence,
    gconvorunonmessagepart,
    gqasimplified,
    qconvoupload
} from "../../interfaces";
import {
    defaultPromptTemplateAsInPythonBackend,
    defaultGreetingsMessage,
    msgLmtHit,
    defaultGreetingContent,
    answerCardHTML,
    noContentNoAccessGreetingContent,
    dfltSettingsLocalStr,
    assistantCodes,
    locationCodes,
    initialExtrConfigString
} from "../../lsts";
import {
    groupListToSelectedGroup,
    handleIsDark,
    getLatestModifiedFile,
    extractPageValue,
    extractFileIDValue,
    getTokenAccountID,
    getTokenMail,
    cleanFileIDString,
    checkWeAreInGeneralPlace,
    removeRefTagsClosedOnly,
    hasSentenceEnder,
    joinShortSentences,
    splitIntoSentences,
    endsWithPunctuation,
    handleNonStreamChat,
    determineCategoryAndGroups,
    get_ovrrds,
    getChunkRandomId,
    emptyIsPDFOpen,
    emptyIsPDFDiffOpen,
    prep_question_and_answers_list,
    preserve_convo_collected_data,
    create_chatapprequest
} from "./ChatUtil";
import {
    encodeHTML,
    makeValidHtmlId,
    shuffleArray,
    getLoc,
    genNewConvoKey,
    isElementVisible,
    removeExtension,
    fetchThumbnail,
    newConvoStamp,
    removeEnclosingQuotes,
    stopWigglePixel
} from "./../../util_glob";
import { libPivotStyles, prdAssistantExternalFileListStyle, chatAdmPivotStyles } from "./../../styles_glob";

import lines_placeholder from "../../assets/thumb_placeholder.png";
import { GlobalContext } from "../../GlobalContext";

const Chat = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { msalInstance, getToken } = useMsal();
    const [token, setToken] = useState<AuthenticationResult | null>();
    const {
        user,
        loggedInUser,
        fileList,
        groupList,
        selectedGroup,
        allUsrs,
        compMbrshps,
        activeComp,
        permissionLevels,
        setUser,
        setLoggedInUser,
        setFileList,
        setGroupList,
        setSelectedGroup,
        setCompUsrs,
        setAllUsrs,
        setCompMbrshps,
        setActiveComp,
        setSourceHighlighting
    } = useContext(GlobalContext);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const answersLength = useRef<number>(0);
    const clickedCitation = useRef<any>(null);
    const xplrrMsgStrm = useRef<HTMLDivElement | null>(null);
    const addUserButtonRef = useRef<HTMLDivElement>(null);
    const redeemURLRef = useRef<string | null>(null);
    const redeemURLSecondPathRef = useRef<string | null>(null);
    const convChunksStr = useRef<string>("");
    const convActvSentnce = useRef<qsentenceforaudio>(emptyaudiosentence);
    const msgCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const convoRunOnInputMessage = useRef<gconvorunonmessagepart[]>([]);

    const [compFlatList, setCompFlatList] = useState<gcompflat[]>([]);
    const [fileCount, setFileCount] = useState<number>(0);

    const [includeCategory, setIncludeCategory] = useState<string>("");
    const [includeCategoryGroups, setIncludeCategoryGroups] = useState<string>("");

    const [greetingMessageHtml, setGreetingMessageHtml] = useState<string>("");

    const [isLib, setIsLib] = useState<string>("");
    const [lTiers, setLTiers] = useState<gtiers[]>();

    const [initLoad, setInitLoad] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [isShareCalloutVisible, setIsShareCalloutVisible] = useState<boolean>(false);
    const [accMenuItems, setAccMenuItems] = useState<IContextualMenuItem[]>([]);
    const [newSpacePyro, setNewSpacePyro] = useState<string>("");
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);

    const [xplrrChatVisible, setXplrrChatVisible] = useState<boolean>(false);
    const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false); // History Panel Open

    const [isSpaceModalOpen, setIsSpaceModalOpen] = useState(false); // New Space Modal Open
    const [isNewSpaceInfoBip, setIsNewSpaceInfoBip] = useState(false); // New Space Modal Open
    const [isSpaceEditModalOpen, setIsSpaceEditModalOpen] = useState(false); // Edit Space Modal Open
    const [isSpaceAnalyticsModalOpen, setIsSpaceAnalyticsModalOpen] = useState(false); // Edit Space Modal Open

    const [activeConvoKey, setActiveConvoKey] = useState<string>(""); // Active Conversation Key [Bigint yyyyMMddHHmmss]
    const [convos, setConvos] = useState<{ key: string; name: string }[]>([]); // Historic Convos

    const [suggestedQuestions, setSuggestedQuestions] = useState<gsuggestedquestions[]>();
    const [recentConvosAccrosSpaces, setRecentConvosAccrosSpaces] = useState<grecentconvosaccrossspaces[]>();

    const [spaceChatLog, setSpaceChatLog] = useState<gChatlogItem[]>([]);

    const [isCancellingMidway, setIsCancellingMidway] = useState<boolean>(false);
    const [loadingChatModeSwitch, setLoadingChatModeSwitch] = useState(false); // Edit Space Modal Open
    const [isIframe, setIsIframe] = useState<number>();

    const [shouldPlayAudio, setShouldPlayAudio] = useState<string>("");

    const [isAccountMenuVisible, setIsAccountMenuVisible] = useState<boolean>(false);
    const [initialLoadComleted, setInitialLoadComleted] = useState<boolean>(false);

    const [isUserListModalOpen, setIsUserListModalOpen] = useState(false);
    const [isCompListModalOpen, setIsCompListModalOpen] = useState(false);

    const [noMembership, setNoMembership] = useState(false);
    const [noGroupsShared, setNoGroupsShared] = useState(false);
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [isSpacePanelOpen, setIsSpacePanelOpen] = useState(false);
    const [isSpacePanelLoading, setIsSpacePanelLoading] = useState(false);
    const [isBlurredFull, setIsBlurredFull] = useState(false);
    const [isConvoModeOn, setIsConvoModeOn] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isSpeechPaused, setIsSpeechPaused] = useState<boolean | null>(null);
    const [loadingMsg, setLoadingMsg] = useState("");

    const [config, setConfig] = useState<gConf>(JSON.parse(localStorage.getItem("configObj6") || dfltSettingsLocalStr));
    const [thumbnailUrls, setThumbnailUrls] = useState<string[]>(fileList.map(() => lines_placeholder));
    const [lastMessage, setLastMessage] = useState<string[]>([]);

    const [isPDFModalOpen, setIsPDFModalOpen] = useState<ispdfmodalopen>(emptyIsPDFOpen);
    const [isPDFDiffModalOpen, setIsPDFDiffModalOpen] = useState<ispdfdiffmodalopen>(emptyIsPDFDiffOpen);
    const [expected_next_user_response_type, set_expected_next_user_response_type] = useState<string>("");
    const [question_and_answers_list, set_question_and_answers_list] = useState<gqasimplified[]>([]);
    const [convo_discard_stamp, set_convo_discard_stamp] = useState<number>(0);
    lines_placeholder;

    useEffect(() => {
        if (selectedGroup && selectedGroup.assistanttype == assistantCodes.prd) {
            const fetchThumbnails = async () => {
                for (let i = 0; i < fileList.length; i++) {
                    const url = await fetchThumbnail(fileList[i], selectedGroup);
                    setThumbnailUrls(prevUrls => {
                        const updatedUrls = [...prevUrls];
                        updatedUrls[i] = url;
                        return updatedUrls;
                    });
                }
            };
            fetchThumbnails();
        }
    }, [fileList, selectedGroup]);

    // Effect⇒[client] (INIT LOAD) [Essential ENTRY POINT]
    useEffect(() => {
        if (!msalInstance) return;

        hndlIfrm();
        setInitLoad(true);
        if (initialLoadComleted === false) {
            applyLocalConfig();
            qGetUserInfo().then(userObj => {
                if (userObj === null) {
                    uMenuEmpty();
                    setNoMembership(true);
                    if (userObj === null) {
                        uMenuEmpty();
                        setNoMembership(true);
                        fetch(import.meta.env.VITE_EXT_SERV_ANIP)
                            .then(response => response.json())
                            .then(data => {
                                if (data && data.ip && data.ip != "") {
                                    qLi("land", `user_is_null ${data.ip}`, getHeader(), user?.userMail || "no_usermail");
                                } else {
                                    qLi("land", `user_is_null nip`, getHeader(), user?.userMail || "no_usermail");
                                }
                                return;
                            });
                    }
                    return;
                }
                getCompFlatList().then((cFlatList: gcompflat[]) => {
                    setInitialLoadComleted(true);
                    qLi("land", `init_load`, getHeader(), userObj.userMail || user?.userMail || "no_usermail");
                    handlePathNavigation(cFlatList, userObj);
                });
            });
            window.global_is_tracking_silence = false;
            window.globalConvPerfRecog = newConvoStamp();
            window.convoPerfOnRecogFin = newConvoStamp();

            window.global_run_on_sentence = {
                segments: [],
                recognized: []
            };
        }
        window.greetingMessageAddNewSpace = (param: string) => {
            if (isIframe && isIframe > 0) {
                toast.error("This feature is not available in the embedded version of the app.");
            } else {
                qUSp("dataEx", "1", getHeader());
            }
        };
        window.greetingMessageViewDocs = (param: string) => {
            if (isIframe && isIframe > 0) {
                toast.error("This feature is not available in the embedded version of the app.");
            } else {
                var currentPath = window.location.hash.split("/");
                currentPath[currentPath.length - 1] = "library";
                window.location.hash = currentPath.join("/");
                return false;
            }
        };
        getTiers();
    }, [msalInstance]);

    // Effect⇒[activeComp]
    useEffect(() => {
        if (activeComp.companyid !== "") {
            import.meta.env.DEV == true && console.log("active_comp", activeComp);

            uMenu(user, compFlatList, activeComp);
            getGroups().then((groupList: any) => {
                if (user && user.userId && user.userId !== "") {
                    let subpaths;
                    if (redeemURLSecondPathRef.current) {
                        let rU = redeemURLSecondPathRef.current;
                        subpaths = rU.split("/").filter(Boolean);
                        redeemURLSecondPathRef.current = null;
                    } else {
                        subpaths = getLoc().split("/").filter(Boolean);
                    }
                    const groupPath = decodeURIComponent(subpaths[1]);
                    if (selectedGroup.selectionId !== groupPath || groupPath == undefined) {
                        if (groupList && groupList.length > 0) {
                            let gr = groupList.filter((group: any) => group.groupid === groupPath)[0];
                            if (gr) {
                                setSelectedGroup(groupListToSelectedGroup(gr));
                            } else {
                                if (groupPath) {
                                    navigate(`/${activeComp.companyid}`);
                                }
                            }
                        } else {
                            setNoGroupsShared(true);
                        }
                    }
                }
            });
            getCompanyUsers();
        }
    }, [activeComp]);

    //Effect⇒[selectedGroup] ON SPACE CHANGE
    useEffect(() => {
        if (selectedGroup.selectionId !== "") {
            setConvos([]);
            setLoadingChatModeSwitch(true);
            newConverson();
            import.meta.env.DEV === true && console.log("ue_selected_group", selectedGroup);
            qGetSpaceFiles(true, true).then((fls: Array<gfile>) => {
                setInitLoad(false);

                document.title = `kgnf ¦ ${selectedGroup.selectionText}`;

                closeSBSWhenScrenTooSmall(true);
                dealWithFilesForPDFViewers(fls);
                try {
                    if (fls?.length > 50) localStorage.setItem(`kgnf_chs_${selectedGroup.oid}`, JSON.stringify(fls));
                } catch (e) {
                    console.log("!q_ceiling!");
                    localStorage.removeItem(`kgnf_chs_${selectedGroup.oid}`);
                }
            });
            const subpaths = getLoc().split("/").filter(Boolean);
            if (subpaths?.[3]) {
                histGetByKey(user.userId, subpaths?.[3]).then(() => setLoadingChatModeSwitch(false));
            } else {
                qHistGetLatest(user.userId, selectedGroup.selectionId, activeComp.companyid, 1, getHeader()).then((lConv: any) => {
                    if (lConv && lConv.length > 0) {
                        setActiveConvoKey(lConv[0].key); // Set the active conversation key to the latest key (Datetime string)
                        const histObj = JSON.parse(lConv[0].content);
                        setAnswersFunction(histObj, true);
                        setStreamedAnswers(histObj);
                    } else {
                        setActiveConvoKey(genNewConvoKey()); // Set the active conversation key to the latest key (Datetime string)
                        setAnswersFunction([], true);
                        setStreamedAnswers([]);
                    }
                    setLoadingChatModeSwitch(false);
                });
            }
            if (selectedGroup?.permissionlevel >= 2) getGroupsMemberships();
        }
    }, [selectedGroup]);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [xplrrChatVisible]);
    useEffect(() => {
        // Wipe out Convo Mode Artefacts when Turned Off
        if (isConvoModeOn == false) {
            if (convChunksStr.current != "") convChunksStr.current = "";
            if (convActvSentnce.current.answerid != emptyaudiosentence.answerid) convActvSentnce.current = emptyaudiosentence;
        }
    }, [isConvoModeOn]);

    //Effect ⇒ showDocLibInChatTab
    useEffect(() => {
        if (initLoad == false) {
            if (!activeConvoKey) return;
            setLoadingChatModeSwitch(true);
            const oldConvo = activeConvoKey;
            setConversation(oldConvo);
        }
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
    }, [config.showDocLibInChatTab]);

    //Effect ⇒ isLib
    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" });
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
    }, [isLib]);

    useEffect(() => {
        if (isSpeaking == false) {
            window.global_convo_last_speech_finish = newConvoStamp();
        } else {
            window.global_convo_last_speech_start = newConvoStamp();
            console.log("🕑", (newConvoStamp() - window.convoPerfOnRecogFin) / 1000);
        }
    }, [isSpeaking]);
    //Effect ⇒ streamedAnswers
    useEffect(() => {
        if (window.globalStreamOneSecondFlag == false || typeof window.globalStreamOneSecondFlag === "undefined") {
            window.globalStreamOneSecondFlag = true;
            if (xplrrMsgStrm.current) {
                if (xplrrMsgStrm.current.scrollHeight - (xplrrMsgStrm.current.scrollTop + xplrrMsgStrm.current.clientHeight) < 250) {
                    if (chatMessageStreamEnd.current) {
                        smoothScrollIntoView(chatMessageStreamEnd.current, { behavior: "smooth" });
                    }
                }
            }
            const timeoutId = setTimeout(() => {
                window.globalStreamOneSecondFlag = false;
                clearTimeout(timeoutId);
            }, 500);
        }
        //console.log("streamedAnswers", streamedAnswers);
    }, [streamedAnswers]);

    //Effect ⇒ isStreaming
    useEffect(() => {
        if (isStreaming == false) window.globalAnswerStreamingFirstCitationFlag = false;
    }, [isStreaming]);

    //Effect ⇒ answers, config.showDocLibInChatTab
    useEffect(() => {
        scrollChatToEnd();
    }, [answers, config.showDocLibInChatTab]);

    //Effect ⇒ location
    useEffect(() => {
        if (initialLoadComleted == true) handlePathNavigation(compFlatList, user);
    }, [location]);

    //Effect ⇒ suggestedQuestions

    useEffect(() => {
        setGreetingMessageHtml(createSuggestedQuestionsHTML());
    }, [suggestedQuestions]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.altKey && event.key.toLowerCase() === "p") {
                event.preventDefault();
                setIsConfigPanelOpen(!isConfigPanelOpen);
            }
        };
        const handleResize = debounce(() => {
            closeSBSWhenScrenTooSmall();
        }, 500);
        window.addEventListener("resize", handleResize);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("keydown", handleKeyDown);
            handleResize.cancel();
        };
    }, []);

    // Effect⇒[answers] Save Convo to Local Storage (Convo History) (Indexed Hash Array of Arrays)
    useEffect(() => {
        if (answers && answers.length > 0) {
            if (answersLength.current != answers.length) {
                histAdd(parseInt(activeConvoKey), user.userId, selectedGroup.selectionId, activeComp.companyid, answers[0][0]);
                answersLength.current = answers.length;
            }
            !activeConvoKey && newConverson();
        }
        let question_answer_simplified_list: gqasimplified[] = prep_question_and_answers_list(answers);
        console.log("qa_simplified_list", question_answer_simplified_list);
        set_question_and_answers_list(question_answer_simplified_list);
        window.global_simple_convo_list = question_answer_simplified_list;
        window.greetingMessageBeginConversation = (param: string) => {
            if (param != "") {
                if (selectedGroup.assistanttype == assistantCodes.cca) {
                    console.log("👇");
                    let convoButton = document.getElementById("kgnfconvostart");
                    if (convoButton) {
                        (convoButton as any).dispatchEvent(
                            new MouseEvent("click", {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            })
                        );
                        setTimeout(() => {
                            let convoInput = document.getElementById("kgnfqinputfield");
                            if (convoInput) {
                                console.log("🎯");
                                convoInput.focus();
                                (convoInput as HTMLTextAreaElement).value = param;
                                (convoInput as any).dispatchEvent(
                                    new Event("input", {
                                        bubbles: true,
                                        cancelable: true
                                    })
                                );
                            }
                            setTimeout(() => {
                                let sendButton = document.getElementById("kgnfqsendbutton");
                                if (sendButton) {
                                    console.log("📨");
                                    (sendButton as any).dispatchEvent(
                                        new MouseEvent("click", {
                                            bubbles: true,
                                            cancelable: true,
                                            view: window
                                        })
                                    );
                                }
                            }, 200);
                        }, 200);
                    }
                } else {
                    makeApiRequest(param);
                }
            } else {
                let dcs = fileList.map((f: gfile) => f.name);
                let shfl = shuffleArray([...dcs]).slice(0, 3);
                makeApiRequest(shfl.join(", "));
            }
        };
        if (isConvoModeOn == true) {
            setTimeout(() => {
                console.log("wipe_convo");
                convChunksStr.current = "";
                convActvSentnce.current = emptyaudiosentence;
            }, 2000);
        }
        setLastMessage([answers?.length ? answers[answers.length - 1][1].choices[0].message.content : ""]);
        console.log("setLastMessage", [answers?.length ? answers[answers.length - 1][1].choices[0].message.content : ""]);

        //answersLength.current = answers.length; // !!!
    }, [answers]);

    // Effect ⇒ activeConvoKey
    useEffect(() => {
        clickedCitation.current = "";
        window.globalConvoKey = activeConvoKey;
        console.log("active_convo_key", activeConvoKey);
        if (answers && answers.length > 0) {
            window.global_expected_next_user_response_type = answers[answers.length - 1][1]?.expected_next_user_response_type || "";
            window.global_active_scenario_id = answers[answers.length - 1][1]?.active_scenario_id || "";
        }
    }, [activeConvoKey]);

    // Effect⇒[config] UE Save Config to Local Storage
    useEffect(() => {
        preserveLocalConfig();
        setSourceHighlighting(config.sourceHighlighting);
    }, [config]);

    // Effect fileList
    useEffect(() => {
        window.globalFL = fileList;
        if (selectedGroup.assistanttype == assistantCodes.cca) {
            window.globalFL.map((f: gfile) => {
                f.invoicedata = typeof f?.invoicedata === "string" ? JSON.parse(removeEnclosingQuotes(f?.invoicedata || '"[]"')) : f?.invoicedata;
            });
        }
        if (fileList?.length > 0) openFileHotlink();
    }, [fileList]);

    // Navigation Set Location
    const setChatTab = () => {
        navigate(`${activeComp.companyid}/${selectedGroup.selectionId}/chat`);
    };
    const setLibTab = () => {
        navigate(`/${activeComp.companyid}/${selectedGroup.selectionId}/library`);
    };
    const navChatLib = (loc: string, groupid: string) => {
        if (loc == locationCodes.chat) {
            navigate(`/${activeComp.companyid}/${groupid}/chat`);
        } else {
            navigate(`/${activeComp.companyid}/${groupid}/library`);
        }
    };
    const setGrpTab = () => {
        setIsSpacePanelOpen(false);
        navigate(`${activeComp.companyid}`);
    };
    // Handle Path - Handle Nav - Path Navigation Handler
    const handlePathNavigation = async (cFlatList: gcompflat[], userObj: any) => {
        let subpaths = getLoc().split("/").filter(Boolean);
        if (redeemURLRef.current) {
            let rU = redeemURLRef.current;
            subpaths = rU.split("/").filter(Boolean);
            window.history.replaceState({}, "", `${window.location.origin}/#${rU}`);
            redeemURLRef.current = null;
        }
        subpaths.length > 0 && pathGetValidComp(cFlatList, subpaths);
        // Root Ex. kognify.ai
        if (subpaths.length == 0) {
            // Handle Company
            const homeComps = cFlatList.filter((comp: any) => comp.companyid == userObj?.companyid) || [];
            if (homeComps.length > 0) {
                navigate(`/${homeComps[0].companyid}`);
            } else if (cFlatList.length > 0) {
                navigate(`/${cFlatList[0].companyid}`);
            } else {
                setNoMembership(true);
                uMenuEmpty();
                return;
            }
        } else if (subpaths.length === 1) {
            // Root Ex. kognify.ai/AB
            setIsLib(locationCodes.grp);
            closeSBS();
            // Change Company if not the same Current
            if (activeComp.companyid !== subpaths[0]) {
                if (cFlatList.length > 0) {
                    const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])?.[0] || null;
                    if (comp) {
                        setActiveComp(comp);
                    } else {
                        navigate("/");
                    }
                }
            }
        } else if (subpaths.length >= 2) {
            // Root Ex. kognify.ai/AB/grp...
            let groupPath = decodeURIComponent(subpaths[1]);
            let isUserMenu = groupPath == "acc" || groupPath == "adm";
            if (activeComp.companyid !== subpaths[0]) {
                closeSBS();
                if (userObj && userObj.userId && userObj.userId !== "") {
                    applyLocalConfig();
                    if (cFlatList.length > 0) {
                        const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])?.[0] || null;
                        if (comp) {
                            setActiveComp(comp);
                            if (groupPath == "acc") {
                                await getTiers();
                                setIsLib("acc");
                            } else if (groupPath == "adm") {
                                await getAllUsers();
                                setIsLib("adm");
                            } else {
                                setIsLib(locationCodes.grp);
                            }
                        }
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                }
            } else {
                // Company Same as Current
                if (selectedGroup.selectionId !== groupPath) {
                    closeSBS();
                    if (groupList?.length > 0) {
                        let gr = groupList.filter((group: any) => group.groupid === groupPath)?.[0] || null;
                        if (gr != null) {
                            // Set Group to Found Group
                            setSelectedGroup(groupListToSelectedGroup(gr));
                        } else if (groupPath == "acc") {
                            await getTiers();
                            setIsLib("acc");
                        } else if (groupPath == "adm") {
                            await getAllUsers();
                            setIsLib("adm");
                        } else {
                            // Reload. No Access to Group, but has access to other Groups
                            setSelectedGroup(groupListToSelectedGroup());
                            window.location.href = "/";
                            window.location.reload();
                            return;
                        }
                    } else {
                        // No Groups Available
                        if (groupPath == "acc") {
                            await getTiers();
                            setIsLib("acc");
                        } else if (groupPath == "adm") {
                            await getAllUsers();
                            setIsLib("adm");
                        } else {
                            setSelectedGroup(groupListToSelectedGroup());
                            window.location.href = "/";
                            window.location.reload();
                            return;
                        }
                    }
                } else {
                    if (subpaths?.[3]) {
                        histGetByKey(user.userId, subpaths?.[3]).then(() => setLoadingChatModeSwitch(false));
                    } else {
                        setIsLoading(false);
                    }
                }
            }
            if (subpaths?.[2]) {
                if (subpaths[2] == "chat") {
                    if (subpaths?.[3]) {
                        import.meta.env.DEV === true && console.log("¦nav_03¦", subpaths[3]);
                        setActiveConvoKey(subpaths[3]);
                    }
                    setIsLib(locationCodes.chat);
                } else if (subpaths[2] === "library") {
                    setIsLib(locationCodes.lib);
                } else if (subpaths[2] === "explorer") {
                    setIsLib(locationCodes.xplr);
                }
            } else if (isUserMenu == false) {
                setIsLib(locationCodes.grp);
            }
        }
    };
    // Open History Panel
    const toggleLeftPanel = () => {
        histGetList(user.userId, selectedGroup.selectionId, activeComp.companyid, 5);
        setIsLeftPanelOpen(!isLeftPanelOpen);
    };
    // Open Spaces Panel
    const toggleSpacePanel = () => {
        if (isSpacePanelOpen == false) {
            if (recentConvosAccrosSpaces && recentConvosAccrosSpaces.length > 0) {
                setIsSpacePanelOpen(!isSpacePanelOpen);
                histGetRecent(user.userId, activeComp.companyid);
            } else {
                setIsSpacePanelLoading(true);
                histGetRecent(user.userId, activeComp.companyid).then((convos: any) => {
                    setIsSpacePanelOpen(!isSpacePanelOpen);
                    setIsSpacePanelLoading(false);
                });
            }
        } else {
            setIsSpacePanelOpen(!isSpacePanelOpen);
        }
    };
    // Library Space Analytics
    const openSpacesAnalyticsPanel = () => {
        getChatLog();
        setIsSpaceAnalyticsModalOpen(true);
    };
    // Manage Include Category (Legacy for file scoped chatting)
    const updateCatsState = (newVal: string) => {
        // After file is Uploaded. Toast Notification
        if (newVal !== "") {
            if (newVal.includes("¦Unsuported URL ⇛ Only Youtube Supported for Now")) {
            } else {
                let subpaths = window.location.hash.split("/").filter(Boolean);
                if (subpaths.length > 2) {
                    if (decodeURIComponent(subpaths[2]) == selectedGroup.selectionId) {
                        qGetSpaceFiles().then((fls: Array<gfile>) => {
                            setInitLoad(false);
                            closeSBSWhenScrenTooSmall(true);
                            if (fls && fls.length > 0) {
                                const dFl = fls[0];
                                const fileStrng = `${dFl.name}_page=${1}_fid=${dFl.fileid}`;
                                setIsPDFModalOpen({
                                    defaultFile: fileStrng,
                                    startPage: "1",
                                    isOpen: false,
                                    searchString: "",
                                    contentlist: dFl?.contentlist || [],
                                    pagemap: dFl?.pagemap || {},
                                    fileId: dFl.fileid,
                                    refPageNum: "1",
                                    isCitation: false,
                                    rndo: Math.random().toString(36).substring(2, 6),
                                    activeGroupId: selectedGroup.selectionId,
                                    fileList: fls,
                                    fl: dFl
                                });
                            } else {
                                setIsPDFModalOpen({
                                    defaultFile: "",
                                    startPage: "1",
                                    isOpen: false,
                                    searchString: "",
                                    contentlist: [],
                                    pagemap: {},
                                    fileId: "",
                                    refPageNum: "1",
                                    isCitation: false,
                                    rndo: Math.random().toString(36).substring(2, 6),
                                    activeGroupId: selectedGroup.selectionId,
                                    fileList: fls,
                                    fl: undefined
                                });
                            }
                        });
                    }
                }
            }
        }
    };
    // Refresh Filelist
    const listRefreshRequest = () => {
        let subpaths = window.location.hash.split("/").filter(Boolean);
        if (subpaths.length > 2) {
            if (decodeURIComponent(subpaths[2]) == selectedGroup.selectionId) qGetSpaceFiles();
        }
    };
    const pathGetValidComp = (cFlatList: gcompflat[], subpaths: string[]) => {
        if (cFlatList.length > 0) {
            const comp = cFlatList.filter((comp: any) => comp.companyid === subpaths[0])[0];
            if (comp) {
                import.meta.env.DEV === true && console.log("cmp¦", comp);
            } else {
                navigate("/");
            }
        }
    };
    const hndlIfrm = () => {
        if (window.location.href?.indexOf("ifrm=true") > 0 == true) {
            // LEGACY - ifrm was boolean
            setIsIframe(1);
        } else if (window.location.href?.indexOf("ifrm=1") > 0 == true) {
            setIsIframe(1);
        } else if (window.location.href?.indexOf("ifrm=2") > 0 == true) {
            setIsIframe(2);
            switchXplrrModuleVisibility();
        } else {
            setIsIframe(0);
        }
    };
    const getTokenDyn = async () => {
        const utok = localStorage.getItem("msalAuth4Tok");
        if (utok) {
            const utokcache: AuthenticationResult = JSON.parse(utok);
            setToken(utokcache);
            return utokcache;
        }
        if (!msalInstance) return null;
        if (token) return token;

        let tkn = await getToken(loginRequest.scopes);
        setToken(tkn);
        return tkn;
    };
    const redeemCode = async (userMail: string) => {
        let params = new URLSearchParams(window.location.search);
        let rdmCd = params.get("redeemCode");
        let convCode = params.get("convCode") || undefined;
        let mE = params.get("mE") || "0";
        let anon = params.get("A") || "0";
        let sub = params.get("sub") || "";

        if (rdmCd !== null) {
            params.delete("redeemCode");
            params.delete("spt");
            params.delete("A");
            params.delete("convCode");
            params.delete("mE");
            params.delete("sub");

            const newUrl = window.location.pathname + (params.toString() ? "?" + params.toString() : "");
            if (userMail !== "") {
                let userCheck = await qCodeChk(rdmCd, userMail, mE, convCode);
                if (userCheck.cmp) {
                    if (!groupList.some((g: ggroup) => g.groupid == rdmCd)) {
                        let navU = convCode ? `/${userCheck.cmp}/${rdmCd}/chat/${convCode}` : `/${userCheck.cmp}/${rdmCd}/chat`;
                        redeemURLRef.current = navU;
                        redeemURLSecondPathRef.current = navU;
                    }
                } else {
                    window.history.replaceState({}, "", newUrl);
                }
            } else {
                window.history.replaceState({}, "", newUrl);
            }
        }
        return rdmCd;
    };
    const getHeader = (token?: any) => {
        if (token) {
            return {
                ...(loggedInUser?.token ? { Authorization: `Bearer ${token.accessToken}` } : {}),
                "X-MS-CLIENT-PRINCIPAL-NAME": getTokenMail(token),
                "X-MS-CLIENT-PRINCIPAL-ID": getTokenAccountID(token),
                "Content-Type": "application/json"
            };
        } else {
            return {
                ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
                "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
                "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
                "Content-Type": "application/json"
            };
        }
    };
    const openFileHotlink = () => {
        let subpaths = location.pathname.split("/").filter(Boolean);
        if (subpaths.length > 3 && subpaths?.[2] === "library" && subpaths?.[3]) {
            const item = fileList.find(f => f.fileid == subpaths?.[3]);
            if (!item) return;
            setTimeout(() => {
                onShowCitation(`${item.name}#page=1_fid=${subpaths?.[3]}`, null, 0, undefined);
            }, 1300);
        }
    };
    const dealWithFilesForPDFViewers = (fls: Array<gfile>) => {
        if (fls && fls.length > 0) {
            const dFl = fls[0];
            const fileStrng = `${dFl.name}_page=${1}_fid=${dFl.fileid}`;
            setIsPDFModalOpen({
                defaultFile: fileStrng,
                startPage: "1",
                isOpen: false,
                searchString: "",
                contentlist: dFl?.contentlist || [],
                pagemap: dFl?.pagemap || {},
                fileId: dFl.fileid,
                refPageNum: "1",
                isCitation: false,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: fls,
                fl: dFl
            });
        } else {
            closeSBSWhenNoFiles();
            setIsPDFModalOpen({
                defaultFile: "",
                startPage: "1",
                isOpen: false,
                searchString: "",
                contentlist: [],
                pagemap: {},
                fileId: "",
                refPageNum: "1",
                isCitation: false,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: fls,
                fl: undefined
            });
        }
    };
    const closeSBSWhenScrenTooSmall = (dontSendToast?: boolean) => {
        if (window.innerWidth < 768) {
            if (config.showDocLibInChatTab == true) {
                if (dontSendToast != true)
                    toast.warning("Side-by-Side View for Chat and Documents is currently not supported on screens with less than 768px of width");

                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));

                return;
            }
        }
    };
    const closeSBSWhenNoFiles = () => {
        if (config.showDocLibInChatTab == true) {
            setConfig(prevConfig => ({
                ...prevConfig,
                showDocLibInChatTab: false
            }));
        }
        return;
    };
    const closeSBS = () => {
        if (config.showDocLibInChatTab == true) {
            setConfig(prevConfig => ({
                ...prevConfig,
                showDocLibInChatTab: false
            }));
        }
    };
    const uMenuEmpty = () => {
        const newAccountMenuItems = [
            {
                key: "Logout",
                text: "Logout",
                onClick: () => {
                    if (msalInstance) {
                        msalInstance
                            .logoutPopup({
                                postLogoutRedirectUri: "/", // redirects the top level app after logout
                                account: msalInstance.getActiveAccount(),
                                onRedirectNavigate: url => {
                                    return true;
                                }
                            })
                            .then(() => {
                                localStorage.removeItem("msalAuth4");
                                localStorage.removeItem("msalAuth4Tok");
                                if (isIframe && isIframe > 0) {
                                    window.location.reload();
                                } else {
                                    window.location.href = window.location.href;
                                }
                            });
                    }
                },
                iconProps: { iconName: "SignOut" }
            }
        ];
        setAccMenuItems(newAccountMenuItems);
    };
    const uMenu = (userObj: any, cFlatList: gcompflat[], homeComp: any) => {
        const newAccountMenuItems = [
            {
                key: "User",
                disabled: true,
                text: userObj.userMail,
                iconProps: { iconName: "Contact" }
            },
            {
                key: "DarkMode",
                disabled: false,
                text: "User Settings",

                iconProps: { iconName: "Settings" },
                onClick: () => {
                    setIsConfigPanelOpen(true);
                }
            },
            {
                key: "Logout",
                text: userObj.userMail.indexOf("quest_") > -1 ? "Create Account" : "Logout",
                onClick: () => {
                    if (msalInstance) {
                        msalInstance
                            .logoutPopup({
                                postLogoutRedirectUri: "/", // redirects the top level app after logout
                                account: msalInstance.getActiveAccount(),
                                onRedirectNavigate: url => {
                                    return true;
                                }
                            })
                            .then(() => {
                                localStorage.removeItem("msalAuth4");
                                localStorage.removeItem("msalAuth4Tok");
                                if (isIframe && isIframe > 0) {
                                    window.location.reload();
                                } else {
                                    window.location.href = window.location.href;
                                }
                            });
                    }
                },
                iconProps: { iconName: "SignOut" }
            }
        ];
        try {
            if (cFlatList && cFlatList.length > 0 && cFlatList.some(a => a.permissionlevel >= 3 && a.companyid === homeComp.companyid) && isIframe == 0) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageUsers",
                    text: "Administrative",
                    onClick: () => {
                        openUserList();
                    },
                    iconProps: { iconName: "Admin" }
                });
            }
            if (userObj.userMail.indexOf("quest_") == -1 && isIframe == 0 && activeComp.licensingkind == 0) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageAccount",
                    text: "Manage Account",
                    onClick: () => {
                        navigate(`${activeComp.companyid}/acc`);
                    },
                    iconProps: { iconName: "AccountManagement" }
                });
            }
            if (cFlatList && cFlatList.length > 1 && isIframe == 0) {
                newAccountMenuItems.splice(1, 0, {
                    key: "ManageCompanies",
                    text: homeComp.companyname,
                    onClick: () => {
                        setIsCompListModalOpen(true);
                    },
                    iconProps: { iconName: "Group" }
                });
            }
        } catch (err) {
            import.meta.env.DEV === true && console.log("u_menu_errr", err);
        }
        setAccMenuItems(newAccountMenuItems);
    };
    const openUserList = () => {
        getAllUsers().then((users: any) => navigate(`${activeComp.companyid}/adm`));
    };
    const applyLocalConfig = () => {
        const configObj = localStorage.getItem("configObj6");
        if (configObj) {
            let configParsed = JSON.parse(configObj);
            configParsed.showDocLibInChatTab = false;
            handleIsDark(configParsed.isDark);
            setConfig(configParsed);
            closeSBSWhenScrenTooSmall();
        } else {
            let configDefault = JSON.stringify(config);
            localStorage.setItem("configObj6", configDefault);
            closeSBSWhenScrenTooSmall();
        }
    };
    const preserveLocalConfig = () => {
        let configString = JSON.stringify(config);
        localStorage.setItem("configObj6", configString);
    };
    const setAnswersFunction = (histObj: any, setAnswersLength?: boolean) => {
        if (setAnswersLength == true) answersLength.current = (histObj ?? []).length;
        console.log("histObj", histObj);
        setAnswers(histObj);
    };
    const histGetByKey = async (userid: string, key: string) => {
        try {
            let keyConvo = await qHistGetByKey(userid, key, getHeader());
            const histObj = JSON.parse(keyConvo[0].content);
            setActiveConvoKey(keyConvo[0].key); // Set the active conversation key to the latest key (Datetime string)
            setAnswersFunction(histObj, true);
            setStreamedAnswers(histObj);
            setLoadingChatModeSwitch(false);
        } catch {
            setLoadingChatModeSwitch(false);
        }
    };
    const histGetRecent = async (userid: string, company: string) => {
        try {
            let recentConvosUserWide = await qHistGetRecent(userid, company, getHeader());
            setRecentConvosAccrosSpaces(recentConvosUserWide);
        } catch (err) {
            import.meta.env.DEV === true && console.log("errr_recent_convos", err);
        }
    };
    const getSuggestedQuestions = async (fLst: gfile[]) => {
        try {
            if (fLst.length == 0) return setSuggestedQuestions([]);
            let suggestedQuestions = await qGetSuggestedQuestions(fLst, getHeader());
            setSuggestedQuestions(suggestedQuestions);
        } catch {}
    };
    const getTiers = async () => {
        let tiers = await qGetTiers(getHeader());
        setLTiers(tiers);
    };
    const histGetList = async (userid: string, groupid: string, companyid: string, topn: number) => {
        let convos = await qHistGetList(userid, groupid, companyid, topn, getHeader());
        setConvos(convos);
    };
    const histAdd = async (key: number, userid: string, groupid: string, companyid: string, name: string, aa?: any) => {
        qHistAdd(key, userid, groupid, companyid, name, getHeader(), aa || answers);
    };
    const newConverson = () => {
        setActiveConvoKey(genNewConvoKey());
        setAnswersFunction([], true);
        setStreamedAnswers([]);
        setError(undefined);
        console.log("☆new_convo☆");
        window.global_expected_next_user_response_type = "greeting";
        window.global_convo_collected = {};

        window.global_convo_last_speech_finish = newConvoStamp();
        window.global_convo_last_response_finish = newConvoStamp();

        window.global_is_tracking_silence = false;
        window.global_run_on_sentence = {
            segments: [],
            recognized: []
        };
        window.global_convo_extr_conf = new Map<string, string[]>();
    };
    const setConversation = (dateString: string) => {
        setLoadingChatModeSwitch(true);
        initialLoadComleted == true && newConverson();
        histGetByKey(user.userId, dateString).then(() => setLoadingChatModeSwitch(false));
        setActiveConvoKey(dateString);
        setIsLeftPanelOpen(false);
    };
    //  qFuncs ¦ compList ⇒ Get List of Companies
    const getCompFlatList = async () => {
        const token = await getTokenDyn();
        if (!token) return [];
        let compList = await qGetCompFlatList(getHeader(token));
        setCompFlatList(compList);
        return compList;
    };
    // CompMbrshps ⇒ Space Memberships to Show in Space Edit (qGetGroupsMemberships, qGetCompanyUsers) [Per Comp]
    const getGroupsMemberships = async () => {
        let mbrshpsResp = await qGetGroupsMemberships(activeComp.companyid, getHeader());
        mbrshpsResp ? setCompMbrshps(mbrshpsResp) : setCompMbrshps([]);
        return mbrshpsResp;
    };
    // CompUsrs ⇒ User Picker Suggestions  [Per Comp]
    const getCompanyUsers = async () => {
        let compUsers = await qGetCompanyUsers(activeComp.companyid, getHeader());
        setCompUsrs(compUsers);
        return compUsers;
    };
    // AllUsrs ⇒ Company Users List (Owner Panel)[Gets all For User - Filters down t Comp Levl in List]
    const getAllUsers = async () => {
        const token = await getTokenDyn();
        let allUsers = await qGetAllUsers(getHeader(token));
        setAllUsrs(allUsers);
        return allUsers;
    };
    // groupList ⇒ Spaces List + Permissions Level [Per Comp]
    const getGroups = async (cmp?: string) => {
        let grpsresp = await qGetGroups(cmp ?? activeComp.companyid, getHeader());
        for (let i = 0; i < grpsresp.length; i++) {
            if (grpsresp[i].spo_config) grpsresp[i].spo_config = JSON.parse(grpsresp[i].spo_config);
            if (grpsresp[i].convo_config) grpsresp[i].convo_config = JSON.parse(grpsresp[i].convo_config);

            if (grpsresp[i].contenttype_config) grpsresp[i].contenttype_config = JSON.parse(grpsresp[i].contenttype_config || initialExtrConfigString);
        }
        if (grpsresp) setGroupList(grpsresp);
        return grpsresp;
    };
    // qGetUSRINF ⇒ User + Default Company
    const qGetUserInfo = async () => {
        const token = await getTokenDyn();
        if (!token) return null;
        let redeemResult = await redeemCode(getTokenMail(token));
        setLoggedInUser({
            userId: getTokenAccountID(token),
            mail: getTokenMail(token),
            token: token.accessToken
        });
        const response = await fetch("/qGetUserInfo", {
            headers: {
                ...getHeader(token),
                ...{ kgnfhsh: window.location.hash?.replace(/[^a-zA-Z0-9]/g, " ") }
            }
        });
        if (!response.ok) console.log("errr_usr", response);
        let userRes = await response.json();
        setUser({
            userDisplayName: userRes.userDisplayName,
            userId: userRes.userId,
            userMail: userRes.userMail,
            companyid: userRes.companyid,
            name: (token.idTokenClaims as any).name,
            tier: userRes.tier,
            subscriptionid: userRes.subscriptionid,
            status: userRes.status
        });
        return userRes;
    };
    const handleConvoActiveSentence = (txt: string, direct?: boolean, askResponse?: any) => {
        try {
            if (direct == true) {
                convActvSentnce.current = {
                    answerid: askResponse ? askResponse?.interaction_guid : "",
                    text: txt,
                    convoid: window.globalConvoKey,
                    chunkid: getChunkRandomId()
                };
            } else {
                convChunksStr.current = `${convChunksStr.current}${txt}`;

                // REMOVE INTEGRATED DATA TAGS [doc123
                convChunksStr.current = removeRefTagsClosedOnly(convChunksStr.current);

                // CHECK IF THE SENTENCE HAS A SENTENCE ENDER OR TAG
                if (!hasSentenceEnder(convChunksStr.current) || convChunksStr.current.indexOf("[doc") > -1) return;

                //JOIN ALL CHUNKS UP TO NOW, SPLIT TO SENTENCES AND JOINT SUB 5 CHAR SENTENCES
                let sentencesArr = joinShortSentences(splitIntoSentences(convChunksStr.current) || []);

                // IF NO SENTENCES, RETURN
                if (sentencesArr.length == 0) return;

                // IF THE FIRST SENTENCE DOES NOT END WITH A SENTENCE ENDER, RETURN
                if (/[.!?:]$/.test(sentencesArr[0].trim()) == false) return;

                convActvSentnce.current = {
                    answerid: askResponse ? askResponse?.interaction_guid : "",
                    text: sentencesArr[0].replace("*", ""),
                    convoid: window.globalConvoKey,
                    chunkid: getChunkRandomId()
                }; // ACTIVE SENTENCE

                sentencesArr.shift(); // Remove First Item
                convChunksStr.current = sentencesArr.join("");
            }
        } catch (e) {
            console.error("errr_convo_active_sentence", e);
        }
    };

    const sendMessage = useCallback(
        (question: string, q_seq_id?: number, current_intent?: string, ignore_discard?: boolean): void => {
            makeApiRequest(question, q_seq_id, current_intent, ignore_discard);
        },
        [isConvoModeOn]
    );

    const makeApiRequest = async (
        question: string,
        q_seq_id?: number,
        current_intent?: string,
        ignore_discard?: boolean,
        additional_string_data?: string,
        msgovrride?: string
    ) => {
        window.globalIsCancellingMidway = false;
        if ((selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0)) {
            toast.warning(msgLmtHit);
            return;
        }
        if (isConvoModeOn == true) {
            // Manage Audio
            convChunksStr.current = "";
            convActvSentnce.current = emptyaudiosentence;

            // Prepend Previous Messages
            convoRunOnInputMessage.current.push({ message: question, convostamp: window.convoPerfOnRecogFin });
            question = convoRunOnInputMessage.current.map(a => a.message).join(" ");
        }
        if (isStreaming) return;
        lastQuestionRef.current = question;
        error && setError(undefined);

        setIsLoading(true);
        const dynFiltering = determineCategoryAndGroups(selectedGroup, includeCategory, includeCategoryGroups);
        const ovrrds = get_ovrrds(
            selectedGroup,
            config,
            user,
            activeComp,
            activeConvoKey,
            dynFiltering,
            additional_string_data || "",
            current_intent || "",
            msgovrride || ""
        );

        try {
            let inplaceupdate = false;
            if (answers.length > 0 && answers[answers.length - 1][1].discard == true) inplaceupdate = true;
            const request = create_chatapprequest(question, answers, ovrrds, inplaceupdate);

            console.log("◀req▶", request);

            let response;
            scrollChatToEnd();
            setLoadingMsg("Sending...");
            window.global_convo_last_response_start = newConvoStamp();

            response = await chatApi(request, loggedInUser);

            setIsSpeechPaused(null);
            if (!response.body) throw Error("no_response_body");
            if (config.shouldStream) {
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, response.body, inplaceupdate, ignore_discard);

                parsedResponse.pos = null;
                parsedResponse.use_integrated_search = true;

                // Convo Mode, handle conversational cadance minutiae by alowing answers to be discarded
                if (inplaceupdate == true) {
                    // Break cycle of discard, by setting the current replacement to discard false
                    if (parsedResponse.discard != true) {
                        setStreamedAnswers(prevAnswers =>
                            prevAnswers.length > 0 ? [...answers.slice(0, -1), [question, parsedResponse]] : [[question, parsedResponse]]
                        );
                        setAnswers(prevAnswers =>
                            prevAnswers.length > 0 ? [...answers.slice(0, -1), [question, parsedResponse]] : [[question, parsedResponse]]
                        );
                    }
                    //parsedResponse.discard = false;
                } else {
                    if (parsedResponse.discard != true) {
                        setAnswersFunction([...answers, [question, parsedResponse]], false);
                        //setAnswers(prevAnswers => [...prevAnswers, [question, parsedResponse]]);
                    } else {
                        console.log("▢‾discard‾▢", parsedResponse);
                    }
                }
            } else {
                let parsedResult = await handleNonStreamChat(response, question, answers);
                setAnswersFunction([...answers, [parsedResult.question, parsedResult.parsedResponse as ChatAppResponse]], false);
            }
        } catch (e) {
            setError(e);
            setIsStreaming(false);
            console.log("err_chat_091", e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAsyncRequest = async (
        question: string,
        answers: [string, ChatAppResponse][],
        responseBody: ReadableStream<any>,
        inplaceupdate: boolean,
        ignore_discard?: boolean
    ) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;
        window.globalIsCancellingMidway = false;

        const updateState = (newContent: string, direct?: boolean, inplaceupdate?: boolean) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    answer += newContent;
                    isConvoModeOn == true && handleConvoActiveSentence(newContent, direct, askResponse);
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };

                    if (inplaceupdate == true) {
                        setStreamedAnswers(prevAnswers =>
                            prevAnswers.length > 0 ? [...answers.slice(0, -1), [question, latestResponse]] : [[question, latestResponse]]
                        );
                    } else {
                        setStreamedAnswers([...answers, [question, latestResponse]]);
                    }
                    resolve(null);
                }, 10);
            });
        };
        let convoDiscard = false;
        try {
            setIsStreaming(true);

            for await (const event of readNDJSONStream(responseBody)) {
                if ((window.globalIsCancellingMidway as any) == true) break;
                if (isConvoModeOn == true) {
                    // If answer discard flag is true
                    if (event?.discard == true) {
                        // If speech recog asigned ignore_discard is not true, discard the response

                        if (ignore_discard != true) {
                            if (answers.length > 0) {
                                console.log("⊝‾discard‾⊝");
                                askResponse.discard = true;
                                convoDiscard = true;
                                set_convo_discard_stamp(newConvoStamp());
                                break;
                            }
                        } else {
                            // Discard is true but ignore_discard is also true, so we will not discard the response
                            askResponse.discard = false;
                            convoDiscard = false;
                        }
                    } else if (event?.discard == false) {
                        askResponse.discard = false;
                        convoDiscard = false;
                        console.log("discard_false", event);
                    }
                    if (selectedGroup.assistanttype == assistantCodes.cca) {
                        if (event?.expected_next_user_response_type && event.expected_next_user_response_type != "") {
                            console.log("expected_response_type", event.expected_next_user_response_type);

                            askResponse.expected_next_user_response_type = event.expected_next_user_response_type;
                            window.global_expected_next_user_response_type = event.expected_next_user_response_type;

                            set_expected_next_user_response_type(event.expected_next_user_response_type);
                        }
                        if (event?.active_scenario_id && event.active_scenario_id != "") {
                            askResponse.active_scenario_id = event.active_scenario_id;
                            window.global_active_scenario_id = event.active_scenario_id;
                        }
                    }
                }
                if (event?.choices?.[0]?.context?.data_points_integrated?.citations) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event;
                    if (event["direct"]) await updateState(event["choices"][0]["delta"]["content"], true, inplaceupdate);
                } else if (event?.choices?.[0]?.delta?.content) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"], false, inplaceupdate);
                } else if (event?.choices?.[0]?.context) {
                    try {
                        if (!askResponse?.choices) askResponse = event;
                        askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                    } catch (e) {
                        console.error("errr_e_0909", event, "ares", askResponse, e);
                    }
                } else if (event.choices?.[0]?.["message"]?.["content"]) {
                    await updateState(event["choices"][0]["message"]["content"], false, inplaceupdate);
                } else if (event["loading_msg"]) {
                    setLoadingMsg(event["loading_msg"]);
                } else if (event["error"]) {
                    if ((window.globalIsCancellingMidway as any) == true) {
                        import.meta.env.DEV === true && console.log("¦cancelling_midway_01¦");
                    } else throw Error(event["error"]);
                }
            }
        } catch (e) {
            console.error("errr_hndl_cht", e);
        }

        logResponseTiming();

        // Convo - add dot so the last sentence can be routed to speech
        if (isConvoModeOn == true && !endsWithPunctuation(answer)) {
            console.log("▢end_no_punct▢");
            handleConvoActiveSentence(".");
        }
        // post_answer convo_discard management
        if (isConvoModeOn == true) {
            if (convoDiscard != true) {
                // Last Level Cache - keep the message until not-discarded response is received.
                convoRunOnInputMessage.current = convoRunOnInputMessage.current.filter((a: any) => a.convostamp > (askResponse.convostamp || 0));

                // ConvoStamp is the convoPerfOnRecogFin stamp of the recog result that made it to backend and prompted the ai response. Recogs after are not yet shared with backend.
                // CadanceStamp gets updated only on not-discarded responses. It is used here to shear off last level prompt recog cache and by the recgnizer to manage immediate prompt recog cache.
                console.log("cadance_stamp", window.global_convo_cadance_stamp, askResponse.convostamp);
                window.global_convo_last_response_finish = newConvoStamp();
                window.global_convo_cadance_stamp = askResponse.convostamp || 0;
            }
        }
        if (selectedGroup.assistanttype == assistantCodes.cca) {
            answer = cca_replacements(answer);
            convChunksStr.current = cca_replacements(convChunksStr.current);
        }
        let fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };
        setIsStreaming(false);
        preserve_convo_collected_data(askResponse);

        console.log("fullResponse", fullResponse);
        return fullResponse;
    };

    const logResponseTiming = () => {
        let perf_recoging_milli = Math.floor(Date.now()) - window.globalConvPerfRecog;
        let perf_final_milli = Math.floor(Date.now()) - window.convoPerfOnRecogFin;
        console.log("▢ Recog_Update ⇔ Response ▢", perf_recoging_milli / 1000);
        console.log("▢ Recog_Final  ⇔ Response ▢", perf_final_milli / 1000);
    };

    const cca_replacements = (txt: string) => {
        if (selectedGroup.assistanttype == assistantCodes.cca) {
            txt = txt.replace("салва ", "св ").replace("сава ", "св ");
            const regex = /са в (?=\d\s?\d?\s?\d)/g;
            txt = txt.replace(regex, "св ");
        }
        return txt;
    };

    const scrollChatToEnd = () => {
        if (xplrrMsgStrm.current) {
            const element = xplrrMsgStrm.current;
            element.scrollTop = selectedGroup?.assistanttype == assistantCodes.prd ? 0 : element.scrollHeight;
        }
    };

    const getGroupLastModifiedFileDate = async (groupid: string) => {
        let modresp = await qGetGroupLastModifiedFileDate(groupid, getHeader());
        return modresp;
    };
    const qGetSpaceFiles = async (preWipe?: boolean, pullCacheFirst?: boolean) => {
        if (pullCacheFirst == true) {
            import.meta.env.DEV === true && console.log("init_load_true");
            let flCache = JSON.parse(localStorage.getItem(`kgnf_chs_${selectedGroup.oid}`) ?? "[]");
            if (flCache.length > 0) {
                setFileCount(flCache?.length || 0);
                setFileList(flCache);
                setInitLoad(false);
                let latestModified = getLatestModifiedFile(flCache)?.modified;
                if (latestModified) {
                    getGroupLastModifiedFileDate(selectedGroup.selectionId).then(lastMod => {
                        if (lastMod.length > 0 && lastMod[0]?.updated && latestModified && lastMod[0]?.updated == latestModified) {
                            import.meta.env.DEV === true && console.log("no_cache_updates");
                        } else {
                            qGetSpaceFiles();
                        }
                    });
                } else {
                    qGetSpaceFiles();
                }
                getSuggestedQuestions(flCache);
                return flCache;
            }
        }
        if (preWipe == true) setFileList([]);
        const params = new URLSearchParams({
            groupid: selectedGroup.selectionId,
            isinvoicegroup: selectedGroup.invoicesgroup == 1 ? "true" : "false",
            companyid: activeComp.companyid
        });
        const url = "/qGetSpaceFiles" + `?${params.toString()}`;
        const response = await fetch(url, { headers: getHeader() });
        if (!response.ok) {
            setInitLoad(false);
            throw Error("QGETFLS¦Failed to Get User Files");
        }
        let fileListResp = await response.json();
        import.meta.env.DEV === true && console.log("fls¦", fileListResp);
        setFileCount(fileListResp?.length || 0);
        setFileList(fileListResp);
        setInitLoad(false);
        getSuggestedQuestions(fileListResp);
        return fileListResp;
    };
    const getChatLog = async () => {
        let sChatLog: gChatlogItem[] = await qGetChatLog(selectedGroup.selectionId, getHeader());
        setSpaceChatLog(sChatLog);
        return sChatLog;
    };
    const getLegacySearchString = (pageRef: string, index: number) => {
        let searchString;
        try {
            searchString = streamedAnswers?.[index]?.[1]["choices"][0]["context"]["data_points"].find(el => el.startsWith(pageRef));
        } catch {
            let directSearchRes = answers?.[index]?.[1]["choices"][0]["context"]["data_points"] as any;
            searchString = directSearchRes.data_points.find((el: string) => el.startsWith(pageRef));
        }
        searchString = searchString?.replace(pageRef, "").substring(1)?.trim();
        return searchString;
    };
    const onShowCitation = (fileP: string, integratedCitation: string | null, index: number, openSBS?: boolean) => {
        if (!fileP) return;

        fileP = cleanFileIDString(fileP);
        let startPage = extractPageValue(fileP);
        let fid = extractFileIDValue(fileP);
        let pageRef = fileP.replace("/content/", "");

        let srchstr = integratedCitation == null ? getLegacySearchString(pageRef, index) : integratedCitation;
        let isOpenBool = config.showDocLibInChatTab == true ? false : true;

        let citFl = window.globalFL.filter((file: gfile) => file.fileid == fid);

        closeSBSWhenScrenTooSmall(true);
        if (citFl.length == 0) {
            toast.warn("File Not Found in Library");
        } else {
            setIsPDFModalOpen({
                defaultFile: fileP.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                startPage: startPage,
                isOpen: true,
                searchString: srchstr ? srchstr : "",
                contentlist: citFl[0].contentlist || [],
                pagemap: citFl[0].pagemap || {},
                fileId: fid,
                refPageNum: startPage,
                isCitation: true,
                rndo: "",
                activeGroupId: selectedGroup.selectionId,
                fileList: window.globalFL,
                fl: citFl[0]
            });
        }
    };
    const onCompareClicked = (file1: string, file2: string, diff: gdiffresponse[]) => {
        if (!file1 || !file2) return;

        file1 = cleanFileIDString(file1);
        file2 = cleanFileIDString(file2);
        let fid1 = extractFileIDValue(file1);
        let fid2 = extractFileIDValue(file2);
        let citFl1 = window.globalFL.filter((file: gfile) => file.fileid == fid1);
        let citFl2 = window.globalFL.filter((file: gfile) => file.fileid == fid2);

        closeSBSWhenScrenTooSmall(true);

        let startPage = extractPageValue(file1);

        if (citFl1?.length == 0 || citFl1?.length == 0) {
            toast.warn("File Not Found in Library");
        } else {
            setIsPDFDiffModalOpen({
                defaultFile1: file1.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                defaultFile2: file2.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                isOpen: true,
                searchString: "",
                fileId1: fid1,
                fileId2: fid2,
                refPageNum: startPage,
                isCitation: false,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: window.globalFL,
                fl1: citFl1[0],
                fl2: citFl2[0],
                diff: diff
            });
        }
    };
    const onShowCitationXplrr = (fileP: string, integratedCitation: string | null, index: number, openSBS?: boolean) => {
        setXplrrChatVisible(false);
        if (!fileP || isPDFModalOpen.isOpen === true) return;

        let startPage = extractPageValue(fileP);
        let fleid = extractFileIDValue(fileP);
        let pageRef = fileP.replace("/content/", "");

        let srchstr;
        if (integratedCitation == null) {
            srchstr = getLegacySearchString(pageRef, index);
        } else {
            srchstr = integratedCitation;
        }
        let citFl = window.globalFL.filter((file: gfile) => file.fileid == fleid);
        if (citFl.length == 0) {
            toast.warn("File Not Found in Library");
        } else {
            setIsPDFModalOpen({
                defaultFile: fileP.replace("/content/", "").replace("#fid", "_fid").replace("#page", "_page"),
                startPage: startPage,
                isOpen: false,
                searchString: srchstr ? srchstr : "",
                contentlist: citFl[0].contentlist || [],
                pagemap: citFl[0].pagemap || {},
                fileId: fleid,
                refPageNum: startPage,
                isCitation: true,
                rndo: Math.random().toString(36).substring(2, 6),
                activeGroupId: selectedGroup.selectionId,
                fileList: window.globalFL,
                fl: citFl[0]
            });
        }
    };
    const manageCitationOpen = (filePath: string, integratedCitation: string | null, index: number, el: HTMLElement | null, openSBS?: boolean) => {
        if (clickedCitation) {
            removeCitationActive();
            applyCitationActive(el?.id);
        }
        if (openSBS == true) {
            if (config.showDocLibInChatTab == false) {
                if (window.innerWidth < 768) {
                    onShowCitation(filePath, integratedCitation, index, openSBS);
                } else {
                    setConfig(prevConfig => ({
                        ...prevConfig,
                        showDocLibInChatTab: true
                    }));
                    setTimeout(() => {
                        onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
                        if (clickedCitation?.current) {
                            setTimeout(() => {
                                applyCitationActive(clickedCitation.current);
                            }, 1000);
                        }
                    }, 50);
                }
            } else {
                onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
            }
        } else if (openSBS == false) {
            if (config.showDocLibInChatTab == true) {
                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));
                setTimeout(() => {
                    onShowCitation(filePath, integratedCitation, index, openSBS);
                    if (clickedCitation?.current) {
                        setTimeout(() => {
                            applyCitationActive(clickedCitation.current);
                        }, 1000);
                    }
                }, 50);
            } else {
                onShowCitation(filePath, integratedCitation, index, openSBS);
            }
        } else {
            if (config.showDocLibInChatTab == true) {
                onShowCitationXplrr(filePath, integratedCitation, index, openSBS);
            } else {
                onShowCitation(filePath, integratedCitation, index, openSBS);
            }
        }
    };
    // Callback Func to Handle Space Update for Files
    const handleSelectionChange = (newSelection: { name: string; groupIds: string; groupNames: string }[]) => {
        setIncludeCategory(newSelection.map(item => item.name).join(";"));
        setIncludeCategoryGroups([...new Set(newSelection.map(item => item.groupIds))].join(";")); // Get Unique Group IDs for Selected Files
        //qGetFiles();
    };
    const handleSpaceChange = (selectionId: string, selectionText: string, permissionlevel: number, filecount: number, description: string, aCK?: string) => {
        setAnswersFunction([], true);
        setStreamedAnswers([]);
        aCK ? navigate(`/${activeComp.companyid}/${selectionId}/chat/${aCK}`) : navigate(`/${activeComp.companyid}/${selectionId}/chat`);
    };
    const addSpace = async (nGroup: gnewgroup) => {
        if (nGroup.id == "") return null;

        let owners = [{ userid: user.userId, username: user.userDisplayName, level: 3, mail: user.userMail, isnew: false }];

        const groupname = nGroup.groupname;
        const groupid = nGroup.id;
        const groupdescription = nGroup.description;
        const appid = nGroup.appid;
        const scrt = nGroup.scrt;
        const specializedSettings = nGroup.specializedSettings;
        if (!groupname) {
            setIsSpaceEditModalOpen(false);
            return null;
        }
        fetch("/qAddGroup", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                groupid: groupid,
                groupname: groupname,
                companyid: activeComp.companyid,
                description: groupdescription,
                sharewithcode: 0,
                custaiinst: defaultPromptTemplateAsInPythonBackend,
                custgreetingmessage: defaultGreetingsMessage,
                isintegrateddata: true,
                appid: appid,
                scrt: scrt,
                isnew: true,
                ...specializedSettings
            })
        })
            .then(Response => import.meta.env.DEV === true && console.log("sps_add"))
            .catch(error => console.error("Error:", error));

        fetch("/qAddMembership", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                usersArr: owners,
                groupId: groupid,
                companyid: activeComp.companyid
            })
        })
            .then(Response => {
                getGroups().then((grpsresp: any) => {
                    getGroupsMemberships();
                    setNoGroupsShared(false);
                    toast.success("New Space Created");
                    setTimeout(() => {
                        let pageElement = document.querySelector(`#${makeValidHtmlId(groupid)}`);
                        if (pageElement) {
                            smoothScrollIntoView(pageElement, { behavior: "smooth" });
                            setNewSpacePyro(nGroup.id);
                            setTimeout(() => {
                                setNewSpacePyro("");
                            }, 5000);
                        }
                    }, 1);
                });
            })
            .catch(error => console.error("error:", error));
        setIsSpaceModalOpen(false);
        return null;
    };
    const userMngmntUpdate = (timestamp: string) => {
        getCompanyUsers().then(() => {
            getGroupsMemberships().then(() => {
                getCompFlatList().then(() => getAllUsers());
            });
        });
    };
    const handleCompChange = (activeComp: gcompflat) => {
        navigate(`/${activeComp.companyid}`);
        setIsCompListModalOpen(false);
    };
    const handleGroupListClick = (group: { groupid: string; groupname: string; permissionlevel: number; filecount: number }) => {
        navigate(`/${activeComp.companyid}/${group.groupid}/chat`);
    };
    const handleLeftSpacePanelClick = (item: any, aCK?: string) => {
        setIsSpacePanelOpen(false);
        if (aCK) {
            setLoadingChatModeSwitch(true);
            if (config.showDocLibInChatTab == true) {
                setConfig(prevConfig => ({
                    ...prevConfig,
                    showDocLibInChatTab: false
                }));
            }
        }
        handleSpaceChange(item.groupid, item.groupname, item.permissionlevel, item.filecount, item.description, aCK);
    };
    // This is user click not citation change
    const xplrrCitationChangeCallback = (callBackProps: gXplrrCitationChangeCallback) => {
        const fileFullPath: string = fileList.filter((file: gfile) => file.fileid == callBackProps.fileid)[0].full_path;
        const citationFullPath = fileFullPath.split("/content/").slice(-1) + "#page=" + callBackProps.destPageNum + "_fid=" + callBackProps.fileid;
        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            isOpen: false,
            defaultFile: citationFullPath,
            startPage: callBackProps.destPageNum,
            searchString: "",
            fileId: callBackProps.fileid,
            isCitation: callBackProps.isCitation
        }));
    };
    const toggleChatWithDocs = () => {
        if (window.innerWidth < 768) {
            toast.warning("Side-by-Side View for Chat and Documents is currently not supported on screens with less than 768px of width");
            return;
        }
        let valNow = true;
        if (config.showDocLibInChatTab == true) valNow = false;
        setConfig(prevConfig => ({
            ...prevConfig,
            showDocLibInChatTab: !!valNow
        }));
    };
    const handleIsCancellingMidway = () => {
        setIsCancellingMidway(true);
        window.globalIsCancellingMidway = true;
    };
    const onEditSpaceSave = () => {
        getGroups().then((grpsresp: any) => {
            const gr = grpsresp.filter((group: any) => group.groupid === selectedGroup.selectionId)[0];

            setSelectedGroup(groupListToSelectedGroup(gr));
            toast.success("Space Configuration Saved");
        });
    };
    const onGroupDel = () => {
        toast.info("Space Deletion in Progress..");
        navigate(`/${activeComp.companyid}`);
    };
    const handlePivotItemClick = (item: any) => {
        if (item.props.itemKey == "back") {
            setChatTab();
            return;
        }
    };
    const handleAdmPivotItemClick = (item: any) => {
        if (item.props.itemKey == "back") {
            setGrpTab();
            return;
        }
    };
    const inviteUser = async (usr: string) => {
        const response = qAddMember(usr, selectedGroup.selectionId, activeComp.companyid, getHeader());
        userMngmntUpdate(new Date().toISOString());
        setIsShareCalloutVisible(!isShareCalloutVisible);
        toast.success("User Added to Space");
    };
    const onNewGroupClick = () => {
        setIsSpaceModalOpen(true);
        setIsNewSpaceInfoBip(false);
    };
    const histItemHandleEdit = (key: string, newText: string) => {
        const toastId = toast("Changing Conversation Name...", { autoClose: false });
        qHistEditName(key, user.userId, newText, getHeader()).then(() => {
            toast.update(toastId, { render: "Conversation Name Changed", autoClose: 1000 });
        });
    };
    const histItemHandleDelete = (key: string) => {
        const toastId = toast("Deleting Conversation...", { autoClose: false });
        qHistDelete(key, user.userId, getHeader()).then(Response => {
            histGetList(user.userId, selectedGroup.selectionId, activeComp.companyid, 5);
            toast.update(toastId, { render: "Conversation Deleted", autoClose: 1000 });
        });
    };
    const onXplrrPDFDismiss = () => {
        window.globalBase64String = "";
        stopWigglePixel("stopWigglePixel_chat1");
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            isOpen: false,
            rndo: ""
        }));
    };
    const onXplrrDiffPDFDismiss = () => {
        window.globalBase64String = "";
        setShouldPlayAudio(Math.random().toString(36).substring(2, 6));
        setIsPDFDiffModalOpen(prevConfig => ({
            ...prevConfig,
            isOpen: false,
            rndo: ""
        }));
    };
    const removeCitationActive = () => {
        let prevEl = document.getElementById(clickedCitation.current || "placeholder_id");
        prevEl?.classList.remove("activeCitFlash");
    };
    const applyCitationActive = (elId: string | undefined) => {
        let obtainCitationElement = document.getElementById(elId || "placeholder_id");
        obtainCitationElement?.classList.add("activeCitFlash");
        clickedCitation.current = obtainCitationElement?.id;
    };
    const onDataHighlight = (searchString: any) => {
        setIsPDFModalOpen(prevConfig => ({
            ...prevConfig,
            searchString: searchString,
            rndo: Math.random().toString(36).substring(2, 6)
        }));
    };
    const onSendFeedback = (prps: gfeedback) => {
        fetch("/qFeedback", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify(prps)
        }).then(Response => toast.success("Feedback Sent"));
        setAnswers(prevAnswers =>
            prevAnswers.map(([user, response]) =>
                response.interaction_guid === prps.interaction_guid ? [user, { ...response, pos: prps.pos }] : [user, response]
            )
        );
        let answrs = answers.map(([user, response]) =>
            response.interaction_guid === prps.interaction_guid ? [user, { ...response, pos: prps.pos }] : [user, response]
        );
        setTimeout(() => {
            histAdd(parseInt(activeConvoKey), user.userId, selectedGroup.selectionId, activeComp.companyid, answers[0][0], answrs);
        }, 2000);
    };
    const createSuggestedQuestionsHTML = () => {
        let defaultContent = selectedGroup.filecount > 0 ? "" : selectedGroup.permissionlevel >= 2 ? defaultGreetingContent : noContentNoAccessGreetingContent;
        const allQuestions = (suggestedQuestions || [])
            .map((q: any) => q.suggestedquestions)
            .join(";")
            .split(";")
            .filter((question: string) => question !== "" && question != null && question !== undefined);
        if (allQuestions && allQuestions.length > 0 && selectedGroup?.showexamplequestionsonstart == 1) {
            let selectedQuestions = shuffleArray([...allQuestions]).slice(0, 3);
            if (selectedGroup.manualquestionsuggestions != "") {
                selectedQuestions = [...selectedGroup.manualquestionsuggestions.split(";"), ...selectedQuestions];
            }
            const html = selectedQuestions.map(question =>
                answerCardHTML
                    .replace(/{kgnf_replace}/g, encodeHTML(question))
                    .replace(selectedGroup.assistanttype == assistantCodes.cca ? ">Suggested Question<" : "‾‾‾ ‾", "> Start a Call <")
            );
            return `<div horizontal wrap tokens=5 class='answerCardContainer'>${html.join("")}</div>`;
        } else if (selectedGroup.manualquestionsuggestions != "") {
            const html = selectedGroup.manualquestionsuggestions
                .split(";")
                .map(question =>
                    answerCardHTML
                        .replace(/{kgnf_replace}/g, encodeHTML(question))
                        .replace(selectedGroup.assistanttype == assistantCodes.cca ? ">Suggested Question<" : "‾‾‾ ‾", "> Start a Call <")
                );
            return `<div horizontal wrap tokens=5 class='answerCardContainer'>${html.join("")}</div>`;
        } else {
            return defaultContent;
        }
    };
    const getFieldExtractionList = () => {
        try {
            return selectedGroup?.extractionfieldlist ? JSON.parse(selectedGroup?.extractionfieldlist || "{excl:[],instr:[]}") : { excl: [], instr: [] };
        } catch (e) {
            console.log("errr_getFieldExtractionList", e);
            return { excl: [], instr: [] };
        }
    };
    const getGroupList = () => {
        return groupList && groupList.length > 0
            ? groupList.map(group => ({
                  selectedGroup: groupListToSelectedGroup(group)({} as gselectedgroup),
                  handleGroupListClick: (groupid: string, groupname: string, permissionlevel: number) => {
                      handleGroupListClick({
                          groupid: groupid,
                          groupname: groupname,
                          permissionlevel: permissionlevel,
                          filecount: group.filecount
                      });
                  }
              }))
            : [];
    };
    const switchXplrrModuleVisibility = () => {
        if (xplrrChatVisible == false) {
            setXplrrChatVisible(true);
            setIsBlurredFull(true);
        }
    };

    const renderConvoTempNonAnswer = (index: number, answr: [user: string, response: ChatAppResponse], totalAnswers: number) => {
        return (
            <div key={index}>
                <div className={`${isConvoModeOn && index == totalAnswers - 1 && styles.userMsgSpkr}`}>
                    <UserChatMessage message={answr[0]} />
                </div>
                <div className={styles.chatMessageGptMinWidth}>
                    <AnswerLoading loadingMsg={loadingMsg} shouldPulse={isConvoModeOn} totalAnswers={totalAnswers} />
                </div>
            </div>
        );
    };
    const isOnCallback = (isOn: boolean) => {
        console.log("convoIsOn", isOn);
        setIsConvoModeOn(prev => isOn);
    };
    const onXplrrPDFModelClosed = () => {
        window.globalHiglightFound = false;
        window.globalWigglePixel = 0;
    };
    const onXplrrPDFModelDiffClosed = () => {
        window.globalHiglightFound = false;
        window.globalWigglePixel = 0;
    };
    const renderAnswer = (index: number, answr: [user: string, response: ChatAppResponse], totalAnswers: number) => {
        return (
            <div key={index}>
                <div className={`${isConvoModeOn && index == totalAnswers - 1 && styles.userMsgSpkr}`}>
                    <UserChatMessage message={answr[0]} />
                </div>
                {isConvoModeOn && index == totalAnswers - 1 && (
                    <canvas data-key={`${activeConvoKey}${index}`} ref={msgCanvasRef} className={styles.msgviscanvas}></canvas>
                )}
                <div className={styles.answerContainer}>
                    {answr[1].active_scenario_id && answr[1].active_scenario_id != "" && (
                        <div className={styles.active_scenario_id}>{answr[1].active_scenario_id?.substring(0, 30)}</div>
                    )}
                    <div className={styles.chatMessageGpt}>
                        <Answer
                            key={index}
                            index={index}
                            answer={answr[1]}
                            isLoading={isLoading}
                            isStreaming={isStreaming}
                            speakStatus={{
                                isSpeaking: isSpeaking,
                                isPaused: isSpeechPaused
                            }}
                            selectedGroup={selectedGroup}
                            isSBS={config.showDocLibInChatTab}
                            isReverseAnswerList={selectedGroup.assistanttype == assistantCodes.prd}
                            totalAnswers={selectedGroup.assistanttype == assistantCodes.prd ? 1 : totalAnswers}
                            showFollowupQuestions={config.useSuggestFollowupQuestions && answers.length - 1 === index}
                            onSendFeedback={onSendFeedback}
                            manageSpeechPause={(val: boolean) => setIsSpeechPaused(val)}
                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                            clickedCitation={clickedCitation.current || "placeholder_cit_id"}
                            onCitationClicked={(filePath: string, integratedCitation: string | null, el: HTMLElement | null, openSBS?: boolean) => {
                                manageCitationOpen(filePath, integratedCitation, index, el, openSBS);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };
    const setIsSpeakingStatusFromSpeechOutput = (bool: boolean) => {
        setTimeout(() => {
            setIsSpeaking(bool);
        }, 100);
    };
    const convoEnd = (qna: gqasimplified[], convokey: string) => {
        let convoUpload: qconvoupload = {
            selectedGroup: selectedGroup,
            loggedInUser: loggedInUser,
            convo: qna,
            convoName: convokey
        };
        console.log("convoUpload", convoUpload);
        qUploadCCAConvo(convoUpload);
    };
    return (
        <ThemeProvider theme={customTheme}>
            {/* MAIN CONTAINER */}
            <div className={styles.container} id={"rro"}>
                <ToastContainer position="bottom-left" pauseOnHover transition={Bounce} style={{ zIndex: 99999999 }} />
                {isConvoModeOn == true && (
                    <SpeechOutput
                        chunk={convActvSentnce.current}
                        pauseSpeak={isSpeechPaused}
                        isSpeakingCallback={(val: boolean) => {
                            setIsSpeakingStatusFromSpeechOutput(val);
                        }}
                        msgCanvasRef={msgCanvasRef}
                        selectedGroup={selectedGroup}
                        activeConvoKey={activeConvoKey}
                    />
                )}
                {/* HEADER */}
                {isIframe == 0 ? (
                    <div className={styles.headerMain}>
                        <div className={styles.headerSubMain}>
                            <div className={styles.commandsContainer}>
                                <div className={styles.gridDots} onClick={toggleSpacePanel}>
                                    {isSpacePanelLoading ? (
                                        <div className={styles.spacePanelLoadingIcon}>
                                            <Spinner size={SpinnerSize.medium} ariaLive="assertive" />
                                        </div>
                                    ) : (
                                        <HiOutlineViewList size={25} />
                                    )}
                                </div>

                                {/* BANNER */}
                                <h2 className={styles.leftLogo} onClick={setGrpTab}>
                                    {isLib != locationCodes.grp ? (
                                        <div className={styles.homeIconGridDots}>
                                            <TbGridDots />
                                        </div>
                                    ) : null}
                                </h2>
                                {/* LEFT HEADER BUTTONS */}
                                {checkWeAreInGeneralPlace(isLib) == false ? (
                                    <div>
                                        <div className={styles.vertLineHeaderWhitelabel} />
                                        <div className={styles.headSpaceContainerWhiteLabel}>
                                            <div
                                                className={`${config.showDocLibInChatTab == false ? styles.headSpaceName : styles.headSpaceNameSBS}`}
                                                onClick={setChatTab}
                                            >
                                                <span>{selectedGroup.selectionText} </span>
                                            </div>
                                            <div className={styles.headSpaceButts}>
                                                <SettingsButton
                                                    className={`${styles.settingsButton} ${isLib == locationCodes.chat ? styles.settingsButtonActive : ""}`}
                                                    onClick={setChatTab}
                                                    buttonText="Chat"
                                                    iconId="PiChatTeardropDotsBold"
                                                />
                                                <SettingsButton
                                                    className={`${styles.settingsButton} ${isLib == locationCodes.lib ? styles.settingsButtonActive : ""}`}
                                                    onClick={setLibTab}
                                                    buttonText={activeComp.permissionlevel > 1 ? "Manage" : "Content"}
                                                    iconId="TbSettings2"
                                                />
                                                {/*SHARE BUTTON*/}
                                                <div ref={addUserButtonRef}></div>
                                                {selectedGroup.permissionlevel >= 2 ? (
                                                    <div className={styles.headSpaceButsShare} onClick={() => setIsShareCalloutVisible(!isShareCalloutVisible)}>
                                                        <IoShareSocialOutline />
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className={styles.headMenu}></div>
                                    </div>
                                ) : null}
                                {/* SPLIT CHAT-PDF */}
                                <div className={styles.commandsC} id="addSpcBttnID">
                                    {checkWeAreInGeneralPlace(isLib) == false &&
                                        isElementVisible("#dLM") == false &&
                                        (config.showDocLibInChatTab == true) == true && (
                                            <TooltipHost content="Close Side-by-Side View" directionalHint={DirectionalHint.topCenter}>
                                                <IconButton onClick={toggleChatWithDocs} className={styles.SBSIconWrap}>
                                                    <div className={styles.closeSBSPanelIcon}>
                                                        <LuPanelRightClose size={22}></LuPanelRightClose>
                                                    </div>
                                                </IconButton>
                                            </TooltipHost>
                                        )}
                                </div>
                                {/* USER ACCOUNT */}
                                <div id="userPhoto" onClick={() => setIsAccountMenuVisible(true)} className={styles.iconCircle}>
                                    <div className={`${styles.iconCircleIcon} ${isAccountMenuVisible == true ? styles.iconCircleIconActive : ""}`}>
                                        <FiUser size={16} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {/* CONTEXT ACCOUNT MENU */}
                {isAccountMenuVisible && (
                    <ContextualMenu items={accMenuItems} target={document.getElementById("userPhoto")} onDismiss={() => setIsAccountMenuVisible(false)} />
                )}

                {/* MAIN */}
                <div className={styles.chatRoot}>
                    {/* IFRAME ⇒ TOP BLUR */}
                    {isIframe == 1 && <div className={styles.blurBackForIframe}></div>}

                    {/* MAIN CONTAINER */}
                    <div className={styles.chatContainer}>
                        {/* IFRAME ⇒ HEADER */}
                        {isIframe == 1 && (
                            <div className={styles.iframeAccountOptions}>
                                {/* IFRAME ⇒ HEADER LOGO */}
                                <div
                                    className={`${styles.linkImgIframe} ${config.isDark ? styles.linkDarkIframe : ""}`}
                                    onClick={() => {
                                        window.open(window.location.href.replace("ifrm=true", "").replace("&A=1", ""), "_blank", "noopener,noreferrer");
                                    }}
                                >
                                    <img src={kognifyLogo} height="30px" />
                                </div>
                                {/* IFRAME ⇒ USER AVATAR */}
                                <div id="userPhoto" onClick={() => setIsAccountMenuVisible(true)} className={styles.iconCircleIframe}>
                                    <div className={`${styles.iconCircleIcon} ${isAccountMenuVisible == true ? styles.iconCircleIconActive : ""}`}>
                                        <FiUser size={16} />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* LIBRARY */}
                        {isLib == locationCodes.lib && initLoad == false ? (
                            <div className={styles.chatEmptyState}>
                                <Pivot onLinkClick={handlePivotItemClick} styles={libPivotStyles} defaultSelectedKey="Sources">
                                    <PivotItem
                                        headerText=""
                                        itemKey="back"
                                        onRenderItemLink={() => (
                                            <div className={styles.backPivotItemIconCont}>
                                                <MdArrowBackIos size={20} />
                                            </div>
                                        )}
                                    />
                                    <PivotItem headerText="Sources" itemKey="Sources">
                                        <div className={styles.detailsListWrapper}>
                                            {/*LIBRARY*/}
                                            <DetailsListDocumentsExample
                                                fileList={fileCount > 0 ? fileList : undefined}
                                                selectedGroup={selectedGroup}
                                                selectionPass={includeCategory}
                                                onSelectionChange={handleSelectionChange}
                                                groupList={groupList.map(
                                                    (item: { groupid: string; groupname: string; userid: string; permissionlevel: number }) => ({
                                                        key: item.groupid,
                                                        text: item.groupname,
                                                        memberlevel: item.permissionlevel
                                                    })
                                                )}
                                                updateCatsState={updateCatsState}
                                                fileSpaceChangeTrigger={() => qGetSpaceFiles()}
                                                getOFromListVal={selectedGroup.permissionlevel}
                                                openSpacesEditPanel={() => setIsSpaceEditModalOpen(true)}
                                                openSpacesAnalyticsPanel={openSpacesAnalyticsPanel}
                                                user={user}
                                                loggedInUser={loggedInUser}
                                                onDelFile={() => qGetSpaceFiles()}
                                                onCitationClicked={(filePath: string, integratedCitation: string | null) =>
                                                    onShowCitation(filePath, integratedCitation, 0)
                                                }
                                                onCompareClicked={onCompareClicked}
                                                companyId={activeComp.companyid}
                                                listRefreshRequest={listRefreshRequest}
                                            />
                                        </div>
                                    </PivotItem>
                                    {selectedGroup.permissionlevel > 2 ? (
                                        <PivotItem headerText="Security">
                                            <div className={styles.settingsPivot}>
                                                <SpaceUserListV2
                                                    users={compMbrshps}
                                                    activeComp={activeComp.companyid}
                                                    spaceSecurityUpdate={userMngmntUpdate}
                                                    companies={compFlatList}
                                                />
                                            </div>
                                        </PivotItem>
                                    ) : null}
                                    {selectedGroup.permissionlevel > 2 ? (
                                        // SETTINGS
                                        <PivotItem headerText="Settings">
                                            <div className={styles.setPivWrap}>
                                                <div className={styles.settingsPivot}>
                                                    <SpaceEditForm selectedGroup={selectedGroup} onEditSpaceSave={onEditSpaceSave} onGroupDel={onGroupDel} />
                                                </div>
                                            </div>
                                        </PivotItem>
                                    ) : null}
                                    {/* INV ASSISTANT SHOW Extraction Settings */}
                                    {selectedGroup.permissionlevel > 2 && selectedGroup.assistanttype == assistantCodes.ipa ? (
                                        <PivotItem headerText="Extraction Settings">
                                            <div className={styles.setPivWrap}>
                                                <div className={styles.settingsPivot}>
                                                    <FieldSelectionForInvoices
                                                        selectedGroup={selectedGroup}
                                                        loggedInUser={loggedInUser}
                                                        extractionfieldlist={getFieldExtractionList()}
                                                        onEditSpaceSave={onEditSpaceSave}
                                                    />
                                                </div>
                                            </div>
                                        </PivotItem>
                                    ) : /* DOC EXTRACTION ASSISTANT SHOW Extraction Settings */
                                    selectedGroup.permissionlevel > 2 && selectedGroup.assistanttype == assistantCodes.dpa ? (
                                        <PivotItem headerText="Extraction Settings">
                                            <div className={styles.setPivWrap}>
                                                <div className={styles.settingsPivot}>
                                                    <DocFieldSettings
                                                        selectedGroup={selectedGroup}
                                                        loggedInUser={loggedInUser}
                                                        onEditDocExtractionDone={onEditSpaceSave}
                                                        fileList={window.globalFL.map((file: gfile) => ({ fileid: file.fileid, name: file.name }))}
                                                        purpose="doc"
                                                    />
                                                </div>
                                            </div>
                                        </PivotItem>
                                    ) : selectedGroup.permissionlevel > 2 && selectedGroup.assistanttype == assistantCodes.cca ? (
                                        <PivotItem headerText="Call Settings">
                                            <div className={styles.setPivWrap}>
                                                <div className={styles.settingsPivot}>
                                                    <CallAssistantSettings
                                                        selectedGroup={selectedGroup}
                                                        fileList={window.globalFL.map((file: gfile) => ({ fileid: file.fileid, name: file.name }))}
                                                        loggedInUser={loggedInUser}
                                                        onEditDocExtractionDone={onEditSpaceSave}
                                                        purpose="call"
                                                    />
                                                </div>
                                            </div>
                                        </PivotItem>
                                    ) : null}

                                    {selectedGroup.permissionlevel > 2 ? (
                                        <PivotItem headerText="Analytics">
                                            <div className={styles.anltxc}>
                                                <AnalyticsConvoList keyA={selectedGroup.selectionId} />
                                            </div>
                                        </PivotItem>
                                    ) : null}
                                </Pivot>
                            </div>
                        ) : // CHAT ⇒ NOT PROD ASSIST
                        isLib == locationCodes.chat && selectedGroup?.assistanttype != assistantCodes.prd && initLoad == false ? (
                            <div className={config.showDocLibInChatTab == true ? styles.SBSWrap : styles.ChatOnlyWrap}>
                                <div className={config.showDocLibInChatTab == true ? styles.sideBySideUnderWrapSBS : styles.chatFullScreenUnderWrap}>
                                    <div
                                        ref={xplrrMsgStrm}
                                        className={config.showDocLibInChatTab == true ? styles.chatMessageStreamSBS : styles.chatFullScreenMessageStream}
                                    >
                                        <div className={config.showDocLibInChatTab == true ? styles.chatMessageStreamInnerSBS : styles.chatMessageStreamInner}>
                                            {(isStreaming ? streamedAnswers : !isStreaming ? answers : []).map((answr, index) => {
                                                return answr[1].discard != true
                                                    ? renderAnswer(index, answr, streamedAnswers?.length ?? answers.length)
                                                    : renderConvoTempNonAnswer(index, answr, streamedAnswers?.length ?? answers.length);
                                            })}

                                            {isLoading && (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerLoading loadingMsg={loadingMsg} />
                                                    </div>
                                                </>
                                            )}
                                            {error ? (
                                                <>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                                    </div>
                                                </>
                                            ) : null}

                                            {!isStreaming && !isLoading && !error && answers.length == 0 && loadingChatModeSwitch == false ? (
                                                <>
                                                    <div className={styles.chatMessageGptMinWidthHI}>
                                                        {selectedGroup.assistanttype == assistantCodes.prd ? (
                                                            <AnswerDocs
                                                                selectedGroup={selectedGroup}
                                                                fileList={fileList}
                                                                loggedInUser={loggedInUser}
                                                                onCitationClicked={(filePath: string, integratedCitation: string | null) =>
                                                                    onShowCitation(filePath, integratedCitation, 0)
                                                                }
                                                            />
                                                        ) : (
                                                            <AnswerHi
                                                                content={
                                                                    selectedGroup.custgreetingmessage
                                                                        ? `${selectedGroup.custgreetingmessage} \n ${greetingMessageHtml}`
                                                                        : `${defaultGreetingsMessage} \n ${greetingMessageHtml}`
                                                                }
                                                                selectedGroup={selectedGroup}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            ) : loadingChatModeSwitch == true ? (
                                                <div className={styles.chatMessageGptMinWidthHI}>
                                                    <div className={styles.typingDots}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div ref={chatMessageStreamEnd} />
                                        </div>
                                    </div>
                                    <div className={config.showDocLibInChatTab == true ? styles.chatInputSBS : styles.chatInput}>
                                        <div>
                                            <QuestionInput
                                                clearOnSend={true}
                                                isStreaming={isStreaming}
                                                disabled={isLoading}
                                                placeholder={window.innerWidth < 400 ? "Start..." : "Start a conversation..."}
                                                permissionLevel={selectedGroup.permissionlevel}
                                                selectedGroup={selectedGroup}
                                                lTiers={lTiers || []}
                                                msgLmtHit={msgLmtHit}
                                                loggedInUser={loggedInUser}
                                                onSend={makeApiRequest}
                                                updateCatsState={updateCatsState}
                                                isCancellingMidway={handleIsCancellingMidway}
                                                toggleLeftPanel={toggleLeftPanel}
                                                newConverson={newConverson}
                                                listRefreshRequest={listRefreshRequest}
                                                isOnCallback={isOnCallback}
                                                convoEnd={convoEnd}
                                                isSpeaking={isSpeaking}
                                                isSpeechPaused={isSpeechPaused}
                                                lastMessage={lastMessage}
                                                activeConvoKey={activeConvoKey}
                                                question_and_answers_list={question_and_answers_list}
                                                convo_discard_stamp={convo_discard_stamp}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {config.showDocLibInChatTab == true ? (
                                    <div className={styles.xplrrPDFZ_SBS}>
                                        <XplrrPDFSideByChat
                                            startPage={isPDFModalOpen.startPage}
                                            citationFileId={isPDFModalOpen.defaultFile}
                                            xplrrCitationChangeCallback={xplrrCitationChangeCallback}
                                            fileId={isPDFModalOpen.fileId}
                                            refPageNum={isPDFModalOpen.refPageNum}
                                            isCitation={isPDFModalOpen.isCitation}
                                            rndo={isPDFModalOpen.rndo}
                                            searchString={isPDFModalOpen.searchString}
                                            activeGroupState={isPDFModalOpen.activeGroupId}
                                            fileList={fileList}
                                            shouldPlayAudio={shouldPlayAudio}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.underInput}>
                                        <div className={styles.chatInfo}></div>
                                    </div>
                                )}
                            </div>
                        ) : // CHAT ⇒ PRODUCT ASSISTANT BOT
                        (isLib == locationCodes.chat && selectedGroup?.assistanttype == assistantCodes.prd) ||
                          window.location.href?.indexOf("ifrm=2") > 0 == true ||
                          isIframe == 2 ? (
                            <div className={styles.ChatOnlyWrap}>
                                {/* PRODUCT DOCUMENTATION BLUR */}
                                <div className={isBlurredFull == true ? styles.blurBackFull : ""}>
                                    <div
                                        className={isBlurredFull == true ? styles.blurBackFull : ""}
                                        onClick={() => {
                                            setIsBlurredFull(false);
                                            setXplrrChatVisible(false);
                                        }}
                                    ></div>
                                </div>
                                {/* PRODUCT DOCUMENTATION CHAT */}
                                <div className={styles.xplrrChatWrp}>
                                    <div className={styles.xplrrChatInput} onClick={() => switchXplrrModuleVisibility()}>
                                        {/* QUESTION INPUT CONTAINER */}
                                        <div
                                            className={
                                                xplrrChatVisible == true
                                                    ? isIframe && isIframe > 0
                                                        ? styles.questionInputXplrrWideIframe
                                                        : styles.questionInputXplrrWide
                                                    : isIframe && isIframe > 0
                                                    ? styles.questionInputXplrrWideIframe
                                                    : styles.questionInputXplrr
                                            }
                                        >
                                            {/* QUESTION INPUT */}
                                            <QuestionInputXplrr
                                                clearOnSend
                                                placeholder="Ask a question..."
                                                disabled={isLoading}
                                                onSend={(question: string) => {
                                                    makeApiRequest(question);
                                                }}
                                                toggleLeftPanel={toggleLeftPanel}
                                                newConverson={newConverson}
                                            />
                                        </div>
                                        {/* PRODUCT DOCUMENTATION CHAT SLIDE DOWN */}
                                        {xplrrChatVisible == true ? (
                                            <div
                                                ref={xplrrMsgStrm}
                                                className={isIframe == 2 ? styles.xplr_chatMessageStreamProductAssist : styles.xplr_chatMessageStream}
                                            >
                                                <div
                                                    className={
                                                        isIframe == 2 ? styles.xplr_chatMessageStreamInnerProductAssist : styles.xplr_chatMessageStreamInner
                                                    }
                                                >
                                                    <div ref={chatMessageStreamEnd} />
                                                    {isLoading && (
                                                        <>
                                                            <UserChatMessage message={lastQuestionRef.current} />
                                                            <div className={styles.chatMessageGptMinWidthProductAssist}>
                                                                <AnswerLoading loadingMsg={loadingMsg} />
                                                            </div>
                                                        </>
                                                    )}
                                                    {!!error && (
                                                        <>
                                                            <UserChatMessage message={lastQuestionRef.current} />
                                                            <div className={styles.chatMessageGptMinWidth}>
                                                                <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                                            </div>
                                                        </>
                                                    )}
                                                    {streamedAnswers.length < 1 && isStreaming != true && (
                                                        <div className={styles.chatMessageGptMinWidthHI}>
                                                            {/* SLIDE DOWN DOC LIST */}
                                                            <AnswerDocs
                                                                selectedGroup={selectedGroup}
                                                                fileList={fileList}
                                                                loggedInUser={loggedInUser}
                                                                onCitationClicked={(filePath: string, integratedCitation: string | null) =>
                                                                    onShowCitation(filePath, integratedCitation, 0)
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                    {(isStreaming ? streamedAnswers : !isStreaming ? answers : [])
                                                        .slice()
                                                        .reverse()
                                                        .map((answr, index) => renderAnswer(index, answr, streamedAnswers?.length ?? 0))}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/** PRODUCT DOCUMENTATION EXTERNAL FILE LIST */}
                                    {isIframe == 0 ? (
                                        <div className={styles.xplrrChatFilelist}>
                                            <Stack horizontal tokens={{ childrenGap: 20 }} className={styles.prdAssistantFileList}>
                                                {fileList.slice(0, 20).map((file, index) => (
                                                    <Stack.Item key={index}>
                                                        <DocumentCard
                                                            key={index}
                                                            type={DocumentCardType.normal}
                                                            className={styles.hiDocCard}
                                                            onClick={() => {
                                                                onShowCitation(`${file.name}#page=1_fid=${file.fileid}`, null, 0);
                                                            }}
                                                        >
                                                            <DocumentCardTitle
                                                                title={removeExtension(file.name)}
                                                                shouldTruncate={true}
                                                                className={styles.docCardTitle}
                                                            />
                                                            <DocumentCardPreview
                                                                key={index}
                                                                previewImages={[
                                                                    {
                                                                        previewImageSrc: thumbnailUrls[index],
                                                                        imageFit: 2,
                                                                        name: file.name
                                                                    }
                                                                ]}
                                                                styles={prdAssistantExternalFileListStyle}
                                                            />
                                                        </DocumentCard>
                                                    </Stack.Item>
                                                ))}
                                            </Stack>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : isLib == locationCodes.grp ? (
                            <div className={styles.grpScrn}>
                                <div className={styles.spaceCards}>
                                    {noGroupsShared == true ? (
                                        <div>
                                            <div className={styles.spaceSplashGreetings}>
                                                <div className={styles.hi}>Hi{user.name ? `, ${user.name}` : ""}!</div>
                                                <div className={styles.hiAskAnything}>It seems there are no Assistants shared with you.</div>
                                            </div>
                                            <div>
                                                <NoSpacesCard
                                                    onCreateClicked={() => setIsSpaceModalOpen(true)}
                                                    showCreateSpacePrompt={true}
                                                    activeComp={activeComp}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.groupCardsListOutsideWrap}>
                                            <div className={styles.spaceSplashGreetings}>
                                                <img src={kognifyLogo} className={styles.kgnfLogoCenter} height="58px"></img>

                                                <div className={styles.hiAskAnything}>Your Assistants</div>
                                            </div>
                                            <GroupCardsList
                                                key={selectedGroup.oid}
                                                grL={getGroupList()}
                                                onNewGroupClick={onNewGroupClick}
                                                compLvl={activeComp.permissionlevel}
                                                permissionLevelsMap={permissionLevels}
                                                newSpacePyro={newSpacePyro}
                                                user={user}
                                                activeComp={activeComp}
                                                navChatLib={navChatLib}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : // ACCOUNT SETTINGS
                        isLib === locationCodes.acc ? (
                            <div>
                                <div className={styles.accountInfoWrap}>
                                    <AccountInfo
                                        key={groupList?.length}
                                        groups={groupList}
                                        user={user}
                                        lTiers={lTiers || []}
                                        activeComp={activeComp}
                                        selectedGroup={selectedGroup}
                                    />
                                </div>
                            </div>
                        ) : // ADMINISTRATIVE
                        isLib === locationCodes.adm ? (
                            /* Administrative Menu */
                            <div>
                                <Pivot
                                    onLinkClick={handleAdmPivotItemClick}
                                    className={styles.spaceSecurityPivot}
                                    styles={chatAdmPivotStyles}
                                    defaultSelectedKey="usersAdm"
                                >
                                    <PivotItem
                                        headerText=""
                                        itemKey="back"
                                        onRenderItemLink={() => (
                                            <div className={styles.backPivotItemIconCont}>
                                                <MdArrowBackIos size={20} />
                                            </div>
                                        )}
                                    />
                                    <PivotItem headerText="Users" itemKey="usersAdm" className={styles.settingsPivot}>
                                        <UserList
                                            key={allUsrs.length}
                                            users={allUsrs}
                                            activeComp={activeComp}
                                            userMngmntUpdate={userMngmntUpdate}
                                            companies={compFlatList}
                                            loggedInUser={loggedInUser}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Companies" itemKey="compAdm">
                                        <div className={styles.settingsPivot}>
                                            <CompList
                                                comps={compFlatList}
                                                newActiveComp={handleCompChange}
                                                activeComp={activeComp}
                                                loggedInUser={loggedInUser}
                                            />
                                        </div>
                                    </PivotItem>
                                    <PivotItem headerText="Company Administration" itemKey="compSett">
                                        <div>
                                            <h3 className={styles.modalTitle}>{activeComp.companyname}</h3>
                                            <CompEditForm activeComp={activeComp} selectedGroup={selectedGroup} />
                                        </div>
                                    </PivotItem>
                                </Pivot>
                            </div>
                        ) : noMembership == true ? (
                            <div>
                                <NoSpacesCard onCreateClicked={() => setIsSpaceModalOpen(true)} showCreateSpacePrompt={false} activeComp={activeComp} />
                            </div>
                        ) : (
                            <div className={styles.loadingIndicatorCenterScreen}>
                                <div className={styles.typingDots}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* USER SETTINGS FROM ACCOUNT MENU */}
                    <Panel
                        headerText="Settings"
                        isOpen={isConfigPanelOpen}
                        isLightDismiss={true}
                        isBlocking={false}
                        onDismiss={() => setIsConfigPanelOpen(false)}
                        closeButtonAriaLabel="Close"
                        onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                        isFooterAtBottom={true}
                    >
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={config.experimentalFeatures}
                            label="Beta Features"
                            onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                setConfig(prevConfig => ({
                                    ...prevConfig,
                                    experimentalFeatures: !!checked
                                }));
                            }}
                        />
                        <div className={styles.betaFeaturesDetails}>Show New Features</div>

                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={config.isDark}
                            label="Dark Mode"
                            onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                handleIsDark(!!checked);
                                setConfig(prevConfig => ({
                                    ...prevConfig,
                                    isDark: !!checked
                                }));
                            }}
                        />
                        <div className={styles.betaFeaturesDetails}>Apply Dark Theme</div>
                        <div className={styles.versionLabel}>
                            <div>{import.meta.env.VITE_V_TIMESTAMP}</div>
                        </div>
                    </Panel>
                </div>

                {/* HISTORY LEFT PANEL */}
                <Panel
                    isOpen={isLeftPanelOpen}
                    onDismiss={toggleLeftPanel}
                    isLightDismiss={true}
                    closeButtonAriaLabel="Close"
                    headerText="Conversations"
                    type={PanelType.customNear}
                    customWidth="400px"
                >
                    <div className={styles.convoListBox}>
                        {convos.map(item => (
                            <ListItemButton
                                key={item.key}
                                onClick={() => {
                                    if (config.showDocLibInChatTab == true) {
                                        setConfig(prevConfig => ({
                                            ...prevConfig,
                                            showDocLibInChatTab: false
                                        }));
                                    }
                                    setConversation(item.key);
                                }}
                                onDelete={() => histItemHandleDelete(item.key)}
                                onEdit={newText => histItemHandleEdit(item.key, newText)}
                                text={item.name}
                                className={`${styles.conversationsList} ${activeConvoKey === item.key ? styles.listItemBold : ""}`}
                                disabled={isLoading || isStreaming}
                            />
                        ))}
                    </div>
                </Panel>

                {/*SPACES LEFT PANEL */}
                <SpacePanel
                    isSpacePanelOpen={isSpacePanelOpen}
                    toggleSpacePanel={toggleSpacePanel}
                    groupList={groupList?.sort((a, b) => {
                        if (a.oid < b.oid) {
                            return 1;
                        }
                        if (a.oid > b.oid) {
                            return -1;
                        }
                        return 0;
                    })}
                    selectedGroup={selectedGroup}
                    setGrpTab={setGrpTab}
                    handleLeftSpacePanelClick={(item: any, aCK?: string) => {
                        handleLeftSpacePanelClick(item, aCK);
                    }}
                    recentConvosAccrossSpaces={recentConvosAccrosSpaces || []}
                    activeConvoKey={activeConvoKey}
                />

                {/* GLOBAL USER LIST MODAL */}
                <Modal
                    isOpen={isUserListModalOpen}
                    onDismiss={() => {
                        setIsUserListModalOpen(false);
                    }}
                    isBlocking={false}
                    containerClassName={styles.glUserLostModal}
                >
                    <h3 className={styles.modalTitle}>Global Users List</h3>
                    <UserList
                        users={allUsrs}
                        activeComp={activeComp}
                        userMngmntUpdate={userMngmntUpdate}
                        companies={compFlatList}
                        loggedInUser={loggedInUser}
                    />{" "}
                </Modal>

                {/* COMPANIES MODAL */}
                <Modal
                    isOpen={isCompListModalOpen}
                    onDismiss={() => setIsCompListModalOpen(false)}
                    isBlocking={false}
                    containerClassName={styles.spaceAddModal}
                >
                    <CompList comps={compFlatList} newActiveComp={handleCompChange} activeComp={activeComp} loggedInUser={loggedInUser} />
                </Modal>

                {/* ADD NEW SPACE MODAL*/}
                <AddSpaceModal
                    isSpaceModalOpen={isSpaceModalOpen}
                    closeSpacesPanel={() => setIsSpaceModalOpen(false)}
                    isNewSpaceInfoBip={isNewSpaceInfoBip}
                    user={user}
                    addSpace={addSpace}
                />

                {/*PDF MODAL*/}
                <RModal
                    isOpen={isPDFModalOpen.isOpen}
                    onRequestClose={onXplrrPDFDismiss}
                    shouldCloseOnOverlayClick={true}
                    onAfterClose={() => {
                        onXplrrPDFModelClosed();
                    }}
                    closeTimeoutMS={100}
                    className={{
                        base: styles.modalContent,
                        afterOpen: styles.modalContentAfterOpen,
                        beforeClose: styles.modalContentBeforeClose
                    }}
                    overlayClassName={styles.modalOverlay}
                    shouldFocusAfterRender={true}
                    appElement={document.getElementById("rro")!}
                >
                    <XplrrPDF
                        startPage={isPDFModalOpen.startPage}
                        citationFileId={isPDFModalOpen.defaultFile}
                        xplrrCitationChangeCallback={xplrrCitationChangeCallback}
                        fileId={isPDFModalOpen.fileId}
                        refPageNum={isPDFModalOpen.refPageNum}
                        isCitation={isPDFModalOpen.isCitation}
                        rndo={isPDFModalOpen.rndo}
                        searchString={isPDFModalOpen.searchString}
                        activeGroupState={isPDFModalOpen.activeGroupId}
                        fileList={fileList}
                        shouldPlayAudio={shouldPlayAudio}
                        isIframe={isIframe && isIframe > 0 ? true : false}
                        onXplrrPDFDismiss={onXplrrPDFDismiss}
                        onDataHighlight={onDataHighlight}
                        isLib={isLib}
                        loggedInUser={loggedInUser}
                        onInvValueUpdate={listRefreshRequest}
                    />
                </RModal>

                {/*DIFF PDF MODAL*/}
                <RModal
                    isOpen={isPDFDiffModalOpen.isOpen}
                    onRequestClose={onXplrrDiffPDFDismiss}
                    shouldCloseOnOverlayClick={true}
                    onAfterClose={() => {
                        onXplrrPDFModelDiffClosed();
                    }}
                    closeTimeoutMS={100}
                    className={{
                        base: styles.modalContent,
                        afterOpen: styles.modalContentAfterOpen,
                        beforeClose: styles.modalContentBeforeClose
                    }}
                    overlayClassName={styles.modalOverlay}
                    shouldFocusAfterRender={true}
                >
                    <XplrrPDFDiff
                        fileItem1={isPDFDiffModalOpen.fl1}
                        fileItem2={isPDFDiffModalOpen.fl2}
                        selectedGroup={selectedGroup}
                        rndo={isPDFDiffModalOpen.rndo}
                        diffRes={isPDFDiffModalOpen.diff}
                        onDiffPDFDismiss={onXplrrDiffPDFDismiss}
                    />
                </RModal>

                {/* INVITE USER */}
                {isShareCalloutVisible && (
                    <div className={styles.inviteCallout}>
                        <InviteUserCallout
                            isOpen={isShareCalloutVisible}
                            onDismiss={() => setIsShareCalloutVisible(!isShareCalloutVisible)}
                            addUserButtonRef={addUserButtonRef}
                            inviteUser={inviteUser}
                            selectedGroup={selectedGroup}
                            activeConvoKey={activeConvoKey}
                        />
                    </div>
                )}
            </div>
        </ThemeProvider>
    );
};

export default Chat;
