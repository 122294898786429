import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { RiArrowRightWideLine } from "react-icons/ri";

import { gcontentlist, gfile, gXplrrSpaceOrder } from "../../interfaces";

interface renderLinkDataProps {
    file: gfile | undefined;
    fileMap: gXplrrSpaceOrder;
    activeFileId: string | undefined;
    currentPage: number;
    referencePage: number;
    styles: any;
    handleClick: (target: EventTarget, item: gcontentlist, indx: number) => void;
    expandCollapseSection: (flId: string, srcText: string) => void;
    perFilesPerSectionStateMap: Map<string, Map<string, boolean>>;
}

export const renderLinkData = ({
    file,
    fileMap,
    activeFileId,
    currentPage,
    referencePage,
    styles,
    handleClick,
    expandCollapseSection,
    perFilesPerSectionStateMap
}: renderLinkDataProps) => {
    if (!file || !fileMap || (fileMap && Object.keys(fileMap).length < 1)) return null;

    let weAreOver = true;
    let weAreOverRef = true;
    let setActive = false;
    let setActiveRef = false;
    const fllmnt: JSX.Element[] = [];
    let fl = file;

    if (fl.fileid == activeFileId) {
        const linkData = fl.contentlist;
        let linkPageNumTracking = 0;
        let linkDataLength = linkData.length;
        let lvlZeroItem: any;

        const minItemLevel = Math.min(...Object.values(fileMap[parseInt(fl.fileid)]?.fileItemOrder).map(item => item["itemLevel"]));
        linkData?.map((item: any, index: number) => {
            if (item.srcText !== "") {
                const { currentValue, splitValues, splitLength, itemLevel, pageNumExtracted } = fileMap[parseInt(fl.fileid)]?.fileItemOrder[index];
                const {
                    currentValue: currentValueNext,
                    splitValues: splitValuesNext,
                    splitLength: splitLengthNext,
                    itemLevel: itemLevelNext,
                    pageNumExtracted: pageNumExtractedNext
                } = fileMap[parseInt(fl.fileid)].fileItemOrder[index + 1 < linkDataLength ? index + 1 : index];
                let nextItem = linkData[index + 1 < linkDataLength ? index + 1 : index];

                if (splitValues.join("").length < 3) return;
                if (item.destPageNum < linkPageNumTracking) return;

                linkPageNumTracking = item.destPageNum;
                let samePageLink = false;
                if (index > 0 && linkData[index - 1].destPageNum == item.destPageNum) samePageLink = true;
                if (weAreOver != currentPage > item.destPageNum) setActive = true;

                weAreOver = currentPage > item.destPageNum;

                if (weAreOverRef != referencePage > item.destPageNum) setActiveRef = true;

                weAreOverRef = referencePage > item.destPageNum;

                let itemClass = `${styles.pdfContListNormal} ${setActive ? styles.pdfContListSelected : ""} ${setActiveRef ? styles.pdfContListBold : ""}`;
                let contClass = `${itemLevel == 0 ? styles.contentItem : itemLevel == 1 ? styles.contentItemSub : styles.contentItemSub2}`;
                if (itemLevel == minItemLevel || 0) {
                    lvlZeroItem = item;
                    fllmnt.push(
                        <div className={styles.itemContBacka} key={index}>
                            <div key={index} className={itemClass}>
                                <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                    <div className={styles.contentItemNumbeing}>{splitValues[0]}</div>
                                    <div className={styles.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                    <div
                                        className={`${itemLevelNext > itemLevel ? styles.contentPageNum : styles.contentPageNumNoExpandNeeded}`}
                                        onClick={e => {
                                            if (itemLevelNext > itemLevel) {
                                                e.stopPropagation();
                                                expandCollapseSection(fl.fileid, item.srcText);
                                            }
                                        }}
                                    >
                                        <div>{pageNumExtracted}</div>
                                        {itemLevelNext > itemLevel ? (
                                            perFilesPerSectionStateMap.get(fl.fileid)?.get(lvlZeroItem.srcText) == true ? (
                                                <div className={styles.expandContentSubItemsIcon}>
                                                    <IoIosArrowDown size={15} />
                                                </div>
                                            ) : (
                                                <div className={styles.expandContentSubItemsIcon}>
                                                    <RiArrowRightWideLine size={15} />
                                                </div>
                                            )
                                        ) : (
                                            <div className={styles.expandContentSubItemsNoIcon}></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    if (perFilesPerSectionStateMap.get(fl.fileid)?.get(lvlZeroItem?.srcText) == true) {
                        if (itemLevel == 1) {
                            contClass = `${styles.contentItemSub}`;
                            fllmnt.push(
                                <div className={styles.itemContBacka} key={index}>
                                    <div key={index} className={itemClass}>
                                        <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                            <div className={styles.contentItemNumbeing}>{splitValues[0]}</div>
                                            <div className={styles.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                            <div className={styles.contentPageNumNoExpandNeeded}>{pageNumExtracted}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else if (itemLevel >= 2) {
                            contClass = `${styles.contentItemSub2}`;

                            fllmnt.push(
                                <div key={index}>
                                    <div className={styles.itemContBacka} key={index}>
                                        <div key={index} className={itemClass}>
                                            <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                                <div className={styles.contentItemNumbeing}>{splitValues[0]}</div>
                                                <div className={styles.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                                <div className={styles.contentPageNumNoExpandNeeded}>{pageNumExtracted}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    }
                }
                if (setActive) setActive = false;
                if (setActiveRef) setActiveRef = false;
            }
        });
    }
    return fllmnt;
};
