import React, { useState, useEffect } from "react";
import { TextField, Label, Stack, IconButton, ITextFieldStyles } from "@fluentui/react";
import styles from "./TagInput.module.css";
import { textFieldStylesTagInput, iconButtonStylesTagInput } from "../../styles_glob";

interface TagInputProps {
    onChange: (tags: string[]) => void;
    palceholderText: string;
    initialTags?: string[];
}

const TagInput: React.FC<TagInputProps> = ({ onChange, palceholderText, initialTags = [] }) => {
    const [tags, setTags] = useState<string[]>(initialTags);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (JSON.stringify(tags) !== JSON.stringify(initialTags)) {
            console.log("initialTags", initialTags, " tags", tags);
            setTags(initialTags);
        }
    }, [initialTags]);

    const handleAddTag = () => {
        if (inputValue.trim()) {
            const newTags = [...tags, inputValue.trim()];
            setTags(newTags);
            onChange(newTags);
            setInputValue("");
        }
    };

    const handleInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setInputValue(newValue || "");
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleAddTag();
            event.preventDefault();
        }
    };

    const handleRemoveTag = (index: number) => {
        const newTags = tags.filter((_, i) => i !== index);
        setTags(newTags);
        onChange(newTags);
    };

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal tokens={{ childrenGap: 5 }} wrap className={styles.mainStack}>
                {tags.map((tag, index) => (
                    <Stack horizontal key={index} tokens={{ childrenGap: 5 }} verticalAlign="center" className={styles.tagListStack}>
                        <Stack.Item className={styles.labelAndDelBut}>
                            <Label className={styles.tagLab}>{tag}</Label>
                            <IconButton
                                iconProps={{ iconName: "Cancel" }}
                                title="Remove"
                                onClick={() => handleRemoveTag(index)}
                                styles={iconButtonStylesTagInput}
                            />
                        </Stack.Item>
                    </Stack>
                ))}
            </Stack>
            <TextField
                placeholder={palceholderText}
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className={styles.qTagInput}
                styles={textFieldStylesTagInput}
            />
        </Stack>
    );
};

export default TagInput;
