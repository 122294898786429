import React, { useState, useEffect, useMemo, useRef, MutableRefObject } from "react";
import { TextField, PrimaryButton, Dropdown, IDropdownOption, Stack, IconButton, Callout, DirectionalHint, TooltipHost, Toggle } from "@fluentui/react";

import { MdOutlineAutoFixHigh } from "react-icons/md";
import { MdOutlineAutoFixOff } from "react-icons/md";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "./DocFieldSelectionV2.module.css";
import { MdAddCircleOutline } from "react-icons/md";
import { RiEditCircleLine } from "react-icons/ri";
import { TiDeleteOutline } from "react-icons/ti";

import { extrConfConfLabels, extrTypeTooltipTexts, systemExtrConfigName } from "../../lsts";

import { DocFieldSelectionV2 } from "../../components/DocFieldSelection/DocFieldSelectionV2";
import { tooltipStyles, stackStyles, dropdownContentTypeStyles, textFieldModalContentTypeCreateStyles, addUrlCallout } from "./../../styles_glob";
import { gselectedgroup, gloggedinuser, gfileassourceoption, gcontenttype, gextrprop, new_content_type_config, qaconf, qconfemptyprop } from "../../interfaces";
import { getHeaderG } from "../../api/qpi";

import { cleanValues } from "../../util_glob";
import { QuestionSelectionV2 } from "./QuestionSelectionV2";

interface PropertiesEditorProps {
    selectedGroup: gselectedgroup;
    fileList: gfileassourceoption[];
    loggedInUser: gloggedinuser;
    purpose: string;
    onEditDocExtractionDone: () => void;
}
export const DocFieldSettings: React.FC<PropertiesEditorProps> = ({ selectedGroup, fileList, loggedInUser, purpose, onEditDocExtractionDone }) => {
    // Space with applied Edits
    const [editedSpace, setEditedSpace] = useState<gselectedgroup>({ ...selectedGroup });

    // Cont Type Options for Dropdown
    const [contentTypeOptions, setContentTypeOptions] = useState<IDropdownOption[]>(
        editedSpace.contenttype_config.contenttype.map((item: gcontenttype) => ({ key: item.type, text: item.type })) || []
    );
    // Selected Cont Type Option for Dropdown
    const [contentTypeSelectedOption, setContentTypeSelectedOption] = useState<string>(
        editedSpace.contenttype_config.contenttype.map((item: gcontenttype) => ({ key: item.type, text: item.type }))[0].key || "all_content"
    );
    // Selected Cont Type Object
    const [contentTypeDetails, setContentTypeDetails] = useState<gcontenttype>();

    // New Cont Type Object
    const [newContentType, setNewContentType] = useState({ type: "", description: "", autofield: false });

    // API Loading State
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);

    //Add Callout State
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    // Edit Callout State
    const [isEditCalloutVisible, { toggle: toggleIsEditCalloutVisible }] = useBoolean(false);

    // System Extraction Config Name
    const [editContentType, setEditContentType] = useState({ type: "", description: "", autofield: false });

    // Manage Selected Dropdown Option for Content Type
    useEffect(() => {
        setEditContentType({
            type: editedSpace.contenttype_config.contenttype.find((item: gcontenttype) => item.type == contentTypeSelectedOption)?.type || "",
            description: editedSpace.contenttype_config.contenttype.find((item: gcontenttype) => item.type == contentTypeSelectedOption)?.description || "",
            autofield: editedSpace.contenttype_config.contenttype.find((item: gcontenttype) => item.type == contentTypeSelectedOption)?.autofield ?? false
        });
        setContentTypeDetails(editedSpace.contenttype_config.contenttype.find((item: gcontenttype) => item.type == contentTypeSelectedOption));
    }, [contentTypeSelectedOption]);

    useEffect(() => {
        setContentTypeOptions(
            editedSpace.contenttype_config.contenttype.map((item: gcontenttype) => ({ key: item.type, text: item.type, autofield: item.autofield })) || []
        );
    }, [editedSpace.contenttype_config]);

    // Info Texts
    const docExtractionSettings = useMemo(() => {
        if (purpose == "call") {
            return (
                <div className={styles.descrContainer}>
                    <div className={styles.invSetDedcriptionMid}>
                        Configure the data that should be collected from the user in a structured manner, for example, name, email, phone number, product id,
                        etc. You can define the data structure by adding fields and their types. Some JSON knowledge is required.
                    </div>
                    <div className={styles.invSetDedcriptionMid}>
                        Name the fields and decribe them in a clear and concise manner. AI will use this information to collect data from the user, so try name
                        the fields and describe them in a logical and compact manner.
                    </div>

                    <div className={styles.invSetDedcriptionMid}>The general instructions and the Extraction Definition should match.</div>
                    <div className={styles.invSetDedcriptionMid}>
                        To use 'validation source', upload a pdf with structured data, containing all possible values of the field. Then choose this document
                        here. Ducring the user interaction AI will use it, to ground the collected value for this field with a value in the document.
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.descrContainer}>
                    <div className={styles.invSetDedcriptionMid}>
                        Choose wether AI should automatically determine important data in your document and structure it automatically or you want to define a
                        schema manually.
                    </div>
                    <div className={styles.invSetDedcriptionMid}>Threat different content differently by defining different content types.</div>
                </div>
            );
        }
    }, [selectedGroup]);

    // Legacy Extraction Config pulled from Separate Field
    const docextractionconfig = useMemo(() => {
        try {
            let str = selectedGroup.docextractionconfig.replace(/\\/g, "");
            setEditedSpace(prev => ({ ...prev, docextractionconfig: JSON.stringify(docextractionconfig) }));
            return JSON.parse(str.substring(1, str.length - 1)) || [];
        } catch (e) {
            console.error("Error parsing docextractionconfig", e);
            return [];
        }
    }, [selectedGroup.docextractionconfig]);

    // When Editing Structure Config - Write Back to Main Edited Space State
    const writeBackStructure = (value: gextrprop[]) => {
        setContentTypeDetails(prev => (prev ? { ...prev, fields: value } : prev));
        let content_type_obj: gcontenttype = {
            type: contentTypeSelectedOption,
            description: contentTypeDetails?.description || "",
            fields: value,
            autofield: contentTypeDetails?.autofield ?? false,
            qaconf: contentTypeDetails?.qaconf || []
        };
        setEditedSpace(prev => {
            const existingTypes = prev.contenttype_config.contenttype;
            const updatedTypes = existingTypes.some(ct => ct.type === content_type_obj.type)
                ? existingTypes.map(ct => (ct.type === content_type_obj.type ? content_type_obj : ct))
                : [...existingTypes, content_type_obj];

            return {
                ...prev,
                contenttype_config: {
                    ...prev.contenttype_config,
                    contenttype: updatedTypes
                }
            };
        });
    };

    // When Editing QA Config - Write Back to Main EditedSpace State
    const writeBackQA = (value: qaconf[]) => {
        setContentTypeDetails(prev => (prev ? { ...prev, qaconf: value } : prev));
        let content_type_obj: gcontenttype = {
            type: contentTypeSelectedOption,
            description: contentTypeDetails?.description || "",
            fields: contentTypeDetails?.fields || [],
            qaconf: value,
            autofield: contentTypeDetails?.autofield ?? false
        };
        setEditedSpace(prev => {
            const existingTypes = prev.contenttype_config.contenttype;
            const updatedTypes = existingTypes.some(ct => ct.type === content_type_obj.type)
                ? existingTypes.map(ct => (ct.type === content_type_obj.type ? content_type_obj : ct))
                : [...existingTypes, content_type_obj];

            return {
                ...prev,
                contenttype_config: {
                    ...prev.contenttype_config,
                    contenttype: updatedTypes
                }
            };
        });
    };
    // Manage Simple Edited Space State
    const handleChange = (field: keyof gselectedgroup, value: string | number | boolean) => {
        setEditedSpace(prev => ({ ...prev, [field]: value }));
    };

    // Clean Properties Function
    const cleanProperties = (properties: gextrprop[]): gextrprop[] => {
        console.log("properties", properties);
        if (!Array.isArray(properties)) return [];
        return properties
            .filter(prop => prop.name !== "")
            .map(prop => {
                let cleaned: gextrprop = {
                    name: prop.name.replace(/\s+/g, "_"), // Replace spaces with underscores
                    description: prop.description,
                    type: prop.type
                };
                if (prop.type == "string" || prop.type == "number") cleaned.validation_source = prop.validation_source;

                // If it's an array type and has items, clean them recursively
                if (prop.type === "array" && prop.items && prop.items.length > 0) {
                    cleaned.items = cleanProperties(prop.items);
                } else if (prop.type === "object" && prop.items && prop.items.length > 0) cleaned.items = cleanProperties(prop.items);

                return cleaned;
            })
            .filter(prop => {
                // Remove properties that have empty items array when type is 'array'
                if (prop.type === "array" || prop.type === "object") return prop.items && prop.items.length > 0;

                return true;
            });
    };

    // Legacy Safe
    const handleSave = () => {
        setIsLoadingAPI(true);
        let cleanedProps = cleanProperties(
            typeof editedSpace.docextractionconfig == "string" ? JSON.parse(editedSpace.docextractionconfig || "{}") : editedSpace.docextractionconfig
        );

        setEditedSpace(prev => ({ ...prev, docextractionconfig: JSON.stringify(cleanedProps) }));
        fetch("/qDocExtrConf", {
            method: "POST",
            headers: getHeaderG(loggedInUser),
            body: JSON.stringify({
                groupid: editedSpace.selectionId,
                docextractionconfig: JSON.stringify(cleanedProps),
                docextractionuseauto: editedSpace.docextractionuseauto,
                custaiinst: editedSpace.custaiinst,
                convo_config: editedSpace.convo_config,
                contenttype_config: editedSpace.contenttype_config
            })
        }).then(() => {
            setIsLoadingAPI(false);
            onEditDocExtractionDone();
        });
    };

    // Add Content Type
    const handleAddContentType = () => {
        if (newContentType.type && newContentType.description) {
            const newContentTypeObj: gcontenttype = {
                type: cleanValues(newContentType.type),
                description: newContentType.description,
                fields: [],
                autofield: newContentType.autofield
            };
            setEditedSpace(prev => ({
                ...prev,
                contenttype_config: {
                    ...prev.contenttype_config,
                    contenttype: [...prev.contenttype_config.contenttype, newContentTypeObj]
                }
            }));

            console.log("newContentTypeObj", newContentTypeObj);
            setContentTypeDetails(newContentTypeObj);
            setContentTypeOptions(prev => [...prev, { key: newContentTypeObj.type, text: newContentTypeObj.type }]);
            toggleIsCalloutVisible();
            setContentTypeSelectedOption(newContentTypeObj.type);
            setNewContentType(new_content_type_config);
        }
    };

    // Edit Content Type
    const handleEditContentType = () => {
        if (editContentType.type) {
            const updatedContentTypeObj: gcontenttype = {
                type: cleanValues(editContentType.type),
                description: editContentType.description,
                fields: contentTypeDetails?.fields || [],
                autofield: editContentType.autofield
            };

            setEditedSpace(prev => {
                const existingTypes = prev.contenttype_config.contenttype;
                const updatedTypes = existingTypes.some(ct => ct.type == contentTypeSelectedOption)
                    ? existingTypes.map(ct => (ct.type == contentTypeSelectedOption ? updatedContentTypeObj : ct))
                    : [...existingTypes, updatedContentTypeObj];
                return {
                    ...prev,
                    contenttype_config: {
                        ...prev.contenttype_config,
                        contenttype: updatedTypes
                    }
                };
            });
            setContentTypeDetails(updatedContentTypeObj);
            setContentTypeSelectedOption(editContentType.type);
            // Clear
            setEditContentType(new_content_type_config);

            toggleIsEditCalloutVisible();
        }
    };

    // Content Type Delete
    const handleDelete = () => {
        const userConfirmed = window.confirm("Are you sure you want to mark for deletion this configuration? You'll still have to save the changes.");
        if (userConfirmed) handleDeleteContentType();
    };

    // Manage Delete Cont Type
    const handleDeleteContentType = () => {
        const updatedContentTypes = editedSpace.contenttype_config.contenttype.filter(item => item.type !== contentTypeSelectedOption);
        setEditedSpace(prev => ({
            ...prev,
            contenttype_config: {
                ...prev.contenttype_config,
                contenttype: updatedContentTypes
            }
        }));
        setContentTypeSelectedOption(updatedContentTypes[0]?.type || systemExtrConfigName.type);
    };

    function handleQuestionAdd(): void {
        const newQuestion: qaconf = qconfemptyprop;
        const updatedQA = [...(contentTypeDetails?.qaconf || []), newQuestion];
        writeBackQA(updatedQA);
    }

    return (
        <div className={styles.editModalWrap}>
            <Stack styles={stackStyles} tokens={{ childrenGap: 10 }} className={styles.docFieldSettingsMainStack}>
                <div className={styles.editFormSectionTitle}>
                    <span>{"Structure Content"}</span>
                </div>
                {docExtractionSettings}

                <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.docExtrTopsettings}>
                    <Toggle
                        label="Automatic Field Determination for All Content"
                        inlineLabel
                        onText="On"
                        offText="Off"
                        checked={editedSpace.docextractionuseauto == 1}
                        onChange={(_event, checked) => {
                            handleChange("docextractionuseauto", checked ? 1 : 0);
                        }}
                    />
                </Stack>
                {editedSpace.docextractionuseauto === 0 && (
                    <div className={styles.docExtrContentType}>
                        <div className={styles.dropDownSection}>
                            {/*Title */}
                            <div className={styles.confNameTitle}>
                                <div>{contentTypeSelectedOption}</div>
                                <div className={styles.confDescr}>{contentTypeDetails?.description}</div>
                            </div>

                            <TooltipHost
                                content={<div dangerouslySetInnerHTML={{ __html: extrTypeTooltipTexts.ToggleAutoExtractionConf }} />}
                                directionalHint={DirectionalHint.topAutoEdge}
                                calloutProps={tooltipStyles}
                            >
                                {editContentType?.autofield == true ? (
                                    <IconButton
                                        title=""
                                        onClick={() => {
                                            setContentTypeDetails(prev => (prev ? { ...prev, autofield: !prev.autofield } : prev));
                                            setEditContentType(prev => (prev ? { ...prev, autofield: false } : prev));
                                        }}
                                        styles={{
                                            root: {
                                                borderRadius: "8px",
                                                height: "32px",
                                                width: "32px",
                                                marginTop: "4px"
                                            }
                                        }}
                                    >
                                        <MdOutlineAutoFixHigh size={25} color="#ff9e9e" />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        title=""
                                        onClick={() => {
                                            setContentTypeDetails(prev => (prev ? { ...prev, autofield: !prev.autofield } : prev));
                                            setEditContentType(prev => (prev ? { ...prev, autofield: true } : prev));
                                        }}
                                        styles={{
                                            root: {
                                                borderRadius: "8px",
                                                height: "32px",
                                                width: "32px",
                                                marginTop: "4px"
                                            }
                                        }}
                                    >
                                        <MdOutlineAutoFixOff size={25} color="#b38aae" />
                                    </IconButton>
                                )}
                            </TooltipHost>
                            <Dropdown
                                label="Content Types"
                                options={contentTypeOptions}
                                disabled={false}
                                className={styles.docExtrContentTypeDropdown}
                                selectedKey={contentTypeSelectedOption}
                                onChange={(_, option) => contentTypeSelectedOption !== option?.key && setContentTypeSelectedOption(option?.key as string)}
                                styles={dropdownContentTypeStyles}
                            />
                            <div className={styles.docExtrContentTypeButtons}>
                                {/* Add Conf Button */}
                                <TooltipHost
                                    content={<div dangerouslySetInnerHTML={{ __html: extrTypeTooltipTexts.AddContentType }} />}
                                    directionalHint={DirectionalHint.topAutoEdge}
                                    calloutProps={tooltipStyles}
                                >
                                    <IconButton
                                        id={"addTypeRef"}
                                        onClick={toggleIsCalloutVisible}
                                        title=""
                                        styles={{
                                            root: {
                                                borderRadius: "8px",
                                                height: "32px",
                                                width: "32px"
                                            }
                                        }}
                                    >
                                        <MdAddCircleOutline size={24} color="#00c6af" />
                                    </IconButton>
                                </TooltipHost>
                                {/* Edit Conf Button */}
                                <TooltipHost
                                    content={<div dangerouslySetInnerHTML={{ __html: extrTypeTooltipTexts.EditContentType }} />}
                                    directionalHint={DirectionalHint.topAutoEdge}
                                    calloutProps={tooltipStyles}
                                >
                                    <IconButton
                                        id={"editTypeRef"}
                                        onClick={() => {
                                            setEditContentType({
                                                type: contentTypeDetails?.type || "",
                                                description: contentTypeDetails?.description || "",
                                                autofield: contentTypeDetails?.autofield ?? true
                                            });
                                            toggleIsEditCalloutVisible();
                                        }}
                                        title=""
                                        disabled={contentTypeSelectedOption == systemExtrConfigName.type}
                                        styles={{
                                            root: {
                                                borderRadius: "8px",
                                                height: "32px",
                                                width: "32px"
                                            }
                                        }}
                                    >
                                        <RiEditCircleLine size={24} color="#2a8091" />
                                    </IconButton>
                                </TooltipHost>

                                {/* Remove Conf Button */}
                                <TooltipHost
                                    content={<div dangerouslySetInnerHTML={{ __html: extrTypeTooltipTexts.DeleteContentType }} />}
                                    directionalHint={DirectionalHint.topAutoEdge}
                                    calloutProps={tooltipStyles}
                                >
                                    <IconButton
                                        onClick={handleDelete}
                                        title=""
                                        disabled={contentTypeSelectedOption == systemExtrConfigName.type}
                                        styles={{
                                            root: {
                                                borderRadius: "8px",
                                                height: "32px",
                                                width: "32px"
                                            }
                                        }}
                                    >
                                        <TiDeleteOutline size={29} color="#c45258" />
                                    </IconButton>
                                </TooltipHost>
                            </div>
                        </div>
                        {/* QUESTIONS */}
                        {contentTypeDetails?.qaconf && contentTypeDetails?.qaconf.length < 1 ? (
                            <div className={styles.containerTasks}>
                                <h3 className={styles.extrDefTitle}>{"Tasks on Arrival"}</h3>
                                <div className={styles.docExtrAutoFieldTextDesc}>
                                    <div className={styles.noExtractDefinitionsContainerMain}>
                                        <div className={styles.noExtractDefinitionsText}>
                                            <PrimaryButton onClick={handleQuestionAdd} className={styles.addQuestionButtonV2}>
                                                <div className={styles.addButtonV2Text}>
                                                    <MdAddCircleOutline size={24} />
                                                </div>
                                                Add Question
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <QuestionSelectionV2
                                initialProperties={contentTypeDetails?.qaconf}
                                onChange={updated => {
                                    writeBackQA(updated || []);
                                }}
                                isRoot={true}
                                fileList={fileList}
                            />
                        )}

                        {/* JSON FIELD INPUT */}
                        {contentTypeDetails?.autofield != true ? (
                            <div className={styles.jsonEditDocExtr}>
                                <DocFieldSelectionV2
                                    initialProperties={contentTypeDetails?.fields}
                                    onChange={updated => {
                                        writeBackStructure(updated || []);
                                    }}
                                    isRoot={true}
                                    fileList={fileList}
                                />
                            </div>
                        ) : (
                            <div className={styles.docExtrAutoFieldText}>
                                <div className={styles.docExtrAutoFieldTextDesc}>
                                    <div className={styles.noExtractDefinitionsContainerMain}>
                                        <div className={styles.noExtractDefinitionsText}>
                                            <MdOutlineAutoFixHigh size={25} color="#ff9e9e" />
                                            <div>Auto Extraction Enabled</div>
                                            <div>The assistant will automatically determine how to structure the data and what is important to extract.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div className={styles.docExtrSaveButs}>
                    <div />
                    <PrimaryButton
                        text="Save"
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={isLoadingAPI}
                        className={styles.saveSpaceButton}
                        styles={{
                            root: { borderRadius: "8px" },
                            label: { fontSize: "14px", fontFamily: "Urbanist" }
                        }}
                    />
                </div>
            </Stack>
            {isCalloutVisible && (
                <Callout
                    className={styles.callout}
                    styles={addUrlCallout}
                    gapSpace={0}
                    target={"#addTypeRef"}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <Stack tokens={{ childrenGap: 10 }} padding={20}>
                        <div className={styles.addContentTypeCalloutTitle}>Add New Content Type</div>
                        <TooltipHost
                            content={"Descriptive Name of the Content Type"}
                            directionalHint={DirectionalHint.topAutoEdge}
                            calloutProps={tooltipStyles}
                        >
                            <TextField
                                label="Content Type Name"
                                styles={textFieldModalContentTypeCreateStyles}
                                value={newContentType.type}
                                onChange={(_, newValue) => setNewContentType(prev => ({ ...prev, type: cleanValues(newValue || "") }))}
                            />
                        </TooltipHost>
                        <TooltipHost
                            content={"Instruct the assistant on how to recognize this content type when it ingests content"}
                            directionalHint={DirectionalHint.topAutoEdge}
                            calloutProps={tooltipStyles}
                        >
                            <TextField
                                label="Content Type AI Instructions"
                                styles={textFieldModalContentTypeCreateStyles}
                                multiline
                                value={newContentType.description}
                                onChange={(_, newValue) => setNewContentType(prev => ({ ...prev, description: newValue || "" }))}
                            />
                        </TooltipHost>
                        <PrimaryButton
                            text="Add"
                            onClick={handleAddContentType}
                            styles={{
                                root: { width: "100%", borderRadius: "8px", marginTop: "10px" },
                                label: { fontSize: "14px", fontFamily: "Urbanist" }
                            }}
                        />
                    </Stack>
                </Callout>
            )}
            {isEditCalloutVisible && (
                <Callout
                    className={styles.callout}
                    styles={addUrlCallout}
                    gapSpace={0}
                    target={"#editTypeRef"}
                    onDismiss={toggleIsEditCalloutVisible}
                    setInitialFocus
                >
                    <Stack tokens={{ childrenGap: 10 }} padding={20}>
                        <div className={styles.addContentTypeCalloutTitle}>Edit Content Type</div>
                        <TooltipHost
                            content={"Descriptive Name of the Content Type"}
                            directionalHint={DirectionalHint.topAutoEdge}
                            calloutProps={tooltipStyles}
                        >
                            <TextField
                                label="Content Type Name"
                                styles={textFieldModalContentTypeCreateStyles}
                                value={editContentType.type}
                                onChange={(_, newValue) => setEditContentType(prev => ({ ...prev, type: cleanValues(newValue || "") }))}
                            />
                        </TooltipHost>
                        <TooltipHost content={extrConfConfLabels.mutliTextLabel} directionalHint={DirectionalHint.rightBottomEdge} calloutProps={tooltipStyles}>
                            <TextField
                                label="Content Type AI Instructions"
                                styles={textFieldModalContentTypeCreateStyles}
                                multiline
                                value={editContentType.description}
                                onChange={(_, newValue) => setEditContentType(prev => ({ ...prev, description: newValue || "" }))}
                            />
                        </TooltipHost>
                        <PrimaryButton
                            text="Save"
                            onClick={handleEditContentType}
                            styles={{
                                root: { width: "100%", borderRadius: "8px", marginTop: "10px" },
                                label: { fontSize: "14px", fontFamily: "Urbanist" }
                            }}
                        />
                    </Stack>
                </Callout>
            )}
        </div>
    );
};
