import React, { useState, useEffect, useContext } from "react";
import {
    Stack,
    TextField,
    TooltipHost,
    Checkbox,
    PrimaryButton,
    DirectionalHint,
    IconButton,
    Slider,
    IDropdownOption,
    Dropdown,
    ThemeProvider
} from "@fluentui/react";
import { toast } from "react-toastify";
import styles from "./SpaceEditForm.module.css";
import { ImgMapKeys, imgMap, nmeMap } from "../../lsts";
import { gselectedgroup, gspoconfig } from "../../interfaces";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)
import ImageChanger from "../ImageChanger/ImageChanger";
import TagInput from "../TagInput/TagInput";
import { assistantCardItems, multiPurposeAssistantCreationData } from "../../lsts";
import { textFieldStyles, stackStyles, tooltipStyles, textFieldStylesMultiline, dropdownStyles, sliderStyles, textSPOFieldStyles } from "../../styles_glob";
import customTheme from "./../../theme/customTheme";
import { resultCountOptions, convoLangOptions, chatbotScript, chatbotEmbed, assistantCodes } from "./../../lsts";

interface ISpaceEditFormProps {
    selectedGroup: gselectedgroup;
    onEditSpaceSave: () => void;
    onGroupDel: () => void;
}

const SpaceEditForm: React.FC<ISpaceEditFormProps> = ({ selectedGroup, onEditSpaceSave, onGroupDel }) => {
    const { loggedInUser, activeComp } = useContext(GlobalContext);
    const [editedSpace, setEditedSpace] = useState<gselectedgroup>({ ...selectedGroup });
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);
    const [apiKState, setApiKState] = useState("");
    const [shareURL, setShareURL] = useState("");
    const [anonSharelink, setAnonSharelink] = useState(false);

    useEffect(() => {
        setEditedSpace({ ...selectedGroup });
        handleShareURL(anonSharelink);
    }, [selectedGroup]);

    useEffect(() => {
        setIsLoadingAPI(false);
    }, []);

    const getHeader = () => {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    };

    const handleChange = (field: keyof gselectedgroup, value: string | number | boolean) => {
        setEditedSpace(prev => ({ ...prev, [field]: value }));
        if (field === "sharewithcode") {
            handleShareURL(anonSharelink);
            handleSave({
                sharewithcode: value as boolean
            });
        }
    };
    const handleSPOChange = (field: keyof gspoconfig, value: Partial<gspoconfig>) => {
        setEditedSpace(prev => {
            return { ...prev, spo_config: { ...prev.spo_config, ...value } };
        });
    };

    const handleShareURL = (anon: boolean) => {
        let sUrl = `${window.location.origin}?redeemCode=${encodeURIComponent(selectedGroup.selectionId)}&spt=${encodeURIComponent(
            selectedGroup.selectionText
        )}`;
        if (anon) {
            sUrl = `${sUrl}&A=1`;
        }
        setShareURL(sUrl);
    };

    const handleAnonChange = (checked: boolean) => {
        setAnonSharelink(checked);
        handleShareURL(checked);
    };

    interface SaveOptions {
        sharewithcode?: boolean;
        headerimageurl?: string;
    }

    const handleSave = (instaOptions: SaveOptions) => {
        setIsLoadingAPI(true);
        console.log("sadasdasdasdasasdasd", editedSpace.spo_config);
        fetch("/qAddGroup", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                groupid: editedSpace.selectionId,
                groupname: editedSpace.selectionText,
                companyid: "", // Doesnt Matter, Upsert will Ignore It
                description: editedSpace.description,
                custaiinst: editedSpace.custaiinst,
                sharewithcode: instaOptions?.sharewithcode ?? editedSpace.sharewithcode,
                custgreetingmessage: editedSpace.custgreetingmessage,
                isintegrateddata: editedSpace.isintegrateddata,
                allowyoutube: editedSpace.allowyoutube,
                allowweb: editedSpace.allowweb,
                enabledocaudio: editedSpace.enabledocaudio,
                retrievecount: editedSpace.retrievecount,
                sectionoverlap: editedSpace.sectionoverlap,
                maxsectionlength: editedSpace.maxsectionlength,
                sentencesearchlimit: editedSpace.sentencesearchlimit,
                inscope: editedSpace.inscope,
                temperature: editedSpace.temperature,
                responsetokenlimit: editedSpace.responsetokenlimit,
                disablecitationhighlighting: editedSpace.disablecitationhighlighting,
                strictness: editedSpace.strictness,
                invoicesgroup: editedSpace.invoicesgroup,
                questionsuggestions: editedSpace.questionsuggestions,
                nosidebysideview: editedSpace.nosidebysideview,
                showexamplequestionsonstart: editedSpace.showexamplequestionsonstart,
                headerimageurl: instaOptions?.headerimageurl ?? editedSpace.headerimageurl,
                manualquestionsuggestions: editedSpace.manualquestionsuggestions,
                usedocintel: editedSpace.usedocintel,
                allowaudiodocentry: editedSpace.allowaudiodocentry,
                forceocr: editedSpace.forceocr,
                allowvideofiles: editedSpace.allowvideofiles,
                convomode: editedSpace.convomode,
                convolang: editedSpace.convolang,
                hideaisearch: editedSpace.hideaisearch,
                urllinkextraction: editedSpace.urllinkextraction,
                docextractionuseauto: editedSpace.docextractionuseauto,
                spo_config: editedSpace.spo_config
            })
        }).then(() => {
            setIsLoadingAPI(false);
            onEditSpaceSave();
        });
    };
    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {})
            .catch(err => {});

        toast.success("Copied to Clipboard");
    };
    const delSpace = async () => {
        const groupid = selectedGroup.selectionId;
        fetch("/qDelGroup", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ groupid: groupid })
        }).then(Response => {
            onGroupDel();
        });
    };
    const handleDelete = () => {
        const userConfirmed = window.confirm("Are you sure you want to delete this space?");
        if (userConfirmed) {
            delSpace();
        }
    };

    const qGetK = () => {
        const params = new URLSearchParams({ groupid: selectedGroup.selectionId });
        const url = "/qGetK" + `?${params.toString()}`;
        fetch(url, {
            method: "GET",
            headers: getHeader()
        }).then((res: any) => {
            if (res.ok) {
                const apik_arr = res.json().then((data: any) => {
                    if (data && data.length > 0) {
                        const apikey = data[0]?.apikey;
                        setApiKState(apikey);
                    }
                });
            }
        });
    };
    const onImageChange = (imageUrl: string) => {
        setEditedSpace(prev => ({ ...prev, headerimageurl: imageUrl }));
        handleSave({
            headerimageurl: imageUrl
        });
    };
    const onHardDefinedQuestionsChange = (tags: string[]) => {
        setEditedSpace(prev => ({ ...prev, manualquestionsuggestions: tags.join(";") }));
    };
    return (
        <div className={styles.editModalWrap}>
            <ThemeProvider theme={customTheme}>
                <Stack styles={stackStyles}>
                    <div className={styles.editFormSectionTitle}>
                        <span>Name, Description and Image</span>
                    </div>
                    <TextField
                        className={styles.spaceTextFieldsStack}
                        styles={textFieldStyles}
                        label="Space Name"
                        value={editedSpace.selectionText}
                        onChange={(_, newValue) => handleChange("selectionText", newValue || "")}
                    />
                    <TextField
                        className={styles.spaceTextFieldsStack}
                        styles={textFieldStyles}
                        label="Space Description"
                        value={editedSpace.description}
                        onChange={(_, newValue) => handleChange("description", newValue || "")}
                    />
                    <TooltipHost
                        content="Purpose of the Assistant determines the feature set and orchestration paths for the Assistant. Currenly not changeable after creation"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <TextField
                            className={styles.spaceTextFieldsStack}
                            styles={textFieldStyles}
                            label="Assistant Type"
                            disabled={true}
                            value={nmeMap[(editedSpace?.assistanttype ?? "anya") as ImgMapKeys]}
                            onChange={(_, newValue) => handleChange("description", newValue || "")}
                        />
                    </TooltipHost>
                    <div className={styles.custLabelMrgn}>
                        <span>Change Assistant Card Image</span>
                    </div>
                    <div>
                        <ImageChanger
                            defaultImage={imgMap[(editedSpace?.assistanttype ?? "anya") as ImgMapKeys]}
                            customImage={editedSpace?.headerimageurl}
                            onImageChange={onImageChange}
                        />
                    </div>
                </Stack>
                <Stack styles={stackStyles}>
                    <div className={styles.editFormSectionTitle}>
                        <span>Greeting Configuration</span>
                    </div>
                    <TooltipHost
                        content="Message to show when a new conversation is started. Supports markdown."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <TextField
                            className={styles.spaceTextFields}
                            styles={textFieldStylesMultiline}
                            label="Greeting Message"
                            description="Supports Markdown. Bold: **Bold Text**. Italic: *Italic Text. # for Heading 1. ## for Heading 2. For more then one New Line use <br>. For Bullet Points use - or *"
                            multiline
                            resizable={false}
                            value={
                                editedSpace.custgreetingmessage ||
                                (editedSpace.assistanttype == "anya" || editedSpace.assistanttype == ""
                                    ? multiPurposeAssistantCreationData.custgreetingmessage
                                    : assistantCardItems.filter(item => item.creationData.assistanttype === editedSpace.assistanttype)[0]?.creationData
                                          .custgreetingmessage)
                            }
                            onChange={(_, newValue) => handleChange("custgreetingmessage", newValue || "")}
                        />
                    </TooltipHost>
                    <div className={styles.custLabelTagTwoInput}>
                        <span>Greeting Example Questions</span>
                    </div>
                    <TooltipHost
                        content="3 AI Genarated Example Questions will be shown to the user when the Assistant is started"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Enable AI Generated Question Suggestion in Assistant Greeting Message"
                            disabled={false}
                            checked={editedSpace.showexamplequestionsonstart == 1}
                            onChange={(_, checked) => handleChange("showexamplequestionsonstart", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <div className={styles.groupFunc}>
                        <TooltipHost
                            content="Define questions to be shown to the User when the Assistant is started. If AI Generated Questions are enabled, these will be shown before the AI Generated Questions"
                            directionalHint={DirectionalHint.bottomLeftEdge}
                            calloutProps={tooltipStyles}
                        >
                            <div className={styles.custLabelTagInput}>
                                <span>Add Manual Example Questions in Assistant Greeting</span>
                            </div>
                        </TooltipHost>
                        <TagInput
                            onChange={onHardDefinedQuestionsChange}
                            palceholderText="Add an Example Question and press Enter"
                            initialTags={editedSpace.manualquestionsuggestions != "" ? editedSpace.manualquestionsuggestions.split(";") : []}
                        />
                    </div>
                </Stack>
                <Stack styles={stackStyles}>
                    <div className={styles.editFormSectionTitle}>
                        <span>Input Features</span>
                    </div>
                    <TooltipHost
                        content="Enable natural conversations with the Assistant via Speech."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Conversational Mode"
                            disabled={false}
                            checked={editedSpace.convomode == 1}
                            onChange={(_, checked) => handleChange("convomode", checked ? 1 : 0)}
                        />
                        {/*<div className={styles.betaFeaturesDetails}>Show New Features</div>*/}
                    </TooltipHost>
                    <TooltipHost
                        content="Specifying Language Improves Speech Rercognition"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Dropdown
                            label="Speech Recognition Default Language"
                            options={convoLangOptions}
                            disabled={editedSpace.convomode == 1 ? false : true}
                            selectedKey={editedSpace.convolang || "auto"}
                            onChange={(_, option) => handleChange("convolang", `${option?.key}`)}
                            styles={dropdownStyles}
                        />
                    </TooltipHost>
                </Stack>
                <Stack styles={stackStyles}>
                    <div className={styles.editFormSectionTitle}>
                        <span>Response Features</span>
                    </div>
                    <TooltipHost
                        content="The AI Model will add followup question suggestions to the response."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Enable Followup Question Suggestions in Assistant Responses"
                            disabled={false}
                            checked={editedSpace.questionsuggestions == 1}
                            onChange={(_, checked) => handleChange("questionsuggestions", checked ? 1 : 0)}
                        />
                        {/*<div className={styles.betaFeaturesDetails}>Show New Features</div>*/}
                    </TooltipHost>
                    <TooltipHost
                        content="Disable citations and one-click document citation navigation. This setting disables core functionality of the Web App and is intended for integrated spaces or other special cases"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Disable Citations"
                            disabled={false}
                            checked={editedSpace.disablecitationhighlighting == 1}
                            onChange={(_, checked) => handleChange("disablecitationhighlighting", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Hide AI Search Results on top of each answer."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Hide AI Search Results"
                            disabled={false}
                            checked={editedSpace.hideaisearch == 1}
                            onChange={(_, checked) => handleChange("hideaisearch", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost content="Add option to speak the content" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Enable Content Audio"
                            disabled={false}
                            checked={editedSpace.enabledocaudio == 1}
                            onChange={(_, checked) => handleChange("enabledocaudio", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Allow AI Model to use information outside of the supplied sources (General AI Chat experience)"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Allow external knowledge"
                            disabled={false}
                            checked={!(editedSpace.inscope == 1)}
                            onChange={(_, checked) => handleChange("inscope", checked ? 0 : 1)}
                        />
                    </TooltipHost>
                </Stack>
                <Stack styles={stackStyles}>
                    <div className={styles.editFormSectionTitle}>
                        <span>Response AI Configuration</span>
                    </div>
                    {selectedGroup.assistanttype != assistantCodes.cca && (
                        <TooltipHost
                            content="Instructions sent to Language Model containing details how to respond"
                            directionalHint={DirectionalHint.bottomLeftEdge}
                            calloutProps={tooltipStyles}
                        >
                            <TextField
                                className={styles.spaceTextFields}
                                styles={textFieldStylesMultiline}
                                label="AI Instructions"
                                multiline
                                resizable={false}
                                value={
                                    editedSpace.custaiinst ||
                                    (editedSpace.assistanttype == "anya" || editedSpace.assistanttype == ""
                                        ? multiPurposeAssistantCreationData.custaiinst
                                        : assistantCardItems.filter(item => item.creationData.assistanttype === editedSpace.assistanttype)[0]?.creationData
                                              .custaiinst)
                                }
                                onChange={(_, newValue) => handleChange("custaiinst", newValue || "")}
                            />
                        </TooltipHost>
                    )}

                    <TooltipHost
                        content="The amount of search results to be sent to the AI Model"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Dropdown
                            label="Search Results Count"
                            options={resultCountOptions}
                            selectedKey={editedSpace.retrievecount.toString()}
                            onChange={(_, option) => handleChange("retrievecount", Number(option?.key))}
                            styles={dropdownStyles} // If you want to apply custom styles
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="How creative the AI Model should be when generating responses (Higher is more creative)"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Slider
                            styles={sliderStyles}
                            label="AI Temperature (Creativity)"
                            min={0}
                            max={1}
                            step={0.1}
                            defaultValue={editedSpace.temperature}
                            showValue
                            onChange={value => handleChange("temperature", value)}
                        />
                    </TooltipHost>

                    <TooltipHost
                        content="The amount of relevance needed for a search result to be included in the final results. Higher is more strict leading to less results."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Slider
                            styles={sliderStyles}
                            label="Search Relevance Strictness"
                            min={1}
                            max={5}
                            step={1}
                            defaultValue={editedSpace.strictness}
                            showValue
                            onChange={value => handleChange("strictness", value)}
                        />
                    </TooltipHost>
                    <TooltipHost content="Limit how long the responses can be" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                        <Slider
                            styles={sliderStyles}
                            label="Response Token Limit"
                            min={500}
                            max={4096}
                            step={1}
                            defaultValue={editedSpace.responsetokenlimit}
                            showValue
                            onChange={value => handleChange("responsetokenlimit", value)}
                        />
                    </TooltipHost>
                </Stack>
                <Stack styles={stackStyles}>
                    <div className={styles.editFormSectionTitle}>
                        <span>Content Configuration</span>
                    </div>
                    <div className={styles.custLabelMrgn}>
                        <span>Content Sources</span>
                    </div>
                    <TooltipHost
                        content="Allow Default Content Types. PDF, Images and some Office Documents. Some might be missing in Free/Demo "
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Default"
                            disabled={true}
                            checked={true}
                            onChange={(_, checked) => handleChange("allowweb", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost content="Allow Web Pages to added as content." directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Web Page"
                            disabled={false}
                            checked={editedSpace.allowweb == 1}
                            onChange={(_, checked) => handleChange("allowweb", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Allow Entry of Content via Microphone. Supports Conversations"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Conversation"
                            disabled={false}
                            checked={editedSpace.allowaudiodocentry == 1}
                            onChange={(_, checked) => handleChange("allowaudiodocentry", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Allows users to define a URL from which Links are to be followed and extracted. The user writes instructions about what it wants extracted"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Web Page Link Extraction"
                            disabled={false}
                            checked={editedSpace.urllinkextraction == 1}
                            onChange={(_, checked) => handleChange("urllinkextraction", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Upload Video Files to be added as content. Structures audio/video content into optical character recognized key frames and speech-to-text and makes them avalable to the Assistant. (Feature not always available in free/demo version)"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Video Files (Availability may vary)"
                            disabled={false}
                            checked={editedSpace.allowvideofiles == 1}
                            onChange={(_, checked) => handleChange("allowvideofiles", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content={
                            <div>
                                <span className={styles.tooltipYoutubeNormal}>Enable pure video/audio comprehension.</span>
                                <span className={styles.tooltipYoutubeNormal}>
                                    Structures audio/video content into optical character recognized key frames and speech-to-text and makes them avalable to
                                    the Assistant.
                                </span>{" "}
                                <span className={styles.tooltipYoutubeRed}>
                                    Feature not always available in free/demo version. For production use, please contact us for more information
                                </span>
                            </div>
                        }
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Youtube (Availability may vary)"
                            disabled={true}
                            checked={editedSpace.allowyoutube == 1}
                            onChange={(_, checked) => handleChange("allowyoutube", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <div className={styles.custLabelMrgn}>
                        <span>Integrations</span>
                    </div>
                    <TooltipHost content="Setup SharePoint Synchronization" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="SharePoint"
                            disabled={false}
                            checked={editedSpace.spo_config.enabled == 1}
                            onChange={(_, checked) => handleSPOChange("enabled", { enabled: checked ? 1 : 0 })}
                        />
                    </TooltipHost>
                    <div className={`${styles.spoFields} ${editedSpace.spo_config.enabled == 1 ? styles.show : styles.hide}`}>
                        {editedSpace.spo_config.enabled == 1 && (
                            <>
                                <TextField
                                    className={styles.spaceTextFieldsStack}
                                    styles={textSPOFieldStyles}
                                    label="Tenant Domain"
                                    value={editedSpace.spo_config.tenant_id}
                                    onChange={(_, newValue) => handleSPOChange("tenant_id", { tenant_id: newValue || "" })}
                                />
                                <TextField
                                    className={styles.spaceTextFieldsStack}
                                    styles={textSPOFieldStyles}
                                    label="Full Library URL"
                                    value={editedSpace.spo_config.full_lib_url}
                                    onChange={(_, newValue) => handleSPOChange("full_lib_url", { full_lib_url: newValue || "" })}
                                />
                                <TextField
                                    className={styles.spaceTextFieldsStack}
                                    styles={textSPOFieldStyles}
                                    label="Graph Full URL"
                                    value={editedSpace.spo_config.graph_full_url}
                                    onChange={(_, newValue) => handleSPOChange("graph_full_url", { graph_full_url: newValue || "" })}
                                />
                                <TextField
                                    className={styles.spaceTextFieldsStack}
                                    styles={textSPOFieldStyles}
                                    label="Client ID"
                                    value={editedSpace.spo_config.client_id}
                                    onChange={(_, newValue) => handleSPOChange("client_id", { client_id: newValue || "" })}
                                />
                                <TextField
                                    className={styles.spaceTextFieldsStack}
                                    styles={textSPOFieldStyles}
                                    label="Client Secret"
                                    type="password"
                                    value={editedSpace.spo_config.client_secret}
                                    onChange={(_, newValue) => handleSPOChange("client_secret", { client_secret: newValue || "" })}
                                />
                            </>
                        )}
                    </div>
                    <TooltipHost
                        content="Confluence Synchronization. Available upon inquiry"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Confluence"
                            disabled={true}
                            checked={false}
                            onChange={(_, checked) => handleSPOChange("enabled", { enabled: checked ? 1 : 0 })}
                        />
                    </TooltipHost>
                    <div className={styles.custLabelMrgn}>
                        <span>Advanced Data Extraction</span>
                    </div>
                    <TooltipHost
                        content="Set this Assistant to be dedicated to Data Extraction. For now Invoice Processing and General Form Extraction are supported"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Enable Data Structure & Extraction"
                            disabled={false}
                            checked={editedSpace.invoicesgroup == 1}
                            onChange={(_, checked) => handleChange("invoicesgroup", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Use Azure Document Intelligence, for better document sctructure extraction. Improves the quality of the extracted data and subsequently the Success Rate"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Enable Document Intelligence"
                            disabled={false}
                            checked={editedSpace.usedocintel == 1}
                            onChange={(_, checked) => handleChange("usedocintel", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Apply OCR and Visual AI even to documents with available text. "
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Force OCR and Visual AI"
                            disabled={false}
                            checked={editedSpace.forceocr == 1}
                            onChange={(_, checked) => handleChange("forceocr", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Each content is split into smaller sections to be added to the Vector Index. This setting determines the legth in characters of each section (Requires Reindexing)"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Slider
                            styles={sliderStyles}
                            label="Document Section Length"
                            min={500}
                            max={15000}
                            step={100}
                            defaultValue={editedSpace.maxsectionlength}
                            showValue
                            onChange={value => handleChange("maxsectionlength", value)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Length of content in characters added at the start and end of each section, to help with keeping context between sections (Requres Reindexing)"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Slider
                            styles={sliderStyles}
                            label="Section Overlap"
                            min={100}
                            max={500}
                            step={10}
                            defaultValue={editedSpace.sectionoverlap}
                            showValue
                            onChange={value => handleChange("sectionoverlap", value)}
                        />
                    </TooltipHost>
                    <TooltipHost
                        content="Use Microsoft Azure OpenAI Service hosted on the Microsoft Cloud (contact for more options like OpenAI, Claude, Self-Hosted Llamma, others)"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Use Microsoft Azure OpenAI Implementation"
                            disabled={true}
                            checked={editedSpace.isintegrateddata === 1}
                            onChange={(_, checked) => handleChange("isintegrateddata", checked ? 1 : 0)}
                        />
                    </TooltipHost>
                </Stack>
                <Stack styles={stackStyles}>
                    <div className={styles.editFormSectionTitle}>
                        <span>Sharing Options</span>
                    </div>

                    <div className={styles.custLabel}>
                        <span>Share Space with Link (applies immediately)</span>
                    </div>
                    <TooltipHost
                        content="Space will be accessible through a special link to anybody with the link. Options for link sharing will become available to the Contributors and Owners through the Sharing Icon in the Space"
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <Checkbox
                            className={styles.editSpaceCheckbox}
                            label="Allow Space to be accessed with Code"
                            checked={editedSpace.sharewithcode}
                            onChange={(_, newValue) => handleChange("sharewithcode", newValue || "")}
                        />
                    </TooltipHost>
                    {editedSpace.sharewithcode == true ? (
                        <div className={styles.editSpaceShareCode}>
                            <span>
                                <div className={styles.redeemCodeBox}>
                                    {shareURL}
                                    <button className={styles.copyRedeemCodeButton} onClick={e => copyToClipboard(shareURL)}>
                                        Copy
                                    </button>
                                </div>
                            </span>
                            <TooltipHost
                                content="Don't require user to signup/login to access the Space (Quest Access)"
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                calloutProps={tooltipStyles}
                            >
                                <Checkbox
                                    className={styles.editAnonSpaceCheckbox}
                                    label="Get Anonymous Link"
                                    disabled={false}
                                    checked={anonSharelink}
                                    onChange={(_, checked) => handleAnonChange(checked || false)}
                                />
                            </TooltipHost>
                            <br />
                        </div>
                    ) : null}

                    <div className={styles.custLabelMrgn}>
                        <span>Embed in Web Sites [Beta]</span>
                    </div>
                    <span>
                        <div className={styles.redeemCodeBox}>
                            To add Kognify as an expandable icon at the bottom right of your website page, put the generated code at the bottom of your page's
                            HTML after the closing body tag.
                            <button
                                className={styles.copyRedeemCodeButton}
                                onClick={e =>
                                    copyToClipboard(
                                        chatbotScript(
                                            activeComp.companyid,
                                            selectedGroup.selectionId,
                                            selectedGroup.selectionText,
                                            false,
                                            selectedGroup.assistanttype == assistantCodes.prd
                                        )
                                    )
                                }
                            >
                                Get Script Code (requiring login)
                            </button>
                            <button
                                className={`${editedSpace.sharewithcode == true ? styles.copyRedeemCodeButton : styles.copyRedeemCodeButtonDisabled}`}
                                onClick={e =>
                                    copyToClipboard(
                                        chatbotScript(
                                            activeComp.companyid,
                                            selectedGroup.selectionId,
                                            selectedGroup.selectionText,
                                            true,
                                            selectedGroup.assistanttype == assistantCodes.prd
                                        )
                                    )
                                }
                                disabled={editedSpace.sharewithcode == false}
                                title={`${editedSpace.sharewithcode == true ? "" : "Enable Share with Code to get this code"}`}
                            >
                                Get Script Code (quest)
                            </button>
                        </div>
                    </span>
                    <span>
                        <div className={styles.redeemCodeBox}>
                            To embed Kognify as an iFrame window
                            <button
                                className={styles.copyRedeemCodeButton}
                                onClick={e =>
                                    copyToClipboard(chatbotEmbed(activeComp.companyid, selectedGroup.selectionId, selectedGroup.selectionText, false))
                                }
                            >
                                Get Embed Code (requiring login)
                            </button>
                            <button
                                className={`${editedSpace.sharewithcode == true ? styles.copyRedeemCodeButton : styles.copyRedeemCodeButtonDisabled}`}
                                onClick={e =>
                                    copyToClipboard(chatbotEmbed(activeComp.companyid, selectedGroup.selectionId, selectedGroup.selectionText, false))
                                }
                                disabled={editedSpace.sharewithcode == false}
                                title={`${editedSpace.sharewithcode == true ? "" : "Enable Share with Code to get this code"}`}
                            >
                                Get Embed Code (quest)
                            </button>
                        </div>
                    </span>
                </Stack>
                <Stack styles={stackStyles}>
                    {selectedGroup.permissionlevel >= 3 && (
                        <div className={styles.editFormSectionTitle}>
                            <span>API Access</span>
                        </div>
                    )}
                    {selectedGroup.permissionlevel >= 3 && (
                        <div className={styles.userIdCont}>
                            <div className={styles.userIdLabel}>ID</div>
                            <div className={styles.useridText} onClick={() => copyToClipboard(loggedInUser.userId)}>
                                {loggedInUser.userId}
                            </div>
                        </div>
                    )}

                    {selectedGroup.permissionlevel >= 3 && (
                        <div className={styles.apikcontained}>
                            To access this space through API
                            <button className={styles.copyRedeemCodeButton} onClick={e => qGetK()}>
                                Show API Key
                            </button>
                            {apiKState && (
                                <div className={styles.apikeystring} title="Copy" onClick={() => copyToClipboard(apiKState)}>
                                    {apiKState}
                                </div>
                            )}
                        </div>
                    )}
                </Stack>
                <div className={styles.modalBottomButs}>
                    <IconButton className={styles.delSpaceButtons} iconProps={{ iconName: "Delete" }} onClick={handleDelete} />
                    <PrimaryButton
                        text="Save"
                        onClick={() => {
                            handleSave({});
                        }}
                        disabled={isLoadingAPI}
                        className={styles.saveSpaceButton}
                    />
                </div>
            </ThemeProvider>
        </div>
    );
};

export default SpaceEditForm;
