import React, { useState, useContext, FormEvent, ChangeEvent, useRef, useEffect } from "react";
import { Button } from "@fluentui/react-components";
import { Callout, ActionButton, PrimaryButton, Label, Text, Modal, DirectionalHint, TooltipHost, TextField } from "@fluentui/react";
import {
    addUrlCallout,
    addUrlPrimaryButton,
    addUrlYouTubeCallout,
    siteMapPrimaryButton,
    textFieldStylesMultilineSiteMap,
    textFieldSiteMapStyles
} from "../../styles_glob";
import { FaMicrophone } from "react-icons/fa";
import { LuFileVideo } from "react-icons/lu";
import { TbSitemap } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { CgWebsite, CgFileAdd } from "react-icons/cg";
import { FiYoutube } from "react-icons/fi";

import styles from "./AddURLs.module.css";
import { publishTranscriptDraft, getSiteLinks, IUploadResponse, addURLApi, uploadFileApi } from "../../api";
import { onSTT, isValidURL, getBaseURL } from "../../util_glob";
import MultiTagURLInput from "./multiTagURLInput";

import ConversationRecognizer from "../../components/SpeechRecognizer/ConversationRecognizer";
import { gselectedgroup, gingestioniteminfo, gtranscriptpublish, guploadfileinfodata, gailinks } from "../../interfaces";
import { GlobalContext } from "../../GlobalContext";

interface Item {
    id: string;
    value: string;
}

interface Props {
    className?: string;
    updateCatsState: (newValue: string) => void;
    butName: string;
    calloutLabelText: string;
    cTy: string;
    listRefreshRequest: () => void;
    callOutVisibility: (isVisible: boolean) => void;
    selectedGroup: gselectedgroup;
    hideCallout?: boolean;
}

export const AddURLs: React.FC<Props> = ({
    className,
    updateCatsState,
    butName,
    calloutLabelText,
    cTy,
    listRefreshRequest,
    selectedGroup,
    callOutVisibility,
    hideCallout
}) => {
    const { user, activeComp, loggedInUser } = useContext(GlobalContext);
    const [isYoutubeCalloutVisible, setIsYoutubeCalloutVisible] = useState<boolean>(false);
    const [isPDFCalloutVisible, setIsPDFCalloutVisible] = useState<boolean>(false);
    const [isMicModalVisible, setIsMicModalVisible] = useState<boolean>(false);
    const [isSiteMapModalVisible, setIsSiteMapModalVisible] = useState<boolean>(false);
    const [isYoutubeLoading, setIsYoutubeLoading] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uploadedFile, setUploadedFile] = useState<IUploadResponse>();
    const [textYWInput, setTextYWInput] = useState<string>("");
    const [siteMapActive, setSiteMapActive] = useState<boolean>(false);
    const [siteMapInstr, setSiteMapInstr] = useState<string>("");
    const [siteMapURL, setSiteMapURL] = useState<string>("");
    const [siteMapAILinks, setSiteMapAILinks] = useState<gailinks | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [items, setItems] = useState<Item[]>([]);
    const trgtCllrRef = useRef<any>();

    // WEB¦YOUTUBE SUBMIT
    const handleTextSubmit = async (ev: React.FormEvent) => {
        ev.preventDefault();
        setIsYoutubeLoading(true);
        setIsYoutubeCalloutVisible(false);
        try {
            let itemInfo: gingestioniteminfo[] = createInfoListForWebURL(textYWInput ? textYWInput.split(";") : [], selectedGroup);
            const response: IUploadResponse = await addURLApi(textYWInput, selectedGroup, activeComp.companyid, user.userId, cTy, itemInfo, listRefreshRequest);
            setItems([]);
            updateDynamicCatsState(textYWInput);
        } catch (error) {
            console.error(error);
        } finally {
            setIsYoutubeLoading(false);
            setIsYoutubeCalloutVisible(!isYoutubeCalloutVisible);
        }
    };
    // LINK MAP SUBMIT
    const handleURLMapSubmit = async () => {
        setIsYoutubeLoading(true);
        setIsYoutubeCalloutVisible(false);
        try {
            let itemList = siteMapAILinks?.requested_links ? siteMapAILinks?.requested_links.map(a => a[2]).slice(0, 100) : [];
            let itemInfo: gingestioniteminfo[] = createInfoListForWebURL(itemList, selectedGroup);
            console.log("itemInfo", itemInfo);
            const response: IUploadResponse = await addURLApi(
                itemList.join(";"),
                selectedGroup,
                activeComp.companyid,
                user.userId,
                cTy,
                itemInfo,
                listRefreshRequest
            );
            setItems([]);
            setSiteMapAILinks(null);
            updateDynamicCatsState(textYWInput);
        } catch (error) {
            console.error(error);
        } finally {
            setIsYoutubeLoading(false);
            setIsYoutubeCalloutVisible(!isYoutubeCalloutVisible);
        }
    };
    // PDF¦MP4 SUMBIT
    const handleUploadClick = async (ev: FormEvent) => {
        ev.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        const fileList = new Array();
        selectedFiles.forEach((file, index) => {
            formData.append(`files`, file);
            fileList.push(file.name);
        });
        let fileInfoArray: gingestioniteminfo[] = createInfoListForFiles(selectedFiles, selectedGroup);
        formData.append("fileInfo", JSON.stringify(fileInfoArray));
        try {
            const request: FormData = formData;
            setIsPDFCalloutVisible(false);
            uploadFileApi(
                request,
                loggedInUser,
                listRefreshRequest,
                selectedFiles.some(file => file.name.endsWith(".mp4"))
            );
            setUploadedFile(undefined);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
        updateCatsState(fileList.join(";"));
        setSelectedFiles([]);
    };

    const onItemsChange = (items: Item[]) => {
        setItems(items);
        setTextYWInput(items.map(item => item.value).join(";"));
    };
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUploadedFile(undefined);
        const selectedFileList: File[] = [];
        if (e.target.files) {
            for (let i = 0; i < e.target.files.length; i++) {
                selectedFileList.push(e.target.files.item(i)!);
            }
        }
        setSelectedFiles(selectedFileList);
    };
    const handleRemoveFile = (fileToRemove: File) => {
        const filteredFiles = selectedFiles.filter(file => file !== fileToRemove);
        setSelectedFiles(filteredFiles);
    };
    // PDF
    const createInfoListForWebURL = (selectedFiles: string[], selectedGroup: gselectedgroup): gingestioniteminfo[] => {
        let fileInfoArray: any[] = [];
        selectedFiles.forEach((itmName, index) => {
            let fileInfo: guploadfileinfodata = {
                fileSpace: selectedGroup.selectionId,
                company: activeComp.companyid,
                userId: user.userId,
                fdFlName: itmName,
                fdFlSize: "0",
                sectionoverlap: selectedGroup?.sectionoverlap?.toString(),
                maxsectionlength: selectedGroup?.maxsectionlength?.toString(),
                sentencesearchlimit: selectedGroup?.sentencesearchlimit?.toString(),
                invoicesgroup: selectedGroup?.invoicesgroup?.toString(),
                usedocintel: selectedGroup?.usedocintel?.toString(),
                forceocr: selectedGroup?.forceocr?.toString(),
                assistanttype: selectedGroup?.assistanttype ?? "",
                contenttype_config: selectedGroup.contenttype_config
            };
            fileInfoArray.push(fileInfo);
        });
        return fileInfoArray;
    };
    const createInfoListForFiles = (selectedFiles: File[], selectedGroup: gselectedgroup): gingestioniteminfo[] => {
        let fileInfoArray: any[] = [];
        selectedFiles.forEach((file, index) => {
            let fileInfo: guploadfileinfodata = {
                fileSpace: selectedGroup.selectionId,
                company: activeComp.companyid,
                userId: user.userId,
                fdFlName: file.name,
                fdFlSize: file.size.toString(),
                sectionoverlap: selectedGroup?.sectionoverlap?.toString(),
                maxsectionlength: selectedGroup?.maxsectionlength?.toString(),
                sentencesearchlimit: selectedGroup?.sentencesearchlimit?.toString(),
                invoicesgroup: selectedGroup?.invoicesgroup?.toString(),
                usedocintel: selectedGroup?.usedocintel?.toString(),
                forceocr: selectedGroup?.forceocr?.toString(),
                assistanttype: selectedGroup?.assistanttype ?? "",
                contenttype_config: selectedGroup.contenttype_config
            };
            fileInfoArray.push(fileInfo);
        });
        return fileInfoArray;
    };
    const publishTranscriptDraftHandler = async (trnscptPbls: gtranscriptpublish, listRefreshRequest: () => void) => {
        try {
            await publishTranscriptDraft(trnscptPbls, listRefreshRequest);
        } catch (error) {
            console.log("errr_au_0009", error);
        }
    };
    const updateDynamicCatsState = (textYWInput: string) => {
        if (textYWInput.includes("youtube.com") && cTy == "youtube") {
            updateCatsState(textYWInput);
        } else if (textYWInput.includes("https") && cTy == "web") {
            updateCatsState(textYWInput);
        } else {
            updateCatsState(`${textYWInput} ¦Unsuported URL ⇛ Only Youtube Supported for Now`);
        }
    };

    // MAIN CLICK HANDLER
    const handleUploadTypeButtonClick = () => {
        trgtCllrRef.current = document.getElementById(`calloutButton_${cTy}`);
        setIsVisible(cTy);
    };

    // HIDDEN CLICK HANDLER ?
    const handleProgButtonClick = () => {
        trgtCllrRef.current = document.getElementById(`upldCrd_${cTy}`);
        setIsVisible(cTy);
    };
    const setIsVisible = (cTy: string) => {
        if (cTy == "pdf" || cTy == "vid") {
            setIsPDFCalloutVisible(true);
        } else if (cTy == "mic") {
            setIsMicModalVisible(true);
        } else if (cTy == "map") {
            setIsSiteMapModalVisible(true);
        } else {
            setIsYoutubeCalloutVisible(true);
        }
    };
    const getLinksMap = async () => {
        setSiteMapAILinks(null);
        setIsYoutubeLoading(true);
        setIsYoutubeCalloutVisible(false);
        try {
            const webResp = await getSiteLinks(siteMapURL, siteMapInstr, loggedInUser);
            let baseURL = getBaseURL(siteMapURL);
            const updatedLinks = webResp?.requested_links?.map(link => {
                if (link && link[2].indexOf("http") === -1) {
                    return { ...link, 2: (baseURL + "/" + link[2]).replace(/\/\//g, "/") };
                }
                return link;
            });
            webResp.requested_links = updatedLinks;
            console.log("webResp", webResp);
            setSiteMapAILinks(webResp);
        } catch (error) {
            console.error(error);
        } finally {
            setIsYoutubeLoading(false);
        }
    };
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div>
                <ActionButton
                    className={styles.btn_action}
                    allowDisabledFocus
                    onClick={handleUploadTypeButtonClick}
                    disabled={
                        cTy == "pdf"
                            ? false
                            : cTy == "vid" && selectedGroup.allowvideofiles == 1
                            ? false
                            : cTy == "youtube" && selectedGroup.allowyoutube == 1
                            ? false
                            : cTy == "web" && selectedGroup.allowweb == 1
                            ? false
                            : cTy == "mic" && selectedGroup.allowaudiodocentry == 1
                            ? false
                            : cTy == "map"
                            ? false
                            : true
                    }
                >
                    <span className={styles.iconCntnr}>
                        {cTy == "youtube" ? (
                            <FiYoutube size={20} />
                        ) : cTy == "web" ? (
                            <CgWebsite size={20} />
                        ) : cTy == "vid" ? (
                            <LuFileVideo size={20} />
                        ) : cTy == "mic" ? (
                            <FaMicrophone size={22} />
                        ) : cTy == "map" ? (
                            <TbSitemap size={22} />
                        ) : (
                            <CgFileAdd size={20} />
                        )}
                    </span>
                </ActionButton>
                <div id={`hidB_${cTy}`} className={styles.hiddenButTrig} onClick={handleProgButtonClick}></div>

                {/*WEB LINK SCRAPER CALLOUT*/}
                {isSiteMapModalVisible && hideCallout != true && (
                    <Callout
                        styles={addUrlYouTubeCallout}
                        role="dialog"
                        gapSpace={0}
                        className={styles.callout}
                        target={trgtCllrRef.current}
                        onDismiss={() => setIsSiteMapModalVisible(false)}
                    >
                        <form onSubmit={handleURLMapSubmit}>
                            <TextField
                                label="URL"
                                value={siteMapURL}
                                onChange={(ev, newValue) => setSiteMapURL(newValue ?? "")}
                                styles={textFieldSiteMapStyles}
                                disabled={isYoutubeLoading}
                            />
                            <TextField
                                label="AI Instructions"
                                multiline
                                rows={4}
                                value={siteMapInstr}
                                placeholder=""
                                styles={textFieldStylesMultilineSiteMap}
                                onChange={(ev, newValue) => setSiteMapInstr(newValue ?? "")}
                                disabled={isYoutubeLoading}
                            />
                            <div className={styles.addButAndLoad}>
                                <div className={styles.addAndConfig}>
                                    <PrimaryButton
                                        styles={addUrlPrimaryButton}
                                        type="submit"
                                        className={styles.submit}
                                        disabled={
                                            isYoutubeLoading ||
                                            siteMapURL.length < 1 ||
                                            siteMapInstr.length < 1 ||
                                            !isValidURL(siteMapURL) ||
                                            (siteMapAILinks?.requested_links || []).length < 1
                                        }
                                    >
                                        Add
                                    </PrimaryButton>
                                    <TooltipHost
                                        content="Extract Content of Links from the specified URL with AI"
                                        directionalHint={DirectionalHint.bottomAutoEdge}
                                    >
                                        <PrimaryButton
                                            className={styles.siteMapButton}
                                            disabled={isYoutubeLoading || siteMapURL.length < 1 || siteMapInstr.length < 1 || !isValidURL(siteMapURL)}
                                            styles={siteMapPrimaryButton}
                                            onClick={() => getLinksMap()}
                                        >
                                            <div className={styles.centerSiteMapButton}>
                                                <TbSitemap size={20} />
                                            </div>
                                        </PrimaryButton>
                                    </TooltipHost>
                                </div>
                                {isYoutubeLoading && (
                                    <div className={styles.typingDots}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                )}
                            </div>
                        </form>
                        {(siteMapAILinks?.requested_links || []).length > 0 && (
                            <div className={styles.scrollableList}>
                                <Label>Extracted Links:</Label>
                                <ul className={styles.linkList}>
                                    {(siteMapAILinks?.requested_links || []).map((link, index) => (
                                        <li key={index} className={styles.linkItem}>
                                            <div>{link[1]}</div>
                                            <div className={styles.linksmlr}>{link[2]}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Callout>
                )}
                {/*YOUTUBE¦URL CALLOUT*/}
                {isYoutubeCalloutVisible && hideCallout != true && (
                    <Callout
                        styles={addUrlYouTubeCallout}
                        role="dialog"
                        gapSpace={0}
                        className={styles.callout}
                        target={trgtCllrRef.current}
                        onDismiss={() => setIsYoutubeCalloutVisible(false)}
                    >
                        <form onSubmit={handleTextSubmit}>
                            <MultiTagURLInput initialItems={items} onItemsChange={onItemsChange} cTy={cTy} />

                            <div className={styles.addButAndLoad}>
                                <div className={styles.addAndConfig}>
                                    <PrimaryButton
                                        styles={addUrlPrimaryButton}
                                        type="submit"
                                        className={styles.submit}
                                        disabled={isYoutubeLoading || items.length < 1}
                                    >
                                        Add
                                    </PrimaryButton>
                                </div>

                                {isYoutubeLoading && (
                                    <div className={styles.typingDots}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                )}
                            </div>
                        </form>
                    </Callout>
                )}
                {/*PDF CALLOUT*/}
                {isPDFCalloutVisible && hideCallout != true && (
                    <Callout
                        role="dialog"
                        gapSpace={0}
                        styles={addUrlCallout}
                        className={styles.calloutPDF}
                        target={trgtCllrRef.current}
                        onDismiss={() => setIsPDFCalloutVisible(false)}
                    >
                        <form onSubmit={handleUploadClick} encType="multipart/form-data">
                            {selectedFiles.length === 0 && (
                                <>
                                    <div className={styles.btnPDF}>
                                        <Label>Choose Files</Label>
                                        <input
                                            accept={cTy == "vid" ? ".mp4" : ".pdf,.png,.jpg,.jpeg,.csv"}
                                            className={styles.chooseFilesPDF}
                                            type="file"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <Text className={styles.info}>{cTy == "vid" ? "Video Files" : "PDF, IMG, CSV (Contact for more...)"}</Text>
                                </>
                            )}

                            {selectedFiles.length > 0 && (
                                <div className={styles.SubmitContainerPDF}>
                                    <div className={styles.submitLabelPDF}>
                                        <Label>Selected Files ({selectedFiles.length}) </Label>
                                    </div>

                                    <PrimaryButton className={styles.submitPDF} type="submit" disabled={isLoading}>
                                        Submit
                                    </PrimaryButton>
                                </div>
                            )}

                            {isLoading && (
                                <div className={styles.typingDots}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            )}
                            {uploadedFile && <div className={styles.padding8}>{uploadedFile.message}</div>}

                            {selectedFiles.map((item, index) => {
                                return (
                                    <div key={index} className={styles.listPDF}>
                                        <div className={`${styles.itemPDF} ${isPDFCalloutVisible ? styles.itemPDFVisible : ""}`}>{item.name}</div>

                                        <Button
                                            className={styles.removeFromListButtonPDF}
                                            icon={<TiDeleteOutline />}
                                            onClick={() => handleRemoveFile(item)}
                                        ></Button>
                                    </div>
                                );
                            })}
                        </form>
                    </Callout>
                )}
                {/*TRANSCRIPT CALLOUT*/}
                {isMicModalVisible && hideCallout != true && (
                    <Modal
                        isOpen={isMicModalVisible}
                        onDismiss={() => setIsMicModalVisible(false)}
                        isBlocking={false}
                        containerClassName={styles.convoRecModalContainer}
                    >
                        <ConversationRecognizer
                            selectedGroup={selectedGroup}
                            loggedInUser={loggedInUser}
                            publishTranscriptDraftCallback={(transcriptObject: gtranscriptpublish) =>
                                publishTranscriptDraftHandler(transcriptObject, listRefreshRequest)
                            }
                            onSTT={onSTT}
                        />
                    </Modal>
                )}
            </div>
        </div>
    );
};
