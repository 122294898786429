import { ReactElement, useMemo, useEffect, useState, useRef } from "react";
import { gdiffparagraph, gpagedimensions, invaliddifftag } from "../../interfaces";
import { Callout, DirectionalHint, IconButton } from "@fluentui/react";
import { CustomElement, gvaliddifftag } from "../../interfaces";
import { diffCalloutStyles } from "../../styles_glob";
import { convertMarkdownHeadersToHTMLWithNewLines, markdownTableToHtmlOnly, convertMarkdownHeadersToHTML } from "../../util_glob";
import { MdFilterCenterFocus } from "react-icons/md";
/**
 * Describes one top-level object in the array (identified by `fid`).
 */

interface ParagraphOverlayProps {
    pagedimensions: gpagedimensions[];
    docJson?: gdiffparagraph[];
    pageNumber: number;
    pageWidth: number;
    pageHeight: number;
}
const INCHES_TO_POINTS = 72;

export const ParagraphOverlayDIFF = ({ pagedimensions, docJson, pageNumber, pageWidth, pageHeight }: ParagraphOverlayProps): ReactElement | null => {
    if (!docJson || docJson.length < 1) return null;
    const paragraphs = useMemo(() => {
        return docJson;
    }, [docJson]);
    const highlightActive = useRef(false);
    const currentRef = useRef(null);
    const x_scale = useMemo(() => {
        return pageWidth / pagedimensions[pageNumber].width;
    }, [pageWidth, pagedimensions]);
    const paras = useMemo(() => {
        return paragraphs?.filter(para => para.bounding_regions[0]?.page_number == pageNumber + 1);
    }, [paragraphs]);
    const y_scale = pageHeight / pagedimensions[pageNumber].height;

    const getUniqueTags = (paras: { valid_diff_tags?: gvaliddifftag[] }[]): gvaliddifftag[] => {
        const uniqueTagsMap = new Map<string, gvaliddifftag>();

        paras.forEach(prgrph => {
            prgrph.valid_diff_tags?.forEach(tag => {
                if (!uniqueTagsMap.has(tag.tag_name)) {
                    uniqueTagsMap.set(tag.tag_name, tag);
                }
            });
        });

        return Array.from(uniqueTagsMap.values());
    };
    const [calloutVisible, setCalloutVisible] = useState(false);
    const [calloutContent, setCalloutContent] = useState<string | null>(null);
    const [calloutTarget, setCalloutTarget] = useState<HTMLElement | null>(null);
    const [calloutTargetPage, setCalloutTargetPage] = useState<HTMLElement | null>(null);
    const [calloutPageVisible, setCalloutPageVisible] = useState(false);
    const [toShowTags, setToShowTags] = useState(false);
    const [totalValidDiffCount, setTotalValidDiffCount] = useState(() => paras.reduce((sum, prgrph) => sum + (prgrph.valid_diff_tags?.length || 0), 0));
    const [uniqueTags, setUniqueTags] = useState(() => getUniqueTags(paras));

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, content: gdiffparagraph) => {
        highLightsRemove();
        let paragraphGroup = document.getElementsByClassName(`prg_group_${content.paragraph_group_id}`);

        if (content.are_there_differences) {
            for (let i = 0; i < paragraphGroup.length; i++) {
                paragraphGroup[i].classList.add("ph_red", "kgnf_pha");
            }
            highlightActive.current = true;
        } else {
            for (let i = 0; i < paragraphGroup.length; i++) {
                paragraphGroup[i].classList.add("ph_gre", "kgnf_pha");
            }
            highlightActive.current = true;
        }
        if (content.diff_overview) {
            setCalloutContent(content.diff_overview || "");
            setCalloutTarget(event.currentTarget);
            setCalloutVisible(true);
        }
    };
    const setPageCallout = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setCalloutTargetPage(event.currentTarget);
        setCalloutPageVisible(true);
    };
    const highLightsRemove = () => {
        let paragraphGroup = document.getElementsByClassName("kgnf_pha");
        for (let i = 0; i < paragraphGroup.length; i++) {
            paragraphGroup[i].classList.remove("ph_red", "ph_gre");
        }
        for (let i = 0; i < paragraphGroup.length; i++) {
            paragraphGroup[i].classList.remove("kgnf_pha");
        }
    };

    const handleCalloutPageDismiss = () => {
        setCalloutPageVisible(false);
        setCalloutContent(null);
        setCalloutTargetPage(null);
    };
    const handleCalloutDismiss = () => {
        setCalloutVisible(false);
        setCalloutContent(null);
        setCalloutTarget(null);
    };

    const getDetailedChange = (tag: gvaliddifftag) => {
        let hls = document.querySelector(".validqgrp") ? document.querySelector(".validqgrp")?.classList.remove("validqgrp") : null;

        let tag_name = tag.tag_name.split("_")[0];
        let tag_id = tag.tag_name.split("_")[1];
        let qid = `${tag_name}[data-kch="${tag_id}_${pageNumber + 1}"]`;
        console.log("qid", qid);
        let tagGroup: CustomElement = document.querySelector(qid) as CustomElement;
        if (tagGroup) tagGroup.parentElement?.parentElement?.classList.add("validqgrp");
    };

    return (
        <div
            data-testz="zxc"
            ref={currentRef}
            onClick={e => {
                if (highlightActive.current == true) {
                    console.log("highlightActive", highlightActive.current);
                    highlightActive.current = false;
                }
            }}
            style={{
                background: `none`,
                zIndex: 0,
                pointerEvents: "none"
            }}
        >
            <div style={{ position: "absolute", left: `0px`, top: `0px` }}>
                <IconButton
                    style={{
                        position: "absolute",
                        left: `${pageWidth / 2 - 5}px`,
                        top: `8px`
                    }}
                    className={"focusdiffbut"}
                    onClick={setPageCallout}
                    disabled={totalValidDiffCount < 1}
                >
                    <MdFilterCenterFocus size={25} />
                </IconButton>
                <div
                    style={{
                        position: "absolute",
                        left: `${pageWidth / 2 - 200}px`,
                        top: `40px`,
                        width: `400px`,
                        background: `#fff`
                    }}
                ></div>
            </div>

            {paras.map((prgrph, rIdx) => {
                //console.log("prgrph", prgrph);
                const poly = prgrph.bounding_regions[0].polygon || [];
                const pixelCoordinates = poly.map(point => ({
                    x: point.x * INCHES_TO_POINTS * x_scale,
                    y: point.y * INCHES_TO_POINTS * y_scale
                }));
                const xValues = pixelCoordinates.map(point => point.x);
                const yValues = pixelCoordinates.map(point => point.y);

                const left = Math.min(...xValues);
                const right = Math.max(...xValues);
                const top = Math.min(...yValues);
                const bottom = Math.max(...yValues);
                let page_num = prgrph.bounding_regions[0].page_number;
                return (
                    <div
                        style={{
                            background: `none`,
                            zIndex: 0
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                left: `${0}px`,
                                top: `${0}px`
                            }}
                        ></div>
                        <div
                            data-testid="paragraph-overlay"
                            key={`${prgrph.kgnf_id}-${rIdx}`}
                            className={`prg_group_${prgrph.paragraph_group_id} ${
                                prgrph.are_there_differences ? "phk_in" : prgrph.diff_overview ? "phk_va" : "phk_no"
                            }`}
                            style={{
                                position: "absolute",
                                left: `${left}px`,
                                top: `${top}px`,
                                width: `${right - left}px`,
                                height: `${bottom - top}px`,
                                background: `none`
                            }}
                        ></div>
                        <div
                            className="prg_group"
                            style={{
                                position: "absolute",
                                left: `${left}px`,
                                top: `${top}px`,
                                width: `${right - left}px`,
                                height: `${bottom - top}px`
                            }}
                            onClick={e => handleMouseDown(e, prgrph || {})}
                        ></div>
                    </div>
                );
            })}
            {calloutVisible && calloutTarget && (
                <Callout
                    style={{ font: 'normal normal 400 13px/17px "Urbanist","Roboto"', maxWidth: "20vw", width: "100%", padding: "5px", paddingBottom: "2px" }}
                    target={calloutTarget}
                    directionalHint={DirectionalHint.rightCenter}
                    onDismiss={handleCalloutDismiss}
                    gapSpace={0}
                    styles={diffCalloutStyles}
                >
                    <div style={{ font: 'normal normal 600 14px/11px "Urbanist","Roboto"', marginBottom: "5px" }}>
                        Differences between matching Document parts
                    </div>

                    <div
                        style={{ zIndex: 1001111 }}
                        dangerouslySetInnerHTML={{
                            __html: convertMarkdownHeadersToHTMLWithNewLines(markdownTableToHtmlOnly(convertMarkdownHeadersToHTML(calloutContent || "")))
                                ?.replace(new RegExp("```html|```", "g"), "")
                                .replace(/&lt;b&gt;/g, "<b>")
                                .replace(/&lt;\/b&gt;/g, "</b>")
                                .replace("\n", "<br>")
                        }}
                    />
                </Callout>
            )}
            {calloutPageVisible && calloutTargetPage && (
                <Callout
                    style={{ font: 'normal normal 400 13px/17px "Urbanist","Roboto"', maxWidth: "700px", width: "100%", padding: "5px", paddingBottom: "2px" }}
                    target={calloutTargetPage}
                    directionalHint={DirectionalHint.rightCenter}
                    onDismiss={handleCalloutPageDismiss}
                    gapSpace={0}
                    styles={diffCalloutStyles}
                >
                    <div style={{ font: 'normal normal 600 16px/16px "Urbanist","Roboto"', marginBottom: "5px" }}>Page Differences [beta]</div>

                    {uniqueTags.map((tag, idx) => (
                        <div
                            key={idx}
                            className={"dddmmnaz"}
                            style={{
                                cursor: "pointer",
                                zIndex: 10000000000,
                                position: "sticky",
                                pointerEvents: "all",
                                left: 0,
                                right: 0
                            }}
                            onClick={() => {
                                getDetailedChange(tag);
                            }}
                        >
                            <li>{tag.difference_explanation}</li>
                        </div>
                    ))}
                </Callout>
            )}
        </div>
    );
};
