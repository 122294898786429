import React, { useState, useEffect } from "react";
import { TextField, PrimaryButton, DefaultButton, Dropdown, IDropdownOption, Stack, Callout, TooltipHost, DirectionalHint } from "@fluentui/react";
import styles from "./DocFieldSelection.module.css";
import { FaFileImport } from "react-icons/fa";
import { gfileassourceoption, gextrprop, extrtypesarray } from "../../interfaces";
import {
    textFieldStylesDocExtr,
    textFieldStylesMultilineDocExtr,
    dropdownStylesDocExtr,
    textFieldStylesDocExtractImportScheme,
    calloutStylesExportDocCallout,
    dropdownFileSrcStylesDocExtr
} from "../../styles_glob";
import { FaTrashCan } from "react-icons/fa6";
import { extrTypeOptions } from "../../lsts";

interface PropertiesEditorProps {
    initialProperties?: gextrprop[] | [];
    onChange: (updatedProperties: gextrprop[]) => void;
    isRoot?: boolean;
    fileList?: gfileassourceoption[];
}

export const DocFieldSelection: React.FC<PropertiesEditorProps> = ({ initialProperties, onChange, isRoot, fileList }) => {
    const [prprts, setProperties] = useState<gextrprop[]>(initialProperties || []);
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const [importJson, setImportJson] = useState("");
    const [calloutTarget, setCalloutTarget] = useState<HTMLElement | null>(null);
    useEffect(() => {
        setProperties(initialProperties || []);
    }, [initialProperties]);

    const handleUpdate = (index: number, updatedFields: Partial<gextrprop>) => {
        const newProps = [...prprts];
        newProps[index] = { ...newProps[index], ...updatedFields };
        setProperties(newProps);

        onChange(newProps);
    };
    const handleSrcUpdate = (index: number, updatedFields: Partial<gextrprop>) => {
        const newProps = [...prprts];
        newProps[index] = { ...newProps[index], ...updatedFields };
        setProperties(newProps);

        onChange(newProps);
    };
    const handleRemove = (index: number) => {
        const newProps = [...prprts];
        newProps.splice(index, 1);
        setProperties(newProps);
        onChange(newProps);
    };

    const handleAdd = () => {
        console.log("add", prprts);
        const newProps = [
            ...(prprts || []),
            {
                name: "",
                description: "",
                validation_source: "",
                type: "string" as "string",
                items: []
            }
        ];
        setProperties(newProps);
        onChange(newProps);
    };

    const handleImport = () => {
        try {
            const parsedJson = JSON.parse(importJson);
            console.log("parsedJson", parsedJson);
            let newProps = parseJsonToProperties(parsedJson);
            console.log("newProps", newProps);
            setProperties(newProps);
            onChange(newProps);
            setIsCalloutVisible(false);
        } catch (e) {
            console.error("Invalid JSON", e);
            alert("Invalid JSON format. Please check your input.");
        }
    };
    const importScheme = (event: React.MouseEvent<HTMLElement>) => {
        setCalloutTarget(event.currentTarget);
        setIsCalloutVisible(true);
    };
    function parseJsonToProperties(parsedJson: any): gextrprop[] {
        if (!Array.isArray(parsedJson)) throw new Error("Invalid JSON format. Expected an array of properties.");

        return parsedJson.map((item: any) => {
            if (typeof item !== "object" || item === null) throw new Error("Invalid property format. Expected an object.");

            const { name, description, type, items } = item;

            if (typeof name !== "string" || typeof description !== "string" || !extrtypesarray.includes(type)) throw new Error("Invalid property fields.");

            let validation_source = "";
            let property: gextrprop = { name, description, type };

            if (item.type == "string" || item.type == "number") property = { name, description, type, validation_source };
            if (type === "array" && Array.isArray(items)) property.items = parseJsonToProperties(items);
            if (type === "object") property.items = parseJsonToProperties(items);

            return property;
        });
    }
    const onRenderTitle = (options?: IDropdownOption[]) => {
        const selectedItem = options && options[0];
        return (
            <span
                className={selectedItem?.key === "" ? "placeholder" : ""}
                style={{
                    color: selectedItem?.key === "" ? "gray" : "#333",
                    fontStyle: selectedItem?.key === "" ? "italic" : "normal"
                }}
            >
                {selectedItem?.text || "Validation Source"}
            </span>
        );
    };
    return (
        <div className={styles.container}>
            {isCalloutVisible && (
                <Callout
                    target={calloutTarget}
                    onDismiss={() => setIsCalloutVisible(false)}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    gapSpace={10}
                    isBeakVisible={false}
                    styles={calloutStylesExportDocCallout}
                >
                    <div style={{ paddingTop: 5, paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
                        <TextField
                            className={styles.calloutImportScheme}
                            label="Paste JSON Scheme"
                            multiline
                            rows={5}
                            value={importJson}
                            onChange={(_, newValue) => setImportJson(newValue || "")}
                            styles={textFieldStylesDocExtractImportScheme}
                        />
                        <PrimaryButton text="Import" onClick={handleImport} style={{ marginTop: 10 }} />
                    </div>
                </Callout>
            )}
            {isRoot && (
                <div className={styles.importScheme} onClick={importScheme}>
                    <h3>Extraction Definition</h3>

                    <div className={styles.importSchemeImportButton}>
                        <TooltipHost content="Import Extraction Scheme from JSON. " directionalHint={DirectionalHint.bottomRightEdge}>
                            <FaFileImport size={15} />
                        </TooltipHost>
                    </div>
                </div>
            )}

            {prprts.length == 0 && (
                <div className={styles.noExtractDefinitionsContainer}>
                    <div className={styles.noExtractDefinitionsText}>Click 'Add New Field' to start defining the fields you want to get extracted </div>
                </div>
            )}
            {prprts.length > 0 &&
                prprts.map((prop, index) => (
                    <div key={index} className={styles.fieldContainer}>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <div className={styles.stackContainer}>
                                <div className={styles.fieldRow}>
                                    <TextField
                                        placeholder="Field Name"
                                        value={prop.name}
                                        onChange={e => handleUpdate(index, { name: (e.target as HTMLInputElement).value })}
                                        className={`${styles.fieldInput} ${styles.fieldColumnName}`}
                                        styles={textFieldStylesDocExtr}
                                    />
                                    <Dropdown
                                        selectedKey={prop.type}
                                        options={extrTypeOptions}
                                        onChange={(e, option) =>
                                            handleUpdate(index, { type: option?.key as "string" | "number" | "boolean" | "array" | "object" })
                                        }
                                        className={`${styles.fieldInput} ${styles.fieldColumn}`}
                                        styles={dropdownStylesDocExtr}
                                    />
                                    {prop.type === "string" || prop.type === "number" ? (
                                        <Dropdown
                                            selectedKey={prop.validation_source || ""}
                                            placeholder="Validation Source"
                                            onRenderTitle={onRenderTitle}
                                            options={[
                                                { key: "", text: "" }, // Empty option
                                                ...(fileList?.map(file => ({ key: file.fileid.toString(), text: file.name })) || [])
                                            ]}
                                            onChange={(e, option) => handleUpdate(index, { validation_source: option?.key.toString() })}
                                            className={`${styles.fieldInput} ${styles.fieldColumn}`}
                                            styles={dropdownFileSrcStylesDocExtr}
                                        />
                                    ) : null}
                                </div>

                                <TextField
                                    value={prop.description}
                                    onChange={e => handleUpdate(index, { description: (e.target as HTMLInputElement).value })}
                                    multiline
                                    placeholder="Description of field purpose for AI"
                                    className={`${styles.fieldInput} ${styles.fieldInputFullWidth}`}
                                    styles={textFieldStylesMultilineDocExtr}
                                />
                                <div className={styles.removeButtonContainer}>
                                    <div onClick={() => handleRemove(index)} className={styles.removeButton}>
                                        <FaTrashCan />
                                    </div>
                                </div>
                            </div>
                            {prop.type === "array" && (
                                <div className={styles.arrayContainer}>
                                    <h3>List Items</h3>
                                    <DocFieldSelection
                                        initialProperties={prop.items || []}
                                        onChange={subProps => {
                                            handleUpdate(index, { items: subProps });
                                        }}
                                        fileList={fileList}
                                    />
                                </div>
                            )}
                            {prop.type === "object" && (
                                <div className={styles.arrayContainer}>
                                    <h3>Object</h3>
                                    <DocFieldSelection
                                        initialProperties={prop.items || []}
                                        onChange={subProps => {
                                            handleUpdate(index, { items: subProps });
                                        }}
                                        fileList={fileList}
                                    />
                                </div>
                            )}
                        </Stack>
                    </div>
                ))}
            <PrimaryButton text="Add New Field" onClick={handleAdd} className={styles.addButton} />
        </div>
    );
};
