import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { TooltipHost, TooltipDelay, Spinner, SpinnerSize } from "@fluentui/react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import styles from "./../QuestionInput/QuestionInput.module.css";
import { IoMicOutline, IoMicOffOutline } from "react-icons/io5";
import { prepRecog, createVisGradient, applyAmendedPhraseList } from "./../../api/aud";
import { qGetMatching } from "./../../api";
import { gselectedgroup, gjsonprop, gconvocollectionconf, gqasimplified } from "../../interfaces";
import { assistantCodes, conv_collect_replacements } from "../../lsts";
import { getOnlyNumeric, newConvoStamp } from "../../util_glob";
import {
    getGroundingDataSet,
    parse_extraction_config,
    value_specific_rules,
    get_random_symbols,
    empty_positive_ai_result,
    conjoin_with_previously_low_level_discarded_string,
    get_recog_cache_discrepency,
    reset_conjoined_conditional,
    get_latest_conjoined,
    on_recog_msg_too_short,
    fill_final_msg_if_empty,
    hanle_mid_filter_matches,
    hanle_one_filter_match,
    filter_results_processing,
    grounding_with_filter_down,
    ai_val_chech,
    realtime_convo,
    apply_replacements
} from "./recog_util";

interface SpeechRecognizerProps {
    onRecognized: (
        result: string,
        detectedLanguage: string,
        current_intent: string,
        is_request_for_info?: boolean,
        ignore_discard?: boolean,
        value_valid_bot_not_in_data?: string,
        msg_ovrride?: string
    ) => void;
    onRecognizing: (text: string, detectedLanguage: string) => void;
    isOnCallback: (isOn: boolean) => void;
    convoEnd: (question_and_answers_list: gqasimplified[], convokey: string) => void;
    selectedGroup: gselectedgroup;
    isSpeaking?: boolean;
    isSpeechPaused: boolean | null;
    lastMessage: string[];
    activeConvoKey: string;
    question_and_answers_list: gqasimplified[];
    convo_discard_stamp: number;
}

const SpeechRecognizer: React.FC<SpeechRecognizerProps> = ({
    onRecognized,
    onRecognizing,
    isOnCallback,
    convoEnd,
    selectedGroup,
    isSpeaking,
    isSpeechPaused,
    lastMessage,
    activeConvoKey,
    question_and_answers_list,
    convo_discard_stamp
}) => {
    const [isListening, setIsListening] = useState<boolean>(false); // Is recognizing
    const [isLoading, setIsLoading] = useState<boolean>(false); // Is waiting for Recognition to Start
    const [convoLang, setConvoLang] = useState<string>(selectedGroup.convolang); // Language [Configured in Group Settings]
    const [expectedResponseType, setExpectedResponseType] = useState<string>(""); // What we expect from User [determined by OAI backend when generating response]

    const [isOn, setIsOn] = useState<boolean>(false); // Convo is Turned On

    const [convo_discard_stamp_state, set_convo_discard_stamp_state] = useState<number>(0);
    const [recognzrState, setRecognzrState] = useState<boolean>(false);
    const recRef = useRef<sdk.SpeechRecognizer | null>(null);
    const visAudCtxRef = useRef<AudioContext | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const tracking_silence_now_ref = useRef<boolean>(false);

    useEffect(() => {
        return () => {
            try {
                if (recRef.current) recRef.current.close();
            } catch {}
        };
    }, []);

    useEffect(() => {
        console.log("▢path▢", window.global_expected_next_user_response_type);

        let expected_values = window.global_convo_extr_conf?.get(window.global_expected_next_user_response_type);

        if (recRef.current) applyAmendedPhraseList(recRef.current, expected_values || []);

        check_for_call_over(window.global_expected_next_user_response_type);

        if (isOn == true && selectedGroup.assistanttype == assistantCodes.cca && question_and_answers_list?.length > 1 && question_and_answers_list?.length < 3)
            silenceCheckForGreet(0, undefined, true, true, 2000, 6000, 5000, "79.1");
    }, [question_and_answers_list]);

    useEffect(() => {
        if (isListening) initVisualizer();
    }, [isListening]);

    useEffect(() => {
        if (isSpeaking == false && isOn == true) startListening();
    }, [isSpeaking]);

    useEffect(() => {
        if (convo_discard_stamp_state != convo_discard_stamp) silenceCheckForDicards(0, undefined, true, true, 2000, 6000, 5000, "73.1");
    }, [convo_discard_stamp]);

    useEffect(() => {
        if (isSpeechPaused == true && isSpeaking == true && isOn == true) {
            startListening();
        } else if (isSpeechPaused == false) {
            stopListening();
        }
    }, [isSpeechPaused]);

    const x_conf: gjsonprop[] = useMemo(() => {
        return parse_extraction_config(selectedGroup);
    }, [selectedGroup]);

    const initRec = useCallback((): sdk.SpeechRecognizer => {
        const newRecognizer = prepRecog(convoLang, selectedGroup.assistanttype);
        newRecognizer.recognized = recRecognized;
        newRecognizer.recognizing = recRecognizing;
        newRecognizer.canceled = recCanceled;
        newRecognizer.sessionStarted = recStarted;
        newRecognizer.sessionStopped = recStopped;

        return newRecognizer;
    }, [onRecognized, onRecognizing]);

    const startListening = useCallback((): void => {
        if (recognzrState == true) import.meta.env.dev === true && console.log("recog_state_true");

        // MAIN INIT
        if (!recRef.current) {
            recRef.current = initRec();
            recRef.current.startContinuousRecognitionAsync(
                () => {
                    setIsListening(true);
                    setIsLoading(false);
                },
                error => console.error("errr_start_rec:", error)
            );
        }
    }, [initRec]);

    const stopListening = useCallback((): void => {
        if (recRef.current) {
            recRef.current.stopContinuousRecognitionAsync(
                () => {
                    postStop();
                },
                error => {
                    import.meta.env.dev === true && console.log("errr_stop_rec", error);
                    postStop();
                }
            );
        }
    }, []);

    const toggleListening = useCallback(
        (toggle_src?: boolean): void => {
            if (isListening) {
                setIsLoading(true);
                setIsOn(false);
                isOnCallback(false);
                stopListening();
            } else {
                setIsLoading(true);
                setIsOn(true);
                isOnCallback(true);
                startListening();
            }
        },
        [isListening, startListening, stopListening]
    );

    const silenceCheck = async (
        counter: number,
        stamp?: number,
        imp?: boolean,
        int?: boolean,
        t_out?: number,
        speech_thresh?: number,
        recog_thresh?: number,
        src?: string,
        runid?: string
    ) => {
        if (int != true && window.global_is_tracking_silence == true) return;
        if (!runid) runid = get_random_symbols();

        if (window.global_is_tracking_silence != true) window.global_is_tracking_silence = true;
        if (!stamp) stamp = newConvoStamp();
        if (!t_out) t_out = 1000;
        if (!speech_thresh) speech_thresh = 4000;
        if (!recog_thresh) recog_thresh = 5000;
        setTimeout(() => {
            let now = newConvoStamp();
            if (!stamp) stamp = newConvoStamp();
            if (!t_out) t_out = 1000;
            if (!speech_thresh) speech_thresh = 4000;
            if (!recog_thresh) recog_thresh = 5000;

            if (!window.global_convo_last_response_start) window.global_convo_last_response_start = now;
            if (!window.global_convo_last_response_finish) window.global_convo_last_response_finish = now;
            if (!window.global_convo_last_speech_start) window.global_convo_last_speech_start = now;
            if (!window.global_convo_last_speech_finish) window.global_convo_last_speech_finish = now;

            let since_last_recoging = now - window.globalConvPerfRecog;
            let since_last_speech_finish = now - window.global_convo_last_speech_finish;

            // CHECK IF WE ARE TALKING RIGHT NOW
            if (window.global_convo_last_speech_start > stamp) {
                console.log(`👂${src}.2 ¦${runid}¦${counter} ❚❚`);
                window.global_is_tracking_silence == false;
                return;
            }
            if (since_last_speech_finish > speech_thresh && since_last_recoging > recog_thresh) {
                if (window.global_convo_last_speech_start > window.global_convo_last_speech_finish) {
                    console.log(`👂${src}.1 ¦${runid}¦${counter} ⟳`);
                    silenceCheck(counter + 1, stamp, imp, true, t_out, speech_thresh, recog_thresh, src, runid);
                } else {
                    console.log("👂1.1");
                    console.log(
                        `👂${src}.2 ¦${runid}¦${counter} 
                        🛫 (${speech_thresh / 1000}>${since_last_speech_finish / 1000}) (${recog_thresh / 1000}>${since_last_recoging / 1000})`
                    );
                    tracking_silence_now_ref.current = false;
                    window.global_is_tracking_silence = false;
                    processRecognized("", convoLang, true);
                }
            } else {
                console.log(`👂${src}.3 ⟳`);
                silenceCheck(counter + 1, stamp, imp, true, t_out, speech_thresh, recog_thresh, src, runid);
            }
            // CHECK IF WE STOPPED TALKING RECENTLY AND NOW WE HAVE RECOGING IN PROGRESS
        }, t_out);
    };

    const silenceCheckForDicards = async (
        counter: number,
        stamp?: number,
        imp?: boolean,
        int?: boolean,
        t_out?: number,
        speech_thresh?: number,
        recog_thresh?: number,
        src?: string,
        runid?: string
    ) => {
        if (int != true && window.global_is_tracking_silence == true) return;
        if (!runid) runid = get_random_symbols();

        if (window.global_is_tracking_silence != true) window.global_is_tracking_silence = true;
        if (!stamp) stamp = newConvoStamp();
        if (!t_out) t_out = 1000;
        if (!speech_thresh) speech_thresh = 4000;
        if (!recog_thresh) recog_thresh = 5000;
        setTimeout(() => {
            let now = newConvoStamp();

            if (!window.global_convo_last_response_start) window.global_convo_last_response_start = now;
            if (!window.global_convo_last_response_finish) window.global_convo_last_response_finish = now;
            if (!window.global_convo_last_speech_start) window.global_convo_last_speech_start = now;
            if (!window.global_convo_last_speech_finish) window.global_convo_last_speech_finish = now;

            if (!speech_thresh) speech_thresh = 4000;
            if (!recog_thresh) recog_thresh = 5000;

            let since_last_recoging = now - window.globalConvPerfRecog;
            let since_last_recoged = now - window.convoPerfOnRecogFin;
            if (!stamp) stamp = newConvoStamp();
            if (since_last_recoging > stamp) {
                console.log(`👂${src}.2 ¦${runid}¦${counter} ❚❚`);
                window.global_is_tracking_silence == false;
                return;
            }

            if (since_last_recoged > speech_thresh || since_last_recoging > recog_thresh) {
                if (window.global_convo_last_speech_start > window.global_convo_last_speech_finish) {
                    console.log(`👂${src}.1 ¦${runid}¦${counter} ⟳`);
                    silenceCheckForDicards(counter + 1, stamp, imp, true, t_out, speech_thresh, recog_thresh, src, runid);
                } else {
                    console.log("👂1.1");
                    tracking_silence_now_ref.current = false;
                    window.global_convo_rollon_recognizing.push("[мълчaние]");
                    window.global_is_tracking_silence = false;
                    processRecognized("[мълчaние]", convoLang, true, true);
                }
            } else {
                console.log(`👂${src}.3 ⟳`);
                silenceCheckForDicards(counter + 1, stamp, imp, true, t_out, speech_thresh, recog_thresh, src, runid);
            }
            // CHECK IF WE STOPPED TALKING RECENTLY AND NOW WE HAVE RECOGING IN PROGRESS
        }, t_out);
    };

    const silenceCheckForGreet = async (
        counter: number,
        stamp?: number,
        imp?: boolean,
        int?: boolean,
        t_out?: number,
        speech_thresh?: number,
        recog_thresh?: number,
        src?: string,
        runid?: string
    ) => {
        if (int != true && window.global_is_tracking_silence == true) return;
        if (!runid) runid = get_random_symbols();

        if (window.global_is_tracking_silence != true) window.global_is_tracking_silence = true;
        if (!stamp) stamp = newConvoStamp();
        if (!t_out) t_out = 1000;
        if (!speech_thresh) speech_thresh = 4000;
        if (!recog_thresh) recog_thresh = 5000;
        setTimeout(() => {
            let now = newConvoStamp();

            if (!speech_thresh) speech_thresh = 4000;
            if (!recog_thresh) recog_thresh = 5000;

            if (!window.global_convo_last_response_start) window.global_convo_last_response_start = now;
            if (!window.global_convo_last_response_finish) window.global_convo_last_response_finish = now;
            if (!window.global_convo_last_speech_start) window.global_convo_last_speech_start = now;
            if (!window.global_convo_last_speech_finish) window.global_convo_last_speech_finish = now;

            let since_last_recoging = now - window.globalConvPerfRecog;
            let since_response_start = now - window.global_convo_last_response_start;
            let since_response_finish = now - window.global_convo_last_response_finish;
            let since_speech_start = now - window.global_convo_last_speech_start;
            let since_speech_finish = now - window.global_convo_last_speech_finish;

            if (window.global_simple_convo_list?.length >= 3) {
                console.log(`👂${src}.1 ¦${runid}¦${counter} ❚❚`);
                window.global_is_tracking_silence = false;
                return;
            }

            console.log("👂", speech_thresh, since_last_recoging);
            if (
                since_response_finish > speech_thresh &&
                since_response_start > speech_thresh &&
                since_speech_start > recog_thresh &&
                since_speech_finish > recog_thresh &&
                since_last_recoging > recog_thresh
            ) {
                if (window.global_convo_last_speech_start > window.global_convo_last_speech_finish) {
                    console.log(`👂${src}.1 ¦${runid}¦${counter} ⟳`);
                    silenceCheckForGreet(counter + 1, stamp, imp, true, t_out, speech_thresh, recog_thresh, src, runid);
                } else {
                    console.log("👂1.1");
                    tracking_silence_now_ref.current = false;
                    if (window.global_convo_rollon_recognizing == undefined) window.global_convo_rollon_recognizing = [];
                    window.global_convo_rollon_recognizing.push("[без отговор]");
                    window.global_is_tracking_silence = false;
                    processRecognized("[без отговор]", convoLang, true, true);
                }
            } else {
                console.log(`👂${src}.3 ⟳`);
                silenceCheckForGreet(counter + 1, stamp, imp, true, t_out, speech_thresh, recog_thresh, src, runid);
            }
            // CHECK IF WE STOPPED TALKING RECENTLY AND NOW WE HAVE RECOGING IN PROGRESS
        }, t_out);
    };

    const check_for_call_over = (exp_resp_type: string) => {
        if (exp_resp_type.toLowerCase() == "call_over") {
            setTimeout(() => {
                if (recRef.current) toggleListening(false);
                let convokey = activeConvoKey;
                console.log("▩call_over", convokey);
                convoEnd(window.global_simple_convo_list, convokey);
            }, 4000);
        }
    };

    const get_superficial_details = () => {
        let car_model = window.global_convo_extr_conf.get("model") || [""];
        let car_brand = window.global_convo_extr_conf.get("make") || [""];
        let car_color = window.global_convo_extr_conf.get("car_color") || [""];
        let car_details = "";
        if (car_model.length > 0) {
            let car_details = ` ${car_color[0]} ${car_brand[0]} ${car_model[0]}`;
        }

        return car_details;
    };

    const processRecognized = async (eResultText: string, eResultLanguage: string, force_through: boolean, ignore_discard?: boolean) => {
        // USED TO BUILD THE FINAL MESSAGE TO SEND TO THE BACKEND
        let fin = {
            final_upstream_msg: "",
            should_run_oai: false,
            conjoined_obj: {
                conjoined_msg: "",
                conjoined_segments: ""
            },
            conj_clean: "",
            value_valid_bot_not_in_data: "",
            additional_instructions: "",
            msg_ovrride: ""
        };
        fin.conjoined_obj = conjoin_with_previously_low_level_discarded_string(eResultText); // Join runon finalizations and segments caches with latest recognized string

        console.log("▩0", window.global_run_on_sentence.recognized, "¦▢¦", window.global_run_on_sentence.segments);

        // FINAL RECOG STAMP. PLAYS IMPORTANT ROLE IN RECOG CACHING.
        let newFinStamp = newConvoStamp();
        window.convoPerfOnRecogFin = newFinStamp;

        // GET TASK EXPECTED FROM THE USER. RETURNED BY OAI BACKEND, ON LAST RESPONSE.
        let exp_resp_type = window.global_expected_next_user_response_type;

        try {
            get_recog_cache_discrepency(); // Log Timing

            if (on_recog_msg_too_short(fin.conjoined_obj.conjoined_msg) == true) return; // Up front msg length check
            let uniq_strings: string[] = []; // User Defined Extraction Fields. Kept in Group Settings, JSON but might not be parsed. Should parse it.

            // IF EXTRACTION CONFIG EXISTS
            if (x_conf && x_conf.length > 0) {
                console.log("▩1");

                // GET EXTRACTION FIELD DATA
                let data_options: gconvocollectionconf = getGroundingDataSet(exp_resp_type, x_conf); // Full Config Info + Source Data
                let conf_details: gjsonprop = data_options.conf?.[0] || ({} as gjsonprop); // Field Config

                // IF GROUNDING DATA EXISTS AND IS ОК
                if (data_options.values != null && data_options.shouldGo == true) {
                    let keyname = data_options.keyname; // Extraction Field Name [same as exp_resp_type]

                    fin.conjoined_obj.conjoined_segments = apply_replacements(fin.conjoined_obj.conjoined_segments, conv_collect_replacements); // Apply Replacements to Values [if any]
                    fin.conjoined_obj.conjoined_msg = apply_replacements(fin.conjoined_obj.conjoined_msg, conv_collect_replacements); // Apply Replacements to Values [if any]

                    console.log("▩2", fin.conjoined_obj.conjoined_segments, " ¦ ", fin.conjoined_obj.conjoined_msg);

                    fin.final_upstream_msg = fin.conjoined_obj.conjoined_segments; // Set the Recog Segments as Final Message due to beter preservation of characters [instead of finalized recog which pushes to create sentences]

                    // IF USER MSG HAS TASK INTENT AND IT HAS CONFIGURED EXTRACTION CONFIG WITH SOURCE VALUES
                    if (keyname && data_options.values.length > 0) {
                        let msg_numeric = getOnlyNumeric(fin.conjoined_obj.conjoined_segments); // Get Numerics from User Message

                        uniq_strings = grounding_with_filter_down(data_options, exp_resp_type, msg_numeric.toString());

                        console.log("▩3");

                        // IF NUMBERS IN USER MESSAGE MORE THEN 2 AND VALUE SPECIFIC RULES ARE MET
                        if (
                            value_specific_rules(exp_resp_type, msg_numeric, fin.conjoined_obj) == true ||
                            (msg_numeric.length > 1 && uniq_strings.length > 0 && uniq_strings.length < 2)
                        ) {
                            console.log("▩4.1", msg_numeric, exp_resp_type, uniq_strings);
                            // GROUNDING FILTERING RESULTS FOUND
                            if (uniq_strings.length > 0) {
                                console.log("▩5");

                                // SINGLE MATCH - BEST CASE SCENARIO
                                if (uniq_strings.length == 1) {
                                    console.log("▩6");

                                    fin = await hanle_one_filter_match(uniq_strings, fin, exp_resp_type);

                                    console.log("▩6.1", get_superficial_details());
                                } else if (uniq_strings.length < 20) {
                                    // MULTIPLE MATCHES FOUND
                                    console.log("▩7");

                                    fin = await hanle_mid_filter_matches(uniq_strings, fin, exp_resp_type);
                                    fin.should_run_oai = true;
                                } else {
                                    // TOO MANY MATCHES FOUND BUT VALUE RULES (RAN OVER WHOLE MSG) WERE MET
                                    console.log("▩8");

                                    fin.conjoined_obj = get_latest_conjoined();
                                    let ai_value_extract = await ai_val_chech(
                                        fin.conjoined_obj.conjoined_segments,
                                        keyname,
                                        conf_details,
                                        lastMessage,
                                        question_and_answers_list,
                                        selectedGroup
                                    );

                                    if (ai_value_extract.user_asks_or_requests == true) {
                                        if (force_through != true) {
                                            silenceCheck(0, undefined, true, false, 1000, 2000, 2000, "12.2");
                                            console.log("▩9");
                                            return;
                                        }
                                    } else if (ai_value_extract.has_ai_recognized_value == true && ai_value_extract.value_ok) {
                                        uniq_strings = grounding_with_filter_down(data_options, exp_resp_type, ai_value_extract.requested_value.toString());

                                        if (uniq_strings.length > 0) {
                                            console.log("▩10");
                                            let res = await filter_results_processing(uniq_strings, ai_value_extract, exp_resp_type, fin, 11);
                                            fin = res.fin;
                                            if (res.halt == true && force_through != true) {
                                                console.log("▩12");
                                                silenceCheck(0, undefined, true, false, 1000, 3000, 4000, "13.2");
                                                return;
                                            }
                                        } else {
                                            console.log("▩13");
                                            fin.value_valid_bot_not_in_data = `This value is not in our system, tell the user that the supplied value is not in our system. Value: ${ai_value_extract.requested_value}`;
                                        }
                                    } else {
                                        if (force_through != true) {
                                            console.log("▩14");
                                            silenceCheck(0, undefined, true, false, 1000, 3000, 4000, "14.2");
                                            return;
                                        } else {
                                            console.log("▩15");
                                        }
                                    }
                                }
                                // NO GROUNDING FILTERING RESULTS (OVER WHOLE MSG), BUT STILL MEETS VALUE RULES
                            } else {
                                console.log("▩16");
                                // NO GROUNDING FILTERING RESULTS, BUT STILL MEETS VALUE RULES
                                let ai_value_extract = await ai_val_chech(
                                    fin.conjoined_obj.conjoined_segments,
                                    keyname,
                                    conf_details,
                                    lastMessage,
                                    question_and_answers_list,
                                    selectedGroup
                                );

                                // AI DETECTED USER INTERUPTION
                                if (ai_value_extract.user_asks_or_requests == true) {
                                    console.log("▩17");
                                } else if (ai_value_extract.has_ai_recognized_value == true && ai_value_extract.value_ok) {
                                    // AI FOUND VALUE
                                    console.log("▩19", ai_value_extract.requested_value);

                                    uniq_strings = grounding_with_filter_down(data_options, exp_resp_type, ai_value_extract.requested_value.toString());

                                    // HAVE FILTERING RESULTS FOR AI FOUND VALUE
                                    if (uniq_strings.length > 0) {
                                        console.log("▩21");

                                        let res = await filter_results_processing(uniq_strings, ai_value_extract, exp_resp_type, fin, 21);
                                        fin = res.fin;
                                        if (res.halt == true && force_through != true) {
                                            console.log("▩22");

                                            silenceCheck(0, undefined, true, false, 1000, 2000, 2000, "22.1");
                                            return;
                                        }
                                    } else {
                                        // NO FILTERING RESULTS FOR AI FOUND VALUE
                                        console.log("▩23");
                                        fin.value_valid_bot_not_in_data = `ADDITIONAL VALIDATION INSTRUCTIONS:\nThis value is not in our system, tell the user that the supplied value is not in our system. Value: ${ai_value_extract.requested_value}`;
                                    }
                                } else {
                                    // NO USER INTERUPTION AND NO AI FOUND VALUE, STILL MSGS MEETS VALUE RULES
                                    if (force_through != true) {
                                        console.log("▩24");
                                    } else {
                                        console.log("▩25");
                                    }
                                }
                            }
                        } else {
                            // VALUE RULES NOT MET, BUT FIELD COLLECTION CONFIG ACTIVE
                            console.log("▩26");

                            // CHECK WITH AI IF USER CHANGES SUBJECT AND PASS IF YES
                            let ai_value_extract = empty_positive_ai_result;

                            // DETECT IF INTERUPTION BY YSER
                            if (fin.conjoined_obj.conjoined_segments.length > 1) {
                                ai_value_extract = await ai_val_chech(
                                    fin.conjoined_obj.conjoined_segments,
                                    keyname,
                                    conf_details,
                                    lastMessage,
                                    question_and_answers_list,
                                    selectedGroup
                                ); // AI Value Extract + Topic Change
                            }

                            // AI DETECTED USER INTERUPTION
                            if (ai_value_extract.user_asks_or_requests == true) {
                                console.log("▩27");
                            } else {
                                console.log("▩28");
                                if (force_through != true) {
                                    silenceCheck(0, undefined, true, false, 1000, 3000, 3000, "27.1");
                                    return;
                                }
                            }
                        }
                    } else {
                        // NO FIELD CONFIG OR VALUES FOR FIELD CONFIG

                        console.log("▩29", fin.conjoined_obj.conjoined_segments, fin.conjoined_obj.conjoined_msg);

                        fin.final_upstream_msg = fin.conjoined_obj.conjoined_msg; // Use Finalized Recog msg to upstream [we might not be in data collection, so we need the final messages ability to transform to sentence]
                        reset_conjoined_conditional(newFinStamp); // Reset Arrays collecting segments and finalized recognitions based on cadance stamp
                    }
                } else {
                    // NO GROUNDING DATA FОR MSG
                    console.log("▩30");

                    fin.final_upstream_msg = fin.conjoined_obj.conjoined_msg; // Send finalized msg to upstream [we might not be in data collection, so we need the final messages ability to transform to sentence]
                    reset_conjoined_conditional(newFinStamp); // Reset Arrays collecting segments and finalized recognitions based on cadance stamp
                }
                // IF THERE'S DATA AND WE ARE FLAGGED FROM ABOVE TO RUN AI
                if (uniq_strings.length > 0 && fin.should_run_oai == true) {
                    console.log("▩31");
                    let enhanced_msg_with_hints = `${fin.conjoined_obj.conjoined_segments}`;
                    if (fin.additional_instructions != "") enhanced_msg_with_hints = `${fin.conjoined_obj.conjoined_segments} ${fin.additional_instructions}`; // Enhanced msg with hints for AI to use

                    let res = await qGetMatching(enhanced_msg_with_hints, (lastMessage || [""])[0], uniq_strings.join(", "), conf_details, selectedGroup);
                    if (res && res.matching_value) {
                        fin.additional_instructions = `Correct ${exp_resp_type}: ${res.matching_value}`;
                        if (res.response) fin.msg_ovrride = res.response;
                    }
                }

                fin.final_upstream_msg = fill_final_msg_if_empty(fin.final_upstream_msg, fin.conjoined_obj); // Fill Final Msg if not filled by above logic

                realtime_convo(fin.conjoined_obj.conjoined_msg, window.convoPerfOnRecogFin, window.globalConvoKey, "fin"); // Preserve the Final Recog in Q

                let fin_msg_enhanced = "";
                if (fin.value_valid_bot_not_in_data != "" && fin.additional_instructions != "") {
                    console.log("▩31.4");
                    fin_msg_enhanced = `${fin.additional_instructions}¦${fin.value_valid_bot_not_in_data}`; // Enhanced msg with hints for AI to use
                } else if (fin.value_valid_bot_not_in_data != "") {
                    console.log("▩31.5");
                    fin_msg_enhanced = `¦${fin.value_valid_bot_not_in_data}`; // Enhanced msg with hints for AI to use
                } else {
                    console.log("▩31.6");
                    fin_msg_enhanced = `${fin.additional_instructions}¦`; // Enhanced msg with hints for AI to use
                }
                // SEND MSG UPSTREAM
                if (fin_msg_enhanced != "") {
                    console.log("▩32", fin.final_upstream_msg);
                    onRecognized(fin.final_upstream_msg, eResultLanguage, exp_resp_type, true, ignore_discard, fin_msg_enhanced, fin.msg_ovrride);
                } else {
                    console.log("▩33", fin.final_upstream_msg);
                    onRecognized(fin.final_upstream_msg, eResultLanguage, exp_resp_type, true, ignore_discard, "", fin.msg_ovrride);
                }

                reset_conjoined_conditional(newFinStamp); // Reset Partials Tracking for next recognition. Clear immediate cache based on cadance stamp (which is value of convoPerfOnRecogFin for the last succesfull msg that made it to backend and back and was not discarded)
            } else {
                // NO EXTRACTION FIELD CONFIG AT ALL
                console.log("▩34");

                realtime_convo(fin.conjoined_obj.conjoined_msg, window.convoPerfOnRecogFin, window.globalConvoKey, "fin"); // PRESERVE FINAL RECOG IN Q
                onRecognized(fin.conjoined_obj.conjoined_msg, eResultLanguage, exp_resp_type, true, ignore_discard); // SEND MSG UPSTREAM
                reset_conjoined_conditional(newFinStamp); // Reset Partials Tracking for next recognition. Clear immediate cache based on cadance stamp (which is value of convoPerfOnRecogFin for the last succesfull msg that made it to backend and back and was not discarded)
            }
        } catch (error) {
            console.error("errr_rcgnzd", error);
        }
        //restartRec();
    };

    const processRecognizing = (eResultText: string, eResultLanguage: string) => {
        try {
            window.globalConvPerfRecog = newConvoStamp();
            realtime_convo(eResultText, window.globalConvPerfRecog, window.globalConvoKey, "upd");

            if (window.global_convo_rollon_recognizing == undefined) window.global_convo_rollon_recognizing = [];
            window.global_convo_rollon_recognizing.push(eResultText);

            onRecognizing(eResultText, eResultLanguage);
        } catch (error) {
            console.error("errr_rcgzng", error);
        }
    };

    const recRecognized = (s: sdk.Recognizer, e: sdk.SpeechRecognitionEventArgs) => {
        console.log("⇶", e.result.text);
        if (e.result.reason == sdk.ResultReason.RecognizedSpeech) processRecognized(e.result.text, e.result.language, false);
    };

    const recRecognizing = (s: sdk.Recognizer, e: sdk.SpeechRecognitionEventArgs) => {
        console.log("⇉", e.result.text);
        processRecognizing(e.result.text, e.result.language);
    };

    const recStarted = (s: sdk.Recognizer, e: sdk.SessionEventArgs) => {
        setRecognzrState(true);
    };

    const recCanceled = (s: sdk.Recognizer, e: sdk.SpeechRecognitionCanceledEventArgs) => {
        setRecognzrState(false);
    };

    const recStopped = (s: sdk.Recognizer, e: sdk.SessionEventArgs) => {
        setRecognzrState(false);
    };

    const postStop = (): void => {
        setIsLoading(false);
        setIsListening(false);
        if (recRef.current) {
            try {
                recRef.current.close();
                recRef.current = null;
            } catch {}
        }
        if (visAudCtxRef.current) {
            try {
                visAudCtxRef.current.close();
                visAudCtxRef.current = null;
            } catch {}
        }
    };

    const initVisualizer = async () => {
        try {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                visAudCtxRef.current = new AudioContext();
                const sourceNode = visAudCtxRef.current.createMediaStreamSource(stream);
                const analyserNode = visAudCtxRef.current.createAnalyser();
                analyserNode.fftSize = 64;
                sourceNode.connect(analyserNode);
                const canvas = canvasRef.current;
                if (canvas) {
                    const canvasCtx = canvas.getContext("2d");
                    const bufferLength = analyserNode.frequencyBinCount;
                    const dataArray = new Uint8Array(bufferLength);
                    let barHeights = new Array(bufferLength).fill(0);
                    const drawEqualizer = () => {
                        analyserNode.getByteFrequencyData(dataArray);
                        if (!canvasCtx) return;
                        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                        dataArray.slice(0, 10).forEach((value, i) => {
                            const targetHeight = (value / 255) * canvas.height;
                            barHeights[i] = barHeights[i] + (targetHeight - barHeights[i]) * 0.3; // Easing

                            const barWidth = canvas.width / 10;
                            canvasCtx.fillStyle = createVisGradient(canvasCtx, canvas, barHeights[i]);
                            canvasCtx.fillRect(i * barWidth, canvas.height - barHeights[i], barWidth, barHeights[i]);
                        });
                        if (isListening == true) {
                            requestAnimationFrame(drawEqualizer);
                        } else {
                            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                            stream.getTracks().forEach(track => track.stop());
                        }
                    };
                    drawEqualizer();
                }
            });
        } catch (error) {
            console.error("error_init_vis", error);
        }
    };

    return (
        <div className={styles.spchRecContainer}>
            <TooltipHost content="Conversational Mode" id="myTooltipId" calloutProps={{ gapSpace: 0 }} delay={TooltipDelay.zero}>
                <div id={"kgnfconvostart"} onClick={() => toggleListening(true)} className={styles.spchInpIconToggle}>
                    {isOn ? (
                        <IoMicOffOutline size={25} />
                    ) : isLoading == false ? (
                        <IoMicOutline size={25} />
                    ) : (
                        <Spinner size={SpinnerSize.small} className={styles.recLoadingSpinner} />
                    )}
                </div>
            </TooltipHost>
            {(isListening || isSpeaking) && <canvas ref={canvasRef} className={styles.recEqCanvas} />}
        </div>
    );
};

export default SpeechRecognizer;
