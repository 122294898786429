import { useState, useEffect, useRef, useCallback } from "react";
import {
    DetailsList,
    IColumn,
    TextField,
    IconButton,
    ITextFieldStyles,
    IDetailsHeaderProps,
    IStyle,
    Sticky,
    StickyPositionType,
    DetailsHeader,
    IDetailsRowProps,
    DetailsRow,
    IDetailsRowStyles,
    IDetailsHeaderStyles,
    DetailsListLayoutMode
} from "@fluentui/react";

import { copyToClipboardInvoice } from "../../util_glob";
import styles from "./InvoiceItemsTable.module.css";
import { invDetailListStyles, invItemsTextFieldStyles, invDetailsRowStyles, invDetailsRowTotalsStyles, detailsHeaderCustomStyles } from "./../../styles_glob";
import { Partial } from "@react-spring/web";
import { FaRegCopy } from "react-icons/fa6";
import { TbRulerMeasure } from "react-icons/tb";
import { FaSortDown } from "react-icons/fa";
import { FaSortUp } from "react-icons/fa6";
import { ImStack } from "react-icons/im";
import { LuSave } from "react-icons/lu";
import { qUpdateInvoiceStructuredData } from "../../api/qpi";
import { toast } from "react-toastify";
import { gloggedinuser, gselectedgroup } from "../../interfaces";
import { BiSolidUpArrowSquare } from "react-icons/bi";
interface InvoiceItemsTableProps {
    jsonString: string;
    loggedInUser: gloggedinuser;
    fid: string;
    selectedGroup: gselectedgroup;
    listRefreshRequest?: () => void;
    onDataHighlight?: (searchString: string) => void;
}

const InvoiceItemsTable: React.FC<InvoiceItemsTableProps> = ({ jsonString, loggedInUser, fid, selectedGroup, listRefreshRequest, onDataHighlight }) => {
    const [gridData, setGridData] = useState(jsonString ? JSON.parse(jsonString) : "{}");
    const [invoiceItemsData, setInvoiceItemsData] = useState<any[]>([]);

    const [invoiceState, setInvoiceState] = useState<string>("");
    const [isSorted, setIsSorted] = useState<{ column: string; isSortedDescending: boolean } | null>(null);
    const [columns, setColumns] = useState<IColumn[]>([]);
    const dragColumnRef = useRef<string | null>(null);

    useEffect(() => {
        if (jsonString) {
            try {
                const parsedData = JSON.parse(jsonString);
                setInvoiceState(parsedData?.status_message || "");
                setGridData(parsedData || {});
                initializeColumns();
            } catch (error) {
                console.error("Error parsing JSON string:", error);
            }
        }
    }, [jsonString]);

    useEffect(() => {
        if (gridData?.invoice_items.length > 0) setInvoiceItemsData(gridData.invoice_items);

        console.log("inv_item_data", gridData);
    }, [gridData.invoice_items]);

    useEffect(() => {
        if (gridData?.invoice_items.length > 0) initializeColumns();
    }, [invoiceItemsData]);

    const renderEditableCell = useCallback(
        (item: any, index?: number, column?: IColumn) => {
            const fieldName = column?.fieldName || "";
            const fieldNameCitationShouldBeOn =
                fieldName === "item_client_code" ||
                fieldName === "item_code" ||
                fieldName === "description" ||
                fieldName === "unit_price" ||
                fieldName === "total_price";
            return (
                <div className={styles.showInCitationLink}>
                    {fieldNameCitationShouldBeOn && (
                        <div className={styles.invTableCitationLink} onClick={onDataHighlight ? () => onDataHighlight(String(item[fieldName])) : undefined}>
                            <BiSolidUpArrowSquare size={15}></BiSolidUpArrowSquare>
                        </div>
                    )}

                    <TextField
                        value={item[fieldName]}
                        onChange={(e, newValue) => handleCellChange(index, fieldName, newValue)}
                        styles={invItemsTextFieldStyles}
                    />
                </div>
            );
        },
        [invoiceItemsData]
    );

    const initializeColumns = () => {
        const cols: IColumn[] = [
            {
                key: "item_number",
                name: "№",
                fieldName: "item_number",
                minWidth: 20,
                maxWidth: 40,
                currentWidth: 20,
                isResizable: true,
                isPadded: false,
                onRender: renderEditableCell
            },
            {
                key: "item_client_code",
                name: "Client Code",
                fieldName: "item_client_code",
                currentWidth: 70,
                minWidth: 40,
                maxWidth: 90,
                isResizable: true,
                onRender: renderEditableCell
            },
            {
                key: "item_code",
                name: "Vendor Code",
                fieldName: "item_code",
                minWidth: 40,
                maxWidth: 90,
                currentWidth: 70,
                isResizable: true,
                isPadded: false,
                onRender: renderEditableCell
            },
            {
                key: "item_type",
                name: "Type",
                fieldName: "item_type",
                minWidth: 50,
                maxWidth: 70,
                currentWidth: 50,
                isResizable: true,
                isPadded: false,
                onRender: renderEditableCell
            },
            {
                key: "description",
                name: "Description",
                fieldName: "description",
                minWidth: 150,
                isResizable: true,
                isPadded: false,
                onRender: renderEditableCell
            },
            {
                key: "quantity",
                name: "Quantity",
                fieldName: "quantity",

                minWidth: 30,
                currentWidth: 50,
                isResizable: true,
                isPadded: false,
                onRender: renderEditableCell
            },
            {
                key: "item_measure",
                name: "Measure",
                fieldName: "item_measure",
                minWidth: 30,
                currentWidth: 50,
                isResizable: true,
                isPadded: false,
                onRender: renderEditableCell
            },
            {
                key: "unit_price",
                name: "Unit Price",
                fieldName: "unit_price",
                minWidth: 50,

                currentWidth: 60,
                isResizable: true,
                isPadded: false,
                onRender: renderEditableCell
            },
            {
                key: "total_price",
                name: "Line Price",
                fieldName: "total_price",
                minWidth: 50,
                currentWidth: 60,
                isResizable: true,
                onRender: renderEditableCell
            },
            {
                key: "calculated_total_price",
                name: "AI Price",
                fieldName: "calculated_total_price",
                minWidth: 50,
                currentWidth: 70,
                isResizable: true,
                onRender: renderDisabledCell
            }
        ];
        setColumns(cols);
    };

    const renderDisabledCell = useCallback(
        (item: any, index?: number, column?: IColumn) => {
            const fieldName = column?.fieldName || "";
            return (
                <TextField
                    value={item[fieldName]}
                    onChange={(e, newValue) => handleCellChange(index, fieldName, newValue)}
                    styles={invItemsTextFieldStyles}
                    disabled={true}
                />
            );
        },
        [gridData.invoice_items]
    );
    const handleCellChange = (index: number | undefined, fieldName: string, newValue: string | undefined) => {
        if (index === undefined) return;
        let updatedData = gridData;
        if (invoiceItemsData !== undefined && invoiceItemsData.length > 0) {
            invoiceItemsData[index][fieldName] = newValue;
            setGridData((prev: any) => ({ ...prev, invoice_items: [...invoiceItemsData] }));
        }
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        const isDescending = isSorted?.column === column.key ? !isSorted.isSortedDescending : false;
        const sortedData = [...invoiceItemsData].sort((a, b) => {
            const aValue = a[column.fieldName || ""];
            const bValue = b[column.fieldName || ""];
            if (aValue < bValue) return isDescending ? 1 : -1;
            if (aValue > bValue) return isDescending ? -1 : 1;
            return 0;
        });
        setGridData({ ...gridData, invoice_items: sortedData });
        setIsSorted({ column: column.key, isSortedDescending: isDescending });
    };
    const onTotalClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {};

    const onColumnDragStart = (columnKey: string) => {
        dragColumnRef.current = columnKey;
    };

    const onColumnDrop = (targetColumnKey: string) => {
        const sourceColumnKey = dragColumnRef.current;
        if (!sourceColumnKey || sourceColumnKey === targetColumnKey) return;

        const updatedData = invoiceItemsData.map((row: any) => {
            const temp = row[sourceColumnKey];
            row[sourceColumnKey] = row[targetColumnKey];
            row[targetColumnKey] = temp;
            return row;
        });

        setGridData({ ...gridData, invoice_items: updatedData });

        dragColumnRef.current = null;
    };

    const iconHeaders = (column: IColumn | undefined) => {
        switch (column?.key || "") {
            case "quantity":
                return (
                    <div className={styles.flatHeaderName} title="Quantity of Units">
                        <ImStack size={12} />
                    </div>
                );
            case "item_measure":
                return (
                    <div className={styles.flatHeaderName} title="Measure of Units">
                        <TbRulerMeasure size={12} />
                    </div>
                );
            default:
                return <div className={styles.flatHeaderName}>{column?.name}</div>;
        }
    };

    const saveEditing = () => {
        if (loggedInUser) {
            console.log("saving‾invoice‾data", gridData);
            qUpdateInvoiceStructuredData(loggedInUser, gridData, fid, selectedGroup.selectionId).then(res => {
                if (res.status == "success") {
                    toast.success("Invoice data updated successfully");
                    listRefreshRequest && listRefreshRequest();
                } else {
                    toast.error("Error updating invoice data");
                }
            });
        }
    };

    const onRenderDetailsHeader = (props: IDetailsHeaderProps, defaultRender?: (props: IDetailsHeaderProps) => JSX.Element) => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header}>
                <DetailsHeader
                    {...props}
                    styles={detailsHeaderCustomStyles}
                    onRenderColumnHeaderTooltip={tooltipProps => (
                        <div
                            draggable
                            onDragStart={() => tooltipProps?.column?.key && onColumnDragStart(tooltipProps.column.key)}
                            onDragOver={e => e.preventDefault()}
                            onDrop={() => tooltipProps?.column?.key && onColumnDrop(tooltipProps.column.key)}
                            onClick={(ev: any) => tooltipProps?.column?.key && onColumnClick(ev, tooltipProps.column)}
                        >
                            <div className={styles.headerGroup}>
                                <div className={styles.columnHeader}>{iconHeaders(tooltipProps?.column)}</div>

                                {isSorted?.column === tooltipProps?.column?.key && (
                                    <div>
                                        {isSorted?.isSortedDescending ? (
                                            <div className={styles.sortIconUp}>
                                                <FaSortUp size={9} />
                                            </div>
                                        ) : (
                                            <div className={styles.sortIconDown}>
                                                <FaSortDown size={9} />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                />
            </Sticky>
        );
    };

    return (
        <div className={styles.invTableComponent}>
            <div className={styles.invTableTopMenu}>
                <div className={styles.invTableTopMenuLeftInfoBar}>
                    <div className={styles.invTableMenuText}>Items: {gridData?.invoice_items.length}</div>
                    <div className={styles.invTableMenuText}>Status: {invoiceState}</div>
                </div>
                <div className={styles.invTableTopMenuRightButs}>
                    <div className={styles.invTableSaveButtonCopyData}>
                        <IconButton
                            onClick={() => {
                                copyToClipboardInvoice(gridData);
                            }}
                            title={"Copy To CSV Tables"}
                        >
                            <FaRegCopy size={22} color="#00c6af" />
                        </IconButton>
                    </div>
                    <div className={styles.invTableSaveButton}>
                        <IconButton onClick={saveEditing} title={"Save Changes"}>
                            <LuSave size={24} color="#00c6af" />
                        </IconButton>
                    </div>
                </div>
            </div>
            <DetailsList
                items={invoiceItemsData}
                columns={columns.map(col => ({ ...col, onColumnClick }))}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                checkboxVisibility={2}
                selectionMode={2}
                isHeaderVisible={true}
                onRenderItemColumn={renderEditableCell}
                selectionPreservedOnEmptyClick={true}
                onRenderDetailsHeader={(props?: IDetailsHeaderProps) => onRenderDetailsHeader(props!)}
                onRenderRow={(prps: IDetailsRowProps | undefined): JSX.Element => {
                    return <DetailsRow {...(prps as IDetailsRowProps)} styles={invDetailsRowTotalsStyles} />;
                }}
                styles={invDetailListStyles}
            />
            <div className={styles.invTableBottomMenu}>
                <DetailsList
                    items={[
                        {
                            item_number: "Total",
                            item_client_code: "",
                            item_code: "",
                            item_type: "",
                            description: "",
                            quantity: invoiceItemsData.reduce((acc, item) => acc + Number(item.quantity), 0),
                            item_measure: "",
                            unit_price: invoiceItemsData.reduce((acc, item) => acc + Number(item.unit_price), 0),
                            total_price: invoiceItemsData.reduce((acc, item) => acc + Number(item.total_price), 0),
                            calculated_total_price: invoiceItemsData.reduce((acc, item) => acc + Number(item.calculated_total_price), 0)
                        }
                    ]}
                    columns={columns.map(({ onRender, ...rest }) => ({
                        ...rest,
                        onTotalClick
                    }))}
                    isHeaderVisible={false}
                    layoutMode={DetailsListLayoutMode.justified}
                    checkboxVisibility={2}
                    selectionMode={2}
                    styles={invDetailsRowTotalsStyles}
                />
            </div>
        </div>
    );
};

export default InvoiceItemsTable;
