import React, { useState, useMemo } from "react";
import { TooltipHost, DirectionalHint, Toggle } from "@fluentui/react";
import { TbSchema } from "react-icons/tb";
import { toast } from "react-toastify";
import { VscCollapseAll } from "react-icons/vsc";
import { sortObjectByKeyOrder, parseJsonToProperties } from "./../../util_glob";
import "./JsonGridGen.css";
import { HiArrowsExpand } from "react-icons/hi";
interface JsonGridGenProps {
    jsonString?: string;
    onDataHighlight: (data: string) => void;
}

const hidden_fields = ["structured_data"];

const JsonGridGen: React.FC<JsonGridGenProps> = ({ jsonString, onDataHighlight }) => {
    const [gridData, setGridData] = useState(jsonString ? JSON.parse(jsonString) : "{}");
    const [collapsedFields, setCollapsedFields] = useState<{ [key: string]: boolean }>({});

    const itmOrd = [
        "invoice_date",
        "contractor_name",
        "contractor_address",
        "contractor_vat_identification",
        "iban",
        "customer_name",
        "customer_address",
        "customer_vat_identification",
        "invoice_total",
        "vat",
        "invoice_currency",
        "item_number",
        "description",
        "item_code",
        "item_measure",
        "quantity",
        "unit_price",
        "total_price",
        "invoice_items"
    ];

    const getSchemeIntoClipboard = (json: any) => {
        const properties = parseJsonToProperties(json);
        const propertiesJson = JSON.stringify(properties, null, 2);
        navigator.clipboard
            .writeText(propertiesJson)
            .then(() => {
                console.log("Copied to clipboard!");
                toast.success("Content Scheme copied to clipboard!");
            })
            .catch(error => {
                console.error("Error copying to clipboard: ", error);
            });
    };

    const toggleCollapse = (key: string) => {
        let el = document.querySelector(`.${"A" + key}`);
        if (el) {
            if (el.classList.contains("nexpanded")) {
                (el as HTMLElement).style.height = (el as HTMLElement).scrollHeight + "px"; // Expand smoothly
                el.classList.toggle("nexpanded");
            } else {
                if ((el as HTMLElement).style.height) {
                    (el as HTMLElement).style.height = el.scrollHeight + "px"; // Set height dynamically
                    (el as HTMLElement).style.height = "0px";
                } else {
                    (el as HTMLElement).style.height = el.scrollHeight + "px"; // Set height dynamically
                    setTimeout(() => {
                        (el as HTMLElement).style.height = "0px";
                    }, 200);
                }
                el.classList.toggle("nexpanded");
            }
        }

        setCollapsedFields(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const renderGrid = (data: any, level = 0) => {
        console.log("data", data);
        let da = data;
        console.log("da", da);
        try {
            da = data["data"];
        } catch (e) {
            console.log("error", e);
        }
        if (da === undefined) {
            da = data;
        }

        return Object.entries(sortObjectByKeyOrder(da, itmOrd))?.map(([key, value]) => (
            <div key={key} className={`grid_row level_${level}_gen ${hidden_fields.includes(key) ? "hidden_field_marker" : ""}`}>
                <div
                    className={`grid_cell key ${data?.data ? "lvl_" + level : "llevell_" + level}`}
                    title={key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
                >
                    {typeof value === "object" && value !== null ? (
                        <div className="collapse_button" onClick={() => toggleCollapse(key + "_" + level)}>
                            <div className="expand_bu">{collapsedFields[key + "_" + level] ? <HiArrowsExpand /> : ""}</div>
                            <div>{key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}</div>
                        </div>
                    ) : (
                        key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())
                    )}
                </div>
                <div className={`grid_cell value ${"A" + key + "_" + level}`}>
                    {(() => {
                        return null;
                    })()}
                    {typeof value === "object" && value !== null ? (
                        Array.isArray(value) ? (
                            <div className="array-container">
                                {value.map((item, index) => (
                                    <div key={index} className="array_item">
                                        {typeof item === "object" ? renderGrid(item, level + 1) : <div className="value_link">{String(item)}</div>}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="array-container-roro-gen">{renderGrid(value, level + 1)}</div>
                        )
                    ) : (
                        <div className="value_link">
                            <div
                                onClick={() => onDataHighlight(String(value))}
                                style={{
                                    marginLeft: "0px",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    padding: "2px",
                                    cursor: "pointer"
                                }}
                                title={String(value)}
                            >
                                {String(value)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        ));
    };

    const gridRendition = useMemo(() => {
        return gridData && <div className="json-grid">{renderGrid(gridData)}</div>;
    }, [gridData]);

    return (
        <div className="json_grid_main">
            <div className="json_grid_header">
                <div>Data</div>
                <div
                    onClick={() => {
                        getSchemeIntoClipboard(gridData["data"] || gridData);
                    }}
                    className="exportScheme"
                >
                    <TooltipHost
                        closeDelay={500}
                        content="Copy Content Schema. Can be used in Extraction Settings, to define a Document Type"
                        directionalHint={DirectionalHint.topCenter}
                    >
                        <TbSchema />
                    </TooltipHost>
                </div>
            </div>

            {gridRendition}
        </div>
    );
};

export default JsonGridGen;
