import React, { useEffect, useState } from "react";
import "./NoSpacesCard.css";
import { gactivecomp } from "../../interfaces";

interface Props {
    onCreateClicked: () => void;
    showCreateSpacePrompt: boolean;
    activeComp: gactivecomp;
}

const NoSpacesCard = ({ onCreateClicked, showCreateSpacePrompt, activeComp }: Props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);
    console.log("NoSpacesCard", showCreateSpacePrompt, activeComp);
    return (
        <div className={`no-spaces-card ${show ? "show" : ""}`}>
            <div className={`no-spaces-title`}>No Shared Assistants</div>
            <p>You have no assistants shared with you at the moment.</p>
            {(activeComp?.allowqueststocreate == 1 || !("allowqueststocreate" in activeComp) || activeComp?.allowqueststocreate == null) && (
                <button className={`createSpaceButton`} onClick={onCreateClicked}>
                    Create an Assistant
                </button>
            )}
        </div>
    );
};

export default NoSpacesCard;
