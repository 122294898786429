import React, { useState, useEffect } from "react";
import { TextField, PrimaryButton, Dropdown, IDropdownOption, Stack, Callout, TooltipHost, DirectionalHint } from "@fluentui/react";
import styles from "./QuestionSelectionV2.module.css";
import { FaFileImport } from "react-icons/fa";
import { gfileassourceoption, qaconf, qaconftypes, qaconftypesarray, qconfemptyprop } from "../../interfaces";
import {
    textFieldStylesDocExtr,
    textFieldStylesMultilineDocExtr,
    dropdownStylesDocExtr,
    textFieldStylesDocExtractImportScheme,
    calloutStylesExportDocCallout,
    dropdownFileSrcStylesDocExtr
} from "../../styles_glob";
import { FaTrashCan } from "react-icons/fa6";
import { extrTypeOptions, extrTypeOptionsReduced } from "../../lsts";
import { MdAddCircleOutline } from "react-icons/md";

interface PropertiesEditorProps {
    initialProperties?: qaconf[] | [];
    onChange: (updatedProperties: qaconf[]) => void;
    isRoot?: boolean;
    fileList?: gfileassourceoption[];
}

export const QuestionSelectionV2: React.FC<PropertiesEditorProps> = ({ initialProperties, onChange, isRoot, fileList }) => {
    const [prprts, setProperties] = useState<qaconf[]>(initialProperties || []);
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const [importJson, setImportJson] = useState("");
    const [calloutTarget, setCalloutTarget] = useState<HTMLElement | null>(null);
    useEffect(() => {
        setProperties(initialProperties || []);
    }, [initialProperties]);

    const handleUpdate = (index: number, updatedFields: Partial<qaconf>) => {
        const newProps = [...prprts];
        newProps[index] = { ...newProps[index], ...updatedFields };
        setProperties(newProps);

        onChange(newProps);
    };
    const handleRemove = (index: number) => {
        const newProps = [...prprts];
        newProps.splice(index, 1);
        setProperties(newProps);
        onChange(newProps);
    };

    const handleAdd = () => {
        const newProps = [...prprts, qconfemptyprop];
        setProperties(newProps);
        onChange(newProps);
    };

    const handleImport = () => {
        try {
            const parsedJson = JSON.parse(importJson);
            let newProps = parseJsonToProperties(parsedJson);
            setProperties(newProps);
            onChange(newProps);
            setIsCalloutVisible(false);
        } catch (e) {
            console.error("Invalid JSON", e);
            alert("Invalid JSON format. Please check your input.");
        }
    };
    const importScheme = (event: React.MouseEvent<HTMLElement>) => {
        setCalloutTarget(event.currentTarget);
        setIsCalloutVisible(true);
    };
    function parseJsonToProperties(parsedJson: any): qaconf[] {
        if (!Array.isArray(parsedJson)) throw new Error("Invalid JSON format. Expected an array of properties.");

        return parsedJson.map((item: any) => {
            if (typeof item !== "object" || item === null) throw new Error("Invalid property format. Expected an object.");

            const { qid, qtype, qoutputtype, qtag, question, items } = item;

            if (typeof qid !== "string" || typeof question !== "string" || !qaconftypesarray.includes(qtype)) throw new Error("Invalid property fields.");

            let validation_source = "";
            let property: qaconf = { qid, qtype, qoutputtype, qtag, question, items };

            if (item.type == "string" || item.type == "number") property = { qid, qtype, qoutputtype, qtag, question };
            if (qtype === "array" && Array.isArray(items)) property.items = parseJsonToProperties(items);
            if (qtype === "object") property.items = parseJsonToProperties(items);

            return property;
        });
    }

    const onRenderTitle = (options?: IDropdownOption[]) => {
        const selectedItem = options && options[0];
        return (
            <span
                className={selectedItem?.key === "" ? "placeholder" : ""}
                style={{
                    color: selectedItem?.key === "" ? "gray" : "#333",
                    fontStyle: selectedItem?.key === "" ? "italic" : "normal"
                }}
            >
                {selectedItem?.text || "Validation Source"}
            </span>
        );
    };
    return (
        <div className={styles.container}>
            {isCalloutVisible && (
                <Callout
                    target={calloutTarget}
                    onDismiss={() => setIsCalloutVisible(false)}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    gapSpace={10}
                    isBeakVisible={false}
                    styles={calloutStylesExportDocCallout}
                >
                    <div style={{ paddingTop: 5, paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
                        <TextField
                            className={styles.calloutImportScheme}
                            label="Paste JSON Scheme"
                            multiline
                            rows={5}
                            value={importJson}
                            onChange={(_, newValue) => setImportJson(newValue || "")}
                            styles={textFieldStylesDocExtractImportScheme}
                        />
                        <PrimaryButton text="Import" onClick={handleImport} style={{ marginTop: 10 }} />
                    </div>
                </Callout>
            )}
            {isRoot && (
                <div className={styles.importScheme}>
                    <h3 className={styles.extrDefTitle}>{"Tasks"}</h3>

                    <div className={styles.importSchemeImportButton} onClick={importScheme}>
                        <TooltipHost
                            content="Import Extraction Scheme from JSON. You can also copy scheme from existing files"
                            directionalHint={DirectionalHint.bottomRightEdge}
                        >
                            <FaFileImport size={15} />
                        </TooltipHost>
                    </div>
                </div>
            )}

            {prprts.length == 0 && (
                <div className={styles.noExtractDefinitionsContainer}>
                    <div className={styles.noExtractDefinitionsText}>Click 'Add New Field' to start defining the fields you want to get extracted </div>
                </div>
            )}
            {prprts.length > 0 &&
                prprts.map((prop, index) => (
                    <div key={index} className={styles.fieldContainer}>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <div className={styles.stackContainer}>
                                <div className={styles.fieldRow}>
                                    <TextField
                                        placeholder="Task ID"
                                        value={prop.qid}
                                        onChange={e => handleUpdate(index, { qid: (e.target as HTMLInputElement).value })}
                                        className={`${styles.fieldInputTi} ${styles.fieldColumnName}`}
                                        styles={textFieldStylesDocExtr}
                                    />
                                    <Dropdown
                                        selectedKey={prop.qtype}
                                        options={extrTypeOptionsReduced}
                                        onChange={(e, option) => handleUpdate(index, { qtype: option?.key as qaconftypes })}
                                        className={`${styles.fieldInputD} ${styles.fieldColumn}`}
                                        styles={dropdownStylesDocExtr}
                                    />
                                    {prop.qtype === "string" || prop.qtype === "number" ? (
                                        <Dropdown
                                            selectedKey={prop.qtag || ""}
                                            placeholder="Tags"
                                            onRenderTitle={onRenderTitle}
                                            options={[
                                                { key: "", text: "" }, // Empty option
                                                ...(fileList?.map(file => ({ key: file.fileid.toString(), text: file.name })) || [])
                                            ]}
                                            onChange={(e, option) => handleUpdate(index, { qtag: option?.key.toString() })}
                                            className={`${styles.fieldInput} ${styles.fieldColumn}`}
                                            styles={dropdownFileSrcStylesDocExtr}
                                        />
                                    ) : null}
                                </div>

                                <TextField
                                    value={prop.question}
                                    onChange={e => handleUpdate(index, { question: (e.target as HTMLInputElement).value })}
                                    multiline
                                    className={`${styles.fieldInput} ${styles.fieldInputFullWidth}`}
                                    styles={textFieldStylesMultilineDocExtr}
                                    resizable={false}
                                    autoAdjustHeight={true}
                                />
                                <div className={styles.removeButtonContainer}>
                                    <div onClick={() => handleRemove(index)} className={styles.removeButton}>
                                        <FaTrashCan size={12} />
                                    </div>
                                </div>
                            </div>
                            {prop.qtype === "array" && (
                                <div className={styles.arrayContainer}>
                                    <h3>List Items</h3>
                                    <QuestionSelectionV2
                                        initialProperties={prop.items || ([] as qaconf[])}
                                        onChange={subProps => {
                                            handleUpdate(index, { items: subProps || ([] as qaconf[]) });
                                        }}
                                        fileList={fileList}
                                    />
                                </div>
                            )}
                            {prop.qtype === "object" && (
                                <div className={styles.arrayContainer}>
                                    <h3>Object</h3>
                                    <QuestionSelectionV2
                                        initialProperties={prop.items || []}
                                        onChange={subProps => {
                                            handleUpdate(index, { items: subProps });
                                        }}
                                        fileList={fileList}
                                    />
                                </div>
                            )}
                        </Stack>
                    </div>
                ))}
            <PrimaryButton onClick={handleAdd} className={styles.addButtonV2}>
                <div className={styles.addButtonV2Text}>
                    <MdAddCircleOutline size={24} />
                </div>
                Add Task
            </PrimaryButton>
        </div>
    );
};
