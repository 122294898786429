import React, { useState, useEffect, useMemo } from "react";
import {
    Toggle,
    TextField,
    PrimaryButton,
    DefaultButton,
    Dropdown,
    IDropdownOption,
    Stack,
    IconButton,
    Callout,
    DirectionalHint,
    TooltipHost
} from "@fluentui/react";
import styles from "./CallAssistantSettings.module.css";
import { DocFieldSelection } from "../../components/DocFieldSelection/DocFieldSelection";
import {
    tooltipStyles,
    stackStyles,
    textFieldStylesScenarioMultiline,
    textFieldStyles,
    textFieldStylesScenarios,
    textFieldStylesSingleScenarios
} from "./../../styles_glob";
import { gselectedgroup, gloggedinuser, gfileassourceoption, gconvoconfig, gextrprop } from "../../interfaces";
import { getHeaderG } from "../../api/qpi";
import { TbSchema } from "react-icons/tb";
import { FaFileImport } from "react-icons/fa";
import { assistantCodes } from "../../lsts";
import { assistantCardItems, multiPurposeAssistantCreationData } from "../../lsts";
interface PropertiesCallProps {
    selectedGroup: gselectedgroup;
    fileList: gfileassourceoption[];
    loggedInUser: gloggedinuser;
    purpose: string;
    onEditDocExtractionDone: () => void;
}

export const CallAssistantSettings: React.FC<PropertiesCallProps> = ({ selectedGroup, fileList, loggedInUser, purpose, onEditDocExtractionDone }) => {
    const [editedSpace, setEditedSpace] = useState<gselectedgroup>({ ...selectedGroup });
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);
    const docextractionconfig = useMemo(() => {
        try {
            let str = selectedGroup.docextractionconfig.replace(/\\/g, "");
            setEditedSpace(prev => ({ ...prev, docextractionconfig: JSON.stringify(docextractionconfig) }));
            return JSON.parse(str.substring(1, str.length - 1)) || [];
        } catch (e) {
            console.error("Error parsing docextractionconfig", e);
            return [];
        }
    }, [selectedGroup.docextractionconfig]);

    const handleChange = (field: keyof gselectedgroup, value: string | number | boolean) => {
        setEditedSpace(prev => ({ ...prev, [field]: value }));
    };
    const handleConvoConfigChange = (field: keyof gconvoconfig, value: Partial<gconvoconfig>) => {
        console.log("handleConvoConfigChange", field, value);
        setEditedSpace(prev => {
            return { ...prev, convo_config: { ...prev.convo_config, ...value } };
        });
    };
    const cleanProperties = (properties: gextrprop[]): gextrprop[] => {
        console.log("properties", properties);
        if (!Array.isArray(properties)) return [];

        return properties
            .filter(prop => prop.name !== "")
            .map(prop => {
                let cleaned: gextrprop = {
                    name: prop.name.replace(/\s+/g, "_"), // REPLACE SPACES WITH UNDERSCORES
                    description: prop.description,
                    type: prop.type
                };
                if (prop.type == "string" || prop.type == "number") cleaned.validation_source = prop.validation_source;

                // IF IT'S AN ARRAY TYPE AND HAS ITEMS, CLEAN THEM RECURSIVELY
                if (prop.type === "array" && prop.items && prop.items.length > 0) {
                    cleaned.items = cleanProperties(prop.items);
                } else if (prop.type === "object" && prop.items && prop.items.length > 0) {
                    cleaned.items = cleanProperties(prop.items);
                }

                return cleaned;
            })
            .filter(prop => {
                // Remove properties that have empty items array when type is 'array'
                if (prop.type === "array" || prop.type === "object") {
                    return prop.items && prop.items.length > 0;
                }
                return true;
            });
    };
    const handleSave = () => {
        setIsLoadingAPI(true);
        let cleanedProps = cleanProperties(JSON.parse(editedSpace.docextractionconfig));
        setEditedSpace(prev => ({ ...prev, docextractionconfig: JSON.stringify(cleanedProps) }));
        fetch("/qDocExtrConf", {
            method: "POST",
            headers: getHeaderG(loggedInUser),
            body: JSON.stringify({
                groupid: editedSpace.selectionId,
                docextractionconfig: JSON.stringify(cleanedProps),
                docextractionuseauto: editedSpace.docextractionuseauto,
                custaiinst: editedSpace.custaiinst,
                convo_config: editedSpace.convo_config
            })
        }).then(() => {
            setIsLoadingAPI(false);
            onEditDocExtractionDone();
        });
    };

    const docExtractionSettings = useMemo(() => {
        if (purpose == "call") {
            return (
                <div>
                    <div className={styles.invSetDedcriptionMid}>
                        Configure the data that should be collected from the user in a structured manner, for example, name, email, phone number, product id,
                        etc. You can define the data structure by adding fields and their types. Some JSON knowledge is required.
                    </div>
                    <div className={styles.invSetDedcriptionMid}>
                        Name the fields and decribe them in a clear and concise manner. AI will use this information to collect data from the user, so try name
                        the fields and describe them in a logical and compact manner.
                    </div>

                    <div className={styles.invSetDedcriptionMid}>The general instructions and the Extraction Definition should match.</div>
                    <div className={styles.invSetDedcriptionMid}>
                        To use 'validation source', upload a pdf with structured data, containing all possible values of the field. Then choose this document
                        here. Ducring the user interaction AI will use it, to ground the collected value for this field with a value in the document.
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className={styles.invSetDedcriptionMid}>
                        Choose wether AI should automatically determine important data in your document and structure it automatically or you want to define a
                        schema manually.
                    </div>
                    <div className={styles.invSetDedcriptionMid}>For manual schema definition, you have 2 options.</div>
                    <div className={styles.invSetDedcriptionMid}>
                        1. Upload a document, wait for it to be processed and when you open it you can copy it's schema by clicking the export (
                        <TbSchema size={10} />) icon top-right. After that, you can import the copied schema, here by disabling the auto-extraction toggle and
                        clicking the import button button <FaFileImport size={11} />.
                    </div>
                    <div className={styles.invSetDedcriptionMid}>
                        2. Define the scheme manually by creating a JSON structure in the editor below. You will need JSON knowledge.
                    </div>
                </div>
            );
        }
    }, [selectedGroup]);
    return (
        <div className={styles.editModalWrap}>
            <Stack styles={stackStyles}>
                <div className={styles.editFormSectionTitle}>
                    <span>{purpose == "call" ? "Information Collection Settings" : "Content Extraction Settings"}</span>
                </div>
                {docExtractionSettings}
                <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.docExtrTopsettings}>
                    <Toggle
                        label="Automatic Field Determination"
                        inlineLabel
                        onText="On"
                        offText="Off"
                        checked={editedSpace.docextractionuseauto == 1}
                        onChange={(_event, checked) => {
                            handleChange("docextractionuseauto", checked ? 1 : 0);
                        }}
                    />
                </Stack>

                {editedSpace.docextractionuseauto === 0 && (
                    <DocFieldSelection
                        initialProperties={docextractionconfig}
                        onChange={updated => {
                            console.log("Updated JSON:", updated);
                            handleChange("docextractionconfig", JSON.stringify(updated));
                        }}
                        isRoot={true}
                        fileList={fileList}
                    />
                )}
                {purpose == "call" && (
                    <TooltipHost
                        content="How chould the assistant start the conversation with the user."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <TextField
                            className={styles.spaceTextFields}
                            styles={textFieldStyles}
                            label="Greeting"
                            multiline
                            resizable={false}
                            value={editedSpace.convo_config.greeting}
                            onChange={(_, newValue) => handleConvoConfigChange("greeting", { greeting: newValue ? newValue : "" })}
                        />
                    </TooltipHost>
                )}
                {purpose == "call" && (
                    <TooltipHost
                        content="Instructions for AI on how to conduct the conversation, detailed instruction on how to collect data from the user and how to handle unexpected situations."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <TextField
                            className={styles.spaceTextFields}
                            styles={textFieldStylesScenarioMultiline}
                            label="General Scenario Instructions"
                            multiline
                            resizable={false}
                            value={
                                editedSpace.custaiinst ||
                                (editedSpace.assistanttype == "anya" || editedSpace.assistanttype == ""
                                    ? multiPurposeAssistantCreationData.custaiinst
                                    : assistantCardItems.filter(item => item.creationData.assistanttype === editedSpace.assistanttype)[0]?.creationData
                                          .custaiinst)
                            }
                            onChange={(_, newValue) => handleChange("custaiinst", newValue || "")}
                        />
                    </TooltipHost>
                )}
                {purpose == "call" && (
                    <div>
                        <div className={styles.editFormSectionTitle}>
                            <span>{"Scenario Definitions"}</span>
                        </div>
                        <div className={styles.docExtrSaveButs}>
                            <DefaultButton
                                text="Add Scenario"
                                onClick={() => {
                                    const newScenario = {
                                        id: Date.now().toString(),
                                        name: "",
                                        condition: "",
                                        description: ""
                                    };
                                    handleConvoConfigChange("scenarios", { scenarios: [...(editedSpace.convo_config.scenarios || []), newScenario] });
                                }}
                            />
                        </div>
                        <div>
                            {editedSpace.convo_config.scenarios?.map((scenario, index) => (
                                <div key={scenario.id} className={styles.scenarioItem}>
                                    <TextField
                                        label="Scenario Name"
                                        value={scenario.name}
                                        styles={textFieldStylesSingleScenarios}
                                        placeholder="short_descriptive_name"
                                        onChange={(_, newValue) => {
                                            const updatedScenarios = editedSpace.convo_config.scenarios.map((s, i) =>
                                                i === index ? { ...s, name: newValue?.toLowerCase()?.replace(/[^А-Яа-яA-Za-z0-9]/g, "_") || "" } : s
                                            );
                                            handleConvoConfigChange("scenarios", { scenarios: updatedScenarios });
                                        }}
                                    />
                                    <TextField
                                        label="Contditions"
                                        placeholder="Clear contditions stating when the scenario should be used, e.g. 'if user asks to register an appointment'"
                                        value={scenario.condition}
                                        styles={textFieldStylesSingleScenarios}
                                        onChange={(_, newValue) => {
                                            const updatedScenarios = editedSpace.convo_config.scenarios.map((s, i) =>
                                                i === index ? { ...s, condition: newValue || "" } : s
                                            );
                                            handleConvoConfigChange("scenarios", { scenarios: updatedScenarios });
                                        }}
                                    />
                                    <TextField
                                        label="Scenario Description"
                                        placeholder="Description of the scenario and the steps that should be taken to handle it."
                                        multiline
                                        resizable={false}
                                        styles={textFieldStylesScenarios}
                                        value={scenario.description}
                                        onChange={(_, newValue) => {
                                            const updatedScenarios = editedSpace.convo_config.scenarios.map((s, i) =>
                                                i === index ? { ...s, description: newValue || "" } : s
                                            );
                                            handleConvoConfigChange("scenarios", { scenarios: updatedScenarios });
                                        }}
                                    />
                                    <div className={styles.scenarioItemButtons}>
                                        <IconButton
                                            styles={{ root: { borderRadius: "6px", padding: "2px", margin: "2px", marginBottom: "0px" } }}
                                            iconProps={{ iconName: "Delete" }}
                                            onClick={() => {
                                                const updatedScenarios = editedSpace.convo_config.scenarios.filter((_, i) => i !== index);
                                                handleConvoConfigChange("scenarios", { scenarios: updatedScenarios });
                                            }}
                                            className={styles.deleteButton}
                                            title="Delete Scenario"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className={styles.docExtrSaveButs}>
                    <div />
                    <PrimaryButton
                        text="Save"
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={isLoadingAPI}
                        className={styles.saveSpaceButton}
                    />
                </div>
            </Stack>
        </div>
    );
};
