export interface guser {
    userDisplayName: string;
    userId: string;
    userMail: string;
    companyid: string;
    name: string;
    tier: number;
    subscriptionid: string;
    status: string;
}
export interface gcontentlist {
    fileid: string;
    srcPageNum: number;
    srcText: string;
    destPageId: number;
    destPageNum: number;
}
export interface gcontentlistai {
    text: string;
    page_id: number;
    page_number: string;
    hierarchical_numbering: string;
}
export interface gpagemap {
    [key: string]: number;
}
export interface gpagecontent {
    page_num: string;
    text: string;
}
export interface gpagedimensions {
    page_number: string;
    width: number;
    height: number;
    scaledHeight?: number;
}

export interface gchunktopics {
    topics: string[];
    page_num: Number;
}

export interface gfileassourceoption {
    fileid: string;
    name: string;
}

export interface gfile {
    fileid: string;
    name: string;
    size: string;
    full_path: string;
    modified: string;
    groupids: string;
    groupnames: string;
    filerole: string;
    ownerid: string;
    filepermlevel: number;
    pagemap: gpagemap;
    contentlist: gcontentlist[];
    pagecontent: gpagecontent[];
    globalXplrrOrder?: number;
    indexchunks?: number[];
    pagedimensions: gpagedimensions[];
    sourcetype: string;
    sourceurl: string;
    docstatus: string;
    invoicedata: string | any;
    contentlistai: string;
    doc_category: string;
    transcription: gtranscriptentry[];
    transcriptionkey: number;
    transcriptname: string;
    diffids: number[];
    approved: number;
    topics: gchunktopics[];
}
export interface gXplrrExtendedFileList {
    globalXplrrOrder: number;
    fileid: string;
    name: string;
    size: string;
    full_path: string;
    modified: string;
    groupids: string;
    groupnames: string;
    filerole: string;
    ownerid: string;
    filepermlevel: number;
    pagemap: gpagemap;
    contentlist: gcontentlist[];
    pagecontent: gpagecontent[];
    pagedimensions: gpagedimensions[];
    sourcetype: string;
    sourceurl: string;
    docstatus: string;
    invoicedata: string;
    contentlistai: string;
    doc_category: string;
    transcription: gtranscriptentry[];
    transcriptionkey: number;
    transcriptname: string;
    diffids: number[];
    approved: number;
    topics: gchunktopics[];
}
export interface ggroup {
    groupid: string;
    groupname: string;
    userid: string;
    permissionlevel: number;
    filecount: number;
    description: string;
    sharewithcode: boolean;
    custaiinst: string;
    custgreetingmessage: string;
    isintegrateddata: number;
    allowyoutube: number;
    allowweb: number;
    enabledocaudio: number;
    retrievecount: number;
    sectionoverlap: number;
    maxsectionlength: number;
    sentencesearchlimit: number;
    inscope: number;
    temperature: number;
    responsetokenlimit: number;
    disablecitationhighlighting: number;
    strictness: number;
    invoicesgroup: number;
    questionsuggestions: number;
    nosidebysideview: number;
    isowner: number;
    dailymsgs: number;
    premiumby: string;
    oid: number;
    tier: number;
    premwhen: number;
    companyid: string;
    apikey: string;
    producecontentlist: number;
    producequestions: number;
    assistanttype: string;
    showexamplequestionsonstart: number;
    headerimageurl: string;
    manualquestionsuggestions: string;
    usedocintel: number;
    extractionfieldlist: string;
    allowaudiodocentry: number;
    forceocr: number;
    allowvideofiles: number;
    convomode: number;
    convolang: string;
    hideaisearch: number;
    docextractionconfig: string;
    docextractionuseauto: number;
    landingpage: number;
    urllinkextraction: number;
    spo_config: gspoconfig;
    convo_config: gconvoconfig;
    contenttype_config: gcontenttypeconfig;
}
export interface gselectedgroup {
    selectionId: string;
    selectionText: string;
    permissionlevel: number;
    description: string;
    sharewithcode: boolean;
    filecount: number;
    custaiinst: string;
    custgreetingmessage: string;
    isintegrateddata: number;
    allowyoutube: number;
    allowweb: number;
    enabledocaudio: number;
    retrievecount: number;
    sectionoverlap: number;
    maxsectionlength: number;
    sentencesearchlimit: number;
    inscope: number;
    temperature: number;
    responsetokenlimit: number;
    disablecitationhighlighting: number;
    strictness: number;
    invoicesgroup: number;
    questionsuggestions: number;
    nosidebysideview: number;
    isowner: number;
    dailymsgs: number;
    premiumby: string;
    oid: number;
    tier: number;
    premwhen: number;
    companyid: string;
    apikey: string;
    producecontentlist: number;
    producequestions: number;
    assistanttype: string;
    showexamplequestionsonstart: number;
    headerimageurl: string;
    manualquestionsuggestions: string;
    usedocintel: number;
    extractionfieldlist: string;
    allowaudiodocentry: number;
    forceocr: number;
    allowvideofiles: number;
    convomode: number;
    convolang: string;
    hideaisearch: number;
    docextractionconfig: string;
    docextractionuseauto: number;
    landingpage: number;
    urllinkextraction: number;
    spo_config: gspoconfig;
    convo_config: gconvoconfig;
    contenttype_config: gcontenttypeconfig;
}

export interface gspoconfig {
    tenant_id: string;
    full_lib_url: string;
    graph_full_url: string;
    client_id: string;
    enabled: number;
    client_secret: string;
}

export interface gconvoconfig {
    greeting: string;
    scenarios: gscenarios[];
}
export interface gscenarios {
    id: string;
    name: string;
    condition: string;
    description: string;
}

export interface guploadfileinfodata {
    fileSpace: string;
    company: string;
    userId: string;
    fdFlName: string;
    fdFlSize: string;
    sectionoverlap: string;
    maxsectionlength: string;
    sentencesearchlimit: string;
    invoicesgroup: string;
    usedocintel: string;
    forceocr: string;
    assistanttype: string;
    contenttype_config: gcontenttypeconfig;
}
export interface gcompusrs {
    company: string;
    userid: string;
    usermail: string;
    username: string;
}
export interface gallusrs {
    companyid: string;
    userid: string;
    usermail: string;
    username: string;
    permissionlevel: number;
    tier: number;
}
export interface guseraddition {
    membermail: string;
    memberdisplayname: string;
    membercompany: string;
    memberlevel: number;
}
export interface gcompmbrshps {
    groupid: string;
    groupname: string;
    memberid: string;
    memberusername: string;
    membermail: string;
    membercompany: string;
    memberlevel: number;
    isinvite: number;
}
export interface gactivecomp {
    companyid: string;
    companyname: string;
    permissionlevel: number;
    licensingkind: number;
    alloweddomains: string;
    allowqueststocreate: number;
}
export interface gcompflat {
    companyid: string;
    companyname: string;
    permissionlevel: number;
    licensingkind: number;
    alloweddomains: string;
    allowqueststocreate: number;
}
export interface gnewcomp {
    companyid: string;
    companyname: string;
    licensingkind: number;
}
export interface gloggedinuser {
    userId: string;
    mail: string;
    token: string;
}
export interface ispdfmodalopen {
    defaultFile: string;
    startPage: string;
    isOpen: boolean;
    searchString: string;
    contentlist: gcontentlist[];
    pagemap: gpagemap;
    fileId: string;
    refPageNum: string;
    isCitation: boolean;
    rndo: string;
    activeGroupId: string;
    fileList: gfile[];
    fl: gfile | undefined;
}
export interface ispdfdiffmodalopen {
    defaultFile1: string;
    defaultFile2: string;
    isOpen: boolean;
    searchString: string;
    fileId1: string;
    fileId2: string;
    refPageNum: string;
    isCitation: boolean;
    rndo: string;
    activeGroupId: string;
    fileList: gfile[];
    fl1: gfile | undefined;
    fl2: gfile | undefined;
    diff: gdiffresponse[] | undefined;
}
export interface gpdfcache {
    [key: string]: gpdfcachevalue;
}
export interface gpdfcachevalue {
    base64string: string;
    fileid: string;
    name: string;
    modified: string;
}
export interface gXplrrCitationChangeCallback {
    fileid: string;
    destPageNum: string;
    isCitation: boolean;
    rndo: string;
}
// Space ⇒ File ⇒ Order
export interface gXplrrContentOrder {
    [key: string]: gXplrrSpaceOrder;
}
// Keep Files in Order
export interface gXplrrSpaceOrder {
    [key: number]: gXplrrFileOrder;
}
export interface gXplrrFileOrder {
    order: number;
    opened: boolean;
    fileItemOrder: gXplrrFileItemHash;
}
export interface gXplrrFileItemHash {
    [key: number]: gXplrrFileItemOpened;
}
export interface gXplrrFileItemOpened {
    opened: boolean;
    currentValue: string;
    splitValues: string[];
    splitLength: number;
    itemLevel: number;
    pageNumExtracted: number;
}
export interface gXplrrSpaceIndex {
    [key: string]: gXplrrContentListFileOrder;
}
export interface gXplrrContentListFileOrder {
    [key: string]: number;
}
export interface gChatlogItem {
    key: string;
    usermail: string;
    question: string;
    response: string;
    positive: number;
    feedback: string;
    convo_id: number;
}
export interface gdynfilter {
    category: string;
    groups: string;
}
export interface qsentenceforaudio {
    text: string;
    answerid: string;
    chunkid: string;
    convoid: string;
}
export const emptyaudiosentence: qsentenceforaudio = { text: "", answerid: "", chunkid: "", convoid: "" };

export interface gConf {
    promptTemplate: string;
    retrieveCount: number;
    retrievalMode: string;
    useSemanticRanker: boolean;
    shouldStream: boolean;
    useSemanticCaptions: boolean;
    searchOnly: boolean;
    useHighlightsAsSource: boolean;
    useSuggestFollowupQuestions: boolean;
    isDark: boolean;
    experimentalFeatures: boolean;
    showDocLibInChatTab: boolean;
    sourceHighlighting: boolean;
}
export interface ggroupcardprops {
    selectedGroup: gselectedgroup;
    handleGroupListClick: (groupid: string, groupname: string, permissionlevel: number) => void;
}
export interface ggroupcardslistprops {
    grL: ggroupcardprops[];
    onNewGroupClick: () => void;
    compLvl: number;
    permissionLevelsMap: { [key: number]: string };
    newSpacePyro: string;
    user: guser;
    activeComp: gactivecomp;
    navChatLib: (loc: string, groupid: string) => void;
}
export interface greindexparams {
    fileString: string;
    selectedGroup: gselectedgroup;
    userId: string;
    company: string;
    header: any;
    modString: string;
    idString: string;
}
export interface gnewgroup {
    id: string;
    groupname: string;
    description: string;
    appid: string;
    scrt: string;
    specializedSettings: any;
}
export interface ginvoiceextractiondata {
    name: string;
    invoicedata?: string;
}
export interface gsuggestedquestions {
    fileid: number;
    suggestedquestions?: string;
}
export interface gingestioniteminfo {
    fileSpace: string;
    company: string;
    userId: string;
    fdFlName: string;
    fdFlSize: string;
    sectionoverlap: string;
    maxsectionlength: string;
    sentencesearchlimit: string;
    invoicesgroup: string;
    usedocintel: string;
}
export interface gtiers {
    tier: number;
    msgsplmt: number;
    docsplmt: number;
    spslmt: number;
    name: string;
    monthlypayment: number;
    demosps: number;
}
export interface gInnerMap {
    [key: string]: boolean;
}
export interface gOuterMap {
    [key: string]: gInnerMap;
}
export interface grecentconvosaccrossspaces {
    groupid: string;
    lastinteractiontimestamp: string;
    name: string;
    groupname: string;
    key: string;
}
export interface gfeedback {
    feedback: string;
    pos: number | null;
    interaction_guid: string;
}
export interface gcustominvoiceinstructions {
    id: number;
    groupid: string;
    contractorid: string;
    fieldname: string;
    instruction: string;
    userid: string;
}
export interface gcustominvoiceinstructionscallout {
    isOpen: boolean;
    path: string;
    instructions: gcustominvoiceinstructions[];
}
export interface gtranscriptentry {
    speakerId: string;
    speakerName: string;
    text: string;
    isEditing: boolean;
    isRenaming: boolean;
    editText?: string;
}
export interface gtranscriptobject {
    id?: number;
    fileid?: string | null;
    content: gtranscriptentry[];
    groupid: string;
    userid: string;
    stamp?: string;
    key: number;
    transcriptname: string;
}
export interface gtranscriptpublish {
    selectedGroup: gselectedgroup;
    loggedInUser: gloggedinuser;
    transcript: gtranscriptentry[];
    transcriptKey: string;
    transcriptName: string;
}
export interface qconvoupload {
    selectedGroup: gselectedgroup;
    loggedInUser: gloggedinuser;
    convo: gqasimplified[];
    convoName: string;
}

export interface gpdftextitem {
    pageIndex: number;
    pageNumber: number;
    itemIndex: number;
    str: string;
}
export interface gdiff {
    diffid: string;
    fid1: number;
    fid2: number;
    results: diffresults[];
}
export interface diffresults {
    mapping1: number[];
    mapping2: number[];
    tagged_1_original: string;
    tagged_2_original: string[];
    [key: string]: any;
}
export interface ginvoiceconfinstructions {
    fieldid: string;
    instr: string;
    level?: string;
}
export interface ginvoicefieldconf {
    excl: string[];
    instr: ginvoiceconfinstructions[];
}
export interface ganswercallback {
    filePath: string;
    integratedCitation: string | null;
    el: HTMLElement | null;
    openSBS?: boolean;
}
export type gcaption = {
    content: string;
    filename: string;
    page: string;
    fileid: string;
    chunkid?: number;
};
// Indexing Metadata - Automate Advanced Preagent Indexing Results for Paragraph - used for Paragraph Highlighing and Later for DIFF Highlighting
export interface gautoparagraphresult {
    fileid: number;
    paragrphs: gautoparagraph[];
}
export interface gautoparagraph {
    role: string | null;
    spans: gspan[];
    content: string;
    kgnf_id: number;
    tot_pos: number;
    kgnf_lines: gkgnfline[];
    bounding_regions: gboundingregion[];
}
interface gkgnfline {
    spans: gspan[];
    content: string;
    polygon: gpolypoint[];
    tot_pos: number;
}

export interface gdiffresponse {
    id: number;
    diffid: string;
    fid1: number;
    fid2: number;
    overview: string;
    paragraphs: gdiffparagraphresult[];
}

// Diff Paragraphs - used for DIFF Highlighting
export interface gdiffparagraphresult {
    fid: number;
    all_lines: glinetagged[];
    paragraphs: gdiffparagraph[];
}
export interface gdiffparagraph {
    role: string | null;
    spans: gspan[];
    content: string;
    kgnf_id: number;
    tot_pos: number;
    kgnf_lines: glinetagged[] /*same as line array but located in corresponding paragraph*/;
    diff_overview?: string;
    content_tagged?: string;
    valid_diff_tags?: gvaliddifftag[];
    bounding_regions: gboundingregion[];
    invalid_diff_tags?: invaliddifftag[];
    paragraph_group_id: number;
    are_there_differences?: boolean;
    grid?: Number;
}
export interface glinetagged {
    c_key: string;
    spans: gspan[];
    content: string;
    polygon: gpolypoint[];
    tot_pos: number;
    content_tagged?: string;
    page_num?: number;
    para_id?: number;
}
export interface gvaliddifftag {
    tag_name: string;
    difference_explanation: string;
}
export interface invaliddifftag {
    tag_name: string;
    why_invalid: string;
}
interface gboundingregion {
    polygon: gpolypoint[];
    page_number: number;
}
interface gpolypoint {
    x: number;
    y: number;
}
interface gspan {
    length: number;
    offset: number;
}
export interface gailinks {
    next_page_links: string[] | undefined;
    requested_links: grequestedlink[] | undefined;
}
export interface grequestedlink {
    0: number; // Numeric ID
    1: string; // Link description (text label)
    2: string; // URL path
}
export interface gspeakprops {
    isSpeaking: boolean;
    isPaused: boolean | null;
}
export interface CustomElement extends HTMLElement {
    listenerAdded?: boolean;
}
export interface gfanalyse {
    filename: string;
    structured_data_per_page: any;
}

export interface gjsonprop {
    name: string;
    description: string;
    type: "string" | "number" | "boolean" | "array" | "object";
    validation_source?: string;
    items?: gjsonprop[];
}

export interface gconvorunonmessagepart {
    message: string;
    convostamp: number;
}

export interface grecognitionitem {
    msg: string;
    cadancestamp: number;
}
export interface grecognitionresult {
    recognized: grecognitionitem[];
    segments: grecognitionitem[];
}
export interface gcongoinedrecognitionresult {
    conjoined_msg: string;
    conjoined_segments: string;
}
export interface gconvocollectionconf {
    values: any[];
    keyname: string;
    shouldGo: boolean;
    joinkeys: string[];
    conf: gjsonprop[];
}
export interface gquitem {
    role: string;
    content: string;
}
export interface gqasimplified {
    role: string;
    content: string;
}

// Extraction Config - Source Content Scheme
export interface gextrprop {
    name: string;
    description: string;
    type: extrtypes;
    validation_source?: string;
    items?: gextrprop[];
}
export const gemptyextrprop = {
    name: "",
    description: "",
    validation_source: "",
    type: "string" as "string",
    items: []
};

export type extrtypes = "string" | "number" | "boolean" | "array" | "object";
export const extrtypesarray = ["string", "number", "boolean", "array", "object"];

// Extraction Config - QnA Config
export interface qaconf {
    qid: string;
    qtype: qaconftypes;
    qoutputtype: qaconfsourcetypes;
    qtag: string;
    question: string;
    items?: qaconf[];
}

export const qconfemptyprop: qaconf = {
    qid: "",
    qtype: "string",
    qoutputtype: "string",
    qtag: "string" as "string",
    question: ""
};

export type qaconftypes = "string" | "number" | "boolean" | "array" | "object";
export type qaconfsourcetypes = "string" | "number" | "boolean" | "array";
export const qaconftypesarray = ["string", "number", "boolean", "array", "object"];

export interface gcontenttypeconfig {
    contenttype: gcontenttype[];
}
export interface gcontenttype {
    type: string;
    description: string;
    fields: gextrprop[];
    autofield: boolean;
    qaconf?: qaconf[];
}
export const empty_contenttype_config = {
    contenttype: [{ type: "all_content", description: "All Content not covered by other Content Types", fields: [], autofield: true }]
};
export const new_content_type_config = { type: "", description: "", autofield: false };

export const empty_extraction_field_list = "{excl:[],instr:[]}";
export const empty_spo_config = {
    tenant_id: "",
    full_lib_url: "",
    graph_full_url: "",
    client_id: "",
    enabled: 0,
    client_secret: ""
};
export const empty_convo_config: gconvoconfig = {
    greeting: "",
    scenarios: []
};
export interface column_sorting {
    column?: string;
    isSortedDescending?: boolean;
    isSorted?: boolean;
}
