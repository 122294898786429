import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Callout, DirectionalHint, ThemeProvider, IconButton, TooltipHost } from "@fluentui/react";
import _, { set } from "lodash";
import FileDropdown from "../FileDropdown/FileDropdown";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

import { pdfjs } from "react-pdf";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import styles from "./XplrrPDFSideByChat.module.css";

import customTheme from "./../../theme/customTheme";
import { fetchSpeechAOI, getClearTextForAudio, getVoiceVersion, prepSsmlDocReader } from "../../api/aud";
import PageSelector from "../PageSelector/PageSelector";
import XplrrBOXPDF from "../XplrrBOXPDF/XplrrBOXPDF";

import { HiOutlineVolumeOff, HiOutlineVolumeUp } from "react-icons/hi";
import { HiMiniPlayPause } from "react-icons/hi2";
import { MdDownloading } from "react-icons/md";

import { GlobalContext } from "../../GlobalContext";
import { applyReplacements, assistantCodes, pb_excl } from "../../lsts";
import {
    scrollToPageDefault,
    reendableUserScrollHandling,
    scrollToPageNoSmooth,
    scrollCustomAnimPage,
    scrollCustomAnimElement,
    scrollToPageCSTM
} from "../../scrll_glob";
import { addDotIfNeeded, additionalFixes, filterHighlightString, stopWigglePixel, wigglePixelSBS } from "../../util_glob";
import { prepRegex, prepareHighlightRegexInvIncluded } from "../../rgx_glob";
import {
    gcontentlist,
    gfile,
    gXplrrCitationChangeCallback,
    gXplrrSpaceOrder,
    gXplrrFileOrder,
    gXplrrContentListFileOrder,
    gXplrrSpaceIndex,
    gXplrrExtendedFileList
} from "../../interfaces";
import { renderLinkData } from "../XplrrPDF/renderLinkData";
import { playButStylesSBS, pauseButStylesSBS } from "../../styles_glob";
import { qReadify } from "../../api";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface highLightItem {
    startIndex: number;
    endIndex: number;
    element: HTMLElement;
    processedText: string;
}

interface ViewerPDFProps {
    startPage: string;
    citationFileId: string;
    xplrrCitationChangeCallback: (callBackProps: gXplrrCitationChangeCallback) => void;
    fileId: string;
    refPageNum: string;
    isCitation: boolean;
    rndo?: string;
    searchString: string;
    activeGroupState: string;
    fileList: gfile[];
    shouldPlayAudio: string;
}

// fileId			PARAM
// activeFileId		CURRENT FILE TO LOAD		[state]
// flId				CITATION FILE [state]

const XplrrPDFSideByChat = ({
    startPage,
    citationFileId,
    xplrrCitationChangeCallback,
    fileId,
    refPageNum,
    isCitation,
    rndo,
    searchString,
    activeGroupState,
    fileList,
    shouldPlayAudio
}: ViewerPDFProps) => {
    const [fileListStateVar, setFileListStateVar] = useState<gfile[]>([]); // Files to Showcase
    const [file, setFile] = useState<gfile>(); // Current File
    const [numPages, setNumPages] = useState<number>(); // Number of Doc Pages

    const [fileMap, setFileMap] = useState<gXplrrSpaceOrder>({}); // Full File⇒Item Map
    const [contentFileOrder, setContentFileOrder] = useState<gXplrrContentListFileOrder>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [audioPage, setAudioPage] = useState(0);
    const [referencePage, setReferencePage] = useState(0);
    const [manualSection, setManualSecton] = useState<number>();
    const [activeCitationState, setActiveCitationState] = useState<string>();
    const [isContentTableShowing, setIsContentTableShowing] = useState<boolean>(false);
    const [flId, setFlId] = useState<string>();
    const [activeFileId, setActiveFileId] = useState<string>();
    const [docRendered, setDocRendered] = useState(false);

    const [pageLoaded, setPageLoaded] = useState(true);
    const [perFilesPerSectionStateMap, setPerFilesPerSectionStateMap] = useState<Map<string, Map<string, boolean>>>(new Map());
    const [contentRefreshTriggerIncrement, setContentRefreshTriggerIncrement] = useState(0);
    const { sourceHighlighting, docAudio, selectedGroup } = useContext(GlobalContext);
    const [srcId, setSrcId] = useState<string>(selectedGroup.invoicesgroup == 1 ? "sbs" : "sbs");
    // AUDIO
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

    const synthesizerRef = useRef<sdk.SpeechSynthesizer | null>(null);
    const audioRef = useRef<sdk.SpeakerAudioDestination | null>(null);

    useEffect(() => {
        // Prevent Runs before the Space has aligned
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;
        setActiveCitationState(citationFileId);

        if (fileId != flId || fileId != activeFileId) {
            window.globalBase64String = "";
            window.globalPDFPageDimensions = [];

            setFlId(fileId);
            setActiveCitationState(citationFileId);

            const fileToLoad = fileList.find(f => f.fileid == fileId);
            setActiveFileId(fileId);
            setFile(fileToLoad);

            setCurrentPage(parseInt(startPage));
            setReferencePage(parseInt(startPage));
        } else {
            setReferencePage(parseInt(startPage));
            console.log("XplrrPDFSideByChat¦setReferencePage", parseInt(startPage));
            setHighlightsPerfAfterLoad();
        }
    }, [rndo]);

    useEffect(() => {
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;
        if (activeCitationState && fileId == flId && activeFileId == flId) setHighlightsPerf();

        const fileMap: gXplrrSpaceOrder = fileMapGen(fileList); // Generate Content List File Order and Item Opened Map
        const cachedFileOrder: gXplrrContentListFileOrder = setContentFileOrderToState(fileList); // Order Items, based on Local State Cache or Default
        const extendedFileList: gXplrrExtendedFileList[] = fileList.map((flfile, flIndex) => {
            return {
                ...flfile,
                globalXplrrOrder: cachedFileOrder[flfile.fileid] == null ? fileMap[parseInt(flfile.fileid)].order : cachedFileOrder[flfile.fileid]
            };
        });
        setFileMap(fileMap); // Preprocess Need data for Dynamic Content List
        setFileListStateVar(extendedFileList.sort((a, b) => a.globalXplrrOrder - b.globalXplrrOrder));
    }, [activeFileId]);

    useEffect(() => {
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;

        // Preserve File Order to Local Cache
        if (Object.keys(contentFileOrder).length > 0) {
            const fileOrderCache = localStorage.getItem("xprrContentListFileOrder1");
            if (fileOrderCache) {
                const fullFileOrderCacheParsed: gXplrrSpaceIndex = JSON.parse(fileOrderCache);
                fullFileOrderCacheParsed[selectedGroup.selectionId] = contentFileOrder;
                localStorage.setItem("xprrContentListFileOrder1", JSON.stringify(fullFileOrderCacheParsed));
            } else {
                const fullFileOrderCache: gXplrrSpaceIndex = {};
                fullFileOrderCache[selectedGroup.selectionId] = contentFileOrder;
                localStorage.setItem("xprrContentListFileOrder1", JSON.stringify(fullFileOrderCache));
            }
        }
    }, [contentFileOrder]);

    // UE - shouldPlayAudio
    useEffect(() => {
        stopSpeech();
    }, [shouldPlayAudio]);

    // UE - perFilesPerSectionStateMap
    useEffect(() => {
        setContentRefreshTriggerIncrement(prevValue => prevValue + 1);
    }, [perFilesPerSectionStateMap]);

    // UE - perFilesPerSectionStateMap
    useEffect(() => {
        setSrcId(selectedGroup.invoicesgroup == 1 ? "sbs" : "sbs");
    }, [selectedGroup.invoicesgroup]);

    const setHighlightsPerf = async () => {
        if (!searchString) {
            // This is a Navigation Request without Highlights
            setCurrentPage(parseInt(startPage));
            scrollCustomAnimPage(parseInt(startPage), currentPage);
            return;
        }
        if (activeCitationState && isCitation && sourceHighlighting == true) applyHighlightsPerf();

        removeFontColorToMatchesRed();
        const allResults = await applyHighlightMatches();
        const allResultsNull = allResults?.every(result => result === null);
        if (allResultsNull) if (startPage) scrollToPageNoSmooth(parseInt(startPage));

        reendableUserScrollHandling(100);
        stopWigglePixel("wigglePixel_SBS02");
    };
    const setHighlightsPerfAfterLoad = async () => {
        if (!searchString) {
            // This is a Navigation Request without Highlights
            setCurrentPage(parseInt(startPage));
            scrollCustomAnimPage(parseInt(startPage), currentPage);
            return;
        }

        removeFontColorToMatchesRed();
        if (activeCitationState && sourceHighlighting == true) applyHighlightsPerf();

        const allResults = await applyHighlightMatches();
        const allResultsNull = allResults?.every(result => result === null);
        if (allResultsNull) {
            if (startPage) {
                scrollToPageNoSmooth(parseInt(startPage));
                reendableUserScrollHandling();
            }
        }
        setPageLoaded(true);
    };
    const findMatchingSequenceRGX = async (pg: number[]) => {
        const filteredTargetString = filterHighlightString(searchString);
        let rgx = prepareHighlightRegexInvIncluded(filteredTargetString, selectedGroup.assistanttype);

        const selectorPG = pg.map(pg => `.pdf_itm_${pg - 1}`).join(", ");
        const items = document.querySelectorAll(selectorPG);
        const itemsArray = Array.from(items);

        let processedText = "";
        let elementMap: highLightItem[] = [];

        for (let index = 0; index < itemsArray.length; index++) {
            const item = itemsArray[index] as HTMLElement;
            const originalItemText = item.textContent?.trim();
            const itemText = filterHighlightString(originalItemText);
            if (itemText.length > 0) {
                elementMap.push({
                    startIndex: processedText.length,
                    endIndex: processedText.length + itemText.length - 1,
                    element: item as HTMLElement,
                    processedText: itemText
                });
                processedText += itemText;
            }
        }
        let matches = [...processedText.matchAll(rgx)];
        if (selectedGroup.assistanttype == assistantCodes.ipa) {
            matches = matches.filter(
                match =>
                    (match && match.length < 2 && match[0].length <= searchString.length) ||
                    (match && match.length > 1 && match[1].length <= searchString.length)
            );
        }
        matches.forEach(match => {
            let startIndex = match.index || 0;
            const endIndex = startIndex + match[0].length - 1;

            const matchingElements = elementMap.filter(
                item =>
                    (item.startIndex <= startIndex && startIndex <= item.endIndex) ||
                    (item.startIndex <= endIndex && endIndex <= item.endIndex) ||
                    (startIndex <= item.startIndex && item.endIndex <= endIndex)
            );
            matchingElements.forEach((item, index) => {
                if (index == matchingElements.length - 1) {
                    if (window.globalHiglightFound == true) {
                        import.meta.env.DEV === true && console.log("glbalHglghtFnd_not_true", window.globalHiglightFound);
                    } else {
                        window.globalHiglightFound = true;
                        try {
                            let itmPg = parseInt(item.element.id.split("_")[1]);
                            let skndEl = item.element;
                            if (index > 0 && skndEl.innerText.length < 5) skndEl = matchingElements[index - 1].element;

                            scrollCustomAnimElement(itmPg, skndEl, skndEl, currentPage);
                        } catch {
                            smoothScrollIntoView(item.element as HTMLElement, { behavior: "smooth", block: "center" });
                        }
                    }
                }
                item.element.classList.add(styles.citHglghtrRed);
            });
        });

        if (matches.length > 0 && matches[0].index !== undefined) {
            return { startIndex: matches[0].index, endIndex: matches[0].index + matches[0][0].length - 1 };
        } else {
            return null;
        }
    };
    const applyHighlightMatches = async () => {
        if (file?.indexchunks) {
            let highlightPages: number[] = findRange([...file.indexchunks, file.pagedimensions.length], parseInt(startPage));
            if (highlightPages.length > 0 && highlightPages[0] > 1) highlightPages = [highlightPages[0] - 1].concat(highlightPages); // Add one more pages infront. Need to deep dive in index/number logic
            window.globalHiglightFound = false;
            wigglePixelSBS();
            const promises = highlightPages.map((page, index) => setHighlightsV3(page, index, highlightPages.length));

            const results = await Promise.all(promises);
            const allResultsNull = results?.every(result => result === null);
            if (allResultsNull) {
                const results = await findMatchingSequenceRGX(highlightPages);
                return [results];
            } else {
                return results;
            }
        }
    };
    const setHighlightsV3 = async (page: number, i: number, lignth: number) => {
        await waitForAllPDFItems(page - 1, 50);
        if (window.globalHiglightFound == false) {
            if (i == lignth - 1) {
                const difBetweenSrcAndTrgPage = currentPage - page;
                if (Math.abs(difBetweenSrcAndTrgPage) > 5) {
                    if (difBetweenSrcAndTrgPage < 0) {
                        import.meta.env.DEV === true && console.log(`scrollToPage ${currentPage} ⇒ ${currentPage + 1}`);
                        scrollToPageCSTM(currentPage + 1);
                    } else {
                        import.meta.env.DEV === true && console.log(`scrollToPage ${currentPage} ⇒ ${currentPage - 1}`);
                        scrollToPageCSTM(currentPage - 1);
                    }
                }
            }
        }
        const result = await findMatchingSequenceRGX([page]);
        return result;
    };
    const setContentFileOrderToState = (fileList: gfile[]) => {
        const fileOrderCache = localStorage.getItem("xprrContentListFileOrder1");
        if (fileOrderCache) {
            const fullFileOrderCacheParsed: gXplrrSpaceIndex = JSON.parse(fileOrderCache);
            const fileOrderCacheParsed: gXplrrContentListFileOrder = fullFileOrderCacheParsed[selectedGroup.selectionId];
            if (fileOrderCacheParsed && Object.keys(fileOrderCacheParsed)?.length > 0) {
                setContentFileOrder(fileOrderCacheParsed);
                return fileOrderCacheParsed;
            } else {
                const defaultOrder = getDefaultFileOrderFromCache(fileList);
                setContentFileOrder(defaultOrder);
                return defaultOrder;
            }
        } else {
            const defaultOrder = getDefaultFileOrderFromCache(fileList);
            setContentFileOrder(defaultOrder);
            return defaultOrder;
        }
    };
    const getDefaultFileOrderFromCache = (fileList: gfile[]) => {
        const defaultOrder: gXplrrContentListFileOrder = {};
        fileList.map((flfile, flIndex) => {
            defaultOrder[flfile.fileid] = flIndex;
        });
        return defaultOrder;
    };
    //Callback from PDXplrr To Determine Current Page on Scroll
    const handleScroll = (pageNumCur: number) => {
        if (pageNumCur != currentPage) setCurrentPage(pageNumCur);
        console.log("handleScroll", pageNumCur);
    };
    const fileMapGen = (fileList: gfile[]) => {
        let fileMap: gXplrrSpaceOrder = {};
        fileList.map((flfile, flIndex) => {
            let arrItems: gXplrrFileOrder = {
                order: flIndex,
                opened: false,
                fileItemOrder: {}
            };
            if (flfile.contentlist && flfile.contentlist.length > 0) {
                flfile.contentlist.forEach((flItem, flItemIndex) => {
                    const { currentValue, splitValues, splitLength, itemLevel, pageNumExtracted } = contentListValueNumbering(flItem);
                    arrItems.fileItemOrder[flItemIndex] = {
                        opened: false,
                        currentValue: currentValue,
                        splitValues: splitValues,
                        splitLength: splitLength,
                        itemLevel: itemLevel,
                        pageNumExtracted: pageNumExtracted
                    };
                });
            }

            fileMap[parseInt(flfile.fileid)] = arrItems;
        });
        return fileMap;
    };
    const setPages = (numP: number): void => {
        setNumPages(numP);

        window.globalTextArray = [];
        window.globalTextArrayAudio = [];
        window.globalTextMap = new Map<string, boolean>();
        window.globalPDFExtr = [];
        window.globalPDFPageMap = new Map<number, any>();
        window.globalPDFContent = [];
        window.globalAllNessesaryPDFPagesLoaded = false;
        window.globalPDFPageDimensions = [];
    };
    const removeFontColorToMatchesRed = (): void => {
        const elements = document.getElementsByClassName(styles.citHglghtrRed);
        while (elements.length > 0) {
            elements[0].classList.remove(styles.citHglghtrRed);
        }
    };
    function findRange(numbers: number[], target: number): number[] {
        numbers.sort((a: number, b: number) => a - b);
        let startIndex = numbers.findIndex((num: number) => num > target);
        let upper = startIndex === -1 ? numbers[numbers.length - 1] : numbers[startIndex];
        let endIndex = numbers.lastIndexOf(target);
        let lower = endIndex !== -1 ? numbers[endIndex] : numbers[startIndex - 1];
        if (lower === undefined || upper === undefined) return []; // Return an empty array if target is out of the provided range

        let result = [];
        for (let i = lower; i <= upper; i++) result.push(i);

        if (target === upper) result.push(upper);

        try {
            window.globalpdfObservableRange = result.length;
        } catch {}
        return result;
    }
    const waitForAllPDFItems = async (index: number, maxAttempts: number = 50): Promise<true | null> => {
        const isReactPdfPageTextRendererDone = (index: number): boolean => {
            let itemProgress = window.globalItemCounts?.get(index);
            if (itemProgress !== undefined && itemProgress.flag == true) {
                let pdfPageItemCount = document.querySelectorAll(`.pdf_itm_${index}`);
                if (pdfPageItemCount.length == itemProgress.count) {
                    return true;
                } else {
                    setReferencePage(index + 1);
                    return false;
                }
            } else {
                return false;
            }
        };
        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            if (isReactPdfPageTextRendererDone(index)) return true;
            await new Promise(resolve => setTimeout(resolve, 50));
        }

        console.warn(`waitFrItms¦FAILED_RENDER_WAIT⇒ ${index} AFTER ${maxAttempts} ATTEMPTS`);
        return null;
    };
    const applyHighlightsPerf = () => {
        if (file?.indexchunks) {
            let highlightPages: number[] = findRange([...file.indexchunks, file.pagedimensions.length], parseInt(startPage));
            for (let i = 0; i < highlightPages.length; i++) {
                document.querySelectorAll(`div[data-page-number="${highlightPages[i]}"] span`).forEach(span => {
                    (span as HTMLElement).classList.add(styles.citHglghtr);
                });
            }
        }
    };
    // Define the function to call after the timeout
    const handleTimeout = () => {
        reendableUserScrollHandling();

        // fileId			PARAM
        // activeFileId		CURRENT FILE TO LOAD		[state]
        // flId				CITATION FILE [state]
        setDocRendered(true);
        if (fileId == activeFileId) setHighlightsPerf();
        setPageLoaded(true);
    };
    const setContentList = (contentList: { title: string; dest: number }[]) => {
        import.meta.env.DEV === true && console.log("VWRPDF¦setContentList", contentList);
    };
    function contentListValueNumbering(item: gcontentlist) {
        const currentValue = item.destPageNum;
        if (item.srcText) {
            let splitValues = item.srcText.trim().replace(" .", ".").split(" ");
            let pageNum = item.destPageNum;
            if (!isNaN(Number(splitValues[splitValues.length - 1]))) {
                pageNum = parseInt(splitValues[splitValues.length - 1]);
                splitValues.pop();
            }
            if (!splitValues || splitValues.length == 0) {
                return {
                    currentValue: "",
                    splitValues: [],
                    splitLength: 0,
                    itemLevel: 0,
                    pageNumExtracted: 0
                };
            }
            return {
                currentValue: currentValue.toString(),
                splitValues: splitValues,
                splitLength: splitValues.length,
                itemLevel: (addDotIfNeeded(splitValues[0]).match(/\./g) || []).length - 1,
                pageNumExtracted: pageNum
            };
        } else {
            return {
                currentValue: "",
                splitValues: [],
                splitLength: 0,
                itemLevel: 0,
                pageNumExtracted: 0
            };
        }
    }
    const handlePageChangeFromPageSelector = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;
        setManualSecton(Number(event.target.value));
        setCurrentPage(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value));
        reendableUserScrollHandling();
    };
    const handleClick = (target: EventTarget, item: gcontentlist, indx: number) => {
        scrollToPageNoSmooth(item.destPageNum);
        setCurrentPage(item.destPageNum);
        reendableUserScrollHandling();
    };
    const handleFileClick = (target: EventTarget, fl: gfile, flIndex: number) => {
        import.meta.env.DEV === true && console.log("handleFileClick¦setActiveFileId", fl.fileid);

        window.globalUserScrollLazyLoadEnabled == true;

        setFile(fl);
        setActiveFileId(fl.fileid);

        setCurrentPage(1);

        reendableUserScrollHandling(100);
    };
    const expandCollapseSection = (flId: string, srcText: string) => {
        setPerFilesPerSectionStateMap(prevMap => {
            const newMap = new Map(prevMap);
            const fileMap = newMap.get(flId) || new Map();
            const newFileMap = new Map(fileMap);

            newFileMap.set(srcText, !fileMap.get(srcText));
            newMap.set(flId, newFileMap);

            return newMap;
        });
    };
    const playSpeech = () => {
        if (audioRef.current && audioPage == currentPage) {
            audioRef.current.resume();
            setIsPlaying(true);
        } else {
            audioRef.current = null;
            setIsLoadingAudio(true);
            fetchSpeechAudio(currentPage);
        }
    };
    const stopSpeech = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };
    const handleAudioAOI = async (page: number, retryCount = 0) => {
        setCurrentPage(page);
        let text = getClearTextForAudio(page);
        if (text && text.trim().length > 0) {
            let aud = await fetchSpeechAOI(text);
            if (aud) {
                aud.loop = false;
                aud.onended = () => {
                    if (numPages && page + 1 < numPages) {
                        scrollToPageDefault(page + 1);
                        handleAudioAOI(page + 1);
                    }
                };
                setAudio(aud);
                aud.play();
                setIsLoadingAudio(false);
                setIsPlaying(true);
            }
        } else {
            if (numPages && currentPage < numPages) {
                scrollToPageDefault(page + 1);
                handleAudioAOI(page + 1);
            }
        }
    };
    const fetchSpeechAudio = async (page: number, retryCount = 0) => {
        const reg = import.meta.env.VITE_STT_R;
        const apk = import.meta.env.VITE_STT_K;

        setCurrentPage(page);
        setAudioPage(page);

        const items = document.querySelectorAll(`.pdf_itm_${page - 1}`);
        let text = Array.from(items)
            .filter(
                item =>
                    item.textContent != null && // Exclude if string is empty
                    //!/^\d+$/.test(item.textContent) && // Exclude if string is only digits
                    !pb_excl.includes(item.textContent.toLowerCase().trim()) && // Exclude if string is in the exclusion array
                    item.textContent.trim() !== "|"
            )
            .map(item => item.textContent)
            .join(" ");

        let readify = await qReadify(text, selectedGroup);
        text = applyReplacements(readify);
        text = additionalFixes(text);

        if (text && text.trim().length > 0) {
            try {
                let player = new sdk.SpeakerAudioDestination();
                player.onAudioEnd = function (_) {
                    if (numPages && page + 1 < numPages) {
                        scrollToPageDefault(page + 1);
                        player.close();
                        fetchSpeechAudio(page + 1);
                    }
                };
                player.onAudioStart = function (_) {
                    import.meta.env.DEV === true && console.log("onAudioStart");
                };
                let voiceVer = getVoiceVersion(text, selectedGroup.convolang);
                let voiceP = voiceVer.voice;
                let lcle = voiceVer.language;

                audioRef.current = player;
                var audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
                const speechConfig = sdk.SpeechConfig.fromSubscription(apk, reg);
                speechConfig.speechSynthesisVoiceName = voiceP;

                const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
                synthesizer.synthesisCompleted = (sender, event) => {
                    import.meta.env.DEV === true && console.log("synth_cmplt_02");
                };
                synthesizer.synthesizing = (sender, event) => {
                    import.meta.env.DEV === true && console.log("synth_02...");
                };

                synthesizerRef.current = synthesizer;
                setIsPlaying(true);
                synthesizer.speakSsmlAsync(
                    prepSsmlDocReader(text, voiceP, lcle),
                    result => {
                        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                            setIsLoadingAudio(false);
                            synthesizer.close();
                        } else {
                            setIsLoadingAudio(false);
                            synthesizer.close();
                        }
                    },
                    error => {
                        console.error("Error: ", error);
                        setIsPlaying(false);
                        synthesizer.close();
                    }
                );
            } catch (error: any) {
                console.error("Error Fetching or Playing Speech¦", error);
            }
        } else {
            if (numPages && currentPage < numPages) {
                scrollToPageDefault(page + 1);
                fetchSpeechAudio(page + 1);
            }
        }
    };
    const callBackVisibleEntries = (visibleEntries: number[]) => {};

    const callBackIsLoading = (isLoading: boolean) => {
        import.meta.env.DEV === true && console.log("callBackIsLoading¦", currentPage);
    };
    const onContentShow = (fl: gfile | undefined, flIndex: number | undefined) => {
        setIsContentTableShowing(true);
    };
    return (
        <ThemeProvider theme={customTheme}>
            <div className={styles.Example}>
                {/* TITLE, PAGE SELECTOR, SPEAK */}

                <div className={styles.headerFileDropdown}>
                    <FileDropdown
                        file={file}
                        fileListStateVar={fileListStateVar}
                        handleFileClick={handleFileClick}
                        onContentShow={onContentShow}
                        activeFileId={activeFileId}
                    />
                </div>

                {isContentTableShowing && (
                    <Callout
                        target={"#pdfTtldrpTrg"}
                        className={styles.fileListCallout}
                        onDismiss={() => setIsContentTableShowing(false)}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        isBeakVisible={false}
                    >
                        <div className={styles.cntntf}>
                            {renderLinkData({
                                file,
                                fileMap,
                                activeFileId,
                                currentPage,
                                referencePage,
                                styles,
                                handleClick,
                                expandCollapseSection,
                                perFilesPerSectionStateMap
                            })}
                        </div>
                    </Callout>
                )}

                {docAudio && (
                    <div className={styles.volButsContainer}>
                        <div className={styles.volButs}>
                            {/* PAGE SELECTOR */}
                            <div className={styles.pageSelectorXplrr}>
                                <div className={styles.pageSelectorOnly}>
                                    <PageSelector
                                        currentPage={currentPage}
                                        numPages={numPages ? numPages : 0}
                                        handlePageChange={handlePageChangeFromPageSelector}
                                    ></PageSelector>
                                </div>
                                <div className={styles.totPagesDisplay}>/ {numPages}</div>
                            </div>
                            {/* SPEAK BUTTON */}
                            <TooltipHost content="Speak Page" directionalHint={DirectionalHint.topCenter}>
                                <IconButton
                                    onClick={() => (isPlaying ? stopSpeech() : playSpeech())}
                                    disabled={pageLoaded == true ? false : true}
                                    styles={isPlaying || isLoadingAudio ? pauseButStylesSBS : playButStylesSBS}
                                    className={selectedGroup.enabledocaudio == 1 ? styles.speakButShow : styles.speakButHide}
                                >
                                    {isPlaying ? (
                                        <HiOutlineVolumeOff size={35} />
                                    ) : isLoadingAudio ? (
                                        <MdDownloading size={35} />
                                    ) : audioRef.current && audioPage == currentPage ? (
                                        <HiMiniPlayPause size={35} />
                                    ) : (
                                        <HiOutlineVolumeUp size={35} />
                                    )}
                                </IconButton>
                            </TooltipHost>
                        </div>
                    </div>
                )}

                <div className={styles.pdfWrapXplrr} id="pdfWrapXplrrIDENT">
                    <div className={styles.pdfViewC}>
                        <XplrrBOXPDF
                            file={file}
                            startPage={referencePage}
                            activeFileId={activeFileId ? activeFileId : ""}
                            handleTimeout={handleTimeout}
                            handleScroll={handleScroll}
                            setPages={setPages}
                            setContentList={setContentList}
                            callBackVisibleEntries={callBackVisibleEntries}
                            callBackIsLoading={callBackIsLoading}
                            srcLoc={srcId}
                            companyId={selectedGroup.companyid}
                        ></XplrrBOXPDF>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default XplrrPDFSideByChat;
