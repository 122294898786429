import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";

export interface AnswerLoadingProps {
    loadingMsg?: string;
    shouldPulse?: boolean;
    totalAnswers?: number;
}
export const AnswerLoading: React.FC<AnswerLoadingProps> = ({ loadingMsg, shouldPulse }) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <AnswerIcon />
                <Stack.Item grow>
                    {shouldPulse == true ? (
                        <div className={styles.answerText}>
                            {"Listening...👂"}
                            <button className={styles.pulsebutton}></button>
                        </div>
                    ) : shouldPulse == false ? (
                        <div className={styles.answerText}>...</div>
                    ) : (
                        <div className={styles.answerText}>
                            {loadingMsg}
                            <div className={styles.typingDots}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    )}
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
