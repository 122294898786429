import React, { useState, useEffect, useContext } from "react";
import {
    Stack,
    TextField,
    TooltipHost,
    Checkbox,
    PrimaryButton,
    ITextFieldStyles,
    IStackStyles,
    ICheckboxStyles,
    ISliderStyles,
    DirectionalHint,
    IconButton,
    Slider,
    IDropdownOption,
    Dropdown,
    IDropdownStyles,
    ITextFieldProps,
    IRenderFunction,
    Toggle
} from "@fluentui/react";
import { toast } from "react-toastify";
import styles from "./CompEditForm.module.css";
import TagInput from "../TagInput/TagInput";
import { gactivecomp, gselectedgroup } from "../../interfaces";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)
import { getHeaderGlob } from "../../util_glob";
import { dropdownStylesCompAdmin, tooltipStylesCompAdmin, stackStylesCompAdmin, toggleStylesCompEdit } from "../../styles_glob";
import { compTierOptions } from "../../lsts";

interface ICompEditFormProps {
    selectedGroup: gselectedgroup;
    activeComp: gactivecomp;
}
const CompEditForm: React.FC<ICompEditFormProps> = ({ selectedGroup, activeComp }) => {
    const { loggedInUser } = useContext(GlobalContext);
    const [editedComp, setEditedComp] = useState<gactivecomp>({ ...activeComp });
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);
    const [allowedDomains, setAllowedDomains] = useState(editedComp.alloweddomains != "" ? editedComp.alloweddomains?.split(";") : []);

    useEffect(() => {
        setEditedComp({ ...activeComp });
    }, [activeComp]);

    useEffect(() => {
        if (isLoadingAPI != false) setIsLoadingAPI(false);
    }, []);

    const handleChange = (field: keyof gactivecomp, value: string | number | boolean) => {
        setEditedComp(prev => ({ ...prev, [field]: value }));
    };

    const handleSave = () => {
        setIsLoadingAPI(true);
        fetch("/qEditComp", {
            method: "POST",
            headers: getHeaderGlob(loggedInUser),
            body: JSON.stringify({
                companyid: activeComp.companyid,
                licensingkind: editedComp.licensingkind,
                alloweddomains: editedComp.alloweddomains,
                allowqueststocreate: editedComp.allowqueststocreate
            })
        }).then(resp => {
            if (!resp.ok) {
                toast.error("Error Saving Company");
            } else {
                toast.success("Company Saved");
            }
            setIsLoadingAPI(false);
        });
    };

    const onAllowedDomainsChange = (tags: string[]) => {
        setAllowedDomains(tags);
        if (tags) {
            setEditedComp(prev => ({ ...prev, alloweddomains: tags?.join(";").toLowerCase() }));
        } else {
            setEditedComp(prev => ({ ...prev, alloweddomains: "" }));
        }
    };

    return (
        <div className={styles.editModalWrap}>
            <Stack styles={stackStylesCompAdmin}>
                <div className={styles.editFormSectionTitle}>
                    <span>General Configuration</span>
                </div>

                <TooltipHost content="Licensing Tier" directionalHint={DirectionalHint.topAutoEdge} calloutProps={tooltipStylesCompAdmin}>
                    <Dropdown
                        label="Licensing Tier"
                        options={compTierOptions}
                        selectedKey={editedComp.licensingkind.toString()}
                        onChange={(_, option) => handleChange("licensingkind", Number(option?.key))}
                        styles={dropdownStylesCompAdmin}
                    />
                </TooltipHost>
                <div className={styles.groupFunc}>
                    <TooltipHost
                        content="Restrict the domains that can access the Assistant. Leave empty to allow all domains."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStylesCompAdmin}
                    >
                        <div className={styles.custLabelTagInput}>
                            <span>Allowed Domains</span>
                        </div>
                    </TooltipHost>
                    <TagInput onChange={onAllowedDomainsChange} palceholderText="Add a Domain and press Enter" initialTags={allowedDomains} />
                </div>
                <div className={styles.custLabelTagInput}>
                    <span>Allow Assistant Creation by Quest</span>
                </div>

                <Toggle
                    inlineLabel
                    label={"Quests and Viewers can create Assistants"}
                    styles={toggleStylesCompEdit}
                    checked={editedComp.allowqueststocreate == undefined || editedComp.allowqueststocreate == 1 ? true : false}
                    onChange={(_event, checked) => {
                        handleChange("allowqueststocreate", checked ? 1 : 0);
                    }}
                />

                <div className={styles.modalBottomButs}>
                    <PrimaryButton
                        text="Save"
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={isLoadingAPI}
                        className={styles.saveSpaceButton}
                    />
                </div>
            </Stack>
        </div>
    );
};

export default CompEditForm;
