import React, { useState, useRef, useEffect } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { getSynth, getSynthOnly, getVoiceVersion, prepSsml } from "./../../api/aud";
import { createVisGradient } from "./../../api/aud";
import { gselectedgroup, qsentenceforaudio } from "../../interfaces";
import { assistantCodes } from "../../lsts";

interface SpeechOutputProps {
    chunk: qsentenceforaudio;
    pauseSpeak: boolean | null;
    isSpeakingCallback: (val: boolean) => void;
    msgCanvasRef?: React.RefObject<HTMLCanvasElement>;
    selectedGroup: gselectedgroup;
    activeConvoKey: string;
}
const SpeechOutput: React.FC<SpeechOutputProps> = ({ chunk, pauseSpeak, isSpeakingCallback, msgCanvasRef, selectedGroup, activeConvoKey }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [que, setQue] = useState<qsentenceforaudio[]>([]);
    const [answerIdState, setAnswerIdState] = useState<string>();
    const [voiceVersionState, setVoiceVersionState] = useState<string>("");
    const playrRef = useRef<HTMLAudioElement | null>(null);
    const audConfRef = useRef<sdk.AudioConfig | null>(null);
    const synthRef = useRef<sdk.SpeechSynthesizer | null>(null);
    const pauseSpeakRef = useRef(pauseSpeak);
    const answerIdRef = useRef<string | null>(null);
    const chunkRef = useRef<qsentenceforaudio | null>(null); // LATEST ARRIVING CHUNK
    const visAudCtxRef = useRef<AudioContext | null>(null);
    const animFrameRef = useRef<number | null>(null);

    useEffect(() => {}, [msgCanvasRef]);

    // GROUP, CHAT CHANGE
    useEffect(() => {
        console.log("reset_que_ue");
        setQue([]);
    }, [selectedGroup, activeConvoKey]);

    // PAUSE
    useEffect(() => {
        if (isPlaying == true && pauseSpeak == true) {
            pauseSpeakRef.current = true;
            playrRef.current && playrRef.current.pause();
        } else if (playrRef.current && pauseSpeak == false) {
            setQue(prevQue => (prevQue.length > 0 ? prevQue.slice(1) : [])); // REMOVE FIRST ELEMENT
        } else {
            pauseSpeakRef.current = pauseSpeak;
        }
    }, [pauseSpeak]);

    // CHUNK QUE MANAGEMENT
    useEffect(() => {
        // IF THE SAME CHUNK WITH SAME ANSWERID IS IN QUE, SKIP
        if (!chunk.text) return;

        // IF ARRIVING CHUNK SAME AS CURRENT CHUNK, SKIP
        if (chunkRef.current?.chunkid == chunk.chunkid) {
            return;
        } else {
            chunkRef.current = chunk;
        }

        setAnswerIdState(chunk.answerid);
        answerIdRef.current = chunk.answerid;

        // IF CHUNK ANSWER ID IS NEW, RES EVERYTHING AND ADD NEW CHUNK TO CLEARED QUE
        if (que.length > 0 && !que.map(q => q.convoid).includes(chunk.convoid)) {
            // DISCARD SYNTH
            synthRef.current?.close();
            synthRef.current = null;

            setQue([chunk]);
            setPlayFlags(false);
        } else {
            //ADD CHUNK TO EXISTING ANSWERID QUE
            setQue(prevQue => [...prevQue, chunk]);
        }
    }, [chunk]);

    // MAIN PLAY LOGIC
    useEffect(() => {
        if (que.length > 0 && isPlaying != true) {
            // IF CHUNKS IN QUE AND NOT PLAYING, RUN LOGIC

            let chunkToPlay = que.at(0);
            if (!chunkToPlay) return;

            let voiceAndLang = getVoiceVersion(chunkToPlay.text, selectedGroup.convolang);
            if (synthRef.current == null) {
                console.log("synth_ref_null");
                setVoiceVersionState(voiceAndLang.voice);

                synthRef.current = getSynthOnly(voiceAndLang.voice, voiceAndLang.language);

                if (synthRef.current == null) return console.log("errr_synth_ref_still_null");
            }
            setPlayFlags(true);
            synthRef.current.speakSsmlAsync(
                prepSsml(chunkToPlay.text, voiceAndLang.voice, voiceAndLang.language),

                result => {
                    if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                        if (result.audioData) {
                            console.log("audio_data_available");
                            isSpeakingCallback(true);
                            handle_speech_synth_result(result);
                        }
                    } else console.error("speech_synth_errr_01", result.errorDetails);
                },
                error => console.error("speech_synth_errr_01" + error)
            );
        }
    }, [que, isPlaying]);

    const handle_speech_synth_result = (result: any) => {
        if (!result.audioData) return console.log("no_audio_data");

        // CONVERT ARRAYBUFFER TO BLOB
        const audioBlob = new Blob([result.audioData], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(audioBlob);
        playrRef.current = new Audio(audioUrl);
        playrRef.current.oncanplaythrough = () => {
            // START VISUALIZATION
            if (playrRef.current) start_visualizer_v2(playrRef.current);

            // PLAY AUDIO
            playrRef.current?.play().catch(err => console.error("errr_audio_playback", err));
        };
        // HANDLE WHEN AUDIO FINISHES PLAYING
        playrRef.current.onended = () => {
            // REMOVE CHUNK WE JUST PLAYED
            setQue(prevQue => (prevQue.length > 0 ? prevQue.slice(1) : []));
            setPlayFlags(false);
            isSpeakingCallback(false);

            // CLEANUP VISUALIZATION
            if (animFrameRef.current) {
                cancelAnimationFrame(animFrameRef.current);
                animFrameRef.current = null;
            }
            if (visAudCtxRef.current) {
                visAudCtxRef.current.close();
                visAudCtxRef.current = null;
            }
        };
        playrRef.current.onerror = err => console.error("errr_loading_vis", err);
    };

    const setPlayFlags = (isPlay: boolean) => {
        setIsPlaying(isPlay);
    };

    const start_visualizer_v2 = (audio: HTMLAudioElement) => {
        if (!msgCanvasRef?.current) return console.log("errr_canvas_not_found");

        // CLOSE PREVIOUS AUDIO CONTEXT
        if (visAudCtxRef.current) visAudCtxRef.current.close();
        if (animFrameRef.current) cancelAnimationFrame(animFrameRef.current);

        // NEW AUDIOCONTEXT
        const audCtx = new AudioContext();
        visAudCtxRef.current = audCtx;

        // ANALYSER
        const analyser = audCtx.createAnalyser();
        analyser.fftSize = 256;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        try {
            // Create a media source for the provided audio
            const source = audCtx.createMediaElementSource(audio);
            source.connect(analyser);
            analyser.connect(audCtx.destination);

            // Get canvas context
            let canvasCtx = msgCanvasRef?.current.getContext("2d");
            const cnvsWidth = msgCanvasRef?.current.width;
            const cnvsHeight = msgCanvasRef?.current.height;

            let keyValue = msgCanvasRef?.current?.getAttribute("data-key") || "0";

            function draw() {
                if (keyValue !== msgCanvasRef?.current?.getAttribute("data-key")) {
                    keyValue = msgCanvasRef?.current?.getAttribute("data-key") || "0";
                    canvasCtx = msgCanvasRef?.current?.getContext("2d") || null;
                }

                animFrameRef.current = requestAnimationFrame(draw);
                analyser.getByteFrequencyData(dataArray);
                if (!canvasCtx) return;

                canvasCtx.fillStyle = "rgb(1, 1, 1)";
                canvasCtx.fillRect(0, 0, 1, 1);
                canvasCtx.clearRect(0, 0, cnvsWidth, cnvsHeight);
                const barWidth = (cnvsWidth / analyser.frequencyBinCount) * 2.5;
                const centerY = cnvsHeight / 2;
                let barHeight,
                    x = 0;

                for (let i = 0; i < analyser.frequencyBinCount; i++) {
                    barHeight = dataArray[i] * 0.3;
                    const hue = (i / analyser.frequencyBinCount) * 360;

                    if (msgCanvasRef?.current) {
                        canvasCtx.fillStyle = createVisGradient(canvasCtx, msgCanvasRef?.current, barHeight);
                    } else {
                        canvasCtx.fillStyle = `hsl(${hue}, 100%, 50%)`;
                    }

                    canvasCtx.fillRect(x, centerY - barHeight, barWidth, barHeight);
                    canvasCtx.fillRect(x, centerY, barWidth, barHeight);
                    x += barWidth + 1;
                }
            }

            draw();
        } catch (error) {
            console.error("Error initializing visualization:", error);
        }
    };

    return <div></div>;
};

export default SpeechOutput;
