import { IDropdownOption } from "@fluentui/react";

import pps from "./assets/pps.png";
import hda from "./assets/hda.png";
import aas from "./assets/aas.png";
import ipa from "./assets/ipa.png";
import tam from "./assets/tam.png";
import mic from "./assets/mic.png";
import cmp from "./assets/cmp.png";
import vid from "./assets/vid.png";
import prd from "./assets/prd.png";
import dpa from "./assets/dpa.png";
import cca from "./assets/cca.png";
import anya from "./assets/anya.png";

import pps_sml from "./assets/Assistants_Headers_Small/pps_sml.png";
import hda_sml from "./assets/Assistants_Headers_Small/hda_sml.png";
import aas_sml from "./assets/Assistants_Headers_Small/aas_sml.png";
import ipa_sml from "./assets/Assistants_Headers_Small/ipa_sml.png";
import tam_sml from "./assets/Assistants_Headers_Small/tam_sml.png";
import mic_sml from "./assets/Assistants_Headers_Small/mic_sml.png";
import cmp_sml from "./assets/Assistants_Headers_Small/cmp_sml.png";
import vid_sml from "./assets/Assistants_Headers_Small/vid_sml.png";
import prd_sml from "./assets/Assistants_Headers_Small/prd_sml.png";
import dpa_sml from "./assets/Assistants_Headers_Small/dpa_sml.png";
import cca_sml from "./assets/Assistants_Headers_Small/cca_sml.png";
import anya_sml from "./assets/Assistants_Headers_Small/anya_sml.png";

export interface finField {
    id: string;
    name: string;
    level: "main" | "item";
    description?: string;
    default?: boolean;
    systemDisabled: boolean;
}
export const finFieldList: finField[] = [
    // Main Fields
    {
        id: "contractor_name",
        name: "Contractor Name",
        level: "main",
        description: "The name of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    {
        id: "contractor_address",
        name: "Contractor Address",
        level: "main",
        description: "The address of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    {
        id: "contractor_vat_identification",
        name: "Contractor VAT Identification",
        level: "main",
        description: "The VAT Idenitification of the Contractor/Supplier/Seller",
        default: true,
        systemDisabled: true
    },
    { id: "customer_name", name: "Customer Name", level: "main", description: "The name of the Customer/Buyer,Reciever", default: true, systemDisabled: true },
    {
        id: "customer_address",
        name: "Customer Address",
        level: "main",
        description: "The address of the Customer/Buyer,Reciever",
        default: true,
        systemDisabled: true
    },
    {
        id: "customer_vat_identification",
        name: "Customer VAT Identification",
        level: "main",
        description: "The VAT Idenitification of the Customer/Buyer,Reciever",
        default: true,
        systemDisabled: true
    },
    { id: "invoice_total", name: "Invoice Total", level: "main", description: "The Grand Total amount of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_date", name: "Invoice Date", level: "main", description: "The Date of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_id", name: "Invoice ID", level: "main", description: "The ID of the Invoice", default: true, systemDisabled: true },
    { id: "invoice_currency", name: "Invoice Currency", level: "main", description: "The Currency of the Invoice", default: true, systemDisabled: true },
    { id: "vat", name: "VAT", level: "main", description: "The VAT Total amount of the Invoice", default: true, systemDisabled: true },
    { id: "purchase_order", name: "Purchase Order", level: "main", description: "The Purchase Order number", default: true, systemDisabled: false },
    { id: "iban", name: "IBAN", level: "main", description: "The IBAN number of the Customer/Buyer,Reciever", default: true, systemDisabled: false },
    { id: "page", name: "Page", level: "main", description: "The Page number of the Invoice (System Field)", default: true, systemDisabled: true },
    { id: "invoice_items", name: "Invoice Items", level: "main", description: "The Items of the Invoice", default: true, systemDisabled: true },
    // Item-Level Fields
    { id: "description", name: "Description", level: "item", description: "The Description of the Item", default: true, systemDisabled: false },
    { id: "quantity", name: "Quantity", level: "item", description: "The Quantity of the Item", default: true, systemDisabled: true },
    { id: "unit_price", name: "Unit Price", level: "item", description: "The Unit Price of the Item", default: true, systemDisabled: true },
    { id: "total_price", name: "Total Price", level: "item", description: "The Total Price of the Item", default: true, systemDisabled: true },
    { id: "item_number", name: "Item Number", level: "item", description: "The Sequential Number of the Item", default: true, systemDisabled: true },
    { id: "item_code", name: "Item Code", level: "item", description: "The Code of the Item", default: true, systemDisabled: false },
    { id: "item_measure", name: "Item Measure", level: "item", description: "The Measure of the Item", default: true, systemDisabled: false },
    { id: "item_type", name: "Item Type", level: "item", description: "The Type of the Item", default: true, systemDisabled: false },
    { id: "item_barcode", name: "Item Barcode", level: "item", description: "The Barcode of the Item", default: true, systemDisabled: false },
    {
        id: "item_client_code",
        name: "Item Client Code",
        level: "item",
        description: "Client Code of the Item",
        default: true,
        systemDisabled: false
    }
];
export const imgMap = {
    pps: pps,
    hda: hda,
    aas: aas,
    ipa: ipa,
    tam: tam,
    mic: mic,
    cmp: cmp,
    vid: vid,
    prd: prd,
    dpa: dpa,
    cca: cca,
    anya: anya,
    "": anya
};
export const imgMapSml = {
    pps: pps_sml,
    hda: hda_sml,
    aas: aas_sml,
    ipa: ipa_sml,
    tam: tam_sml,
    mic: mic_sml,
    cmp: cmp_sml,
    vid: vid_sml,
    prd: prd_sml,
    dpa: dpa_sml,
    cca: cca_sml,
    anya: anya_sml,
    "": anya_sml
};
export const nmeMap = {
    pps: "Policies & Procedures Assistant",
    hda: "IT Helpdesk Assistant",
    aas: "Automation API Agent",
    ipa: "Invoice Processing Agent",
    tam: "Technical Onboarding Mentor",
    anya: "Multi-Purpose Assistant",
    mic: "Interview Assistant",
    cmp: "Changes Assistant",
    vid: "Video Content Assistant",
    prd: "Product Documentation Assistant",
    dpa: "Document Extraction Agent",
    cca: "Call Center Assistant",
    "": "Multi-Purpose Assistant"
};
export const locationCodes = Object.freeze({
    grp: "grp",
    lib: "lib",
    chat: "chat",
    xplr: "xplr",
    acc: "acc",
    adm: "adm"
});
export const assistantCodes = Object.freeze({
    pps: "pps",
    hda: "hda",
    aas: "aas",
    ipa: "ipa",
    tam: "tam",
    anya: "anya",
    mic: "mic",
    cmp: "cmp",
    vid: "vid",
    prd: "prd",
    dpa: "dpa",
    cca: "cca",
    "": "Multi-Purpose Assistant"
});
export type ImgMapKeys = "pps" | "hda" | "aas" | "ipa" | "tam" | "mic" | "cmp" | "vid" | "prd" | "dpa" | "cca" | "";

export const assistantCardItems = [
    {
        title: "Policies & Procedures Assistant",
        description: "Provides quick, accurate and up-to-date information on Company policies and procedures.",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Policies&Procedures Assistant?</span></h2></p><br/>
        <p><strong>Tailor-Made Document Learning Pipeline:</strong></p>
    <ul>
        <li>▸Tailored AI Content Extraction</li>
        <li>▸Supports Web Pages for Content Source</li>
        <li>▸Advanced Document Context Analysis</li>
        <li>▸Summary and Metadata Generation</li>
        <li>▸OCR</li>
    <p><strong>User Experience:</strong></p>
        <li>▸One-Click Navigation to Source</li>
        <li>▸Highlights for most influential Source Content</li>
        <li>▸Suggested Questions after each Answer</li>
        <li>▸4-Level Sharing Capabilities</li>
        <li>▸Audio Reader</li>
        <li>▸Feedback</li>
    <p><strong>Customization Options:</strong></p>
        <li>▸Suggested Questions in Greeting</li>
        <li>▸Custom Greeting Message and Header Image</li>
        <li>▸Custom AI Instructions</li>
    </ul>
</div>`,
        imageSrc: pps,
        imageSrcSmall: pps_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst:
                "You are an assistant that provides quick, accurate and up-to-date information on company policies and procedures. You are here to help employees find the information they need to do their jobs effectively and efficiently.",
            custgreetingmessage:
                "Hello! I'm here to help you with any questions you have about the company policies and procedures.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "pps",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0,
            questionsuggestions: 1
        }
    },
    {
        title: "IT Helpdesk Assistant",
        description: "Optimized for IT Support, FAQs. Provides solutions and sources increasing first call resolution. (Integration options comming soon)",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">IT Helpdesk Assistant Assistant?</span></h2></p><br/>
        <p><strong>Integration Options:</strong></p>
    <ul>
        <li>▸Supports Integrations with SharePoint, ServiceNow, Confluence and others*</li>
        <p><strong>Tailor-Made Document Learning Pipeline:</strong></p>
        <li>▸Tailored AI Content Extraction</li>
        <li>▸Advanced Document Context Analysis</li>
        <li>▸Summary and Metadata Generation</li>
        <p><strong>User Experience:</strong></p>
        <li>▸One-Click Navigation to Source</li>
        <li>▸Highlights on most influential Source Content</li>
        <li>▸Suggested Questions after each Answer</li>
        <li>▸4-Level Permission Capabilities</li>
        <li>▸Feedback</li>
        <p><strong>Customization Options:</strong></p>
        <li>▸Suggested Questions in Greeting</li>
        <li>▸Custom Greeting Message and Header Image</li>
    </ul><br/><span>*Integration Options available for Paid Plans only</span>
</div>`,
        imageSrc: hda,
        imageSrcSmall: hda_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst:
                "You are a Helpdesk assistant that provides solutions and sources. You are here to help employees with IT support, troubleshooting, and FAQs.",
            custgreetingmessage: "Hello! I'm here to help you with any **support**, **troubleshooting**, and **FAQs**.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "hda",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0
        }
    },
    {
        title: "Invoice Processing Agent",
        description: "Fully automated invoice processing and reporting. Invoice Data extraction and validation.",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Invoice Processing Agent?</span></h2></p><br/>
        <p><strong>Invoice Data Extraction:</strong></p>
    <ul>
        <li>▸Multi-agent Ingestion Pipeline for Extraction and Structuring of Data from any Invoice</li>
        <li>▸40%+ better extraction success rate compared to ABBYY Vantage</li>
        <p><strong>User Experience:</strong></p>
        <li>▸Invoice List with Invoice Verification and Post-Edit Functionalities</li>
        <li>▸Approvals</li>
        <li>▸Custom Field Extraction Instructions</li>
        <p><strong>Interations:</strong></p>
        <li>▸Web Hooks and API Endpoints for integration with external systems</li>
    </ul>
</div>`,
        imageSrc: ipa,
        imageSrcSmall: ipa_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: " ",
            custgreetingmessage: "Hi, I'm here to help you with invoice processing and reporting.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 1,
            allowaudiodocentry: 0,
            assistanttype: "ipa",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0
        }
    },
    {
        title: "Technical Onboarding Mentor",
        description: "Optimized for technical documentation, onboarding and training.",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Technical Onboarding Mentor?</span></h2></p><br/>
        <p><strong>Purpose Built for Technical Documentation:</strong></p>
    <ul>
        <li>▸Advanced OCR + Page Layout for Scanned Manuals</li>
        <li>▸Tailored AI Content Extraction</li>
        <li>▸Advanced Document Context Analysis</li>
        <li>▸Summary and Metadata Generation</li>
        <p><strong>User Experience:</strong></p>
        <li>▸One-Click Navigation to Source</li>
        <li>▸Highlights on most influential Source Content</li>
        <li>▸Suggested Questions after each Answer</li>
        <li>▸4-Level Permission Capabilities</li>
        <li>▸Audio Reader</li>
        <li>▸Feedback</li>
        <p><strong>Customization Options:</strong></p>
        <li>▸Suggested Questions in Greeting</li>
        <li>▸Custom Greeting Message and Header Image</li>
    </ul>
</div>`,
        imageSrc: tam,
        imageSrcSmall: tam_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: "You are a Technical Onboarding Mentor AI assistant that provides technical documentation, onboarding and training.",
            custgreetingmessage: "Hello! I'm here to help you with any technical documentation and training.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "tam",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0,
            questionsuggestions: 1
        }
    },
    {
        title: "Interview Assistant",
        description: "Records and transcribes interviews. Analyses and provides tools to extract insights from interviews. (Beta)",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Interview Assistant?</span></h2></p><br/>
        <p><strong>Records Audio from Interviews</strong></p>
    <ul>
        <li>▸Transcribes Real-time Conversations</li>
        <li>▸Supports Speaker Diarization</li>
        <li>▸Supports Real-time Edits to Content</li>
        <li>▸Learns from the Conversations</li>
    </ul>
</div>`,
        imageSrc: mic,
        imageSrcSmall: mic_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: "You are an assistant that helps users get the most out of their interview data.",
            custgreetingmessage: "Hello! I'm here to help you get the most out of your interview data. How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 1,
            assistanttype: "mic",
            showexamplequestionsonstart: 1,
            usedocintel: 0,
            forceocr: 0
        }
    },
    {
        title: "Changes Assistant",
        description: "Intelligently compare and analyze differences in content. (Beta)",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Changes Assistant?</span></h2></p><br/>
        <p><strong>Showcases differences between Similar Documents</strong></p>
    <ul>
        <li>▸Purpose Built for challenging cases.</li>
        <li>▸Performant with similar documents from different sources (ex. Original vs Scanned Signed Copy)</li>
        <li>▸Performant with similar documents with different layouts</li>
        <li>▸Performant with documents containing OCR artefacts, signatures, stamps and other content obstructions</li>
    </ul>
</div>`,
        imageSrc: cmp,
        imageSrcSmall: cmp_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: "You are an assistant that helps users compare and analyze changes in documents.",
            custgreetingmessage: "Hello! I'm here to help you compare and analyze changes in documents. How can I help you today?",
            allowyoutube: 0,
            allowweb: 0,
            invoicesgroup: 0,
            allowaudiodocentry: 1,
            assistanttype: "cmp",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 1,
            docextractionuseauto: 0
        }
    },
    {
        title: "Video Content Assistant",
        description: "Upload and consult with Video Content. Convert Video to Presentations. (Beta)",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Video Content Assistant?</span></h2></p><br/>
        <p><strong>Learns over Audio/Video Content (Uploaded Video Files or Youtube*)</strong></p>
    <ul>
        <li>▸Converts Videos to Presentations preserving In-Frame Text and Audio Speech</li>
        <li>▸One-Click access to video frame with audio subtitles and link to actual video</li>
    </ul><br/><span>*Youtube Integration available for Paid Plans only</span>
</div>`,
        imageSrc: vid,
        imageSrcSmall: vid_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: "You are an assistant that helps users recal content from videos.",
            custgreetingmessage: "Hello! I'm here to help you learn from videos?",
            allowyoutube: 0,
            allowweb: 0,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "vid",
            showexamplequestionsonstart: 1,
            usedocintel: 0,
            forceocr: 0,
            allowvideofiles: 1
        }
    },
    {
        title: "Product Documentation Assistant",
        description: "Offer Product Documentation Experience to your users. (Beta)",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Product Documentation Assistant?</span></h2></p><br/>
        <p><strong>Displays Documents and allows Users to use AI Chat Search</strong></p>
    <ul>
        <li>▸Dedicated Landing Screen showcasing uploaded documents and contents</li>
        <li>▸Allows Users to expand Search and Search in the documentation in Chat manner</li>
    <p><strong>Tailor-Made Document Learning Pipeline:</strong></p>
        <li>▸Tailored AI Content Extraction similar to Policies&Procedures</li>
    </ul>
</div>`,
        imageSrc: prd,
        imageSrcSmall: prd_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: "You are an assistant that answers questions and provides information about products and documentation.",
            custgreetingmessage: "Hello! Please ask you question?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "prd",
            showexamplequestionsonstart: 1,
            usedocintel: 0,
            forceocr: 0,
            allowvideofiles: 0,
            questionsuggestions: 1
        }
    },
    {
        title: "Automation API Agent",
        description: "Primed for Business Process Automation, RPA and API integrations.",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Automation API Agent?</span></h2></p><br/>
        <p><strong>Offers API Access and a blank slate to customize the Platform as needed for Automation Projects</strong></p>
</div>`,
        imageSrc: aas,
        imageSrcSmall: aas_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: " ",
            custgreetingmessage: "Hi, this is an AI Automation API Agent.<br><br>What can I do for you?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 1,
            allowaudiodocentry: 0,
            assistanttype: "aas",
            showexamplequestionsonstart: 1,
            usedocintel: 0,
            forceocr: 0
        }
    },
    {
        title: "Document Extraction Assistant",
        description: "Extract and Analyze Data from Documents (Beta)",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Document Extraction Assistant?</span></h2></p><br/>
        <p><strong>Data Extraction:</strong></p>
    <ul>
        <li>▸Extract and Structure Data from Documents</li>
        <li>▸Robust multi-agent pipeline for data extraction and structure, similar to Invoice Extraction</li>
        <li>▸Can intelligently determine data legible for structuring.</li>
        <li>▸Allows for confuguration of required data and structure format.</li>
        <li>▸Structure Export/Import.</li>
        <li>▸Web Hooks and API Endpoints for integration with external systems</li>
    </ul>
    </div>`,
        imageSrc: dpa,
        imageSrcSmall: dpa_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: " ",
            custgreetingmessage: "Hi, I'm here to help you with document extraction and reporting.<br><br>How can I help you today?",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 1,
            allowaudiodocentry: 0,
            assistanttype: "dpa",
            showexamplequestionsonstart: 1,
            usedocintel: 1,
            forceocr: 0,
            docextractionuseauto: 1
        }
    },
    {
        title: "Call Center Assistant",
        description: "Calls or Receives calls from users and provides or collects information and accomplishes tasks.",
        tooltip: `<div class="assistantsTooltip">
        <p><h2>What's Unique with <span class="assistantsTooltipHeadColored">Call Center Assistant?</span></h2></p><br/>
        <p><strong>Has Natural Conversation Capabilities:</strong></p>
    <ul>
        <li>▸Can start or recieve audio Calls</li>
        <li>▸Listens, reacts and responds like a human.</li>
        <li>▸Fully Supports Multiple languages incuding Bulgarian.</li>
        <p><strong>Completes complex Tasks</strong></p>
        <li>▸Can perform human level assumptions and decisions</li>
        <li>▸Can collect specific information</li>
        <li>▸Can ground collected data aginst external system values</li>
        <li>▸Configurable with natural text</li>
        <p><strong>Unlimited Integration Options:</strong></p>
        <li>▸Multitude of access options possible, including Line, Viber, WhatsApp, Skype, Web and others</li>   
        <li>▸Can pass call results and collected data to external Systems</li>     
        <li>▸Can perform further analysis of collected data and proceed accordingly</li>   
    </ul><br/><span>*Integration Options available for Paid Plans only</span>
</div>`,
        imageSrc: cca,
        imageSrcSmall: cca_sml,
        disabled: false,
        commingSoon: false,
        creationData: {
            custaiinst: `You are a female call consultant, answering calls from people registering insurance claims. Check the conversation history up to now. At the beginning overview the process briefly for the caller. You need to collect 3 pieces of information from them.
- client number,
- car registration number,
- overview of the accident and the sustained damage

Be proactive in the conversation and ask for the information in a friendly manner. Adapt to the caller. After each piece of information, repeat it back, to verify it is correct with the caller.
Avoid repeating the same sentences and be brief.
Check the history and if all the info is collected repeat the collected data and ask if everything is correct. If yes, end the conversation.
Instead of 'претенция', say 'иск'. If you list stuff, don't number it.
Always respond in the language used in the question. If the message is cyrillic it is Bulgarian. Don't respond in Russian.`,
            custgreetingmessage: "Hello! Head on to the manage section to configure your Call Center Assistant.",
            allowyoutube: 0,
            allowweb: 1,
            invoicesgroup: 0,
            allowaudiodocentry: 0,
            assistanttype: "cca",
            showexamplequestionsonstart: 0,
            usedocintel: 0,
            forceocr: 0,
            convomode: 1,
            convolang: "auto",
            disablecitationhighlighting: 1
        }
    }
];
export const multiPurposeAssistantCreationData = {
    custaiinst: "You are an assistant that helps users by answering questions and providing information.",
    custgreetingmessage: "Hello! I'm here to help you with any questions that you might have. How can I help you today?",
    allowyoutube: 0,
    allowweb: 1,
    invoicesgroup: 0,
    allowaudiodocentry: 0,
    assistanttype: "",
    showexamplequestionsonstart: 1,
    usedocintel: 1,
    forceocr: 0,
    allowvideofiles: 1
};
export const detailsListContentTypeOptions = [
    { key: "document", text: "document" },
    { key: "invoice", text: "invoice" },
    { key: "form", text: "form" },
    { key: "receipt", text: "receipt" },
    { key: "contract", text: "contract" },
    { key: "book", text: "book" },
    { key: "policy", text: "policy" },
    { key: "data", text: "data" },
    { key: "offer", text: "offer" },
    { key: "other", text: "other" }
];
export const transcriptLanguageOptions: IDropdownOption[] = [
    { key: "en-US", text: "English" },
    { key: "bg-BG", text: "Bulgarian" }
];
export const defaultPromptTemplateAsInPythonBackend = `"Assistant helps find answers to questions about information in the available documents. If there isn't enough information below, ask for more specific information."`;

export const defaultGreetingsMessage = `<div class="answerText">Hey,</div>
<div class="answerText">I'm <b>Kognify </b> and I'm here to help you understand and get insights from your content.</div>
<div class="answerText">You can chat with me or you can check the Documents that I know about in the Manage Section</div>
<div class="answerCardContainer">
</answerSuggestions>
</div>`;
export const msgLmtHit = "Space Messages Exhausted for the day. Please Consider Upgrading to Premium";

export const defaultGreetingContent = `
<div class="greetingPretext">It looks like there is no content for this Assistant and you have permissions to add content. Head on to the Content Section</div>
<div class="answerCard" onclick="window.greetingMessageViewDocs('')">
    <div class="greetingItemTitle" style="font-size: 24px; color: ">View Content List</div>
    <div class="topGreetingText">View what documents are available to this Assistant. Add Content to get started.</div>
</div>
`;
export const noContentNoAccessGreetingContent = `<div class="greetingPretext">It looks like there is no content for this Assistant yet.</div>`;

export const answerCardHTML = `
<div class="answerCard" onclick="window.greetingMessageBeginConversation('{kgnf_replace}');">
    <div class="topGreetingText">Suggested Question</div>
    <hr class="hrzntlLn">
    <i class="fas fa-lightbulb" style="font-size: 24px; color: #ffc107;"></i>
    <h3 class="greetingItemTitle">{kgnf_replace}</h3>
</div>
`;

export type greplacement = {
    text: string;
    replacement: string;
};

export const pb_excl = ["internal use", "p a g e", "page", "internaluse", "confidential", "Confidential"];

export const pb_replace: greplacement[] = [
    { text: "CSoft", replacement: "Сисофт" },
    { text: "ТРЗ", replacement: "Тъ.Ръ.Зъ. " },
    { text: "Т Р З ", replacement: "Тъ.Ръ.Зъ. " },
    { text: "@", replacement: " " },
    { text: " ат ", replacement: "  " }
];
export function applyReplacements(input: string) {
    let result = input;
    for (const { text, replacement } of pb_replace) {
        const regex = new RegExp(text, "g"); // global replace
        result = result.replace(regex, replacement);
    }
    return result;
}
export const resultCountOptions: IDropdownOption[] = [
    { key: "3", text: "3 results" },
    { key: "5", text: "5 results" },
    { key: "10", text: "10 results" },
    { key: "20", text: "20 results" }
];
export const convoLangOptions: IDropdownOption[] = [
    { key: "auto", text: "auto" },
    { key: "en-US", text: "english" },
    { key: "bg-BG", text: "bulgarian" }
];
export const dfltSettingsLocalStr = `{
    "promptTemplate": "Assistant helps find answers to questions about information in the available documents. If there isn't enough information below, ask for more specific information.",
    "retrieveCount": 5,
    "retrievalMode": "hybrid",
    "useSemanticRanker": true,
    "shouldStream": true,
    "useSemanticCaptions": false,
    "searchOnly": false,
    "useHighlightsAsSource": false,
    "useSuggestFollowupQuestions": true,
    "isDark": false,
    "experimentalFeatures": false,
    "showDocLibInChatTab": false,
    "sourceHighlighting":false
}`;
export const chatbotScript = (companyid: string, selectionId: string, selectionText: string, anon: boolean, shouldBeSearchBox: boolean) => {
    const scrsrc = shouldBeSearchBox ? "https://kgnf.blob.core.windows.net/msc/kgnfyV0_24.js" : "https://kgnf.blob.core.windows.net/msc/kgnfyV0_23.js";
    const ifrmtype = shouldBeSearchBox ? 2 : 1;
    const url =
        anon == true
            ? `${window.location.origin}?redeemCode=${encodeURIComponent(selectionId)}&spt=${encodeURIComponent(selectionText)}&ifrm=${ifrmtype}&A=1`
            : `${window.location.origin}/#/${companyid}/${encodeURIComponent(selectionId)}/chat?&spt=${encodeURIComponent(selectionText)}&ifrm=${ifrmtype}`;

    return `<script src="${scrsrc}"></script>
<script>
    initChatbot({
        chatBotID: "${url}",
        iconSrc: "https://kgnf.blob.core.windows.net/imgs/kgnf_logo_only.svg",
        width: 600,
        height: 800,
        userid:""
    });
</script>`;
};
export const chatbotEmbed = (companyid: string, selectionId: string, selectionText: string, anon: boolean) => {
    const url =
        anon == true
            ? `${window.location.origin}/#/${companyid}/${encodeURIComponent(selectionId)}/chat?ifrm=1`
            : `${window.location.origin}?redeemCode=${encodeURIComponent(selectionId)}&spt=${encodeURIComponent(selectionText)}&ifrm=1&A=1`;

    return `<iframe
    src="${url}"
    style="width: 800px; height: 800px; border: none; z-index: 1000;"allowtransparency="true">
</iframe>`;
};
//export const speechRecogPhraseListLetters = ["а", "в", "с", "е", "н", "к", "м", "о", "р", "т", "х", "у", "gps", "джи пи ес", "с в"];

//export const speechRecogPhraseListLetters = ["а", "в", "с", "е", "н", "к", "м", "о", "р", "т", "х", "у"];

export const speechRecogPhraseListLetters_other = [
    "а",
    "в",
    "е",
    "к",
    "м",
    "н",
    "о",
    "р",
    "с",
    "т",
    "у",
    "х",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0"
];
export const speechRecogPhraseListLetters = [];

export const convoSyllabicStressWords: { [key: string]: string } = {
    "предостав[е]те": "prɛdostɐvɛˈte",
    "дал[и]": "daliˈ",
    "дос[е]га": "dɔsɛɡaˈ",
    "обраб[о]тим": "ɔbrɐbɔˈtim",
    "п[о]-б[ъ]рзо": "po-bɤˈrzo",
    "к[а]жете": "kaˈʒɛte",
    "нив[а]": "nivaˈ",
    "отв[о]реност": "ɔtvɔˈrɛnost",
    "п[о]-м[а]лко": "po-mɐˈlko",
    "изгов[о]рите": "izɡɔvɔˈrite",
    "произнес[е]те": "prɔiznɛseˈte",
    "разб[и]ра:": "razbiˈra",
    "идентифик[а]тор": "identifikaˈtor",
    "изгов[a]ряйки": "izɡɔvɐˈrjajki",
    "д[о]бър ден": "dɔˈbɤr dɛn",
    "кaж[е]те": "kˈaʒˈɛtˈe",
    "дa повторите": "da povtˈorɪte",
    "дa го": "da ɡо",
    "п[о]стбанк": "postbɐŋk"
};
export const veryShortPhrases = ["да", "не", "no"];

export const emptyConvoRecognizedObject = {
    final_upstream_msg: "",
    should_run_oai: false,
    conjoined_obj: {
        conjoined_msg: "",
        conjoined_segments: ""
    },
    conj_clean: "",
    value_valid_bot_not_in_data: ""
};

export const conv_collect_replacements = {
    "€": "e",
    "сaвa ": "св ",
    "сaв ": "св ",
    "съвъ ": "св ",
    "съв ": "св "
};
export const extrTypeOptions: IDropdownOption[] = [
    { key: "string", text: "Text" },
    { key: "number", text: "Number" },
    { key: "boolean", text: "Yes/No" },
    { key: "array", text: "List" },
    { key: "object", text: "Object" }
];
export const extrTypeOptionsReduced: IDropdownOption[] = [
    { key: "string", text: "Text" },
    { key: "number", text: "Number" },
    { key: "boolean", text: "Yes/No" }
];
export const extrTypeTooltipTexts = {
    AddContentType: `
<div class="assistantsTooltip">
        <p><h3 style="color:#00c6af">Create New Content Type</h3></p><br/>
        <p><strong>Content Types allow you to describe different content to the assistant, and specify how it should be processed</strong></p>
    <ul>
        <li>▸Describe specific content, based on its content</li>
        <li>▸Configure tasks the assistant should do when it encounters such a document</li>
    <ul>    
    </div>
`,
    EditContentType: `
<div class="assistantsTooltip">
        <p><h3 style="color:#2a8091">Edit the current Content Type</h3></p><br/>
        <p><strong>Edit the Name</strong></p>
        <p><strong>Edit the Instructions for AI on how to recognize this Content Type</strong></p>
        <p><strong>Toggle automatic extraction (assistant determines what and how to extract)</strong></p>
    </div>
`,
    DeleteContentType: `
<div class="assistantsTooltip">
        <p><h3 style="color:#c45258">Delete the current Content Type</h3></p><br/>
        <p><strong>Delete the currently selected Content Type. Non-reversable</strong></p>
 
    </div>
`,
    ToggleAutoExtractionConf: `
<div class="assistantsTooltip">
    <p><h3 style="color:#2a8091">Toggle Auto Content Extraction</h3></p><br/>
    <p><strong>Let the assistant decide intelligently what content is important and how to structure it.</strong></p>
    <p><strong>Auto Extraction works very well if you don't need persistent data scheme</strong></p>
</div>
`
};
export const initialExtrConfigString = '{contenttype: [{ type: "All", description: "All Content not covered by other Content Types", fields:[], auto: true }]}';

export const systemExtrConfigName = { type: "all_content", description: "All Content not covered by other Content Types", fields: [], auto: true };

export const extrConfConfLabels = {
    toggleAutoConfLable: "Assistant Determines What to Extract",
    mutliTextLabel: "Instruct the assistant on how to recognize this content type when it ingests content"
};
export const compTierOptions: IDropdownOption[] = [
    { key: "0", text: "Credit Card Licensing" },
    { key: "1", text: "Business Licensing" }
];
