const BACKEND_URI = "";

import axios, { AxiosResponse } from "axios";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { pb_excl, speechRecogPhraseListLetters, convoSyllabicStressWords } from "../lsts";
import { escapeSSML } from "../util_glob";

export const fetchSpeechAOI = async (text: string): Promise<HTMLAudioElement | null> => {
    const s = import.meta.env.VITE_TTS_OAI_S;
    const a = import.meta.env.VITE_TTS_OAI_A;
    const d = import.meta.env.VITE_TTS_OAI_D;
    const k = import.meta.env.VITE_TTS_OAI_K;

    const ttsUrl = `https://${s}.openai.azure.com/openai/deployments/tts/audio/speech?api-version=${a}`;

    const requestBody = {
        input: text,
        model: `${d}`,
        voice: "alloy",
        speed: 1.2,
        voiceP: "en-US-AvaNeural"
    };

    try {
        const speechResponse: AxiosResponse<ArrayBuffer> = await axios.post(ttsUrl, requestBody, {
            headers: {
                "api-key": `${k}`,
                "Content-Type": "application/json",
                Accept: "audio/mp3"
            },
            responseType: "arraybuffer"
        });

        const audioBlob = new Blob([speechResponse.data], { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        return audio;
    } catch (error) {
        console.error("Error fetching speech:", error);
        return null;
    }
};
export const fetchSpeechSDK = async (text: string) => {
    const k = import.meta.env.VITE_TTS_OAI_K;
    const r = import.meta.env.VITE_TTS_OAI_R;
    const speechConfig = sdk.SpeechConfig.fromSubscription(`${k}`, `${r}`);

    speechConfig.speechSynthesisVoiceName = "en-US-NovaMultilingualNeural";
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
    synthesizer.speakTextAsync(
        text,
        result => {
            if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                import.meta.env.DEV === true && console.log("speech_synth_ok");
            } else {
                import.meta.env.DEV === true && console.log("speech_synth_errr", result.errorDetails);
            }
            synthesizer.close();
        },
        error => {
            console.error("Error: " + error);
            synthesizer.close();
        }
    );
};
export const getClearTextForAudio = (page: number) => {
    const items = document.querySelectorAll(`.pdf_itm_${page - 1}`);
    return Array.from(items)
        .filter(
            item =>
                item.textContent != null && // Exclude if string is empty
                !/^\d+$/.test(item.textContent) && // Exclude if string is only digits
                !pb_excl.includes(item.textContent.toLowerCase().trim()) && // Exclude if string is in the exclusion array
                item.textContent.trim() !== "|"
        )
        .map(item => item.textContent)
        .join(" ")
        .replace("&", " and ");
};
export const determineBGUS = (text: string) => {
    const containsCyrillic = /[а-яА-ЯЁё]/.test(text);
    let voiceP = "en-US-AvaNeural";
    if (containsCyrillic) {
        voiceP = "bg-BG-KalinaNeural";
    }
    return voiceP;
};
export const ttsTk = async (tkUrl: string, apk: any) => {
    return await axios.post(
        tkUrl,
        {},
        {
            headers: {
                "Ocp-Apim-Subscription-Key": `${apk}`,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }
    );
};
export const getTTSAudio = async (ttsUrl: string, text: string, token: any) => {
    return await axios.post(
        ttsUrl,
        `<speak version='1.0' xml:lang='bg-BG'><voice xml:lang='bg-BG' xml:gender='Female' name='bg-BG-KalinaNeural'>${text}</voice></speak>`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/ssml+xml",
                "X-Microsoft-OutputFormat": "audio-16khz-128kbitrate-mono-mp3"
            },
            responseType: "blob"
        }
    );
};
export const fetchBase64 = async (url: string) => {
    try {
        const response = await axios.get(url, { responseType: "blob" });
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => (reader.result ? resolve(reader.result.toString().split(",")[1]) : "");
            reader.onerror = reject;
            reader.readAsDataURL(response.data);
        });
    } catch (error) {
        console.error("errr_fetch_tts", error);
        return null;
    }
};
export const writeString = (view: DataView, offset: number, string: string) => {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
};
export const createWavBuffer = (samples: Int16Array, sampleRate: number): ArrayBuffer => {
    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);
    const bytesPerSample = 2; // 16-bit Audio
    const numChannels = 1; // Mono
    const byteRate = sampleRate * numChannels * bytesPerSample;
    const blockAlign = numChannels * bytesPerSample;
    const bitsPerSample = 16;
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + samples.length * bytesPerSample, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, byteRate, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, bitsPerSample, true);
    writeString(view, 36, "data");
    view.setUint32(40, samples.length * bytesPerSample, true);
    for (let i = 0; i < samples.length; i++) {
        view.setInt16(44 + i * 2, samples[i], true);
    }
    return buffer;
};
export const playWavBuffer = (buffer: ArrayBuffer) => {
    const audioContext = new AudioContext();
    audioContext.decodeAudioData(
        buffer,
        decodedData => {
            const source = audioContext.createBufferSource();
            source.buffer = decodedData;
            source.connect(audioContext.destination);
            source.start(0);
        },
        error => {
            console.error("Error decoding audio data", error);
        }
    );
};
export const getSynthOnly = (voiceVersion: string, voiceLang: string): sdk.SpeechSynthesizer | null => {
    const reg = import.meta.env.VITE_STT_R;
    const apk = import.meta.env.VITE_STT_K;
    try {
        const speechConfig = sdk.SpeechConfig.fromSubscription(apk, reg);
        speechConfig.speechSynthesisVoiceName = voiceVersion;
        speechConfig.speechSynthesisLanguage = voiceLang;

        const audioOutputStream = sdk.AudioOutputStream.createPullStream();
        const audioConfig = sdk.AudioConfig.fromStreamOutput(audioOutputStream);

        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
        //synthesizer.wordBoundary = (s, e) => {
        //    import.meta.env.DEV === true && console.log("word_boundary", e.audioOffset);
        //};
        return synthesizer;
    } catch (error: any) {
        console.error("errr_creating_synth", error);
        return null;
    }
};
export const getVoiceVersion = (text: string | undefined, selected_lang: string) => {
    if (selected_lang && selected_lang !== "auto") {
        if (selected_lang == "bg-BG") {
            return { voice: "zh-CN-XiaoxiaoMultilingualNeural", language: "bg-BG" };
        } else {
            return { voice: "en-US-CoraMultilingualNeural", language: selected_lang };
        }
    }
    return /[а-яА-ЯЁё]/.test(text || "")
        ? { voice: "zh-CN-XiaoxiaoMultilingualNeural", language: "bg-BG" } // en-US-CoraMultilingualNeural
        : { voice: "en-GB-AdaMultilingualNeural", language: "en-US" }; // en-GB-AdaMultilingualNeural
};
export const createVisGradient = (canvasCtx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, height: number) => {
    const gradient = canvasCtx.createLinearGradient(0, canvas.height - height, 0, canvas.height);
    gradient.addColorStop(0, "rgba(255, 69, 0, 1)");
    gradient.addColorStop(1, "rgba(255, 140, 0, 1)");
    return gradient;
};
export const prepRecog = (convoLangGroupSetting: string, assistantType: string) => {
    const apk = import.meta.env.VITE_STT_K;
    const reg = import.meta.env.VITE_STT_R;
    const lngs = convoLangGroupSetting == "auto" ? ["bg-BG", "en-US"] : convoLangGroupSetting == "bg-BG" ? ["bg-BG"] : ["en-US"];

    let segm_timeout = assistantType == "cca" ? "800" : "2000";
    let end_timeout = assistantType == "cca" ? "1000" : "1000";
    let init_timeout = "10000";

    const speechConfig = sdk.SpeechConfig.fromSubscription(apk, reg);
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

    speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_LanguageIdMode, "Continuous");
    speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, init_timeout);
    speechConfig.setProperty(sdk.PropertyId.Speech_SegmentationSilenceTimeoutMs, segm_timeout);
    speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, end_timeout);
    speechConfig.setProperty(sdk.PropertyId.Speech_SegmentationStrategy, "Semantic");
    speechConfig.setProperty(sdk.PropertyId.SpeechServiceResponse_PostProcessingOption, "TrueText");
    const newRecognizer = sdk.SpeechRecognizer.FromConfig(speechConfig, sdk.AutoDetectSourceLanguageConfig.fromLanguages(lngs), audioConfig);

    if (speechRecogPhraseListLetters.length > 0) {
        const phraseList = sdk.PhraseListGrammar.fromRecognizer(newRecognizer);
        phraseList.addPhrases(speechRecogPhraseListLetters);
    }

    return newRecognizer;
};

export const applyAmendedPhraseList = (recognizer: sdk.SpeechRecognizer, amendValues: string[]) => {
    const phraseListGrammar = sdk.PhraseListGrammar.fromRecognizer(recognizer);

    let phraseListAmended = [
        ...amendValues.map(phrase => {
            try {
                return phrase.toString().split("").join(" ");
            } catch (error) {
                return phrase;
            }
        }),
        ...speechRecogPhraseListLetters
    ];
    console.log("▢expected▢", phraseListAmended);
    if (phraseListAmended.length > 450) {
        phraseListAmended = phraseListAmended.slice(0, 450); // Limit to 100 phrases
    }
    if (phraseListAmended.length == 0) {
        phraseListAmended = speechRecogPhraseListLetters; // Limit to 100 phrases
    }

    phraseListGrammar.clear();
    console.log("phraseListAmended", phraseListAmended);
    phraseListAmended.forEach(phrase => {
        phraseListGrammar.addPhrase(phrase);
    });
};

export const prepSsml = (txt: string, voiceVersion: string, lang: string, prosodyRatePerc = 40) => {
    let ssml = `<speak version="1.0"
       xmlns="http://www.w3.org/2001/10/synthesis"
       xmlns:mstts="http://www.w3.org/2001/mstts"
       xmlns:emo="http://www.w3.org/2009/10/emotionml"
       xml:lang="${lang}">
	<voice name="${voiceVersion}">
		<lang xml:lang="${lang}">
			<s/>
			<prosody rate="${prosodyRatePerc}%">
            <mstts:express-as style="empathetic">${addSyllabicStress(fixSingleLettersAndDigits(escapeSSML(txt)))}</mstts:express-as>
			</prosody>
			<s/>
		</lang>
	</voice>
</speak>
`;
    console.log("¦SSML¦", ssml);
    // poetry-reading
    return ssml;
};

export const prepSsmlDocReader = (txt: string, voiceVersion: string, lang: string, prosodyRatePerc = 40) => {
    let ssml = `<speak version="1.0"
       xmlns="http://www.w3.org/2001/10/synthesis"
       xmlns:mstts="http://www.w3.org/2001/mstts"
       xmlns:emo="http://www.w3.org/2009/10/emotionml"
       xml:lang="${lang}">
	<voice name="${voiceVersion}">
		<lang xml:lang="${lang}">
			<s/>
			<prosody rate="${prosodyRatePerc}%">
            <mstts:express-as style="empathetic">${addSyllabicStress(escapeSSML(txt))}</mstts:express-as>
			</prosody>
			<s/>
		</lang>
	</voice>
</speak>
`;
    console.log("¦SSML¦", ssml);
    // poetry-reading
    return ssml;
};

// INTERNAL
const escapeConvoSSMLRegExp = (text: string) => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // ESCAPE SPECIAL CHARACTERS
};
const addSyllabicStress = (text: string) => {
    Object.keys(convoSyllabicStressWords).forEach(wordWithBrackets => {
        const wordWithoutBrackets = wordWithBrackets.replace(/\[|\]/g, ""); // REMOVE BRACKETS TO MATCH NORMAL TEXT
        const escapedWord = escapeConvoSSMLRegExp(wordWithoutBrackets);
        const regex = new RegExp(`(?:^|\\s|[,.!?:])(${escapedWord})(?=$|\\s|[,.!?:;])`, "gi");
        text = text.replace(regex, match => `<phoneme alphabet="ipa" ph="${convoSyllabicStressWords[wordWithBrackets]}">${match}</phoneme>`);
    });
    return text;
};
const fixSingleLettersAndDigits = (txt: string) => {
    const excludeWords = new Set(["a", "i", "а", "о", "е", "и", "в", "у", "с", "я"]);
    return txt.replace(/(^|[^А-Яа-яA-Za-z0-9])([А-Яа-яA-Za-z0-9])(?=[^А-Яа-яA-Za-z0-9]|$)/g, (fullMatch, boundaryBefore, singleChar, offset, fullStr) => {
        const isLowercase = singleChar == singleChar.toLowerCase();
        if (excludeWords.has(singleChar.toLowerCase()) && isLowercase) return boundaryBefore + singleChar;

        function getPrevWord(str: string, startPos: number) {
            let i = startPos;
            while (i >= 0 && str[i] === " ") i--;
            if (i < 0) return "";
            let end = i;
            while (i >= 0 && /[А-Яа-яA-Za-z0-9]/.test(str[i])) i--;
            return str.slice(i + 1, end + 1);
        }
        function getNextWord(str: string, startPos: number) {
            let i = startPos;
            while (i < str.length && str[i] === " ") i++;
            if (i >= str.length) return "";
            let start = i;
            while (i < str.length && /[А-Яа-яA-Za-z0-9]/.test(str[i])) i++;

            return str.slice(start, i);
        }

        const prevWord = getPrevWord(fullStr, offset - 1);
        const nextWord = getNextWord(fullStr, offset + 1);
        const prevIsSingleWord = prevWord.length === 1;
        const nextIsSingleWord = nextWord.length === 1;

        if (!prevIsSingleWord && !nextIsSingleWord) return boundaryBefore + singleChar;
        return boundaryBefore + `<emphasis level="moderate"><break time="40ms"/><prosody rate="medium">${singleChar}</prosody><break time="40ms"/></emphasis>`;
    });
};

// LEGACY
export const getSynth = (audioConfig: sdk.AudioConfig, voiceVersion: string, voiceLang: string): sdk.SpeechSynthesizer | null => {
    const reg = import.meta.env.VITE_STT_R;
    const apk = import.meta.env.VITE_STT_K;
    try {
        const speechConfig = sdk.SpeechConfig.fromSubscription(apk, reg);
        speechConfig.speechSynthesisVoiceName = voiceVersion;
        speechConfig.speechSynthesisLanguage = voiceLang;
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
        //synthesizer.wordBoundary = (s, e) => {
        //    import.meta.env.DEV === true && console.log("word_boundary", e.audioOffset);
        //};
        return synthesizer;
    } catch (error: any) {
        console.error("errr_creating_synth", error);
        return null;
    }
};
export const callWhspr = async (azureOpenAIEndpoint: string | undefined, formData: FormData, azureOpenAIKey: string | undefined) => {
    return await axios.post(`${azureOpenAIEndpoint}/openai/deployments/whisper/audio/transcriptions?api-version=2023-09-01-preview`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "api-key": azureOpenAIKey
        },
        params: {
            response_format: "json"
        }
    });
};
