import { gcaption, gpagedimensions, gfile, gselectedgroup, gjsonprop, gloggedinuser } from "./interfaces";
import lines_placeholder from "./assets/thumb_placeholder.png";
import MarkdownIt from "markdown-it";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { ITextFieldStyles } from "@fluentui/react";

const md = new MarkdownIt();

export const getTranscriptFormattedDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${hours}h${minutes}m`;
};
export const getNewTranscriptItemKey = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};
export const formatDateConvoList = (date: string) => {
    const d = new Date(date);
    d.setDate(d.getDate() + 1); // Add 30 days
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2); // Pad month with zero
    const day = `0${d.getDate()}`.slice(-2); // Pad day with zero
    return `${year}-${month}-${day}`;
};
export const formatDate30DaysConvoList = (date: string) => {
    const d = new Date(date);
    d.setDate(d.getDate() - 30); // Add 30 days
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2); // Pad month with zero
    const day = `0${d.getDate()}`.slice(-2); // Pad day with zero
    return `${year}-${month}-${day}`;
};
export const onSTT = (text: string, language: string): void => {
    import.meta.env.DEV === true && console.log("¦whspr¦", text, language);
};
export const createWavBuffer = (samples: Int16Array, sampleRate: number): ArrayBuffer => {
    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);

    const bytesPerSample = 2; // 16-bit audio
    const numChannels = 1; // Mono
    const byteRate = sampleRate * numChannels * bytesPerSample;
    const blockAlign = numChannels * bytesPerSample;
    const bitsPerSample = 16;

    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + samples.length * bytesPerSample, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, byteRate, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, bitsPerSample, true);
    writeString(view, 36, "data");
    view.setUint32(40, samples.length * bytesPerSample, true);
    for (let i = 0; i < samples.length; i++) {
        view.setInt16(44 + i * 2, samples[i], true);
    }
    return buffer;
};
export const prepWavBuffer = (audioChunks: Float32Array[], recognitionResultOffset: number) => {
    const audioCtx = new AudioContext();
    const sampleRate = audioCtx.sampleRate;
    const startSample = Math.floor((recognitionResultOffset / 10000000) * sampleRate);
    const comboAudio = new Float32Array(audioChunks.reduce((acc, chunk) => acc + chunk.length, 0));
    let offset = 0;
    for (const chunk of audioChunks) {
        comboAudio.set(chunk, offset);
        offset += chunk.length;
    }
    let relevantAudio = comboAudio.slice(startSample);
    const int16Array = new Int16Array(relevantAudio.length);
    for (let i = 0; i < relevantAudio.length; i++) {
        int16Array[i] = Math.max(-32768, Math.min(32767, Math.floor(relevantAudio[i] * 32768)));
    }
    let wavBuffer = createWavBuffer(int16Array, sampleRate);
    return wavBuffer;
};
export const playWavBuffer = (buffer: ArrayBuffer) => {
    const audioContext = new AudioContext();
    audioContext.decodeAudioData(
        buffer,
        decodedData => {
            const source = audioContext.createBufferSource();
            source.buffer = decodedData;
            source.connect(audioContext.destination);
            source.start(0);
        },
        error => {
            console.error("Error decoding audio data", error);
        }
    );
};
export const handleFocus = (event: any) => {
    event.target.setSelectionRange(event.target.value.length, event.target.value.length);
};
export const encodeHTML = (str: string): string => {
    return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, " ");
};
export const makeValidHtmlId = (str: string) => {
    // Remove any character that isn't a letter, number, hyphen, underscore, colon, or period
    let validStr = str.replace(/[^a-zA-Z0-9-_:.]/g, "");
    // Ensure starts with letter
    if (!/^[a-zA-Z]/.test(validStr)) validStr = "id-" + validStr;

    // Ensure it's not empty
    if (validStr.length === 0) validStr = "id";

    return validStr;
};
export const shuffleArray = (array: string[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};
export const getLoc = () => {
    return window.location.hash.replace("#", "");
};
export const genNewConvoKey = () => {
    return new Date() // Convo Key (Datetime String)
        .toISOString()
        .replace(/[-:.TZ]/g, "")
        .slice(0, 14);
};
export const isElementVisible = (eid: string) => {
    const element = document.getElementById(eid);
    if (!element) return false;
    return true;
};
export const convertMarkdownHeadersToHTML = (markdownText: string) => {
    return markdownText
        .replace(/^####\s+(.*)$/gm, "<h4>$1</h4>")
        .replace(/^###\s+(.*)$/gm, "<h3>$1</h3>")
        .replace(/^##\s+(.*)$/gm, "<h2>$1</h2>")
        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
};
export const convertMarkdownHeadersToHTMLWithNewLines = (markdownText: string) => {
    return markdownText
        .split("\n") // Split by double new lines to preserve paragraph structure
        .map(paragraph => {
            return paragraph
                .replace(/^####\s+(.*)$/gm, "<h4>$1</h4>")
                .replace(/^###\s+(.*)$/gm, "<h3>$1</h3>")
                .replace(/^##\s+(.*)$/gm, "<h2>$1</h2>")
                .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
        })
        .map(line => (line.match(/^<h[2-4]>/) ? line : `<p>${line}</p>`)) // Wrap non-header text in <p>
        .join("\n"); // Rejoin the modified lines
};
export const copyToClipboard = (text: string) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            toast.success("Collected Data copied to Clipboard!");
        })
        .catch(err => {});
};

export const copyToClipboardInvoice = (data: any) => {
    if (!data || typeof data !== "object") {
        toast.error("Invalid data to copy.");
        return;
    }

    // Separate the root object and the invoice_items array
    const { invoice_items, ...rootData } = data;

    // Convert a single object to CSV (one-row table)
    const convertObjectToCSV = (obj: Record<string, any>): string => {
        const headers = Object.keys(obj);
        const values = headers.map(field => `"${String(obj[field]).replace(/"/g, '""')}"`);
        return headers.join(",") + "\n" + values.join(",");
    };

    // Convert an array of objects to CSV (multi-row table)
    const convertArrayToCSV = (arr: Record<string, any>[]): string => {
        if (!Array.isArray(arr) || arr.length === 0) return "";
        const headers = Object.keys(arr[0]);
        const rows = arr.map(row => headers.map(field => `"${String(row[field] ?? "").replace(/"/g, '""')}"`).join(","));
        return headers.join(",") + "\n" + rows.join("\n");
    };

    const rootCSV = convertObjectToCSV(rootData);
    const itemsCSV = convertArrayToCSV(invoice_items || []);

    const combinedCSV = `${rootCSV}\n\n${itemsCSV}`;

    navigator.clipboard
        .writeText(combinedCSV)
        .then(() => {
            toast.success("Root + invoice_items copied to Clipboard as CSV!");
        })
        .catch(err => {
            toast.error("Failed to copy CSV.");
            console.error(err);
        });
};

export const extractTextCptns = (content: string): gcaption | null => {
    content = content.toString();
    const colonIndex = content.indexOf(":");
    let contentText = "";
    if (colonIndex !== -1) {
        contentText = content.substring(colonIndex, content.length);
        content = content.substring(0, colonIndex);
    }
    // const regex = /^(.*?)#page=(\d+)_fid=(\d+)$/;

    const regex = /^(.*?)#page=([^_]+)_fid=(\d+)$/;
    const match = content.match(regex);
    if (match) {
        const [, filename, page, fileid] = match;
        return { content: contentText, filename, page, fileid };
    }
    return null;
};
export const addDotIfNeeded = (str: string) => {
    return str?.endsWith(".") ? str : str + ".";
};
export const adjustHeight = (questionInputContainer: any) => {
    const inputContainers = document.getElementsByClassName(questionInputContainer);
    for (let i = 0; i < inputContainers.length; i++) {
        const inputContainer = inputContainers[i] as HTMLElement;
        let textArr = inputContainers[i].querySelectorAll("textarea");
        for (let j = 0; j < textArr.length; j++) {
            const textElement = textArr[j] as HTMLTextAreaElement;
            if (parseInt(inputContainer.style.height) < inputContainer.scrollHeight) {
                let height = textElement.scrollHeight + 15;
                inputContainer.style.height = height + "px";
            } else {
                let height = textElement.scrollHeight + 15;
                inputContainer.style.height = "auto";
                inputContainer.style.height = height + "px";
            }
        }
    }
};
export const isTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
export const contentPath = (filename: string, fileid: string) => {
    return `${filename}_fid=${fileid}`;
};
export const pdfCacheKey = (fId: string, modifiedDateString: string) => {
    try {
        let mdfd = new Date(modifiedDateString).getTime();
        return `${fId}_${mdfd}`;
    } catch {
        return `${fId}`;
    }
};
export const areAllFlagsTrue = (map: Map<number, { count: number; flag: boolean }>, numbers: number[]): boolean => {
    for (const number of numbers) {
        const item = map.get(number);
        if (!item || !item.flag) {
            return false;
        }
    }
    return true;
};
export const findRange = (numbers: number[], target: number): number[] => {
    numbers.sort((a: number, b: number) => a - b);
    let startIndex = numbers.findIndex((num: number) => num > target);
    let upper = startIndex === -1 ? numbers[numbers.length - 1] : numbers[startIndex];
    let endIndex = numbers.lastIndexOf(target);
    let lower = endIndex !== -1 ? numbers[endIndex] : numbers[startIndex - 1];
    if (lower === undefined || upper === undefined) {
        return [];
    }
    let result = [];
    for (let i = lower; i <= upper; i++) {
        result.push(i - 1);
    }
    if (target === upper) {
        result.push(upper - 1);
    }
    return result;
};
export const getDesiredHeight = (pageDims: gpagedimensions, srcLoc: string) => {
    const A4_ASPECT_RATIO = 297 / 210;
    const originalWidth = pageDims?.width;
    const originalHeight = pageDims?.height;
    const desiredWidth = getDesiredWidth(srcLoc);
    if (originalWidth && originalHeight) {
        const aspectRatio = originalHeight / originalWidth;
        return desiredWidth * aspectRatio;
    }

    return desiredWidth * A4_ASPECT_RATIO;
};
export const getDesiredWidth = (srcLoc: string): number => {
    let widthFactor = 1.9;
    if (srcLoc === "modal" || srcLoc === "inv") {
        // Directly related to components\XplrrPDF\XplrrPDF.module.css - pdfViewC
        let modifierReduct = 1;
        if (srcLoc === "inv") {
            modifierReduct = 1.8;
        }

        if (window.innerWidth > 1800) {
            widthFactor = 1.8 * modifierReduct;
        } else if (window.innerWidth > 1600) {
            widthFactor = 1.6 * modifierReduct;
        } else if (window.innerWidth > 1400) {
            widthFactor = 1.4 * modifierReduct;
        } else if (window.innerWidth > 1200) {
            widthFactor = 1.3 * modifierReduct;
        } else if (window.innerWidth > 1000) {
            widthFactor = 1.2 * modifierReduct;
        } else if (window.innerWidth > 800) {
            widthFactor = 1.13 * modifierReduct;
        } else if (window.innerWidth > 600) {
            widthFactor = 1.08 * modifierReduct;
        } else if (window.innerWidth > 470) {
            widthFactor = 1.1 * modifierReduct;
        } else if (window.innerWidth > 350) {
            widthFactor = 1.11 * modifierReduct;
        } else if (window.innerWidth > 300) {
            widthFactor = 1.15 * modifierReduct;
        } else {
            //Smallest
            widthFactor = 1.15 * modifierReduct;
        }
    } else {
        // "sbs"
        if (window.innerWidth > 800) {
            widthFactor = 1.85;
        } else {
            //Smallest
            widthFactor = 1.85;
        }
    }
    try {
        return window.innerWidth / widthFactor;
    } catch (e) {
        return 800;
    }
};
export const saveEntriesToGlobal = (currentEntries: number[]) => {
    window.globalCurrentVisibleEntries = currentEntries;
};
export const getDesiredWidthDiff = (srcLoc: string): number => {
    let widthFactor = 1.9;

    if (srcLoc === "modal" || srcLoc === "inv") {
        // Directly related to components\XplrrPDF\XplrrPDF.module.css - pdfViewC
        let modifierReduct = 2;

        if (window.innerWidth > 1800) {
            widthFactor = 1.8 * modifierReduct;
        } else if (window.innerWidth > 1600) {
            widthFactor = 1.6 * modifierReduct;
        } else if (window.innerWidth > 1400) {
            widthFactor = 1.4 * modifierReduct;
        } else if (window.innerWidth > 1200) {
            widthFactor = 1.3 * modifierReduct;
        } else if (window.innerWidth > 1000) {
            widthFactor = 1.2 * modifierReduct;
        } else if (window.innerWidth > 800) {
            widthFactor = 1.13 * modifierReduct;
        } else if (window.innerWidth > 600) {
            widthFactor = 1.08 * modifierReduct;
        } else if (window.innerWidth > 470) {
            widthFactor = 1.1 * modifierReduct;
        } else if (window.innerWidth > 350) {
            widthFactor = 1.11 * modifierReduct;
        } else if (window.innerWidth > 300) {
            widthFactor = 1.15 * modifierReduct;
        } else {
            //Smallest
            widthFactor = 1.15 * modifierReduct;
        }
    } else if (srcLoc == "diff") {
        // Directly related to components\XplrrPDF\XplrrPDF.module.css - pdfViewC
        let modifierReduct = 2;

        if (window.innerWidth > 1800) {
            widthFactor = 1.8 * modifierReduct;
        } else if (window.innerWidth > 1600) {
            widthFactor = 1.6 * modifierReduct;
        } else if (window.innerWidth > 1400) {
            widthFactor = 1.4 * modifierReduct;
        } else if (window.innerWidth > 1200) {
            widthFactor = 1.3 * modifierReduct;
        } else if (window.innerWidth > 1000) {
            widthFactor = 1.2 * modifierReduct;
        } else if (window.innerWidth > 800) {
            widthFactor = 1.13 * modifierReduct;
        } else if (window.innerWidth > 600) {
            widthFactor = 1.08 * modifierReduct;
        } else if (window.innerWidth > 470) {
            widthFactor = 1.1 * modifierReduct;
        } else if (window.innerWidth > 350) {
            widthFactor = 1.11 * modifierReduct;
        } else if (window.innerWidth > 300) {
            widthFactor = 1.15 * modifierReduct;
        } else {
            //Smallest
            widthFactor = 1.15 * modifierReduct;
        }
    } else {
        // "sbs"
        if (window.innerWidth > 800) {
            widthFactor = 1.85;
        } else {
            //Smallest
            widthFactor = 1.85;
        }
    }
    try {
        return window.innerWidth / widthFactor;
    } catch (e) {
        return 800;
    }
};
export const getDesiredHeightDiff = (pageDims: gpagedimensions, srcLoc: string) => {
    const A4_ASPECT_RATIO = 297 / 210;
    const originalWidth = pageDims?.width;
    const originalHeight = pageDims?.height;
    const desiredWidth = getDesiredWidthDiff(srcLoc);

    if (originalWidth && originalHeight) {
        const aspectRatio = originalHeight / originalWidth;
        return desiredWidth * aspectRatio;
    }
    return desiredWidth * A4_ASPECT_RATIO;
};
export const areAllFlagsTrueDiff = (numbers: number[], map?: Map<number, { count: number; flag: boolean }>): boolean => {
    for (const number of numbers) {
        const item = map?.get(number);
        if (!item || !item.flag) {
            return false;
        }
    }
    return true;
};
export const removeExtension = (filename: string) => {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
};
export const get_mod_str = (modified: string): string => {
    return new Date(modified).getTime().toString();
};
export const getCachedImage = (file: gfile) => {
    const modString = new Date(file.modified).getTime().toString();
    const cacheKey = `${file.fileid}_${modString}`;
    console.log("cacheKey", cacheKey);
    return localStorage.getItem(cacheKey);
};
export const cacheImage = (file: gfile, imageUrl: string) => {
    const modString = new Date(file.modified).getTime().toString();
    const cacheKey = `${file.fileid}_${modString}`;
    localStorage.setItem(cacheKey, imageUrl);
};
export const getThumbnail = (file: gfile, selectedGroup: gselectedgroup) => {
    const cachedImage = getCachedImage(file);
    const params = new URLSearchParams({
        of: file.ownerid,
        fid: file.fileid,
        cid: selectedGroup.companyid,
        mod: get_mod_str(file.modified)
    });
    const url = cachedImage || `/qthumb?${params.toString()}`;
    if (!cachedImage) {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader.result && (reader.result as string).startsWith("data:image")) {
                        cacheImage(file, reader.result as string);
                    }
                };
                reader.readAsDataURL(blob);
            })
            .catch(e => {
                console.error("Error fetching thumbnail", e);
            });
    }
    return url;
};
export const fetchThumbnail = async (file: gfile, selectedGroup: gselectedgroup) => {
    const params = new URLSearchParams({
        of: file.ownerid,
        fid: file.fileid,
        cid: selectedGroup.companyid,
        mod: new Date(file.modified).getTime().toString()
    });
    const url = `/qthumb?${params.toString()}`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        }
    } catch (e) {
        console.error("Error fetching thumbnail", e);
    }
    return lines_placeholder;
};
export const isValidURL = (str: string) => {
    try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
};
export const getBaseURL = (url: string) => {
    try {
        const parsedURL = new URL(url);
        return `${parsedURL.protocol}//${parsedURL.hostname}`;
    } catch (error) {
        return null;
    }
};
export const getJsonType = (value: any): "string" | "number" | "boolean" | "array" | "object" => {
    if (Array.isArray(value)) return "array";

    switch (typeof value) {
        case "string":
            return "string";
        case "number":
            return "number";
        case "boolean":
            return "boolean";
        case "object":
            return "object";
        default:
            throw new Error("Unsupported type");
    }
};
export const sortObjectByKeyOrder = (obj: any, keyOrder: string[]) => {
    try {
        return Object.fromEntries(
            Object.entries(obj).sort(([a], [b]) => {
                return keyOrder.indexOf(a) - keyOrder.indexOf(b);
            })
        );
    } catch (e) {
        console.log("errr_sortobjectbykeyorder", e);
        return obj || [];
    }
};
export const parseJsonToProperties = (json: any, description = ""): gjsonprop[] => {
    const parseObject = (obj: any, parentDesc: string): gjsonprop[] => {
        return Object.entries(obj).map(([key, value]) => {
            const type = getJsonType(value);

            let prop: gjsonprop = {
                name: key,
                description: `${parentDesc}${key}`,
                type
            };

            if (type == "string" || type == "number") prop["validation_source"] = "";

            if (type === "object" && value !== null) {
                prop.items = parseObject(value, `${parentDesc}${key}.`);
            } else if (type === "array" && (value as any).length > 0) {
                const firstElementType = getJsonType((value as any)[0]);
                if (firstElementType === "object") {
                    prop.items = parseObject((value as any)[0], `${parentDesc}${key}[].`);
                }
            }

            return prop;
        });
    };

    return parseObject(json, description);
};
export const safeJsonParse = (txt: string) => {
    try {
        return JSON.parse(txt);
    } catch {
        return "";
    }
};
export function markdownTableToHtmlOnly(markdown: string): string {
    const tableRegex = /\|(.+?)\|\s*\n\|(-+?\s*\|)+\s*\n((\|(.+?)\|\s*\n)*)/g;
    // Replace tables in the markdown string with HTML, leaving other parts untouched
    return markdown.replace(tableRegex, (match: string) => {
        return md.render(match); // Apply markdown-it rendering only to the table section
    });
}
export function genTbl(jsonData: string): string {
    let json = safeJsonParse(jsonData);
    return `<h3 style="list-style-type: none; padding-bottom: 6px;">Collected Information</h3><ul style="list-style-type: none; padding: 0;">
        ${Object.entries(json)
            .map(([key, value]) => `<li><strong>${key.replace(/_/g, " ")}:</strong> ${value}</li>`)
            .join("")}
    </ul>`;
}
export function jsonToCsv(jsonData: Record<string, string>): string {
    const headers = Object.keys(jsonData).join(","); // CSV Headers
    const values = Object.values(jsonData).join(","); // CSV Values
    return `${headers}\n${values}`; // Combine into CSV format
}
export function jsonToKeyValueCsv(jsonData: Record<string, string>): string {
    return Object.entries(jsonData)
        .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`) // Format as "Key: Value"
        .join("\n"); // Join each pair into a new line
}
export function isEmptyObject(obj: any) {
    try {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    } catch {
        return false;
    }
}
export function getAlphaNumeric(str: string) {
    if (!str) return "";
    try {
        return str.replace(/[^А-Яа-яA-Za-z0-9]/g, "");
    } catch (e) {
        console.log("error_getAlphaNumeric", str, e);
        return str;
    }
}
export function getLettersOnly(str: string) {
    return str.replace(/[^А-Яа-яA-Za-z0-9]/g, "");
}
export function getOnlyNumeric(str: string): string {
    return str.replace(/\D/g, "")?.toString(); // \D matches any non-digit character
}
export const removeEnclosingQuotes = (str: string) => {
    str = str.replace(/\\"/g, '"');
    return str.replace(/^["']|["']$/g, "");
};
export const newConvoStamp = () => {
    return Math.floor(Date.now());
};
export const newWiggleStamp = () => {
    return Math.floor(Date.now());
};
export const fuzzMtch = async (strings: string[], bigString: string, threshold = 0.4) => {
    console.log("⨀fuz⨀", bigString);
    if (!strings.length || !bigString) return null;
    const fuse = new Fuse(strings, {
        includeScore: true,
        threshold: threshold, // Lower = stricter matching
        findAllMatches: true
    });
    const results = fuse.search(bigString);

    if (results.length === 0) return null; // No match found

    // Ensure scores exist and find the best match
    const bestMatch = results.reduce((best, current) => {
        if (current.score === undefined) return best; // Skip undefined scores
        if (best.score === undefined || current.score < best.score) return current;
        return best;
    });

    return bestMatch?.item ? { match: bestMatch.item, score: bestMatch.score ?? 1 } : null;
};
// Wildcard Eval each one in strings against bigString
export function bestWildcardMatch(strings: string[], bigString: string) {
    console.log("⨀rev_match⨀", bigString);
    if (!strings.length || !bigString) return null;

    let bestMatch = null;
    let bestScore = 1; // Lower score = better match

    for (const str of strings) {
        const regex = new RegExp(str, "i"); // Case-insensitive search
        const match = bigString.match(regex);

        if (match) {
            const score = 1 - str.length / bigString.length; // Simple relevance scoring

            if (score < bestScore) {
                bestMatch = str;
                bestScore = score;
            }
        }
    }
    return bestMatch ? { match: bestMatch, score: bestScore } : null;
}
const writeString = (view: DataView, offset: number, string: string) => {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
};
export const randomFourString = () => {
    return Math.random().toString(36).substring(2, 6);
};
export const escapeSSML = (txt: string) => {
    return txt
        .replace(/&/g, "&amp;") // Encode ampersand first
        .replace(/</g, "&lt;") // Encode less-than
        .replace(/>/g, "&gt;") // Encode greater-than
        .replace(/"/g, "&quot;") // Encode standard double quotes
        .replace(/'/g, "&#39;") // Encode standard single quotes
        .replace(/`/g, "&#96;") // Encode backticks
        .replace(/„/g, "&#8222;") // Encode double low-9 quote
        .replace(/“/g, "&#8220;") // Encode left double quote
        .replace(/”/g, "&#8221;") // Encode right double quote
        .replace(/‚/g, "&#8218;") // Encode single low-9 quote
        .replace(/‘/g, "&#8216;") // Encode left single quote
        .replace(/’/g, "&#8217;"); // Encode right single quote
};
export const cleanValues = (prop: string): string => {
    return (prop?.replace(/\s+/g, "_") || "").toLowerCase(); // Replace spaces with underscores
};
export const safelySelectProp = (obj: any, prop: string, expected_fallback: any): any => {
    try {
        return obj[prop] !== undefined ? obj[prop] : null;
    } catch (e) {
        console.error("error selecting property:", e);
        return expected_fallback;
    }
};
export const getHeaderGlob = (loggedInUser: gloggedinuser) => {
    return {
        ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
        "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
        "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
        "Content-Type": "application/json"
    };
};
export const additionalFixes = (str: string) => {
    return str.replace(/„|“/g, " "); // Replace smart quotes with a space
};

export const filterHighlightString = (str: string | undefined): string => {
    return (str || "")
        .toLowerCase()
        .replace(/[^а-яa-z0-9]/gi, "")
        .trim();
};
export const wigglePixelSBS = async () => {
    console.log("wigglePixelSBS", window.globalWigglePixel);
    if (window.globalWigglePixel == 1) return;
    console.log("wigp_state", window.globalWigglePixel);
    window.globalWigglePixel = 1;
    while (window.globalHiglightFound == false) {
        console.log("wigp_roll", window.globalWigglePixel);
        //await document.getElementById("pdfmainid9")?.scrollBy(1, 0);
        window.global_wiggle_stamp = newWiggleStamp();
        await new Promise(r => setTimeout(r, 100));
        //await document.getElementById("pdfmainid9")?.scrollBy(-1, 0);
        window.global_wiggle_stamp = newWiggleStamp();
        await new Promise(r => setTimeout(r, 100));
        if (window.globalWigglePixel == 0) break;
    }
    window.globalWigglePixel = 0;
};
export const wigglePixel = async () => {
    console.log("globalWigglePixel o", window.globalWigglePixel);
    if (window.globalWigglePixel == 1) return;

    window.globalWigglePixel = 1;
    while (window.globalWigglePixel == 1) {
        import.meta.env.DEV === true && console.log("¦wiggle¦", window.globalWigglePixel);

        //await document.getElementById("pdfmainid9")?.scrollBy(1, 0);
        window.global_wiggle_stamp = newWiggleStamp();
        await new Promise(r => setTimeout(r, 100));

        //await document.getElementById("pdfmainid9")?.scrollBy(-1, 0);
        window.global_wiggle_stamp = newWiggleStamp();
        await new Promise(r => setTimeout(r, 100));
    }
    window.globalWigglePixel = 0;
};
export const stopWigglePixel = (src: string) => {
    console.log("stopWigglePixel_box", src, window.globalWigglePixel);
    window.globalWigglePixel = 0;
};
export const add_extension_if_needed = (filename: string) => {
    if (filename && filename.indexOf(".") === -1) {
        filename += ".pdf"; // Add .pdf extension if not present
    }
    return filename;
};
