import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";

export const scrollToElementNoSmooth = (el: HTMLElement) => {
    if (el) el.scrollIntoView({ behavior: "auto", block: "center" });
};
export const scrollToPageCSTM = (pN: number) => {
    var node = document.querySelector(`[data-page-number="${pN}"`);
    if (node) smoothScrollIntoView(node, { behavior: "smooth", block: "center" });
};
export const scrollToPageDefault = (pN: number) => {
    var node = document.querySelector(`[data-page-number="${pN}"`);
    if (node) smoothScrollIntoView(node, { behavior: "smooth", block: "center" });
};
export const reendableUserScrollHandling = (tm: number = 500) => {
    setTimeout(() => (window.globalUserScrollLazyLoadEnabled = true), tm);
};
export const scrollToPageNoSmooth = (pN: number) => {
    var node = document.querySelector(`[data-page-number="${pN}"`);
    if (node) node.scrollIntoView({ behavior: "auto", block: "center" });
};
export const scrollCustomAnimPage = (pg: number, curPage: number) => {
    console.log("scrollCustomAnimPage", pg, curPage);
    if (pg > curPage + 2) {
        scrollToPageNoSmooth(pg - 2);
        setTimeout(() => scrollToPageDefault(pg), 50);
    } else if (pg < curPage - 2) {
        scrollToPageNoSmooth(pg + 2);
        setTimeout(() => scrollToPageDefault(pg), 50);
    } else {
        setTimeout(() => scrollToPageDefault(pg), 50);
    }
    reendableUserScrollHandling();
};
export const scrollCustomAnimElement = (pg: number, el: HTMLElement, scndEl: HTMLElement, curPage: number) => {
    if (pg > curPage + 2) {
        scrollToPageNoSmooth(pg - 2);
        setTimeout(() => {
            smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
            setTimeout(() => {
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
            }, 500);
        }, 50);
    } else if (pg < curPage - 2) {
        scrollToPageNoSmooth(pg + 2);
        setTimeout(() => {
            smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
            setTimeout(() => {
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
            }, 500);
        }, 50);
    } else {
        setTimeout(() => {
            smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
            setTimeout(() => {
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
            }, 500);
        }, 50);
    }
    reendableUserScrollHandling(500);
};
