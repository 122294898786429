import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider as MSALProvider } from "@azure/msal-react";
import { MsalProvider } from "./MsalContext"; // Adjust the import path as necessary
import { GlobalProvider } from "./GlobalContext"; // Adjust the import path as necessary
import { Navigate } from "react-router-dom";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";

try {
    initializeIcons();
} catch (e) {
    import.meta.env.DEV === true && console.log("errr_ilod", e);
}

const router = createHashRouter([
    {
        path: "*",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: "*",
                element: <Chat />
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <GlobalProvider>
        <MsalProvider>
            <RouterProvider router={router} />
        </MsalProvider>
    </GlobalProvider>
);
