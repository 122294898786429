import { gjsonprop, gcongoinedrecognitionresult, gconvocollectionconf, grecognitionresult, gqasimplified, gselectedgroup } from "../../interfaces";
import { removeEnclosingQuotes, getAlphaNumeric, getOnlyNumeric, getLettersOnly, newConvoStamp, fuzzMtch, bestWildcardMatch } from "../../util_glob";
import { qTopicChange } from "./../../api";

// Check Configs for Keys with same main config source
export const get_same_source_conf = (main_conf_fid: string, doc_x: gjsonprop[]) => {
    let join_conf_names: string[] = [];
    let join_confs = doc_x.filter((item: gjsonprop) => item?.validation_source == main_conf_fid);
    return join_confs;
};

// Its possible AI returns multiple config names in the string for Expected Extraction Field Name, check for this
export const get_field_configs_from_expected_intend = (exp_resp_type: string, doc_x: gjsonprop[]) => {
    // Check if Expected Key contains multiple field config names
    let alphaNumConfName = getAlphaNumeric(exp_resp_type.toLowerCase());
    let multi_conf = doc_x.filter((item: gjsonprop) => alphaNumConfName.includes(getAlphaNumeric(item?.name.toLowerCase())));

    let fids: (string | undefined)[] = [];
    for (let i = 0; i < multi_conf.length; i++) {
        if (multi_conf[i]?.validation_source) fids.push(multi_conf[i]?.validation_source?.toString());
    }
    // Get keys only aligning with the same main config source
    multi_conf = multi_conf.filter((item: gjsonprop) => fids.includes(item?.validation_source?.toString()));

    return multi_conf;
};

export const getGroundingDataSet = (exp_resp_type: string, doc_x: gjsonprop[]): gconvocollectionconf => {
    // If Expected Extraction Field Name Exists [Task expected from User, determined by OAI Backend on prev response]
    let exp_resp_type_alphanum = exp_resp_type;
    if (exp_resp_type) {
        exp_resp_type_alphanum = getAlphaNumeric(exp_resp_type.toLowerCase());

        // IF EXTRACTION FIELD CONFIG EXISTS
        if (doc_x) {
            let multi_conf = get_field_configs_from_expected_intend(exp_resp_type_alphanum, doc_x); // Get Field Configs for the Expected Extraction Field
            console.log("▩multi_conf", multi_conf);
            if (multi_conf.length > 0) {
                let main_conf = multi_conf[0]; // Get first Config as Main
                let main_conf_fid = main_conf.validation_source || "0"; // Get Source File Id for Main Config
                console.log("▩main_conf_fid", main_conf_fid);
                let join_confs = get_same_source_conf(main_conf_fid, doc_x); // Get all source configs for main file id

                let additional_src_names = [...multi_conf.map((item: gjsonprop) => item?.name), ...join_confs.map((item: gjsonprop) => item?.name)]; // join multi and join conf names
                additional_src_names = [...new Set(additional_src_names)]; // Unique Additional Source Names

                let source_data = window.globalFL.find(item => parseInt(item.fileid) == parseInt(main_conf_fid || "0")); // Get Source File Data for the Expected Extraction Field

                if (source_data) {
                    try {
                        let json_parsed =
                            typeof source_data?.invoicedata === "string"
                                ? JSON.parse(removeEnclosingQuotes(source_data?.invoicedata || "[]"))
                                : source_data?.invoicedata || []; // Parse Source Data (moved to file list pull)

                        console.log("▩json_parsed", json_parsed);
                        return {
                            values: json_parsed,
                            keyname: exp_resp_type,
                            shouldGo: true,
                            joinkeys: additional_src_names,
                            conf: multi_conf
                        };
                    } catch {
                        // Error Parsing Source Data
                        return {
                            values: [],
                            keyname: exp_resp_type,
                            shouldGo: true,
                            joinkeys: additional_src_names,
                            conf: multi_conf
                        };
                    }
                } else {
                    // No Source Data for Expected Extraction Field Name
                    return {
                        values: [],
                        keyname: exp_resp_type,
                        shouldGo: true,
                        joinkeys: additional_src_names,
                        conf: multi_conf
                    };
                }
            } else {
                // No Matched Configs for Expected Extraction Field Name
                return {
                    values: [],
                    keyname: exp_resp_type,
                    shouldGo: true,
                    joinkeys: [],
                    conf: []
                };
            }
        } else {
            // No Doc Extraction Configs at all
            return {
                values: [],
                keyname: exp_resp_type,
                shouldGo: true,
                joinkeys: [],
                conf: []
            };
        }
    } else {
        // No Expected Extraction Field Name
        return {
            values: [],
            keyname: exp_resp_type,
            shouldGo: false,
            joinkeys: [],
            conf: []
        };
    }
};

export const parse_extraction_config = (selectedGroup: gselectedgroup): gjsonprop[] => {
    try {
        let str = selectedGroup.docextractionconfig.replace(/\\/g, "");
        let doc_x: gjsonprop[] = JSON.parse(str.substring(1, str.length - 1)) || [];
        return doc_x;
    } catch (e) {
        let doc_x: gjsonprop[] = [];
        return doc_x;
    }
};

export const detect_topic_change = async (
    msg: string,
    last_msg: string,
    prev_topic: string,
    question_and_answers_list: gqasimplified[],
    selectedGroup: gselectedgroup,
    conf_details: gjsonprop
) => {
    let res = await qTopicChange(msg, last_msg, prev_topic, question_and_answers_list.slice(-5), selectedGroup, conf_details);
    console.log("📙q_topic_chng¦", res);
    return res;
};

const get_collected_val = (joinkey: string) => {
    let join_val = "";
    let join_oai = "";
    try {
        join_val = window.global_convo_collected[joinkey] || "";
        join_oai = window.global_convo_collected[`${joinkey}_ai`] || "";
    } catch (error) {
        console.error("errr_joinkey", error);
    }
    let join_def = join_oai != "" ? join_oai?.toString()?.replace(" ", "") : join_val?.toString()?.replace(" ", "");
    return join_def;
};
export const cross_join_with_already_collected_vals = (data_options: gconvocollectionconf) => {
    let match_down: any[] = [];
    match_down = data_options.values;

    // Get Additional Config Collected Values from Chat Answers
    if (data_options.joinkeys.length > 0) {
        data_options.joinkeys.forEach((joinkey: string) => {
            console.log("▩joinkey", joinkey, window.global_convo_collected[joinkey]);
            let join_def = get_collected_val(joinkey);

            if (join_def != "") {
                //let join_match = data_options.values.filter((item: any) => {
                //    return item?.[joinkey]?.toString()?.includes(join_def);
                //});

                let join_match = findBestMatchOnProperty(join_def, data_options.values, joinkey); // Find best match on property
                if (join_match.length > 0 && join_match.length < data_options.values.length) {
                    if (join_match.length < match_down.length) match_down = join_match;
                }
            }
        });
    }
    return match_down;
};

// If Car Reg Plate ⇒ Last Char is Letter and at least 4 numbers
// If Client Number ⇒ At least 5 numbers
export const value_specific_rules = (exp_resp_type: string, msg_numeric: string, conjoined_obj: gcongoinedrecognitionresult) => {
    let specific_rules_pass = false;
    if (exp_resp_type == "") return true;
    let keylet = getLettersOnly(exp_resp_type.toLowerCase().trim());
    let last_char = getAlphaNumeric(conjoined_obj.conjoined_segments.toLowerCase().trim()).slice(-1);

    if (keylet.includes("carregistration")) {
        // Last Char is Letter
        if (/^[A-Za-zА-Яа-я]$/.test(last_char) && msg_numeric.length > 3) {
            specific_rules_pass = true;
        }
    } else if (keylet.includes("clientnumber")) {
        // Numbers at least 5
        if (msg_numeric.length > 4) {
            specific_rules_pass = true;
        }
    } else {
        // No Specific Rule
        specific_rules_pass = true;
    }
    return specific_rules_pass;
};

export const we_are_in_specific_validation = (exp_resp_type: string) => {
    let keylet = getLettersOnly(exp_resp_type.toLowerCase().trim());
    return keylet.includes("carregistration") || keylet.includes("clientnumber");
};

export const value_specific_validation = (exp_resp_type: string, value_extracted: string) => {
    let valid_pass = false;
    let longer_or_eq = false;
    let keylet = getLettersOnly(exp_resp_type.toLowerCase().trim());
    let value_extracted_alphanum = getAlphaNumeric(value_extracted.toLowerCase().trim());
    if (keylet.includes("carregistration")) {
        // Valid Length ¦ Between 7 / 8 alphanumerics
        if (value_extracted_alphanum.length >= 7 && value_extracted_alphanum.length <= 8) {
            valid_pass = true;
        }
        // More them Minimum Lenght ¦ More then 7 alphanumeric
        if (value_extracted_alphanum.length >= 7) {
            valid_pass = true;
            longer_or_eq = true;
        }
    } else if (keylet.includes("clientnumber")) {
        // Length 6
        if (value_extracted_alphanum.length == 6) valid_pass = true;
        if (value_extracted_alphanum.length >= 6) longer_or_eq = true;
    } else {
        // No Specific Rule
        valid_pass = true;
        longer_or_eq = true;
    }

    return {
        trulyvalidlength: valid_pass,
        longer_or_eq: longer_or_eq
    };
};

export const get_random_symbols = (len = 4) => [...Array(len)].map(() => "!@#$%^&*()_+-=[]{}|;:',.<>?/`~".charAt(Math.random() * 30)).join("");

export function apply_replacements(input: string, replacements: { [key: string]: string }): string {
    let result = input;
    for (const [from, to] of Object.entries(replacements)) {
        // Use global replace, escaping special regex characters
        const escaped = from.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        result = result.replace(new RegExp(escaped, "g"), to);
    }
    return result;
}

export const conjoin_with_previously_low_level_discarded_string = (msg: string) => {
    // Deal with segments [rollon parts before the final recognition]
    const recogFin = `${window.global_convo_cadance_stamp || 0}`;

    if (window.global_convo_rollon_recognizing.length > 0) {
        // Segments are returned full, up to now on every onRecognizing event - get last & most complete to preserve
        let last_rollon_segments_result = window.global_convo_rollon_recognizing.slice(-1)[0];

        // Add to runon object
        window.global_run_on_sentence.segments.push({
            msg: last_rollon_segments_result,
            cadancestamp: parseInt(recogFin)
        });

        // Empty the global rollon array, for next recognition turn
        window.global_convo_rollon_recognizing = [];
    }
    if (msg != "") {
        // Add onRecognized Contents [finalized turn recognition]
        window.global_run_on_sentence.recognized.push({
            msg: msg,
            cadancestamp: parseInt(recogFin)
        });
    }

    // Return strings for finalized and segmented recognition
    let conjoined_obj: gcongoinedrecognitionresult = {
        conjoined_msg: window.global_run_on_sentence.recognized.map(item => item.msg).join(" "),
        conjoined_segments: window.global_run_on_sentence.segments.map(item => item.msg).join(" ")
    };

    return conjoined_obj;
};

export const get_recog_cache_discrepency = () => {
    let recoging_rsult = getAlphaNumeric(window.global_run_on_sentence.segments.map(item => item.msg).join(" "));
    let recognzd_rsult = getAlphaNumeric(window.global_run_on_sentence.recognized.map(item => item.msg).join(" "));
    let recog_len_diff = recoging_rsult.length - recognzd_rsult.length;
    let now = newConvoStamp();
    let since_last_recoging = now - window.globalConvPerfRecog;
    let since_last_recoged = now - window.convoPerfOnRecogFin;
    let recog_time_diff = since_last_recoging - since_last_recoged;
    import.meta.env.dev === true &&
        console.log("⚙recog_info⚙ ¦time_diff¦", recog_time_diff, "¦len_diff¦", recog_len_diff, "¦run_on_sentns¦", window.global_run_on_sentence);
};

export const on_recog_msg_too_short = (conjoined_text: string) => {
    let alpha_num_msg = getAlphaNumeric(`${conjoined_text}`.toLowerCase().trim());
    if (alpha_num_msg.length < 1) return true;
    return false;
};

export const get_latest_conjoined = () => {
    // Return strings for finalized and segmented recognition
    let conjoined_obj: gcongoinedrecognitionresult = {
        conjoined_msg: window.global_run_on_sentence.recognized.map(item => item.msg).join(" "),
        conjoined_segments: window.global_run_on_sentence.segments.map(item => item.msg).join(" ")
    };

    return conjoined_obj;
};

export const reset_conjoined_conditional = (finStamp: number) => {
    let cadance_stamp = `${window.global_convo_cadance_stamp || "0"}`;
    let cadance_int = parseInt(cadance_stamp);
    let segments = window.global_run_on_sentence.segments.filter((item, index) => {
        return item.cadancestamp > cadance_int;
    });
    let recog = window.global_run_on_sentence.recognized.filter((item, index) => {
        return item.cadancestamp > cadance_int;
    });
    window.global_run_on_sentence = {
        segments: segments,
        recognized: recog
    };
};

function findBestMatchOnProperty(query: string, itemsArray: any[], propertyName: string) {
    const sanitizedQuery = query.toLowerCase().replace(/[^A-Z0-9]/g, ""); // 1) Convert the query to lowercase and remove everything but letters/digits

    const firstDigitIndex = sanitizedQuery.search(/\d/); // 2) Locate the first digit in the sanitized query
    if (firstDigitIndex === -1) return []; // No digits at all => no match

    const digitsOnly = sanitizedQuery.replace(/\D/g, ""); // 3) Extract digits-only for initial matching

    // 4) Prepare an lowercase version of the target property for each item
    const lowercaseProperties = itemsArray.map(obj => {
        return obj[propertyName]?.toString().toLowerCase() || ""; // Handle missing property
    });

    // 5) Initial candidates: all items whose property includes digitsOnly
    let candidateIndexes = lowercaseProperties.map((val, idx) => (val.includes(digitsOnly) ? idx : -1)).filter(idx => idx !== -1);

    if (candidateIndexes.length <= 1) return candidateIndexes.map(idx => itemsArray[idx]); // If 0 or 1 candidate, we can return right away

    let prefix = ""; // 6) Narrow down by prepending letters to the digits portion
    let i = firstDigitIndex - 1;

    while (i >= 0) {
        prefix = sanitizedQuery[i] + prefix;
        const testValue = prefix + digitsOnly;

        const newCandidateIndexes = candidateIndexes.filter(idx => lowercaseProperties[idx].includes(testValue)); // Filter candidate indexes further

        // If new candidates are fewer (but not zero), we narrowed down
        if (newCandidateIndexes.length > 0 && newCandidateIndexes.length < candidateIndexes.length) {
            candidateIndexes = newCandidateIndexes;
            if (candidateIndexes.length === 1) break; // If exactly one remains, we can stop
        }
        i--;
    }
    return candidateIndexes.map(idx => itemsArray[idx]); // 7) Return the matching objects
}

// Match strings from the extraction field values
export const filter_grounding_values = (data_obj: gconvocollectionconf, column: string, filter: string): gconvocollectionconf => {
    let filter_lower = getAlphaNumeric(filter?.toString().toLowerCase());
    if (filter_lower == "") return data_obj;
    //let filter_results: any[] = data_obj.values.filter((item: any) => {
    //    return getAlphaNumeric(item?.[column]?.toString().toLowerCase())?.includes(filter_lower);
    //});
    let filter_results: any[] = findBestMatchOnProperty(filter_lower, data_obj.values, column); // Find best match on property

    data_obj.values = filter_results;
    return data_obj;
};

export const fill_final_msg_if_empty = (final_upstream_msg: string, conjoined_obj: gcongoinedrecognitionresult) => {
    if (final_upstream_msg == "") {
        if (conjoined_obj.conjoined_msg != "") {
            console.log("▩33.1");
            final_upstream_msg = conjoined_obj.conjoined_msg;
        } else {
            console.log("▩33.2");
            final_upstream_msg = conjoined_obj.conjoined_segments;
        }
    }
    return final_upstream_msg;
};

export const hanle_one_filter_match = async (uniq_strings: string[], fin: any, exp_resp_type: string) => {
    fin.conj_clean = fin.conjoined_obj.conjoined_segments; // Use Segments Recog instead of Finals due o beter preservation of characters
    fin.conjoined_obj.conjoined_segments = `${fin.conjoined_obj.conjoined_segments}`; // Found single grounded match - pass_to_upstream - add match as hint to backend
    fin.final_upstream_msg = fin.conjoined_obj.conjoined_segments; // Set segments message + hint as final upstream message
    fin.additional_instructions = `Correct ${exp_resp_type}: ${uniq_strings[0]}`;
    return fin;
};

export const hanle_mid_filter_matches = async (uniq_strings: string[], fin: any, exp_resp_type: string) => {
    fin.conj_clean = fin.conjoined_obj.conjoined_segments; // Found Some Grounded Matches - pass_to_upstream - add matches as hints to backend
    fin.conjoined_obj.conjoined_segments = `${fin.conjoined_obj.conjoined_segments}`; // Add values as hints to backend
    fin.final_upstream_msg = fin.conjoined_obj.conjoined_segments; // Set segments as final upstream message [we are data input so we need the segments ability to preserve single chars]
    fin.additional_instructions = `Correct value options for ${exp_resp_type}: ${uniq_strings.join(", ")}`;
    return fin;
};

export const hanle_more_filter_matches = async (uniq_strings: string[], fin: any, conventional_tech_match: string, exp_resp_type: string) => {
    fin.conj_clean = fin.conjoined_obj.conjoined_segments;
    fin.conjoined_obj.conjoined_segments = `${fin.conjoined_obj.conjoined_segments}`;
    fin.should_run_oai = true;
    fin.additional_instructions = `Possible correct ${exp_resp_type}: ${conventional_tech_match}`;
    return fin;
};

export const conventional_fuzz_tech_match = async (uniq_strings: string[], filter_str: string) => {
    let conventional_tech_match = "";
    const bestMatch = await fuzzMtch(uniq_strings, getOnlyNumeric(filter_str?.toLowerCase().trim()), 0.7); //Run fuzzy match
    // IF FUZZI MATCH FOUND
    if (bestMatch) {
        console.log("▩13");
        conventional_tech_match = bestMatch.match;

        // NO FUZZY MATCH - RUN REVERSE WILDCARD
    } else {
        console.log("▩14");
        const wcmatch = bestWildcardMatch(uniq_strings, getOnlyNumeric(filter_str.toLowerCase().trim())); // Run reverse wildcard search

        // IF REVERSE MATCH FOUND
        if (wcmatch?.match && wcmatch.score > 0.7) {
            console.log("▩15");
            conventional_tech_match = wcmatch.match;
            // NO REVERSE MATCH
        } else {
            console.log("▩16");
        }
    }
    return conventional_tech_match;
};

export const filter_results_processing = async (uniq_strings: string[], ai_value_extract: any, exp_resp_type: string, fin: any, src: number) => {
    let halt = false;
    if (uniq_strings.length == 1) {
        console.log(`▩${src}.0`);
        fin = hanle_one_filter_match(uniq_strings, fin, exp_resp_type);
    } else if (uniq_strings.length < 20) {
        console.log(`▩${src}.1`);
        // APPEND FINAL MESSAGE
        fin = hanle_mid_filter_matches(uniq_strings, fin, exp_resp_type);
    } else {
        // Results more then 20
        let conventional_tech_match = await conventional_fuzz_tech_match(uniq_strings, ai_value_extract.requested_value);
        if (conventional_tech_match != "") {
            console.log(`▩${src}.2`);
            fin = hanle_more_filter_matches(uniq_strings, fin, conventional_tech_match, exp_resp_type);
        } else {
            if (ai_value_extract.user_asks_or_requests == true) {
                console.log(`▩${src}.3`);
            } else {
                if (ai_value_extract.value_ok != true) {
                    console.log(`${src}.4`);
                    halt = true;
                }
            }
        }
    }
    return {
        fin: fin,
        halt: halt
    };
};

export const select_from_filtered_vals = (uniq_objects: any[], exp_resp_type: string) => {
    let uniq_strings: string[] = uniq_objects.map((item: any) => item?.[exp_resp_type]);
    return uniq_strings;
};

export const prep_next_turn_collection_vals = (selectedGroup: gselectedgroup, exp_resp_type: string) => {
    let data_options = getGroundingDataSet(exp_resp_type, parse_extraction_config(selectedGroup));
    console.log("▩data_options", data_options);
    console.log("▩data_values", data_options.values);

    if (!window.global_convo_extr_conf) window.global_convo_extr_conf = new Map<string, string[]>();

    let uniq_strings = grounding_with_filter_down(data_options, exp_resp_type, "");

    console.log("▩uniq_strings", uniq_strings);
    if (uniq_strings.length > 0) {
        window.global_convo_extr_conf.set(exp_resp_type, uniq_strings);
        return uniq_strings;
    } else {
        window.global_convo_extr_conf.set(
            exp_resp_type,
            data_options.values.map((item: any) => item?.[exp_resp_type])
        ); // Matched Column Values
        return data_options.values.map((item: any) => item?.[exp_resp_type]);
    }
};

export const grounding_with_filter_down = (data_options: gconvocollectionconf, exp_resp_type: string, msg_numeric: string): string[] => {
    let matched_vals = filter_grounding_values(data_options, exp_resp_type, msg_numeric); // Match strings from the extraction field values

    console.log("▩matched_vals", matched_vals);
    // FILTER DOWN BY ALREADY COLLECTED VALUES
    let match_down = cross_join_with_already_collected_vals(matched_vals);
    if (match_down.length > 0 && match_down.length < matched_vals.values.length) matched_vals.values = match_down; // Keep join filtered values if Join it reduces the number of matches

    let matched_strings = matched_vals.values.map((item: any) => item?.[exp_resp_type]); // Matched Column Values
    let uniq_strings = [...new Set(matched_strings)]; // Remove Duplicates

    console.log("▩uniq", uniq_strings);
    if (uniq_strings.length == 1) {
        try {
            if (getLettersOnly(exp_resp_type.toLowerCase().trim()).includes("carregistration")) {
                const full_object = matched_vals.values.filter((item: any) => {
                    if (item?.[exp_resp_type] == uniq_strings[0].replace(" ", "")) return true;
                });
                if (full_object.length > 0) {
                    console.log("▩uniq_2", full_object[0]);
                    window.global_convo_extr_conf.set("make", full_object[0]?.["Make"] || "");
                    window.global_convo_extr_conf.set("model", full_object[0]?.["Model"] || "");
                    window.global_convo_extr_conf.set("color", full_object[0]?.["Color"] || "");
                }
            }
        } catch (error) {}
    }

    return uniq_strings;
};

// RUN AI PIPE... AI_VALUE_EXTRACT RETURNS:
// pass                                 ⇒ pass req through if
//      ai pass                 == true
//      longer_or_eq            == true
//      user_asks_or_requests   != true
//      is_different_direction  != true
// results                              ⇒ ai results object
//      returns is_different_direction  ⇒ user changing topic
//      user_asks_or_requests           ⇒ user asking for something
//      requested_value                 ⇒ recognized value
//      value_ok                        ⇒ value is ok
// longer_or_eq                         ⇒ longer then min length for type
export const ai_val_chech = async (
    msg: string,
    keyname: string,
    conf_details: gjsonprop,
    lastMessage: string[],
    question_and_answers_list: gqasimplified[],
    selectedGroup: gselectedgroup
) => {
    // returns is_different_direction ¦ requested_value ¦ user_asks_or_requests ¦ value_ok
    let topic_change = await detect_topic_change(msg, (lastMessage || [""])[0], keyname, question_and_answers_list, selectedGroup, conf_details);

    let top_res = topic_change?.result;
    let in_specific_validation = we_are_in_specific_validation(keyname);

    // WE ARE IN VALUE COLLECTION WITH CONFIG
    if (in_specific_validation == true) {
        // IF VALUE NOT EMPTY
        if (top_res!.requested_value != "") {
            //Returns pass [fullly valid], longer_or_eq [longer then min]
            let ai_val_valid = value_specific_validation(keyname, (top_res?.requested_value || "").toString());
            return {
                has_ai_recognized_value: true,
                requested_value: top_res?.requested_value || "",
                value_ok: top_res?.value_ok || false,
                user_asks_or_requests: top_res?.user_asks_or_requests || false,
                is_different_direction: top_res?.is_different_direction || false,
                longer_or_eq: ai_val_valid.longer_or_eq,
                trulyvalidlength: ai_val_valid.trulyvalidlength
            };
        } else {
            return {
                has_ai_recognized_value: false,
                requested_value: "",
                value_ok: false,
                user_asks_or_requests: top_res?.user_asks_or_requests,
                is_different_direction: top_res?.is_different_direction,
                longer_or_eq: false,
                trulyvalidlength: false
            };
        }
        // NO CONFIG FOR VALUE COLLECTION
    } else {
        return {
            has_ai_recognized_value: false,
            requested_value: top_res?.requested_value || "",
            value_ok: top_res?.value_ok || false,
            user_asks_or_requests: top_res!.user_asks_or_requests,
            is_different_direction: top_res!.is_different_direction,
            longer_or_eq: false,
            trulyvalidlength: false
        };
    }
};

export const realtime_convo = async (sentence_string: string, timestamp: number, activeConvoKey: string, fin_or_upd: string) => {
    try {
        fetch("/realtime_convo", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                convo_id: activeConvoKey,
                timestamp: timestamp,
                sentence_string: sentence_string,
                fin_or_upd: fin_or_upd
            })
        }).then(Response => {
            if (Response.ok) {
                return Response.json();
            } else {
                throw new Error("errr_qusp_01");
            }
        });
    } catch {
        console.log("errr_qusp_02");
    }
};

export const empty_positive_ai_result = {
    has_ai_recognized_value: true,
    requested_value: "",
    value_ok: false,
    user_asks_or_requests: false,
    is_different_direction: false,
    longer_or_eq: false,
    trulyvalidlength: false
};
