import { assistantCodes } from "./lsts";

export const prepRegex = (filterString: string) => {
    // Split the string in half
    const halfLength = Math.floor(filterString.length / 2);
    let startPart = filterString.slice(0, halfLength);
    let endPart = filterString.slice(halfLength);

    // Get the first and last 10 characters (or less if the string is shorter)
    startPart = startPart.slice(0, 15);
    endPart = endPart.slice(-15);

    // Escape special regex characters
    const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    startPart = escapeRegex(startPart);
    endPart = escapeRegex(endPart);

    // Create the regex pattern
    const regexPattern = `${startPart}[\\s\\S]*?${endPart}`;
    return new RegExp(regexPattern, "g");
};
export const filterString = (str: string | undefined): string => {
    return (str || "")
        .toLowerCase()
        .replace(/[^а-яa-z0-9]/gi, "")
        .trim();
};
export const prepareHighlightRegexInvIncluded = (filterString: string, stype: string) => {
    // Split the string in half
    const halfLength = Math.floor(filterString.length / 2);
    let startPart = filterString.slice(0, halfLength);
    let endPart = filterString.slice(halfLength);

    // Get the first and last 10 characters (or less if the string is shorter)
    startPart = startPart.slice(0, 5);
    endPart = endPart.slice(-5);

    // Escape special regex characters
    const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    startPart = escapeRegex(startPart);
    endPart = escapeRegex(endPart);

    if (stype == assistantCodes.ipa) {
        if (filterString.length < 3 || /^\d+$/.test(filterString) == true) {
            return new RegExp(filterString, "g");
        } else {
            // Greedy gets even matches within matches for invoice extract detect
            return new RegExp(`(?=(${startPart}[\\s\\S]*?${endPart}))`, "g");
        }
    } else {
        // Doesnt get matches within matches
        return new RegExp(`${startPart}[\\s\\S]*?${endPart}`, "g");
    }
};
