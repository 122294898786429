import React, { useRef, useEffect, useState, MouseEvent } from "react";
import {
    IconButton,
    IDropdownOption,
    IDetailsHeaderProps,
    DetailsHeader,
    TooltipHost,
    DirectionalHint,
    IObjectWithKey,
    Modal,
    ContextualMenu,
    IContextualMenuItem,
    Dropdown,
    IDropdown,
    ShimmeredDetailsList,
    ThemeProvider
} from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn, IDetailsRowProps, DetailsRow } from "@fluentui/react/lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { Fabric } from "@fluentui/react/lib/Fabric";

import UploadCards from "../../components/UploadCards/UploadCards";
import JsonGridInvoice from "../../components/JsonGridInvoice/JsonGridInvoice";
import AISearchJsonGrid from "../../components/AISearchJsonGrid/AISearchJsonGrid";
import ChartRendererAnalysis from "../../components/ChartRenderer/ChartRendererAnalysis";
import CollapsibleSections from "../../components/CollapsibleSections/CollapsibleSections";
import { AddURLs } from "../../components/AddURLs";
import ConversationRecognizer from "../../components/SpeechRecognizer/ConversationRecognizer";

import { FiYoutube } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
import { TbTableDown } from "react-icons/tb";
import { FiSearch, FiEdit } from "react-icons/fi";

import { reindexApi, dataExtractionApi, downloadPDF, deleteFile, publishTranscriptDraft, getAiDiff, ai_comparison, qJsonAnalysis } from "../../api";
import { greindexparams, gtranscriptpublish, gdiffresponse, gfile, gfanalyse } from "../../interfaces";
import { detailsListContentTypeOptions } from "../../lsts";
import { dropdownDetailsListStyles, detailsListTheme } from "../../styles_glob";
import { onSTT, convertMarkdownHeadersToHTMLWithNewLines, markdownTableToHtmlOnly, convertMarkdownHeadersToHTML, get_mod_str } from "../../util_glob";
import RollingStatus from "../../components/RollingStatus/RollingStatus";
import styles from "./detailedList.module.css";

import { toast } from "react-toastify";
import Select from "react-select";

import {
    classNames,
    controlStyles,
    textFieldStyles,
    IDocument,
    IDetailsListDocumentsExampleState,
    IDetailsListDocumentsExampleProps,
    pdfI,
    formatDateTime,
    parseDocuments,
    _copyAndSort,
    _copyAndSortAdv,
    _copyAndSortAdvTotal,
    _copyAndSortInvStr,
    invoiceValidColumn,
    onRenderDetailsHeader,
    includesText,
    exportCSV,
    onUploadCardClick,
    isItemReady,
    getHeader
} from "./detailedMdls";

export class DetailsListDocumentsExample extends React.Component<IDetailsListDocumentsExampleProps, IDetailsListDocumentsExampleState> {
    public _selection: Selection;
    private _allItems: IDocument[];
    constructor(props: IDetailsListDocumentsExampleProps) {
        super(props);
        this._allItems = parseDocuments(this.props.fileList || []);
        this._onChangeCompactMode = this._onChangeCompactMode.bind(this);
        const columns: IColumn[] = [
            ...(this.props.selectedGroup.assistanttype != "ipa"
                ? [
                      {
                          key: "kgnf_filetype",
                          name: "File Type",
                          className: classNames.fileIconCell,
                          iconClassName: classNames.fileIconHeaderIcon,
                          ariaLabel: "Press to sort on File type",
                          iconName: "Page",
                          isIconOnly: true,
                          fieldName: "name",
                          minWidth: 16,
                          maxWidth: 16,
                          isPadded: false,
                          onColumnClick: this._onColumnClick,
                          onRender: (item: IDocument) => {
                              {
                                  return item.sourcetype == "youtube" ? <FiYoutube /> : item.sourcetype == "web" ? <CgWebsite /> : pdfI;
                              }
                          }
                      }
                  ]
                : []),
            {
                key: "kgnf_key",
                name: "Id",
                fieldName: "fileid",
                minWidth: 25,
                maxWidth: 25,
                isResizable: true,
                data: "number",
                onRender: (item: IDocument) => {
                    return <span style={{ font: "normal normal 11px/20px Urbanist,Roboto", color: "#2F4858" }}>{item.key}</span>;
                },
                isPadded: false
            },
            {
                key: "kgnf_name",
                name: "Name",
                fieldName: "displayName",
                minWidth: 200,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => {
                    return (
                        <div
                            title={item.name}
                            style={{ width: "min-content", cursor: "pointer" }}
                            onClick={() => {
                                this.handleDocumentDisplay(item);
                            }}
                        >
                            {item.name}
                        </div>
                    );
                }
            },
            {
                key: "kgnf_path",
                name: "",
                minWidth: 20,
                maxWidth: 20,
                isResizable: false,
                isPadded: false,
                onRender: (item: IDocument) => (
                    <IconButton
                        iconProps={{ iconName: "MoreVertical" }}
                        styles={{ root: { height: "100%" } }}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            const domEvent = e.nativeEvent;

                            this.onItemContextMenu(item, 0, domEvent);
                        }}
                    />
                )
            },
            {
                key: "kgnf_mod",
                name: "Modified",
                fieldName: "dateModifiedValue",
                minWidth: 110,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "number",
                onRender: (item: IDocument) => {
                    return <span title={formatDateTime(item.dateModified)}>{formatDateTime(item.dateModified)}</span>;
                },
                isPadded: true
            },
            {
                key: "kgnf_size",
                name: "Size",
                fieldName: "fileSize",
                minWidth: 40,
                maxWidth: 40,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span title={item.fileSize}>{item.fileSize}</span>;
                }
            },
            {
                key: "kgnf_type",
                name: "Type",
                fieldName: "doc_category",
                minWidth: 70,
                maxWidth: 190,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                onRender: (item: IDocument) => {
                    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
                    const dropdownRef = useRef<IDropdown | null>(null);
                    useEffect(() => {
                        if (isDropdownVisible && dropdownRef.current) {
                            dropdownRef.current.focus(); // Focus Dropdown so onBLur works
                        }
                    }, [isDropdownVisible]);

                    let options = [{ key: item.doc_category, text: item.doc_category || "Pending" }];
                    for (let i = 0; i < detailsListContentTypeOptions.length; i++) {
                        if (item.doc_category != detailsListContentTypeOptions[i].key) {
                            options.push(detailsListContentTypeOptions[i]);
                        }
                    }
                    return this.props.selectedGroup.permissionlevel >= 1 && item.doc_category && item?.doc_category !== "Pending" && isDropdownVisible ? (
                        <div>
                            <div style={{ padding: "0px !important", marginTop: "0px", marginBottom: "0px" }}>
                                <Dropdown
                                    componentRef={dropdownRef}
                                    selectedKey={item.doc_category}
                                    options={options}
                                    onChange={(event, option) => this.qChangeType(option?.key || "0")}
                                    styles={dropdownDetailsListStyles}
                                    onBlur={() => {
                                        setIsDropdownVisible(false);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div onClick={() => setIsDropdownVisible(true)} className={styles.drpdwnNotActive} title={item.doc_category}>
                            {item.doc_category ?? "Pending"}
                        </div>
                    );
                },
                isPadded: true
            },
            {
                key: "kgnf_status",
                name: "Status",
                fieldName: "docstatus",
                minWidth: 100,
                maxWidth: 190,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                onRender: (item: IDocument) => {
                    return (
                        <div className={`${isItemReady(item) == true ? styles.docStatusReady : styles.docStatusNotReady}`}>
                            <RollingStatus newText={`${item?.isDeleting == true ? "Deleting" : isItemReady(item) == true ? "Ready" : item?.docstatus}`} />
                        </div>
                    );
                },
                isPadded: true
            }
        ];
        if (this.props.selectedGroup.assistanttype == "ipa") {
            columns.splice(4, 0, {
                key: "kgnf_customer",
                name: "Customer",
                fieldName: "invoicedataobj",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => <div title={item?.invoicedataobj?.["customer_name"]}>{item?.invoicedataobj?.["customer_name"]}</div>
            });
            columns.splice(4, 0, {
                key: "kgnf_contractor",
                name: "Contractor",
                fieldName: "invoicedataobj",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => <div title={item?.invoicedataobj?.["contractor_name"]}>{item?.invoicedataobj?.["contractor_name"]}</div>
            });
            columns.splice(4, 0, {
                key: "kgnf_vat",
                name: "Vat",
                fieldName: "invoicedataobj",
                minWidth: 20,
                maxWidth: 40,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => <div title={item?.invoicedataobj?.["vat"]}>{item?.invoicedataobj?.["vat"]}</div>
            });
            columns.splice(4, 0, {
                key: "kgnf_total",
                name: "Total",
                fieldName: "invoicedataobj",
                minWidth: 20,
                maxWidth: 40,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) => <div title={item?.invoicedataobj?.["invoice_total"]}>{item?.invoicedataobj?.["invoice_total"]}</div>
            });
            columns.splice(4, 0, {
                key: "kgnf_valid",
                name: "Valid",
                fieldName: "invoicedataobj",
                minWidth: 40,
                maxWidth: 40,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: IDocument) =>
                    invoiceValidColumn(
                        item,
                        this.props.selectedGroup,
                        this.state.isWorkingOnDelete,
                        this.handleInvoiceExtractionButtonClick,
                        this.handleDocumentDisplay
                    )
            });
            /*
            columns.splice(4, 0, {
                key: "kgnf_confirm",
                name: "Confirmed",
                fieldName: "approved",
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: false,
                onRender: (item: IDocument) => {
                    return (
                        <div className={styles.confirmInvoiceButton}>
                            <ButtonHold
                                confirmed={item.approved == 1}
                                confirm={() => {
                                    this.qApprove();
                                }}
                            />
                        </div>
                    );
                }
            });
        */
        }
        if (this.props.selectedGroup.allowaudiodocentry == 1) {
            columns.splice(4, 0, {
                key: "kgnf_edit",
                name: "Edit",
                fieldName: "editcntnt",
                minWidth: 5,
                maxWidth: 5,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item: IDocument) => {
                    const hasTranscriptions = (item?.transcription?.length || 0) > 0;
                    return (item?.transcription?.length || 0) > 0 ? (
                        <div
                            onClick={() => {
                                this.setState({ isMicModalVisible: true });
                            }}
                            style={{ marginTop: "2px", marginBottom: "-10px", cursor: "pointer" }}
                        >
                            <FiEdit size={15} />
                        </div>
                    ) : (
                        <div></div>
                    );
                }
            });
        }
        if (this.props.selectedGroup.assistanttype == "cmp") {
            columns.splice(4, 0, {
                key: "kgnf_diff",
                name: "Diff",
                fieldName: "highlightItemId",
                minWidth: 5,
                maxWidth: 5,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item: IDocument) => {
                    return (item?.diffids?.length || 0) > 0 ? (
                        item?.diffids.map((diffid: number, index: number) => (
                            <div
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    let item_second = this._allItems.filter(item => item.key == e.currentTarget.getAttribute("data-item-id"))[0];
                                    this.handleDiffButtonClick([item, item_second], true);
                                }}
                                onMouseEnter={() => {
                                    this.setState({
                                        highlightItemId: diffid.toString()
                                    });
                                }}
                                onMouseLeave={() => {
                                    this.setState({
                                        highlightItemId: ""
                                    });
                                }}
                                style={{ marginTop: "2px", marginBottom: "-10px", cursor: "pointer" }}
                                data-item-id={diffid}
                            >
                                <TooltipHost content="Comparison between these 2 documents is available" directionalHint={DirectionalHint.leftCenter}>
                                    {this.state.highlightItemId == item.key.toString() ? (
                                        <div className={styles.diffItemHighlight}>{diffid}</div>
                                    ) : (
                                        <div className={styles.diffItem}>{diffid}</div>
                                    )}
                                </TooltipHost>
                            </div>
                        ))
                    ) : (
                        <div></div>
                    );
                }
            });
        }
        this.state = {
            items: this._allItems,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: false,
            announcedMessage: undefined,
            itemSelectionString: "",
            pathSelectionString: [],
            isModalOpen: false,
            isSecurityModalOpen: false,
            isContextMenuVisible: false,
            isSpaceFilterVisible: false,
            contextMenuTarget: null,
            isWorkingOnDelete: false,
            spacesFilter: "",
            activeFile: {},
            hasDeleted: false,
            invoiceModalItem: undefined,
            summaryModalItem: undefined,
            targetItem: undefined,
            callOutVisibilityBool: false,
            aiSearchData: undefined,
            isMicModalVisible: false,
            highlightItemId: undefined,
            isLoading: false,
            isAnalysisModalVisible: false,
            analysisContent: {}
        };
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                    itemSelectionString: this._selection
                        .getSelection()
                        .map((item: any) => item?.name)
                        .join(";"),
                    pathSelectionString: this._selection.getSelection().map((item: any) => item?.full_path),
                    activeFile: this.getActiveFile(this._selection.getSelection())
                });
                this.props.onSelectionChange(
                    this._selection.getSelection().map((item: any) => {
                        return { name: item?.name, groupIds: item?.groupIds, groupNames: item?.groupNames };
                    })
                );
            }
        });
    }
    public componentDidMount() {
        this.state.items.forEach((item: any) => {
            if (this.props.selectionPass.split(";").includes(item.name)) this._selection.setKeySelected(item.key, true, false);
        });
    }
    public componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) this._selection.setAllSelected(false);
        if (previousProps.fileList !== this.props.fileList) {
            this._allItems = parseDocuments(this.props.fileList || []);
            this.setState({ items: this._allItems });
        }
        if (this.state.highlightItemId !== previousState.highlightItemId && this.state.highlightItemId !== undefined)
            this.setState({ items: [...this.state.items] });
    }
    public getActiveFile = (selection: any) => {
        if (selection?.length > 0) {
            const selFileKey = selection.map((item: any) => item?.key)[0] || "";
            const selectedFile = (this.props.fileList || []).filter(item => item?.fileid == selFileKey);
            return selectedFile[0] || {};
        }
    };

    // CONTEXT MENU
    public onItemContextMenu = (item: any, index: number | undefined, ev: Event | undefined): boolean | void => {
        (ev as PointerEvent)?.preventDefault();
        import.meta.env.DEV === true && console.log("itm¦", item);
        if (this.props.selectedGroup.permissionlevel >= 2) {
            this.setState({
                isContextMenuVisible: true,
                targetItem: item,
                contextMenuTarget: { x: ev ? (ev as PointerEvent).clientX : 0, y: ev ? (ev as PointerEvent).clientY : 0 }
            });
        }
        return true;
    };
    // CONTEXT MENU CLICK HANDLERS
    public onContextMenuItemClick = (ev?: any, item?: IContextualMenuItem | undefined) => {
        if (item?.key === "Share") {
            this.showSecurityModal();
        } else if (item?.key === "Download") {
            this.handleDownloadButtonClick(this._selection.getSelection());
        } else if (item?.key === "Reindex") {
            this.handleReindexButtonClick(this._selection.getSelection());
        } else if (item?.key === "Invoice") {
            this.handleInvoiceExtractionButtonClick(this._selection.getSelection());
        } else if (item?.key === "Delete") {
            this.gHandleBatchDelete(this._selection.getSelection());
        } else if (item?.key === "IndexView") {
            this.handleIndexViewButtonClick(this._selection.getSelection());
        } else if (item?.key === "Diff") {
            this.handleDiffButtonClick(this._selection.getSelection(), false);
        } else if (item?.key === "Analyse") {
            this.handleAnalyseButtonClick(this._selection.getSelection(), false);
        }

        this.setState({ isContextMenuVisible: false });
    };
    // CONTEXT MENU HANDLERS
    public handleDownloadButtonClick = async (item: any[]) => {
        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
        for (let i = 0; i < item.length; i++) {
            const response = downloadPDF(this.getContentPath(item[i].name), item[i].name, this.props.companyId, this.props.loggedInUser);
            await sleep(250);
        }
    };
    public handleReindexButtonClick = async (items: any[]) => {
        let itemAppendedStrings = [];
        let modStrings = [];
        let idStrings = [];
        for (let i = 0; i < items.length; i++) {
            itemAppendedStrings.push(this.getContentPath(items[i].name));
            modStrings.push(new Date(this.getItemFromSel(items[i].name).dateModified)?.getTime()?.toString());
            idStrings.push(items[i].key);
        }
        let itemAppendedArr = itemAppendedStrings.join(";");
        let reindexParams: greindexparams = {
            fileString: itemAppendedArr,
            selectedGroup: this.props.selectedGroup,
            userId: this.props.loggedInUser.userId,
            company: this.props.companyId,
            header: getHeader(this.props.loggedInUser),
            modString: modStrings?.join(";"),
            idString: idStrings?.join(";")
        };
        this._allItems = this._allItems.map(currentItem => {
            if (items.some(selFile => selFile.key === currentItem.key)) return { ...currentItem, docstatus: "Reindexing" };
            return currentItem;
        });
        this.setState({ items: this._allItems });
        const response = await reindexApi(reindexParams, this.props.listRefreshRequest);
        if (response.success !== true) {
            throw Error("Failed reindexFile");
        } else {
            this.props.updateCatsState(itemAppendedArr);
        }
    };
    public handleInvoiceExtractionButtonClick = async (items: any[]) => {
        let itemAppendedStrings = [];
        let modStrings = [];
        let idStrings = [];
        for (let i = 0; i < items.length; i++) {
            itemAppendedStrings.push(this.getContentPath(items[i].name));
            modStrings.push(new Date(this.getItemFromSel(items[i].name).dateModified)?.getTime()?.toString());
            idStrings.push(items[i].key);
        }
        let itemAppendedArr = itemAppendedStrings.join(";");
        let reindexParams: greindexparams = {
            fileString: itemAppendedArr,
            selectedGroup: this.props.selectedGroup,
            userId: this.props.loggedInUser.userId,
            company: this.props.companyId,
            header: getHeader(this.props.loggedInUser),
            modString: modStrings?.join(";"),
            idString: idStrings?.join(";")
        };
        const response = await dataExtractionApi(reindexParams, this.props.listRefreshRequest);
        if (response.success !== true) {
            throw Error("Failed reindexFile");
        } else {
            this.props.updateCatsState(itemAppendedArr);
        }
    };
    public handleIndexViewButtonClick = async (items: any[]) => {
        const params = new URLSearchParams({
            fkey: items[0].key,
            companyid: this.props.companyId
        });
        const url = "/srch_by_filename" + `?${params.toString()}`;
        const response = await fetch(url, {
            headers: getHeader(this.props.loggedInUser)
        });
        let indx_results = await response.json();
        this.setState({
            aiSearchData: {
                title: items[0].name,
                indx_results: indx_results
            }
        });
    };
    public prepDiffCitationURL = (item: any) => {
        return `${item.name}#page=1_fid=${item.key}`;
    };
    public handleDiffButtonClick = async (items: any[], isCellButton: boolean) => {
        if (isCellButton) {
            console.log("isCellButton", items);

            if (items.length == 2) {
                let itemids = items.map(item => item.key);
                if (
                    (items[0].diffids.includes(items[0].key) || items[0].diffids.includes(items[1].key)) &&
                    (items[1].diffids.includes(items[0].key) || items[1].diffids.includes(items[1].key))
                ) {
                    this.setState({ isLoading: true });
                    let diff_results: gdiffresponse[] = await getAiDiff(items[0].key, items[1].key, this.props.loggedInUser);
                    this.setState({ isLoading: false });
                    this.props.onCompareClicked(this.prepDiffCitationURL(items[0]), this.prepDiffCitationURL(items[1]), diff_results);
                } else {
                    if (items[0].diffids.length < 1 || items[1].diffids.length < 1) {
                        let mod0 = get_mod_str(items[0].modified);
                        let mod1 = get_mod_str(items[1].modified);
                        await ai_comparison(
                            items[0].key,
                            items[1].key,
                            this.props.loggedInUser,
                            this.props.companyId,
                            mod0,
                            mod1,
                            items[0].ownerid,
                            items[1].ownerid,
                            this.props.listRefreshRequest
                        );
                    } else {
                        toast.error("Multiple comparisons per file are not available in demo");
                    }
                }
            } else {
                toast.error("Please select 2 files to compare");
            }
        } else {
            if (items.length == 2) {
                if (items[0].diffids.length < 1 || items[1].diffids.length < 1) {
                    // Fresh Files
                    let mod0 = get_mod_str(items[0].modified);
                    let mod1 = get_mod_str(items[1].modified);
                    await ai_comparison(
                        items[0].key,
                        items[1].key,
                        this.props.loggedInUser,
                        this.props.companyId,
                        mod0,
                        mod1,
                        items[0].ownerid,
                        items[1].ownerid,
                        this.props.listRefreshRequest
                    );
                } else {
                    if (
                        (items[0].diffids.includes(items[0].key) || items[0].diffids.includes(items[1].key)) &&
                        (items[1].diffids.includes(items[0].key) || items[1].diffids.includes(items[1].key))
                    ) {
                        // Recompare
                        let mod0 = get_mod_str(items[0].modified);
                        let mod1 = get_mod_str(items[1].modified);
                        await ai_comparison(
                            items[0].key,
                            items[1].key,
                            this.props.loggedInUser,
                            this.props.companyId,
                            mod0,
                            mod1,
                            items[0].ownerid,
                            items[1].ownerid,
                            this.props.listRefreshRequest
                        );
                    } else {
                        toast.error("Multiple comparisons per file are not available in demo");
                    }
                }
            } else {
                toast.error("Please select 2 files to compare");
            }
        }
    };
    public handleAnalyseButtonClick = async (items: IObjectWithKey[], isCellButton: boolean) => {
        let json_arr: gfanalyse[] = [];
        items.forEach(async item => {
            let itm = item as gfile;
            json_arr.push({
                filename: itm.name,
                structured_data_per_page: JSON.parse(itm.invoicedata)
            });
        });
        qJsonAnalysis(json_arr, getHeader(this.props.loggedInUser)).then(res => {
            console.log("nlsz", res);
            this.setState({ analysisContent: res });
            this.setState({ isAnalysisModalVisible: true });
        });
    };
    public visualMarkingCallback = (filesToDel: IDocument[]) => {
        filesToDel.forEach(file => {});
    };
    public qChangeType = async (doc_category: string | number) => {
        let fid = this.state.activeFile.fileid;
        let response = await fetch(`/qChangeFileType`, {
            method: "POST",
            headers: getHeader(this.props.loggedInUser),
            body: JSON.stringify({ fid: fid, doc_category: doc_category })
        }).then(res => {
            if (!res || res.ok == false) {
                toast.error("Error Changing Item Type");
            } else {
                toast.success("Changed Item Type");
                this.props.listRefreshRequest();
            }
        });
    };
    public qApprove = async () => {
        let fid = this.state.activeFile.fileid;
        let response = await fetch(`/qApprove`, {
            method: "POST",
            headers: getHeader(this.props.loggedInUser),
            body: JSON.stringify({ fid: fid, approverid: this.props.loggedInUser?.userId || "" })
        }).then(res => {
            if (!res || res.ok == false) {
                toast.error("Error approving Item");
            } else {
                toast.success("Item Approved");
                this.props.listRefreshRequest();
            }
        });
    };
    public gHandleBatchDelete = async (selFiles: IObjectWithKey[]) => {
        let fileids = selFiles.map(file => file.key).join(";");
        let filesToDel = this._allItems.filter(item => selFiles.some(selFile => selFile.key === item.key));
        let filesKeep = this._allItems.filter(item => selFiles.every(selFile => selFile.key !== item.key));
        let filenms = filesToDel.map(file => file.name).join(";");
        this._allItems = this._allItems.map(currentItem => {
            if (selFiles.some(selFile => selFile.key === currentItem.key)) return { ...currentItem, isDeleting: true };
            return currentItem;
        });
        this.setState({
            items: this._allItems
        });
        let response = await deleteFile(filenms, fileids, getHeader(this.props.loggedInUser), this.props.companyId, this.props.listRefreshRequest);
        if (response.success !== true) {
            throw Error("Failed deleteFile");
        } else {
            this._allItems = filesKeep;
            this.setState({
                items: this._allItems,
                hasDeleted: true
            });
            this.props.onDelFile();
        }
    };
    public onSpaceFilterClick = (option?: IDropdownOption) => {
        let items = this.filterSpace(option?.key as string);
        this.setState({ items: items, spacesFilter: option?.key ? (option.key as string) : "" });
    };
    public handleDelButtonClick = async (item: any) => {
        this._allItems = this._allItems.map(currentItem => {
            if (currentItem.key == item.key) return { ...currentItem, isDeleting: true };
            return currentItem;
        });
        this.setState({ items: this._allItems });
        const response = await deleteFile(item.name, item.key, getHeader(this.props.loggedInUser), this.props.companyId);
        if (response.success !== true) {
            throw Error("Failed deleteFile");
        } else {
            this._allItems = this._allItems.filter(currentItem => currentItem.key !== item.key);
            this.setState({
                items: this._allItems,
                hasDeleted: true
            });
            this.props.onDelFile();
        }
    };
    public qHandleFileSelectionSpaceChange = async (selectionId: string, selectionText: string) => {
        let selFiles = this._selection.getSelection();
        let fileids = selFiles.map(file => file.key).join(";");
        selFiles.forEach(async (file: any) => {
            const response = await fetch("/qSetFilePermissions", {
                method: "POST",
                headers: getHeader(this.props.loggedInUser),
                body: JSON.stringify({ fileKey: file.key, groupKey: selectionId })
            });
            this._allItems = this._allItems.map(item => {
                if (item.key === file.key) {
                    return { ...item, groupIds: selectionId ? selectionId?.split(",") : [], groupNames: selectionText ? selectionText?.split(",") : [] }; // spread the original item and update the Group Props
                }
                return item;
            });
            this.setState({ items: this._allItems });
            this.props.fileSpaceChangeTrigger(new Date().toISOString());
        });
        this.hideSecurityModal();
        const response = await fetch("/srch_update", {
            method: "POST",
            headers: getHeader(this.props.loggedInUser),
            body: JSON.stringify({ fileids: fileids, groupids: selectionId, companyid: this.props.companyId })
        });
    };
    public showInvoiceModal = (itm: IDocument) => {
        this.setState({ invoiceModalItem: itm });
    };
    public getContentPath = (itemSelectionString: string) => {
        const itemSel = itemSelectionString.split(";")[0];
        const itemid = this._allItems.filter(item => item.name == itemSel).map(item => item.key)[0];
        const contPath = `${itemSel}_fid=${itemid}`;
        return contPath;
    };
    public getItemFromSel = (itemSelectionString: string) => {
        const itemSel = itemSelectionString.split(";")[0];
        const item = this._allItems.filter(item => item.name == itemSel)[0];
        return item;
    };
    public handleDocumentDisplay = (item: any) => {
        if (isItemReady(item) == true) {
            this.props.onCitationClicked(`${item.name}#page=1_fid=${item.key}`, null);
        } else {
            toast.warning(`"Item not Ready Yet ${item.docstatus}`);
        }
    };
    public publishTranscriptDraftHandler = async (trnscptPbls: gtranscriptpublish, listRefreshRequest: () => void) => {
        try {
            await publishTranscriptDraft(trnscptPbls, listRefreshRequest);
        } catch (error) {
            console.error(error);
        }
    };
    public callOutVisibility = (callOutVisibility: boolean) => {};
    public showModal = () => this.setState({ isModalOpen: true });
    public hideModal = () => this.setState({ isModalOpen: false });
    public showSecurityModal = () => this.setState({ isSecurityModalOpen: true });
    public hideSecurityModal = () => this.setState({ isSecurityModalOpen: false });
    public hideInvoiceModal = () => this.setState({ invoiceModalItem: undefined });
    public hideAISearchDataModal = () => this.setState({ aiSearchData: undefined });
    public hideSummaryModal = () => this.setState({ summaryModalItem: undefined });
    public isMicModalVisible = () => this.setState({ isMicModalVisible: false });

    public render() {
        const { columns, isCompactMode, items, selectionDetails, isModalSelection, announcedMessage, isModalOpen, pathSelectionString, itemSelectionString } =
            this.state;
        return (
            <Fabric>
                <div className={classNames.controlWrapper} id="#dLM">
                    {this.props.selectedGroup.selectionId !== "" &&
                    this.props.selectedGroup.selectionId !== "FileSelect" && // Exclude FileSelect Group (Legacy)
                    this.props.getOFromListVal >= 2 ? ( // Add Space Button ¦ Selected Group Permission Level greater then Read (Member or Owner)
                        <div className={styles.spaceHead}>
                            <div className={styles.rightButtons}></div>
                            <div className={styles.rightButtons}>
                                {/* Upload */}
                                {this.props.selectedGroup.permissionlevel > 1 && (
                                    <div id="addButtonOpI" className={styles.commsOnly}>
                                        <div className={styles.labelA}>Add</div>
                                        <div className={styles.iIcons}>
                                            <div className={styles.uploadButsContainerSecond}>
                                                <div className={styles.subButsContW}>
                                                    <div>
                                                        <TooltipHost content="Add Documents" directionalHint={DirectionalHint.topCenter}>
                                                            <AddURLs
                                                                className={styles.commandButtonLib}
                                                                updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                butName="PDF"
                                                                calloutLabelText="Upload PDFs from your Device"
                                                                cTy="pdf"
                                                                listRefreshRequest={this.props.listRefreshRequest}
                                                                selectedGroup={this.props.selectedGroup}
                                                                callOutVisibility={this.callOutVisibility}
                                                            />
                                                        </TooltipHost>
                                                    </div>
                                                    {this.props.selectedGroup.allowvideofiles == 1 && (
                                                        <TooltipHost content="Add Video Files" directionalHint={DirectionalHint.topCenter}>
                                                            <AddURLs
                                                                className={styles.commandButtonLib}
                                                                updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                butName="Video"
                                                                calloutLabelText="Upload Video Files from your Device"
                                                                cTy="vid"
                                                                listRefreshRequest={this.props.listRefreshRequest}
                                                                selectedGroup={this.props.selectedGroup}
                                                                callOutVisibility={this.callOutVisibility}
                                                            />
                                                        </TooltipHost>
                                                    )}
                                                    {this.props.selectedGroup.allowyoutube == 1 && (
                                                        <div>
                                                            <TooltipHost content="Add YouTube videos" directionalHint={DirectionalHint.topCenter}>
                                                                <AddURLs
                                                                    className={styles.commandButtonLib}
                                                                    updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                    butName="YouTube"
                                                                    calloutLabelText="Enter YouTube URL"
                                                                    cTy="youtube"
                                                                    listRefreshRequest={this.props.listRefreshRequest}
                                                                    selectedGroup={this.props.selectedGroup}
                                                                    callOutVisibility={this.callOutVisibility}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    )}
                                                    {this.props.selectedGroup.allowweb == 1 && (
                                                        <div>
                                                            <TooltipHost content="Add Web Pages" directionalHint={DirectionalHint.topCenter}>
                                                                <AddURLs
                                                                    className={styles.commandButtonLib}
                                                                    updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                    butName="Web"
                                                                    calloutLabelText="Enter Web URL"
                                                                    cTy="web"
                                                                    listRefreshRequest={this.props.listRefreshRequest}
                                                                    selectedGroup={this.props.selectedGroup}
                                                                    callOutVisibility={this.callOutVisibility}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    )}
                                                    {this.props.selectedGroup.allowaudiodocentry == 1 && (
                                                        <div>
                                                            <TooltipHost content="Record Content" directionalHint={DirectionalHint.topCenter}>
                                                                <AddURLs
                                                                    className={styles.commandButtonLib}
                                                                    updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                    butName="Web"
                                                                    calloutLabelText="Record Audio"
                                                                    cTy="mic"
                                                                    listRefreshRequest={this.props.listRefreshRequest}
                                                                    selectedGroup={this.props.selectedGroup}
                                                                    callOutVisibility={this.callOutVisibility}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    )}
                                                    {this.props.selectedGroup.urllinkextraction == 1 && (
                                                        <div>
                                                            <TooltipHost content="Extract Page Links" directionalHint={DirectionalHint.topCenter}>
                                                                <AddURLs
                                                                    className={styles.commandButtonLib}
                                                                    updateCatsState={(newValue: string) => this.props.updateCatsState(newValue)}
                                                                    butName="Links"
                                                                    calloutLabelText="Follow Links"
                                                                    cTy="map"
                                                                    listRefreshRequest={this.props.listRefreshRequest}
                                                                    selectedGroup={this.props.selectedGroup}
                                                                    callOutVisibility={this.callOutVisibility}
                                                                />
                                                            </TooltipHost>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.hiddenRefs}>
                                            <div id={`calloutButton_pdf`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_youtube`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_vid`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_web`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_mic`} className={styles.hiddenRefElement}></div>
                                            <div id={`calloutButton_map`} className={styles.hiddenRefElement}></div>
                                        </div>
                                    </div>
                                )}

                                <TextField
                                    onChange={this._onChangeText}
                                    className={styles.searchBoxDetailsList}
                                    onRenderPrefix={() => <FiSearch />}
                                    styles={textFieldStyles}
                                />
                                {this.props.selectedGroup.assistanttype == "ipa" && (
                                    <TooltipHost content="Download CSV of all Structured Invoice Data" directionalHint={DirectionalHint.rightTopEdge}>
                                        <div
                                            className={styles.exportCsvButton}
                                            onClick={() => exportCSV(this.props.selectedGroup?.oid?.toString(), getHeader(this.props.loggedInUser))}
                                        >
                                            <TbTableDown size={18} color="#2f4858" />
                                        </div>
                                    </TooltipHost>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={styles.spaceHead}>
                            <button className={styles.libTitle}>{this.props.selectedGroup.selectionText}</button>
                            <div className={styles.rightButtons}>
                                <TextField
                                    onChange={this._onChangeText}
                                    className={styles.searchBoxDetailsList}
                                    onRenderPrefix={() => {
                                        return (
                                            <div style={{ margin: "0px", padding: "opx", width: "5px" }}>
                                                <FiSearch />
                                            </div>
                                        );
                                    }}
                                    styles={textFieldStyles}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {items.length > 0 ? (
                    <div style={{ height: "calc(100vh - 180px)", overflow: this.state.isLoading ? "hidden" : "auto" }}>
                        <ThemeProvider theme={detailsListTheme}>
                            {this.state.isLoading == true && (
                                <div className={styles.loadingOverlayContainerDetList}>
                                    <div className={styles.loadingOverlayDetList}>
                                        <div className={styles.typingDotsDetList}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <MarqueeSelection selection={this._selection}>
                                <DetailsList
                                    className={styles.customDetailsList}
                                    items={items}
                                    compact={isCompactMode}
                                    columns={columns}
                                    isHeaderVisible={true}
                                    selectionMode={SelectionMode.multiple}
                                    //getKey={this._getKey}
                                    setKey="multiple"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selection={this._selection}
                                    selectionPreservedOnEmptyClick={true}
                                    onItemInvoked={this.handleDocumentDisplay}
                                    enterModalSelectionOnTouch={true}
                                    ariaLabelForSelectionColumn="Toggle Selection"
                                    ariaLabelForSelectAllCheckbox="Toggle Selection for all items"
                                    checkButtonAriaLabel="Row checkbox"
                                    onItemContextMenu={this.onItemContextMenu}
                                    styles={controlStyles}
                                    onRenderDetailsHeader={(props?: IDetailsHeaderProps) => onRenderDetailsHeader(props!)}
                                    onRenderRow={(prps: IDetailsRowProps | undefined): JSX.Element => {
                                        return <DetailsRow {...(prps as IDetailsRowProps)} styles={prps?.styles} />;
                                    }}
                                    onShouldVirtualize={() => items.length > 40}
                                />
                            </MarqueeSelection>
                        </ThemeProvider>
                    </div>
                ) : this.props.fileList == undefined ? (
                    <div className={styles.greetingsDetList}>
                        <ShimmeredDetailsList
                            className={styles.customDetailsListShimmer}
                            enableShimmer={true}
                            items={[]}
                            shimmerLines={7}
                            columns={columns}
                            compact={false}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={false}
                            selectionPreservedOnEmptyClick={true}
                            enterModalSelectionOnTouch={true}
                        />
                        <div className={styles.noDocsUploadCardsContainerAbs}>
                            <UploadCards onUploadCardClick={onUploadCardClick} selectedGroup={this.props.selectedGroup}></UploadCards>
                        </div>
                    </div>
                ) : (
                    <ShimmeredDetailsList
                        className={styles.customDetailsList}
                        enableShimmer={true}
                        items={[]}
                        shimmerLines={3}
                        columns={columns}
                        compact={isCompactMode}
                        selectionMode={SelectionMode.multiple}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        onItemInvoked={this.handleDocumentDisplay}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        onRenderDetailsHeader={(props?: IDetailsHeaderProps) => onRenderDetailsHeader(props!)}
                    />
                )}
                {this.state.selectionDetails && <div className={styles.selDet}>{this.state.selectionDetails}</div>}
                {this.state.isContextMenuVisible && (
                    <ContextualMenu
                        items={[
                            {
                                key: "Download",
                                text: "Download",
                                onClick: this.onContextMenuItemClick,
                                disabled: false
                            },
                            { key: "Share", text: "Move", onClick: this.onContextMenuItemClick, disabled: isItemReady(this.state.targetItem) == false },
                            {
                                key: "Reindex",
                                text: "Reindex",
                                onClick: this.onContextMenuItemClick,
                                disabled: false
                            },
                            ...(this.props.selectedGroup.assistanttype == "ipa" &&
                            this.props.selectedGroup.permissionlevel >= 2 &&
                            (this.state.targetItem?.doc_category == "invoice" || this.state.targetItem?.doc_category == "form")
                                ? [{ key: "Invoice", text: "Data Extraction", onClick: this.onContextMenuItemClick }]
                                : []),
                            ...(this.props.selectedGroup.assistanttype == "cmp" && this.props.selectedGroup.permissionlevel >= 2
                                ? [{ key: "Diff", text: "Compare", onClick: this.onContextMenuItemClick }]
                                : []),
                            ...(this.props.selectedGroup.assistanttype == "dpa" && this.props.selectedGroup.permissionlevel >= 1
                                ? [{ key: "Analyse", text: "Analyse", onClick: this.onContextMenuItemClick }]
                                : []),
                            {
                                key: "IndexView",
                                text: "View AI Search Entries",
                                onClick: this.onContextMenuItemClick,
                                disabled: isItemReady(this.state.targetItem) == false
                            },
                            ...(this.props.selectedGroup.permissionlevel >= 2
                                ? [
                                      {
                                          key: "Delete",
                                          text: "Delete",
                                          onClick: this.onContextMenuItemClick,
                                          itemProps: {
                                              styles: {
                                                  root: {
                                                      color: "#8B0000",
                                                      backgroundColor: "#FFCCCB"
                                                  }
                                              }
                                          }
                                      }
                                  ]
                                : [])
                        ]}
                        target={this.state.contextMenuTarget}
                        onDismiss={() => this.setState({ isContextMenuVisible: false })}
                    />
                )}

                {this.state.isSpaceFilterVisible && (
                    <ContextualMenu
                        items={this.getSpaceFilterChoices()}
                        target={document.getElementById("spaceHeaderFilterIcon")}
                        onDismiss={() => this.setState({ isSpaceFilterVisible: false })}
                    />
                )}

                {/*Share*/}
                <Modal
                    isOpen={this.state.isSecurityModalOpen}
                    onDismiss={this.hideSecurityModal}
                    isBlocking={false}
                    containerClassName={styles.changeSpaceModalMain}
                >
                    <div style={{ margin: "20px" }}>
                        <div className={styles.changeSpaceTextPortion}>
                            <div className={styles.changeSpaceHTitle}>Move</div>
                            <div className={styles.changeSpaceHDetails}>Change the Space for the Selected Item</div>
                        </div>
                        <div className={styles.changeSpaceHDropdownContained}>
                            <Select
                                options={this.props.groupList
                                    .filter(
                                        (option: any) =>
                                            option.key !== "FileSelect" && option.memberlevel >= 2 && option.key !== this.props.selectedGroup.selectionId
                                    )
                                    .map((option: any) => ({ value: option.key, label: option.text }))}
                                onChange={(selectedOption: any) => {
                                    if (selectedOption) this.qHandleFileSelectionSpaceChange(selectedOption.value, selectedOption.label);
                                }}
                                placeholder="Select an Assistant..."
                                styles={{
                                    menu: provided => ({
                                        ...provided,
                                        maxHeight: 400
                                    }),
                                    control: provided => ({
                                        ...provided,
                                        maxHeight: 50 // Height of the control bar (adjust as needed)
                                    })
                                }}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        </div>
                    </div>
                </Modal>
                {/*JsonGrid*/}
                <Modal
                    isOpen={this.state.invoiceModalItem == undefined ? false : true}
                    onDismiss={this.hideInvoiceModal}
                    isBlocking={false}
                    containerClassName={styles.invoiceModalWrap}
                >
                    <JsonGridInvoice
                        jsonString={this.state.invoiceModalItem?.invoicedata ?? "{excl:[],instr:[]}"}
                        itmName={this.state.invoiceModalItem?.name}
                        fid={this.state.invoiceModalItem?.key}
                        loggedInUser={this.props.loggedInUser}
                    />
                </Modal>
                {/*AISearchJsonGrid*/}
                <Modal
                    isOpen={this.state.aiSearchData == undefined ? false : true}
                    onDismiss={this.hideAISearchDataModal}
                    isBlocking={false}
                    containerClassName={styles.aiSearchRIndicesModal}
                >
                    <div className={styles.indicesModalTitle}>{this.state.activeFile?.name}</div>
                    <AISearchJsonGrid value={this.state.aiSearchData?.indx_results} itmTitle={this.state.invoiceModalItem?.name} />
                </Modal>
                {/*invoiceModal*/}
                <Modal
                    isOpen={this.state.summaryModalItem == undefined ? false : true}
                    onDismiss={this.hideSummaryModal}
                    isBlocking={false}
                    containerClassName={styles.invoiceModal}
                >
                    <CollapsibleSections data={this.state.summaryModalItem || []} />
                </Modal>

                <Modal
                    isOpen={this.state.isMicModalVisible}
                    onDismiss={() => this.isMicModalVisible()}
                    isBlocking={false}
                    containerClassName={styles.convoRecModalContainer}
                >
                    <ConversationRecognizer
                        selectedGroup={this.props.selectedGroup}
                        loggedInUser={this.props.loggedInUser}
                        publishTranscriptDraftCallback={(transcriptObject: gtranscriptpublish) =>
                            this.publishTranscriptDraftHandler(transcriptObject, this.props.listRefreshRequest)
                        }
                        activeFile={this.state.activeFile}
                        onSTT={onSTT}
                    />
                </Modal>
                <Modal
                    isOpen={this.state.isAnalysisModalVisible}
                    onDismiss={() => this.setState({ isAnalysisModalVisible: false })}
                    isBlocking={false}
                    containerClassName={styles.analysisModalContainer}
                >
                    <div className={styles.analysisTitle}>Analysis</div>
                    {this.state?.analysisContent?.result ? (
                        <div
                            className={styles.analysisContentMD}
                            style={{ zIndex: 1001111 }}
                            dangerouslySetInnerHTML={{
                                __html: convertMarkdownHeadersToHTMLWithNewLines(
                                    markdownTableToHtmlOnly(convertMarkdownHeadersToHTML(this.state?.analysisContent?.result || ""))
                                )
                                    ?.replace(new RegExp("```html|```", "g"), "")
                                    .replace(/&lt;b&gt;/g, "<b>")
                                    .replace(/&lt;\/b&gt;/g, "</b>")
                                    .replace("\n", "<br>")
                            }}
                        />
                    ) : (
                        <div className={styles.analysisTitle}>Loading...</div>
                    )}

                    {this.state?.analysisContent?.chartjs_jsons?.map((chart: any, index: number) => {
                        return (
                            <div key={index} className={styles.analysisChartsMD}>
                                <ChartRendererAnalysis configChart={chart} />
                            </div>
                        );
                    })}
                </Modal>
            </Fabric>
        );
    }

    private getSpaceFilterChoices = () => {
        const itCh: IContextualMenuItem[] = this.props.groupList
            .filter(option => option.key !== "FileSelect")
            .map(option => ({
                key: option.key.toString(),
                text: option.text,
                onClick: () => this.onSpaceFilterClick(option)
            }));
        return itCh;
    };

    private filter = (text?: string): any[] => {
        return this._allItems.filter(i => includesText(i, text)) || this._allItems;
    };

    private filterSpace = (text?: string): any[] => {
        return text
            ? this._allItems.filter(i => i.groupIds?.includes(text ? text : "") || (i.filerole && text == "MyFiles")) || this._allItems
            : this._allItems;
    };

    private _onChangeCompactMode = (ev: MouseEvent<HTMLElement>, checked?: boolean): void => {
        this.setState({ isCompactMode: checked });
    };

    private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string): void => {
        let items = this.filter(text);
        this.setState({ items: items });
    };

    private _getSelectionDetails(): string {
        const selectionCount = this._selection?.getSelectedCount();
        switch (selectionCount) {
            case 0:
                return "No Items Selected";
            case 1:
                return "1 Item Selected";
            case undefined:
                return "No Items Selected";
            default:
                return `${selectionCount} Items Selected`;
        }
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                this.setState({ announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? "descending" : "ascending"}` });
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        let newItems;
        if (currColumn.key == "kgnf_valid") {
            newItems = _copyAndSortAdv(items, currColumn.fieldName!, currColumn.isSortedDescending);
        } else if (currColumn.key == "kgnf_total" || currColumn.key == "kgnf_vat") {
            newItems = _copyAndSortAdvTotal(items, currColumn.key!, currColumn.isSortedDescending);
        } else if (currColumn.key == "kgnf_contractor" || currColumn.key == "kgnf_customer") {
            newItems = _copyAndSortInvStr(items, currColumn.key!, currColumn.isSortedDescending);
        } else {
            newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        }
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };
}
