import React, { useEffect, useContext, useState, useRef, useCallback, useMemo } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import type { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import styles from "../ViewerPDF/ViewerPDF.module.css";
import stylesXplrr from "./XplrrBOXPDF.module.css";
import { GlobalContext } from "../../GlobalContext";
import debounce from "lodash/debounce";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import { ImFilesEmpty } from "react-icons/im";
import { MdErrorOutline } from "react-icons/md";
import { contentPath, pdfCacheKey, areAllFlagsTrue, findRange, getDesiredHeight, getDesiredWidth, saveEntriesToGlobal } from "../../util_glob";
import { qGetFileParagraps } from "../../api/api";
import { wigglePixel, get_mod_str } from "../../util_glob";
import { gfile, gpagedimensions, gpdftextitem, gautoparagraphresult } from "../../interfaces";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

(pdfjs.GlobalWorkerOptions as any).fontExtraProperties = true;
(pdfjs.GlobalWorkerOptions as any).disableFontFace = false;
(pdfjs.GlobalWorkerOptions as any).useSystemFonts = true;

let timeoutId: number;

interface PDFBoxDetails {
    file: gfile | undefined;
    handleTimeout: () => void;
    handleScroll: (pageNumCur: number) => void;
    setPages: (numPages: number) => void;
    setContentList: (contentList: { title: string; dest: number }[]) => void;
    startPage: number;
    activeFileId: string;
    callBackVisibleEntries: (vis: number[]) => void;
    callBackIsLoading: (isL: boolean) => void;
    srcLoc: string;
    companyId: string;
}
interface PDFPageDetails {
    handleTimeout: () => void;
    setContentList: (contentList: { title: string; dest: number }[]) => void;
    nums: number;
    pdf: PDFDocumentProxy;
    startPage: number;
    fileId: string;
    fileIndexChunks: number[];
    filePageDimsState: gpagedimensions[] | [];
}

const XplrrBOXPDF = ({
    file,
    handleTimeout,
    handleScroll,
    setPages,
    setContentList,
    startPage,
    activeFileId,
    callBackVisibleEntries,
    callBackIsLoading,
    srcLoc,
    companyId
}: PDFBoxDetails) => {
    const [progress, setProgress] = useState<number>();
    const [pageN, setPageN] = useState<number>(startPage);
    const [fileId, setFileId] = useState<string>(activeFileId);
    const [isLoa, setIsLoa] = useState(true);
    const [fileIndexChunks, setFileIndexChunks] = useState<number[]>([]);
    const [pgs, setPgs] = useState<React.ReactElement<any, any>[]>([]);
    const [isBlobPulling, setIsBlobPulling] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>(activeFileId);
    const pageRefs = useRef<(Element | null)[]>([]);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const containerRef = useRef(null);
    const paragraphsRef = useRef([]);
    const [resizeCount, setResizeCount] = useState(0);
    const [scrollTr, setScrollTr] = useState("");
    const [rescaleWidth, setRescaleWidth] = useState<number>(getDesiredWidth(srcLoc));
    const [filePageDimsState, setFilePageDimsState] = useState<gpagedimensions[] | []>([]);
    const [loadMark, setLoadMark] = useState<string>();
    const [onDemandRefresh, setOnDemandRefresh] = useState<number>(0);
    const { fileList, loggedInUser } = useContext(GlobalContext);

    useEffect(() => {
        const handleResize = debounce(() => {
            setResizeCount(prevCount => prevCount + 1);
            setRescaleWidth(getDesiredWidth(srcLoc));
        }, 100);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            handleResize.cancel();
        };
    }, []);

    useEffect(() => {
        if (!fileId) return;
        qGetFileParagraps(fileId, loggedInUser).then((res: gautoparagraphresult[]) => {
            paragraphsRef.current = res as any;
        });
    }, [fileId]);

    useEffect(() => {
        if (!activeFileId) return;

        let visibleEntries: number[] = [];
        let cacheEntries: number[] = [];

        observerRef.current = new IntersectionObserver(
            entries => {
                let currentEntries: number[] = [];
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const pageNumber = parseInt((entry.target as HTMLElement).dataset.pageNumber || "1", 10);
                        if (!visibleEntries.includes(pageNumber)) {
                            visibleEntries.push(pageNumber);
                        }
                        currentEntries.push(pageNumber);
                    }
                });
                visibleEntries = visibleEntries.sort((a, b) => a - b);
                if (currentEntries.length < 1) {
                    currentEntries = cacheEntries;
                }
                visibleEntries = currentEntries;
                const minVisible = Math.min(...visibleEntries);
                const maxVisible = Math.max(...visibleEntries);
                for (let i = 1; i <= 5; i++) {
                    visibleEntries.push(maxVisible + i);
                    visibleEntries.push(minVisible - i);
                }
                if (currentEntries.length > 0) {
                    cacheEntries = currentEntries;
                }
                callBackVisibleEntries(visibleEntries);
                saveEntriesToGlobal(currentEntries);
            },
            { root: containerRef.current, rootMargin: "1000px 0px" }
        );

        pageRefs.current.forEach(ref => {
            if (ref) observerRef?.current?.observe(ref);
        });
    }, [activeFileId, resizeCount]);

    useEffect(() => {
        callBackIsLoading(true);

        setLoadMark(`${file ? "fileY" : "fileN"}_${activeFileId}_${startPage}_${window.globalBase64String?.length}`);

        if (!file) return;
        if (file.indexchunks && startPage) {
            setFileIndexChunks(findRange(file.indexchunks, startPage));
        } else {
            setFileIndexChunks([startPage - 1]);
        }

        const cacheBase64Wndw = async (fl: gfile): Promise<boolean> => {
            if (!fl || !fl.fileid || !fl.modified) return false;

            setIsBlobPulling(true);
            process.env.NODE_ENV === "development" && console.log("cacheBase64Wndw", fl);
            if (window.globalPDFCache === undefined) {
                window.globalPDFCache = new Map<
                    string,
                    {
                        base64string: string;
                        fileid: string;
                        name: string;
                        modified: string;
                        pageContent: Array<{ page_num: string; text: string }>;
                        pageDimensions: Array<{ page_number: string; width: number; height: number }>;
                    }
                >();
            }
            if (window.globalPreLoadMap === undefined) window.globalPreLoadMap = new Map<string, string>();

            const cacheKey = pdfCacheKey(fl.fileid, fl.modified);
            const flPath = contentPath(fl.name, fl.fileid);
            if (!window.globalPDFCache.has(cacheKey) && window.globalPreLoadMap.has(cacheKey)) {
                // File loading right now
                process.env.NODE_ENV === "development" && console.log("B64CSH⇒File Loading Right Now");
                return false;
            }
            if (window.globalPDFCache.has(cacheKey)) {
                setLoadingMessage(`CONTENT FETCH ${Math.round(parseInt(fl.size) / (1024 * 1024))}MB`);

                process.env.NODE_ENV === "development" && console.log("XPLRBX¦Cache_Key_Found", cacheKey);
                const cacheVal = window.globalPDFCache.get(cacheKey)?.base64string;
                if (!cacheVal || cacheVal == "" || cacheVal == undefined) {
                    window.globalPDFCache.delete(cacheKey);
                    window.globalPreLoadMap.delete(cacheKey);
                    try {
                        console.log("errr_cache_err_try_again");
                        cacheBase64Wndw(fl);
                    } catch (e) {
                        console.log("errr_cacheBase64Wndw", e);
                    }
                    return false;
                }
                let pgDms = window.globalPDFCache.get(cacheKey)?.pageDimensions;
                if (!pgDms || pgDms.length == 0) pgDms = fl.pagedimensions;

                window.globalPDFPageDimensions = pgDms.map((dim, index) => ({ ...dim, scaledHeight: getDesiredHeight(dim, srcLoc) }));

                window.globalBase64String = `data:application/pdf;base64,${cacheVal}`;
                setOnDemandRefresh(prevNum => prevNum + 1);
                window.globalBase64StringId = cacheKey;
                return true;
            } else {
                window.globalPreLoadMap.set(cacheKey, cacheKey);
                const params = new URLSearchParams({
                    cachemodstring: get_mod_str(fl.modified)
                });
                let flSz = Math.round(parseInt(fl.size) / (1024 * 1024));
                setLoadingMessage(`INITIAL LOAD ${flSz}MB ${flSz > 5 ? "PLEASE WAIT..." : ""}`);

                const base64 = await downloadFile(`/content/${companyId}/${flPath}` + `?${params.toString()}`);
                if (!base64 || base64 == "" || base64 == undefined) {
                    window.globalPDFCache.delete(cacheKey);
                    window.globalPreLoadMap.delete(cacheKey);
                    return false;
                }
                if (fl.pagedimensions) {
                    window.globalPDFPageDimensions = fl.pagedimensions.map((dim, index) => ({ ...dim, scaledHeight: getDesiredHeight(dim, srcLoc) }));
                } else {
                    window.globalPDFPageDimensions = fl.pagedimensions ?? [];
                }

                window.globalBase64String = `data:application/pdf;base64,${base64}`;
                window.globalBase64StringId = cacheKey;
                window.globalPDFCache.set(cacheKey, {
                    base64string: `${base64}`,
                    fileid: fl.fileid,
                    name: fl.name,
                    modified: fl.modified,
                    pageContent: fl.pagecontent,
                    pageDimensions: fl.pagedimensions
                });
                setIsBlobPulling(false);
                setIsLoa(false);
                setProgress(100);
                setLoadMark(`${file ? "fileY" : "fileN"}_${activeFileId}_${startPage}_${window.globalBase64String?.length}`);
                setOnDemandRefresh(prevNum => prevNum + 1);
                return true;
            }
        };

        if (activeFileId != null && activeFileId != "" && activeFileId != undefined) {
            cacheBase64Wndw(file).then((opResult: boolean) => {
                if (opResult == true) {
                    setFileId(activeFileId);
                    setIsLoa(false);
                }
                callBackIsLoading(false);
            });
        } else {
            callBackIsLoading(false);
        }
    }, [activeFileId, resizeCount]);

    useEffect(() => {}, [pageN, fileId]);

    const addToObserver = useCallback((ref: any, index: number) => {
        if (ref) {
            pageRefs.current[index] = ref;
            ref.dataset.pageNumber = (index + 1).toString();
            if (observerRef.current) observerRef.current.observe(ref);
        }
    }, []);

    const pdfOptions = useMemo(
        () => ({
            cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/cmaps/",
            cMapPacked: true,
            standardFontDataUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/standard_fonts/",
            fontExtraProperties: true,
            useSystemFonts: true
        }),
        []
    ); // Empty dependency array since these values don't change

    const preparePdfPage = ({ handleTimeout, nums, setContentList, pdf, startPage, fileId, fileIndexChunks, filePageDimsState }: PDFPageDetails) => {
        const pages = [];
        window.globalItemCounts = new Map<number, { count: number; flag: boolean }>();
        for (let i = 0; i < nums; i++) {
            pages.push(
                <Page
                    key={`pg_${i + 1}`}
                    pageNumber={i + 1}
                    style={{ overflowY: true, behavior: "smooth", border: "1px solid black" }}
                    width={rescaleWidth}
                    loadingText={<div className={styles.loadingText}>LDNG</div>}
                    noData={<div className={styles.loadingText}>LDNG</div>}
                    onGetTextSuccess={(items: any, styles: any) => {
                        // Prepare Page Item Counts for Flagging
                        window.globalItemCounts.set(i, {
                            count: items.items.length,
                            flag: false
                        });
                    }}
                    customTextRenderer={({ pageIndex, pageNumber, itemIndex, str }: gpdftextitem) => {
                        // If Item is Last on Page set Flag to True
                        if (itemIndex == (window.globalItemCounts.get(i)?.count || 0) - 1) {
                            window.globalItemCounts.set(i, {
                                count: window.globalItemCounts.get(i)?.count || 0,
                                flag: true
                            });
                            // If All Page Page Ready Flags are True, Set Loaded to True
                            if (areAllFlagsTrue(window.globalItemCounts, fileIndexChunks)) {
                                if (window.globalAllNessesaryPDFPagesLoaded == false) {
                                    window.globalAllNessesaryPDFPagesLoaded = true;
                                    try {
                                        clearTimeout(timeoutId); // Clear previous timeout
                                    } catch {}
                                    timeoutId = setTimeout(handleTimeout, 100) as unknown as number;
                                }
                            }
                        }
                        return `<span id='CSP_${pageIndex}_${itemIndex}' class='kjgi pdf_itm_${pageIndex}'>${str}</span>`;
                    }}
                />
            );
        }
        return pages;
    };

    const handleLoadSuccess = async (pdfObject: PDFDocumentProxy) => {
        const numPages = pdfObject.numPages;
        window.globalNumPages = numPages;

        const pgs = preparePdfPage({
            handleTimeout: handleTimeout,
            setContentList: setContentList,
            pdf: pdfObject,
            startPage: startPage,
            nums: numPages,
            fileId: fileId,
            fileIndexChunks: fileIndexChunks,
            filePageDimsState: filePageDimsState
        });

        setPgs(pgs);

        console.log("filePageDimsState", filePageDimsState, startPage, window.globalpdfObservableRange);
        setFilePageDimsState(window.globalPDFPageDimensions.map((dim, index) => ({ ...dim, scaledHeight: getDesiredHeight(dim, srcLoc) })));
        setOnDemandRefresh(prevNum => prevNum + 1);
        setTimeout(() => {
            setOnDemandRefresh(prevNum => prevNum + 1);
        }, 100);
        setPages(numPages);
    };

    const downloadFile = async (url: string): Promise<string | null> => {
        try {
            setProgress(undefined);
            const response = await fetch(url);
            if (response && response.body) {
                const reader = response.body.getReader();
                const contentLengthHeader = response.headers.get("kgnfcl");
                const contentLength = contentLengthHeader ? parseInt(contentLengthHeader, 10) : 1;
                let receivedLength = 0;
                const chunks: Uint8Array[] = [];

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    chunks.push(value);
                    receivedLength += value.length;
                    setProgress(receivedLength / contentLength);
                    setLoadingMessage(prevMessage => prevMessage.replace("INITIAL LOAD", "DOWNLOADING ON DEVICE"));
                }
                const allChunks = new Uint8Array(receivedLength);
                let position = 0;
                for (const chunk of chunks) {
                    allChunks.set(chunk, position);
                    position += chunk.length;
                }
                const chunkSize = 0x8000; // 32KB chunks
                let base64 = "";
                for (let i = 0; i < allChunks.length; i += chunkSize) {
                    const chunk = allChunks.subarray(i, i + chunkSize);
                    base64 += String.fromCharCode.apply(null, chunk as unknown as number[]);
                }
                return btoa(base64);
            }
        } catch (err) {
            console.error("download_failed:", err);
        }
        return null;
    };

    const getCurrentPage = (pageDimensions: gpagedimensions[], scrollTop: number, clientHeight: number): number => {
        let cumulativeHeight = 0;
        const middleOfViewport = scrollTop + clientHeight / 2;
        for (let i = 0; i < pageDimensions.length; i++) {
            cumulativeHeight += getDesiredHeight(pageDimensions[i], srcLoc);
            if (cumulativeHeight > middleOfViewport) return i + 1; // +1 because page numbers typically start at 1, not 0
        }
        // Last Page
        return pageDimensions.length;
    };

    const handleScrollCallAdv = (event: any) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        handleScroll(getCurrentPage(filePageDimsState, scrollTop, clientHeight));
        debounce(() => {
            setScrollTr(new Date().toISOString());
        }, 50)();
    };

    const renderedPages = useMemo(() => {
        if (!filePageDimsState) return null;

        return filePageDimsState.map((dim: gpagedimensions, index: number) => {
            const shouldRenderPage =
                (index > startPage - (window.globalpdfObservableRange || 5) && index < startPage + (window.globalpdfObservableRange || 5)) ||
                (window.globalUserScrollLazyLoadEnabled === true && window.globalCurrentVisibleEntries?.includes(index));

            if (shouldRenderPage) {
                //console.log(`Lazy loading page ${index} because it's visible`);

                return (
                    <div style={{ position: "relative" }} key={`${index}_wrp`}>
                        <div
                            key={`${index}_page`}
                            data-load-mark={loadMark}
                            data-p-type={"page"}
                            ref={(ref: any) => addToObserver(ref, index)}
                            style={{
                                width: rescaleWidth,
                                height: dim.scaledHeight,
                                backgroundColor: "#f0f0f0",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            {pgs[index]}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        key={`${index}_mock`}
                        data-load-mark={loadMark}
                        data-p-type={"mock"}
                        ref={(ref: any) => addToObserver(ref, index)}
                        style={{
                            width: rescaleWidth,
                            height: dim.scaledHeight || getDesiredHeight(dim, srcLoc),
                            backgroundColor: "#f0f0f0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    ></div>
                );
            }
        });
    }, [
        filePageDimsState,
        startPage,
        window.globalpdfObservableRange,
        window.globalUserScrollLazyLoadEnabled,
        window.globalCurrentVisibleEntries,
        rescaleWidth,
        addToObserver,
        getDesiredHeight,
        loadMark,
        pgs,
        srcLoc,
        window.globalWigglePixel,
        window.globalHiglightFound,
        window.global_wiggle_stamp
    ]);

    return (
        <div id="pdf-viewer" className={stylesXplrr.pdfViewer}>
            {fileList.length == 0 ? (
                <div className={stylesXplrr.noDocs}>
                    <div className={stylesXplrr.iconNoContent}>
                        <ImFilesEmpty size={24} />
                    </div>
                    <div>Space Empty. Upload documents from the Management Tab</div>
                </div>
            ) : progress != 1 ? (
                <div className={styles.loadingIndicatorContainer}>
                    <div
                        key={rescaleWidth}
                        className={`${
                            srcLoc == "modal"
                                ? stylesXplrr.loadingIndicator
                                : srcLoc == "sbs"
                                ? stylesXplrr.loadingIndicatorStatic
                                : srcLoc == "inv" && file?.invoicedata != null
                                ? stylesXplrr.loadingIndicatorInv
                                : srcLoc == "inv"
                                ? stylesXplrr.loadingIndicatorInvEmpty
                                : stylesXplrr.loadingIndicatorPreLoad
                        } `}
                    >
                        <ProgressIndicator percentComplete={progress} barHeight={20} progressHidden={progress == 1 ? true : false} />
                    </div>
                    <div
                        className={`${
                            srcLoc == "modal"
                                ? stylesXplrr.loadingInfo
                                : srcLoc == "sbs"
                                ? stylesXplrr.loadingInfoStatic
                                : srcLoc == "inv" && file?.invoicedata != null
                                ? stylesXplrr.loadingInfoStatic
                                : srcLoc == "inv"
                                ? stylesXplrr.loadingInfoStaticEmpty
                                : stylesXplrr.loadingInfo
                        }`}
                    >
                        {loadingMessage}
                        {loadingMessage == "ERROR LOADING PDF" ? null : (
                            <div className={stylesXplrr.typingDots}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
            {window.globalBase64String == "data:application/pdf;base64," ? null : (
                <div id={fileId} className={stylesXplrr.pdfViewerSub} ref={containerRef}>
                    <Document
                        key={rescaleWidth}
                        className={stylesXplrr.pdfViewerMainXPL}
                        file={`${window.globalBase64String}`}
                        onLoadSuccess={handleLoadSuccess}
                        options={pdfOptions}
                        noData={<div className={stylesXplrr.loadingText}>Loading...</div>}
                        loading={<div className={stylesXplrr.loadingText}>Loading...</div>}
                        onLoadError={(error: any) => {
                            setLoadingMessage("ERROR LOADING PDF");
                        }}
                        error={
                            <div className={stylesXplrr.errorPDFLoadSystemMsg}>
                                <div className={stylesXplrr.iconNoContent}>
                                    <MdErrorOutline size={26} />
                                </div>
                                <div>Error Loading PDF</div>
                            </div>
                        }
                    >
                        <div className={stylesXplrr.pdfDocContainer}>
                            <div key={"pdfmainid9" + resizeCount} className={stylesXplrr.pdfMain} id="pdfmainid9" onScroll={handleScrollCallAdv}>
                                {renderedPages}
                            </div>
                        </div>
                    </Document>
                </div>
            )}
        </div>
    );
};
export default XplrrBOXPDF;
