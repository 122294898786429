import {
    gfile,
    ggroup,
    gselectedgroup,
    gConf,
    guser,
    gactivecomp,
    gdynfilter,
    gqasimplified,
    empty_contenttype_config,
    empty_convo_config,
    empty_extraction_field_list,
    empty_spo_config
} from "../../interfaces";
import { assistantCodes } from "../../lsts";
import { ChatAppRequest, ResponseMessage } from "../../api";
import { ChatAppResponse, ChatAppResponseOrError, ResponseChoice, RetrievalMode } from "../../api";
import * as DarkReader from "darkreader";

export const groupListToSelectedGroup = (group?: ggroup) => (selectedgroup: gselectedgroup) => {
    return {
        selectionId: group ? group.groupid || "" : "",
        selectionText: group ? group.groupname || "" : "",
        permissionlevel: group ? group.permissionlevel || 0 : 0,
        description: group ? group.description || "" : "",
        sharewithcode: group ? group.sharewithcode || false : false,
        filecount: group ? group.filecount || 0 : 0,
        custaiinst: group ? group.custaiinst || "" : "",
        custgreetingmessage: group ? group.custgreetingmessage || "" : "",
        isintegrateddata: group ? group.isintegrateddata || 0 : 0,
        allowyoutube: group ? group.allowyoutube ?? 1 : 1,
        allowweb: group ? group.allowweb ?? 1 : 1,
        enabledocaudio: group ? group.enabledocaudio ?? 1 : 1,
        retrievecount: group ? group.retrievecount ?? 5 : 5,
        sectionoverlap: group ? group.sectionoverlap ?? 200 : 200,
        maxsectionlength: group ? group.maxsectionlength ?? 3000 : 3000,
        sentencesearchlimit: group ? group.sentencesearchlimit ?? 200 : 200,
        inscope: group ? group.inscope ?? 1 : 1,
        temperature: group ? group.temperature ?? 0.7 : 0.7,
        responsetokenlimit: group ? group.responsetokenlimit ?? 3024 : 3024,
        disablecitationhighlighting: group ? group.disablecitationhighlighting ?? 0 : 0,
        strictness: group ? group.strictness ?? 3 : 3,
        invoicesgroup: group ? group.invoicesgroup ?? 0 : 0,
        questionsuggestions: group ? group.questionsuggestions ?? 0 : 0,
        nosidebysideview: group ? group.nosidebysideview ?? 0 : 0,
        isowner: group ? group.isowner ?? 0 : 0,
        dailymsgs: group ? group.dailymsgs ?? 0 : 0,
        premiumby: group ? group.premiumby ?? "" : "",
        oid: group ? group.oid ?? 0 : 0,
        tier: group ? group.tier ?? 0 : 0,
        premwhen: group ? group.premwhen ?? 0 : 0,
        companyid: group ? group.companyid ?? "" : "",
        apikey: group ? group.apikey ?? "" : "",
        producecontentlist: group ? group.producecontentlist ?? 0 : 0,
        producequestions: group ? group.producequestions ?? 0 : 0,
        assistanttype: group ? group.assistanttype ?? "anya" : "anya",
        showexamplequestionsonstart: group ? group.showexamplequestionsonstart ?? 0 : 0,
        headerimageurl: group ? group.headerimageurl ?? "" : "",
        manualquestionsuggestions: group ? group.manualquestionsuggestions ?? "" : "",
        usedocintel: group ? group.usedocintel ?? 0 : 0,
        extractionfieldlist: group ? group.extractionfieldlist ?? empty_extraction_field_list : empty_extraction_field_list,
        allowaudiodocentry: group ? group.allowaudiodocentry ?? 0 : 0,
        forceocr: group ? group.forceocr ?? 0 : 0,
        allowvideofiles: group ? group.allowvideofiles ?? 0 : 0,
        convomode: group ? group.convomode ?? 0 : 0,
        convolang: group ? group.convolang ?? "auto" : "auto",
        hideaisearch: group ? group.hideaisearch ?? 0 : 0,
        docextractionconfig: group ? group.docextractionconfig ?? "" : "",
        docextractionuseauto: group ? group.docextractionuseauto ?? 0 : 0,
        landingpage: group ? group.landingpage ?? 0 : 0,
        urllinkextraction: group ? group.urllinkextraction ?? 0 : 0,
        spo_config: group ? group.spo_config ?? empty_spo_config : empty_spo_config,
        convo_config: group ? group.convo_config ?? empty_convo_config : empty_convo_config,
        contenttype_config: group ? group.contenttype_config ?? empty_contenttype_config : empty_contenttype_config
    };
};

export const getTokenMail = (token: any) => {
    return token.idTokenClaims?.emails?.length > 0 ? token.idTokenClaims?.emails[0] : token.idTokenClaims?.email;
};
// Dark Mode
export const handleIsDark = (isDark: boolean) => {
    if (isDark == true) {
        DarkReader.enable({
            mode: 1,
            brightness: 100,
            contrast: 100,
            grayscale: 0,
            sepia: 0,
            useFont: false,
            fontFamily: "Urbanist, Roboto",
            textStroke: 0,
            darkSchemeBackgroundColor: "#252525",
            darkSchemeTextColor: "#e8e6e3",
            lightSchemeBackgroundColor: "#dcdad7",
            lightSchemeTextColor: "#181a1b",
            scrollbarColor: "auto",
            selectionColor: "auto",
            styleSystemControls: true
        });
    } else {
        DarkReader.disable();
    }
};
// File Cache Related
export const getLatestModifiedFile = (flCache?: gfile[]) => {
    return flCache?.reduce((latest, current) => {
        const currentDate = new Date(current.modified);
        return currentDate > new Date(latest.modified) ? current : latest;
    });
};
// Citations Related
export const extractPageValue = (fileCitationPath: string) => {
    const match = fileCitationPath.match(/page=(\d+)/);
    return match ? match[1] : "";
};
// Citations Related
export const extractFileIDValue = (fP: string) => {
    let fid = "";
    const fidSplit = fP ? fP.split("_fid=") : [];
    if (fidSplit.length > 1) {
        return fidSplit[1].trim();
    } else {
        return "";
    }
};
// Initial Load Related
export const getTokenAccountID = (token: any) => {
    return token.uniqueId || token.localAccountId || token.oid;
};
export const cleanFileIDString = (fileP: string) => {
    // ViomeRecommendations_F2849CD32684.pdf#page=6_fid=379, ViomeRecommendations_F2849CD32684.pdf_page=31_fid=379
    return fileP.replace("#", "_");
};
export const checkWeAreInGeneralPlace = (isLib: string) => {
    return isLib == "grp" || isLib == "acc" || isLib == "adm";
};
export const getCitationFileId = (fileName: string, fileId: string, filePage: string = "1") => {
    return `${fileName}#page=${filePage}_fid=${fileId}`;
};
export const getFileContentList = (file: gfile) => {
    try {
        let prsd = JSON.parse(file.contentlistai || "{content_table:[]}")?.content_table;
        return prsd || [];
    } catch (e) {
        return [];
    }
};
export const joinShortSentences = (arr: string[]) => {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
        const current = arr[i];
        if (current.length < 5) {
            if (i === 0 && arr.length > 1) {
                // IF FIRST, COMBINE WITH NEXT
                arr[i + 1] = current + "" + arr[i + 1];
            } else if (result.length > 0) {
                // NOT FIRST, COMBINE WITH PREVIOUS
                result[result.length - 1] += "" + current;
            }
        } else {
            result.push(current);
        }
    }
    return result;
};
export const removeRefTags = (txt: string) => {
    return txt.replace(/\[doc\d*[\s\S]*?(?:\]|$)/g, "");
};
export const removeRefTagsClosedOnly = (txt: string) => {
    // matches [doc1 integrated data tags
    return txt.replace(/\[doc\d*[\s\S]*?\]/g, "");
};
export const hasSentenceEnder = (txt: string) => /[.!?:]/.test(txt);

export const hasSentenceEnderPlusComma = (txt: string) => /[.!?:]/.test(txt);

export const splitIntoSentences = (txt: string) => {
    try {
        return txt
            .replace(/\.\.\./g, "§") // REPLACE ... WITH PLACEHOLDER
            .match(/[^.!?:]+[.!?:]+|[^.!?:]+$/g) // SPLIT INTO SENTENCES
            ?.map((s: any) => s.replace(/§/g, "...").trim()) // Restore ...
            .filter(Boolean); // REMOVE EMPTY STRINGS
    } catch {
        return [];
    }
};
export const splitIntoSentencesPlusCommas = (txt: string) => {
    try {
        return txt
            .replace(/\.\.\./g, "§") // Replace "..." with a placeholder
            .match(/[^.!?:,]+[.!?:,]+|[^.!?:,]+$/g) // Updated regex to include commas
            ?.map((s: any) => s.replace(/§/g, "...").trim()) // Restore "..." and trim
            .filter(Boolean); // Remove empty strings
    } catch {
        return [];
    }
};

export const getChunkRandomId = () => {
    return Date.now().toString() + Math.random().toString(36).substring(2, 8);
};
export const endsWithPunctuation = (str: string) => {
    return str.endsWith(".") || str.endsWith("!") || str.endsWith("?");
};
export const handleNonStreamChat = async (response: any, question: any, answers: [user: string, response: ChatAppResponse][]) => {
    const ResponseContext = await response.json();
    const joinedAnswers = ResponseContext.data_points
        .map((a: string) => {
            const [documentName, content] = a.split(/:(.+)/);
            return `[${documentName}]<div>${content}</div>`;
        })
        .join("");
    const emptyResponseChoice: ResponseChoice = {
        index: 0,
        message: { content: joinedAnswers, role: "assistant" },
        context: {
            thoughts: "",
            data_points: ResponseContext.data_points_captions,
            data_points_captions: ResponseContext.data_points_captions,
            data_points_integrated: ResponseContext.data_points_integrated,
            followup_questions: [],
            response_tokens: 0,
            prompt_tokens: 0,
            message_type: "",
            sql_query: "",
            chartjs_json: "",
            sql_result: "",
            no_info_message: {},
            collected_data: {}
        },
        session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
    };
    const emptyChatAppResponse: ChatAppResponse = {
        choices: [emptyResponseChoice],
        datecode: new Date().toISOString(),
        interaction_guid: "",
        pos: null
    };
    const parsedResponse: ChatAppResponseOrError = emptyChatAppResponse;
    if (response.status > 299 || !response.ok) throw Error(parsedResponse.error || "Unknown error");
    return { question: question, parsedResponse: parsedResponse };
};
export const get_ovrrds = (
    selectedGroup: gselectedgroup,
    config: gConf,
    user: guser,
    activeComp: gactivecomp,
    activeConvoKey: string,
    dynFiltering: gdynfilter,
    additional_string_data: string,
    current_intent: string,
    msg_ovvride: string
) => {
    console.log("msg_ovvride", msg_ovvride, current_intent);
    let requstForInfo = false;
    if (window.globalRequestForInfo && window.globalRequestForInfo == true) {
        requstForInfo = true;
        window.globalRequestForInfo = false;
    }
    let scenarios = {};
    let response_ovrrd = "";
    if (selectedGroup.assistanttype == assistantCodes.cca) {
        scenarios = selectedGroup?.convo_config?.scenarios;
        if (current_intent == "greeting") {
            response_ovrrd = selectedGroup.convo_config.greeting;
        } else {
            response_ovrrd = msg_ovvride;
        }
    }

    return {
        prompt_template: selectedGroup.custaiinst,
        exclude_category: undefined,
        include_category: dynFiltering.category.length === 0 ? undefined : dynFiltering.category,
        top: selectedGroup.retrievecount,
        retrieval_mode: config.retrievalMode as RetrievalMode,
        highlights_as_source: config.useHighlightsAsSource,
        suggest_followup_questions: selectedGroup.questionsuggestions == 1 ? true : undefined,
        use_oid_security_filter: false,
        use_groups_security_filter: false,
        user_id: dynFiltering.groups,
        inscope: selectedGroup.inscope,
        temperature: selectedGroup.temperature,
        responsetokenlimit: selectedGroup.responsetokenlimit,
        disablecitationhighlighting: selectedGroup.disablecitationhighlighting,
        strictness: selectedGroup.strictness,
        companyid: activeComp.companyid,
        oid: selectedGroup.oid,
        invoicesgroup: selectedGroup.invoicesgroup,
        uid: user.userId,
        assistanttype: selectedGroup.assistanttype,
        docextractionconfig: selectedGroup.assistanttype == assistantCodes.cca ? selectedGroup.docextractionconfig : undefined,
        requstforinfo: requstForInfo,
        convoid: activeConvoKey,
        convostamp: window.convoPerfOnRecogFin,
        kasd: additional_string_data,
        current_intent: current_intent,
        response_ovrrd: response_ovrrd,
        scenarios: scenarios,
        active_scenario_id: window.global_active_scenario_id
    };
};
export const determineCategoryAndGroups = (selectedGroup: gselectedgroup, includeCategory: string, includeCategoryGroups: string) => {
    let cat = "";
    let groups = "";
    if (selectedGroup.selectionId == "FileSelect") {
        cat = includeCategory; // Set File Filter to Selected Files
        groups = includeCategoryGroups; // Set Group Filter to Selected File's Groups
    } else {
        cat = "";
        groups = selectedGroup.selectionId;
    }
    return { category: cat, groups: groups };
};
export const emptyIsPDFOpen = {
    defaultFile: "",
    startPage: "1",
    isOpen: false,
    searchString: "",
    contentlist: [
        {
            fileid: "",
            srcPageNum: 0,
            srcText: "",
            destPageId: 0,
            destPageNum: 0
        }
    ],
    pagemap: {},
    fileId: "",
    refPageNum: "",
    isCitation: false,
    rndo: "",
    activeGroupId: "",
    fileList: [],
    fl: undefined
};
export const emptyIsPDFDiffOpen = {
    defaultFile1: "",
    defaultFile2: "",
    isOpen: false,
    searchString: "",
    fileId1: "",
    fileId2: "",
    refPageNum: "",
    isCitation: false,
    rndo: "",
    activeGroupId: "",
    fileList: [],
    fl1: undefined,
    fl2: undefined,
    diff: undefined
};
export const prep_question_and_answers_list = (answers: [user: string, response: ChatAppResponse][]): gqasimplified[] => {
    let question_answer_simplified_list: gqasimplified[] = [];
    for (let i = 0; i < answers.length; i++) {
        let question = answers[i][0];
        let answer = answers[i][1];
        question_answer_simplified_list.push({
            role: "user",
            content: question
        });
        question_answer_simplified_list.push({
            role: "assistant",
            content: answer.choices[0].message.content
        });
    }
    return question_answer_simplified_list;
};
export const preserve_convo_collected_data = (askResponse: ChatAppResponse) => {
    if (!window.global_convo_collected) window.global_convo_collected = {};

    if (askResponse?.choices[0]?.context?.collected_data) {
        try {
            let collected_data = JSON.parse(askResponse?.choices[0]?.context?.collected_data);
            window.global_gen_tr = collected_data;
            for (const key of Object.keys(collected_data)) {
                window.global_convo_collected[key] = collected_data[key];
            }
        } catch (e) {
            console.log("errror parsing collected data:", e);
        }
    }
};

export const create_chatapprequest = (
    question: string,
    answers: [user: string, response: ChatAppResponse][],
    ovrrds: any,
    inplaceupdate: boolean
): ChatAppRequest => {
    console.log("⇒inplaceupdate", inplaceupdate);
    let messages: ResponseMessage[] = (inplaceupdate == true ? answers.slice(0, answers.length - 1) : answers).flatMap(a => [
        { content: a[0], role: "user" },
        { content: a[1].choices[0].message.content, role: "assistant" }
    ]);
    let contnt = inplaceupdate == true ? answers[answers.length - 1][0] + question : question;
    const request: ChatAppRequest = {
        messages: [...messages, { content: contnt, role: "user" }],
        stream: true,
        use_integrated_search: true,
        context: { overrides: ovrrds },
        session_state: answers.length
            ? inplaceupdate == false
                ? answers[answers.length - 1]?.[1]?.choices[0].session_state
                : answers.length > 2
                ? answers[answers.length - 2]?.[1]?.choices[0].session_state
                : answers.length > 0
                ? answers[answers.length - 1]?.[1]?.choices[0].session_state
                : null
            : null
    };
    return request;
};
