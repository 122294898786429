import { useState, useEffect, useRef } from "react";
import { pdfjs } from "react-pdf";
import { FaWindowClose } from "react-icons/fa";
import MarkdownIt from "markdown-it";
import styles from "./XplrrPDF.module.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import stringSimilarity from "string-similarity";

import { ThemeProvider, IconButton, Callout } from "@fluentui/react";
import customTheme from "./../../theme/customTheme";

import { gdiff, gfile, gselectedgroup, gdiffresponse, gpagedimensions, gdiffparagraphresult, gvaliddifftag } from "../../interfaces";
import { getDesiredWidthDiff, getDesiredHeightDiff } from "../../util_glob";
import { diffOvrvuCalloutStyles } from "../../styles_glob";
import { convertMarkdownHeadersToHTMLWithNewLines } from "../../util_glob";
import PageSelector from "../PageSelector/PageSelector";
import XplrrBOXDIFFPDF from "../XplrrBOXPDF/XplrrBOXDIFFPDF";
import { MdOutlineQuickreply } from "react-icons/md";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const md = new MarkdownIt();
type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};
type CaptionWithoutContent = {
    filename: string;
    page: string;
    fileid: string;
};
const tableRegex = /\|(.+?)\|\s*\n\|(-+?\s*\|)+\s*\n((\|(.+?)\|\s*\n)*)/g;

interface ViewerPDFProps {
    fileItem1: gfile | undefined;
    fileItem2: gfile | undefined;
    selectedGroup: gselectedgroup;
    rndo: string;
    diffRes?: gdiffresponse[];
    onDiffPDFDismiss: () => void;
}

const XplrrPDFDiff = ({ fileItem1, fileItem2, selectedGroup, rndo, diffRes, onDiffPDFDismiss }: ViewerPDFProps) => {
    const [file1, setFile1] = useState<gfile>();
    const [file2, setFile2] = useState<gfile>();

    const [diffRes1, setDiffRes1] = useState<gdiffparagraphresult | undefined>(
        diffRes ? diffRes[0].paragraphs.find(item => `${item?.fid}` == fileItem1?.fileid) : undefined
    );
    const [diffRes2, setDiffRes2] = useState<gdiffparagraphresult | undefined>(
        diffRes ? diffRes[0].paragraphs.find(item => `${item?.fid}` == fileItem2?.fileid) : undefined
    );
    //const refValidTags = useRef<Map<string, gvaliddifftag>>(new Map());
    const [diffOvrvu, setDiffOvrvu] = useState(diffRes ? diffRes[0].overview : "");
    const [currentPage1, setCurrentPage1] = useState(0);
    const [currentPage2, setCurrentPage2] = useState(0);
    const [numPages1, setNumPages1] = useState<number>();
    const [numPages2, setNumPages2] = useState<number>();
    const [pageLoaded1, setPageLoaded1] = useState(false);
    const [pageLoaded2, setPageLoaded2] = useState(false);
    const [overvuCalloutOpen, setOvervuCalloutOpen] = useState(false);
    const [manualSection, setManualSecton] = useState<number>();

    useEffect(() => {
        setFile1(fileItem1);
        setFile2(fileItem2);
        setDiffRes1(diffRes ? diffRes[0].paragraphs.find(item => `${item?.fid}` == fileItem1?.fileid) : undefined);
        setDiffRes2(diffRes ? diffRes[0].paragraphs.find(item => `${item?.fid}` == fileItem2?.fileid) : undefined);
    }, [fileItem1, fileItem2]);

    const INCHES_TO_POINTS = 72;

    function markdownTableToHtmlOnly(markdown: string): string {
        // Replace tables in the markdown string with HTML, leaving other parts untouched
        return markdown.replace(tableRegex, (match: string) => {
            return md.render(match); // Apply markdown-it rendering only to the table section
        });
    }

    const handleTimeout = (fileid: string) => {
        console.log("handleTimeout", fileid);
        // window.globalDiff = diff || [];
        if (diffRes == undefined) return;

        console.log("diff", diffRes);
        if (fileid == `${file1?.fileid}`) {
            setPageLoaded1(true);
        } else if (fileid == `${file2?.fileid}`) {
            setPageLoaded2(true);
        }
    };
    const handlePageChangeFromPageSelector1 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;
        setManualSecton(Number(event.target.value));
        setCurrentPage1(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value), file1?.fileid || "0");
        reendableUserScrollHandling();
    };
    const handlePageChangeFromPageSelector2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;
        setManualSecton(Number(event.target.value));
        setCurrentPage2(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value), file2?.fileid || "0");
        reendableUserScrollHandling();
    };
    const reendableUserScrollHandling = (tm: number = 500) => {
        setTimeout(() => (window.globalUserScrollLazyLoadEnabled = true), tm);
    };
    const scrollToPageNoSmooth = (pN: number, targetFile: string) => {
        if (targetFile == "0") return;
        var node = document.querySelector(`[data-diff-page="DDP_${targetFile}_${pN - 1}"`);
        if (node) node.scrollIntoView({ behavior: "auto", block: "center" });
    };
    const handleScroll1 = (event: any, pageNumCur: number) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        setCurrentPage1(pageNumCur);
        const element = document.getElementById(`pdfmainid9${fileItem2?.fileid}`);
        if (element) {
            const scrollRatio = scrollTop / (scrollHeight - clientHeight);
            const targetScrollTop = Math.round(scrollRatio * (element.scrollHeight - element.clientHeight));
            element.scrollTop = targetScrollTop;
        }
    };
    const handleScroll2 = (event: any, pageNumCur: number) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        setCurrentPage2(pageNumCur);
        const element = document.getElementById(`pdfmainid9${fileItem1?.fileid}`);
        if (element) {
            const scrollRatio = scrollTop / (scrollHeight - clientHeight);
            const targetScrollTop = Math.round(scrollRatio * (element.scrollHeight - element.clientHeight));
            element.scrollTop = targetScrollTop;
        }
    };
    const handleOvrvuCalloutDismiss = () => {
        setOvervuCalloutOpen(false);
    };
    const callBackVisibleEntries = (visibleEntries: number[]) => {};
    const setContentList = (contentList: { title: string; dest: number }[]) => {};
    const callBackIsLoading = (isLoading: boolean) => {};
    const closeButStyles = {
        root: {
            borderRadius: "5px",
            color: "orange"
        }
    };
    return (
        <div className={styles.xplrrPDFMain}>
            <div className={styles.xplrrPDFMainInternalWrap}>
                <IconButton
                    onClick={() => {
                        setOvervuCalloutOpen(true);
                    }}
                    title="Overview"
                    styles={closeButStyles}
                    className={styles.overvuButDiff}
                >
                    <MdOutlineQuickreply size={25} />
                </IconButton>
                <IconButton
                    onClick={() => {
                        onDiffPDFDismiss();
                    }}
                    title="Close"
                    styles={closeButStyles}
                    className={styles.closeButDiffIC}
                >
                    <FaWindowClose size={35} />
                </IconButton>
                <ThemeProvider theme={customTheme}>
                    <div className={styles.pdfWrapXplrr} id="pdfWrapXplrrIDENT">
                        <div className={styles.pageSelectorXplrr}>
                            <div className={styles.pageSelectorOnly}>
                                <PageSelector
                                    currentPage={currentPage1}
                                    numPages={numPages1 ? numPages1 : 0}
                                    handlePageChange={handlePageChangeFromPageSelector1}
                                ></PageSelector>
                            </div>
                            <div className={styles.totPagesDisplay}>/ {numPages1}</div>
                        </div>

                        <div className={`${styles.pdfViewDiff1}`}>
                            <XplrrBOXDIFFPDF
                                key={`rndo_${rndo}_${file1?.fileid}`}
                                file={file1}
                                handleTimeout={handleTimeout}
                                handleScroll={handleScroll1}
                                setPages={(numPages: number) => {
                                    setNumPages1(numPages);
                                }}
                                startPage={1}
                                callBackVisibleEntries={callBackVisibleEntries}
                                setContentList={setContentList}
                                callBackIsLoading={callBackIsLoading}
                                companyId={selectedGroup.companyid}
                                srcLoc={"inv"}
                                diffPar={diffRes1}
                            />
                        </div>

                        <div className={`${styles.pdfViewDiff2}`}>
                            <XplrrBOXDIFFPDF
                                key={`rndo_${rndo}_${file2?.fileid}`}
                                file={file2}
                                handleTimeout={handleTimeout}
                                handleScroll={handleScroll2}
                                setPages={(numPages: number) => {
                                    setNumPages2(numPages);
                                }}
                                startPage={1}
                                callBackVisibleEntries={callBackVisibleEntries}
                                setContentList={setContentList}
                                callBackIsLoading={callBackIsLoading}
                                companyId={selectedGroup.companyid}
                                srcLoc={"inv"}
                                diffPar={diffRes2}
                            />

                            <div className={styles.pageSelectorXplrr}>
                                <div className={styles.pageSelectorGroup}>
                                    <div className={styles.pageSelectorOnly}>
                                        <PageSelector
                                            currentPage={currentPage2}
                                            numPages={numPages2 ? numPages2 : 0}
                                            handlePageChange={handlePageChangeFromPageSelector2}
                                        ></PageSelector>
                                    </div>
                                    <div className={styles.totPagesDisplay}>/ {numPages2}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Callout
                        gapSpace={0}
                        styles={diffOvrvuCalloutStyles}
                        onDismiss={handleOvrvuCalloutDismiss}
                        setInitialFocus
                        className={styles.calloutDiffOverview}
                        hidden={!overvuCalloutOpen}
                        target={`.${styles.overvuButDiff}`}
                        title="Overview"
                    >
                        <div className={styles.calloutContent}>
                            <div className={styles.overvuContent}>
                                <div className={styles.overvuContentInner}>
                                    <div className={styles.overvuContentTitle}>Differences Overview</div>
                                    <div
                                        className={styles.overvuContentInnerTxt}
                                        dangerouslySetInnerHTML={{
                                            __html: markdownTableToHtmlOnly(convertMarkdownHeadersToHTMLWithNewLines(diffOvrvu))?.replace(
                                                new RegExp("```html|```", "g"),
                                                ""
                                            )
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </Callout>
                </ThemeProvider>
            </div>
        </div>
    );
};

export default XplrrPDFDiff;
