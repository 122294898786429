import React, { useState } from "react";
import { Checkbox, Stack, PrimaryButton, ThemeProvider } from "@fluentui/react";
import styles from "./FieldSelectionForInvoices.module.css";
import { stackStyles, textFieldInvoiceFieldChooser } from "../../styles_glob";
import { finFieldList } from "../../lsts";
import { gselectedgroup, gloggedinuser, ginvoicefieldconf, ginvoiceconfinstructions } from "../../interfaces";
import customTheme from "./../../theme/customTheme";
import { qSaveExtractionFieldList } from "../../api/qpi";
import { TextField } from "@fluentui/react/lib/TextField";

interface FieldSelectionForInvoicesProps {
    selectedGroup: gselectedgroup;
    loggedInUser: gloggedinuser;
    extractionfieldlist: ginvoicefieldconf;
    onEditSpaceSave: () => void;
}

const FieldSelectionForInvoices: React.FC<FieldSelectionForInvoicesProps> = ({ selectedGroup, loggedInUser, extractionfieldlist, onEditSpaceSave }) => {
    const [selectedFields, setSelectedFields] = useState<string[]>(
        extractionfieldlist?.excl?.length > 0
            ? finFieldList.filter(field => !extractionfieldlist.excl.includes(field.id)).map(field => field.id)
            : finFieldList.filter(field => field.default).map(field => field.id)
    );
    console.log("extractionfieldlist", extractionfieldlist);
    const [fieldInstructions, setFieldInstructions] = useState<ginvoiceconfinstructions[]>(extractionfieldlist?.instr || []);
    const [isLoading, setIsLoading] = useState(false);
    const toggleFieldSelection = (fieldId: string) => {
        setSelectedFields(prev => (prev.includes(fieldId) ? prev.filter(id => id !== fieldId) : [...prev, fieldId]));
    };

    const updateInstruction = (fieldId: string, newInstr: string, level: string) => {
        setFieldInstructions(prevInstructions => {
            // Check if the instruction exists
            const exists = prevInstructions.some(instruction => instruction.fieldid === fieldId);

            if (exists) {
                return prevInstructions.map(instruction => (instruction.fieldid === fieldId ? { ...instruction, instr: newInstr, level: level } : instruction));
            } else {
                return [...prevInstructions, { fieldid: fieldId, instr: newInstr, level: level }];
            }
        });
    };

    const savePreferences = () => {
        setIsLoading(true);

        let reverseFileds = finFieldList.filter(field => !selectedFields.includes(field.id)).map(field => field.id);
        let cleanedInstructions = fieldInstructions.filter(instruction => instruction.instr && instruction.instr.trim() !== "");
        let fieldConf: ginvoicefieldconf = {
            excl: reverseFileds || [],
            instr: cleanedInstructions || []
        };
        qSaveExtractionFieldList(loggedInUser, selectedGroup, fieldConf).then(() => {
            setIsLoading(false);
            onEditSpaceSave();
        });
    };

    const renderFields = (level: "main" | "item") =>
        finFieldList
            .filter(field => field.level === level)
            .map((field, index) => (
                <div key={`itf_${level}_${index}`} className={styles.invSetItemContainer}>
                    <Stack horizontal style={{ width: "90vw", maxWidth: "800px" }}>
                        <Stack.Item>
                            <div className={styles.invLabelDetail}>
                                <Checkbox
                                    className={styles.invSetItemCheckbox}
                                    key={field.id}
                                    label={field.name}
                                    disabled={field.systemDisabled}
                                    checked={selectedFields.includes(field.id)}
                                    onChange={() => toggleFieldSelection(field.id)}
                                />
                                <div>{field.description && <div className={styles.invSetItemDetails}>{field.description}</div>}</div>
                            </div>
                        </Stack.Item>
                        <Stack.Item style={{ width: "100%", maxWidth: "800px" }}>
                            <div className={styles.invTextCont}>
                                <TextField
                                    className={styles.invTextFieldFieldChooser}
                                    styles={textFieldInvoiceFieldChooser}
                                    description=""
                                    multiline
                                    value={fieldInstructions.find(instruction => instruction.fieldid === field.id)?.instr || ""}
                                    disabled={!selectedFields.includes(field.id)}
                                    resizable={false}
                                    maxLength={100}
                                    onChange={(e, newValue) => updateInstruction(field.id, newValue || "", level)}
                                />
                            </div>
                        </Stack.Item>
                    </Stack>
                </div>
            ));

    return (
        <div className={styles.invSetMainWrap}>
            <Stack styles={stackStyles} tokens={{ childrenGap: 10 }} style={{ width: "90vw", maxWidth: "800px" }} key="fsonefs">
                <div className={styles.invSetSectionTitle}>
                    <span>Field Selection & Custom AI Instructions</span>
                </div>
                <div className={styles.invSetDedcription}>
                    Select the fields that will get extracted from your Invoice. Some essential fields cannot be disabled.
                </div>
                <div className={styles.invSetDedcriptionMid}>
                    Custom instructions can be added for each field with general instructions about what AI should expect and return. These instructions are
                    optional and intended for cases not yet covered out-of-the-box. Keep instructions short and to the point.
                </div>
                <div className={styles.invSetDedcriptionEnd}>Degraded extraction performance may result from custom instructions. </div>

                <div className={styles.invSetHorizontalLine} />
                <ThemeProvider theme={customTheme}>
                    <div className={styles.invSetCustLabelTagInput}>
                        <div className={styles.invFieldTitleOne}>Main Fields</div>
                        <div className={styles.invFieldTitleTwo}>Custom Instructions</div>
                    </div>

                    <Stack key={"mainfielditems"} tokens={{ childrenGap: 5 }}>
                        {renderFields("main")}
                    </Stack>

                    <div className={styles.invSetHorizontalLine} />

                    <div className={styles.invSetCustLabelItemInput}>
                        <span>Item-Level Fields</span>
                    </div>

                    <Stack key={"itemfielditems"} tokens={{ childrenGap: 5 }}>
                        {renderFields("item")}
                    </Stack>
                </ThemeProvider>
            </Stack>

            <div className={styles.invSetModalBottomButs}>
                <div></div>
                <PrimaryButton text="Save" onClick={savePreferences} disabled={isLoading} className={styles.invSetSaveSpaceButton} />
            </div>
        </div>
    );
};

export default FieldSelectionForInvoices;
