import { ChatAppRequest, IUploadResponse } from "./models";
import { newConvoStamp } from "../util_glob";
import { toast } from "react-toastify";
import {
    gselectedgroup,
    greindexparams,
    gingestioniteminfo,
    gloggedinuser,
    gtranscriptentry,
    gtranscriptobject,
    gtranscriptpublish,
    gdiff,
    gChatlogItem,
    gfile,
    gautoparagraphresult,
    gdiffresponse,
    gailinks,
    gfanalyse,
    gjsonprop,
    gqasimplified,
    qconvoupload
} from "../interfaces";
import { getHeaderG } from "./qpi";

function getHeaders(): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    return headers;
}
export async function chatApi(request: ChatAppRequest, loggedInUser: gloggedinuser): Promise<Response> {
    console.log("chat_api", newConvoStamp() - window.globalConvPerfRecog);

    return await fetch(`/chat`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(request)
    });
}
export async function deleteFile(fileName: string, fileid: string, header: any, companyid: string, progressCallback?: () => void): Promise<IUploadResponse> {
    const toastId = toast("Deleting Files. Prepping...", { autoClose: false });
    const response = await fetch("/file_remove", {
        method: "POST",
        headers: header,
        body: JSON.stringify({ fileName: fileName, fileid: fileid, companyid: companyid })
    });
    if (!response.ok) {
        throw new Error(`Delete File Failed: ${response.statusText}`);
    }

    toast.update(toastId, { render: "Deleting...", autoClose: false });

    const dataResponse: any = await response.json();
    const taskId = dataResponse.task_id;

    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const fetchDelTaskStatus = async (taskId: string) => {
        const statusResponse = await fetch(`/task-status/${taskId}`);
        if (!statusResponse.ok) {
            throw new Error(`Fetching Task Status Failed: ${statusResponse.statusText}`);
        }
        return await statusResponse.json();
    };
    let taskCompleted = false;
    let taskResult = false;
    let taskMessage = "";

    while (!taskCompleted) {
        await wait(1000);
        const chkResp = await fetchDelTaskStatus(taskId);
        import.meta.env.DEV === true && console.log("deleteFileApi¦Next Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 2000
            });
        } else {
            import.meta.env.DEV === true && console.log("Toast", `Deleting File...`);
            if (progressCallback) {
                progressCallback();
            }
            toast.update(toastId, {
                render: chkResp.message
            });
        }
    }

    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}
export async function reindexApi(props: greindexparams, progressCallback?: () => void): Promise<IUploadResponse> {
    const response = await fetch("/reindex_docs", {
        method: "POST",
        headers: props.header,
        body: JSON.stringify({
            filestring: props.fileString,
            userid: props.userId,
            company: props.company,
            filespace: props.selectedGroup.selectionId,
            sectionoverlap: props.selectedGroup.sectionoverlap,
            maxsectionlength: props.selectedGroup.maxsectionlength,
            sentencesearchlimit: props.selectedGroup.sentencesearchlimit,
            modstring: props.modString,
            idstring: props.idString,
            invoicesgroup: props.selectedGroup.invoicesgroup,
            assistanttype: props.selectedGroup.assistanttype
        })
    });

    if (!response.ok) {
        throw new Error(`Reindexing Files Failed: ${response.statusText}`);
    }
    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("pldFlAp¦Init_Response1", dataResponse);

    const taskId = dataResponse.task_id;

    let itterCounter = 0;

    let taskCompleted = false;
    let taskResult = false;

    let taskMessage = "";
    let taskMessagePrev = "-1";

    const toastId = toast("Reindexing Files...", { autoClose: false });

    while (!taskCompleted) {
        itterCounter++;
        // reindexApi
        await wait(2000);
        const chkResp = await fetchTaskStatus(taskId);
        import.meta.env.DEV === true && console.log("pldFlAp¦Next_Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 7000
            });
        } else {
            import.meta.env.DEV === true && console.log("Toast", `Reindexing Files...`);
            if (chkResp.fileCount !== undefined) {
                try {
                    let progress = chkResp.progressCount / chkResp.fileCount;
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Reindexing Files... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                } catch {
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Reindexing Files... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                }
            }

            if (itterCounter % 10 === 0 || itterCounter % 10 === 5 || itterCounter < 5) {
                if (progressCallback) {
                    progressCallback();
                }
            }
        }

        if (taskMessagePrev !== taskMessage) {
            if (progressCallback) {
                progressCallback();
            }
        }
        taskMessagePrev = taskMessage;
    }

    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}
// Main Upload
export async function uploadFileApi(
    request: FormData,
    loggedInUser: gloggedinuser,
    progressCallback?: () => void,
    isVideoFiles?: boolean
): Promise<IUploadResponse> {
    const toastId = toast.loading("Uploading Files...", { autoClose: false, closeOnClick: true, closeButton: true });
    let isRequestFinished = false;
    setTimeout(() => {
        if (!isRequestFinished && progressCallback) if (progressCallback) progressCallback();
    }, 2000);

    const response = await fetch(isVideoFiles == true ? "/upload_video_fw" : "/upload", {
        method: "POST",
        body: request
    });

    if (!response.ok) throw new Error(`Uploading_Files_Failed: ${response.statusText}`);

    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("pldFlAp¦Init_Response2", dataResponse);

    const taskId = dataResponse.task_id;
    let taskCompleted = false;
    let taskResult = false;
    let taskMessage = "";
    let taskMessagePrev = "-1";
    let itterCounter = 0;

    while (!taskCompleted) {
        itterCounter++;
        await wait(4000);
        const chkResp = await fetchTaskStatus(taskId);
        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 7000,
                isLoading: false
            });
        } else {
            if (chkResp.filecount !== undefined) {
                try {
                    let progress = chkResp.progresscount / chkResp.filecount;
                    taskMessage = `Processing Files... ${chkResp.progresscount} of ${chkResp.filecount} Done`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                } catch {
                    taskMessage = `Processing Files... ${chkResp.progresscount} of ${chkResp.filecount} Done`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                }
            }

            if (itterCounter % 10 === 0 || itterCounter % 10 === 5 || itterCounter < 40) {
                if (progressCallback) {
                    progressCallback();
                }
            }
        }
        if (taskMessagePrev !== taskMessage) {
            if (progressCallback) {
                progressCallback();
            }
        }
        taskMessagePrev = taskMessage;
    }
    removeTaskIdFromLocal(taskId);
    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}
export async function getSiteLinks(urlString: string, ai_instr: string, loggedInUser: gloggedinuser): Promise<gailinks> {
    const response = await fetch("/get_web_links", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            url: urlString,
            ai_instr: ai_instr,
            uid: loggedInUser.userId
        })
    });
    if (!response.ok) throw new Error(`Add URL File Failed: ${response.statusText}`);
    return response.json();
}

// Get Loc
export const qGetCompFlatList = async (headers: any) => {
    const response = await fetch("/qGetCompFlatList", { headers: headers });
    if (!response.ok) throw Error("Failed to Get User Companies");
    let compList = await response.json();
    return compList;
};
export const qGetGroups = async (companyid: string, headers: any) => {
    const params = new URLSearchParams({ compd: companyid });
    const url = "/qGetGroups" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("QGETGRPS¦Failed to get Groups");
    let grpsresp = await response.json();
    return grpsresp;
};

// Main YT
export async function addURLApi(
    urlString: string,
    selectedGroup: gselectedgroup,
    company: string,
    claimsUserId: string,
    cTy: string,
    itemInfo: gingestioniteminfo[],
    progressCallback?: () => void
): Promise<IUploadResponse> {
    let isRequestFinished = false;
    setTimeout(() => {
        if (!isRequestFinished) if (progressCallback) progressCallback();
    }, 2000);
    const response = await fetch("/add_url", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            url: urlString,
            fileSpace: selectedGroup.selectionId,
            company: company,
            userId: claimsUserId,
            cty: cTy,
            sectionoverlap: selectedGroup.sectionoverlap,
            maxsectionlength: selectedGroup.maxsectionlength,
            sentencesearchlimit: selectedGroup.sentencesearchlimit,
            fileInfo: { ...itemInfo }
        })
    });

    let messageArr = ["Downloading Page", "Preparing Page for Ingestion", "Creating Embeddings", "Adding Indices"];
    if (cTy == "youtube") {
        messageArr = [
            "Downloading Video",
            "Running Optical Character Recognition",
            "Capturing Key Frames",
            "Running Whisper on audio",
            "Putting it all together",
            "Creating Embeddings",
            "Adding Indices"
        ];
    }

    if (!response.ok) throw new Error(`Add URL File Failed: ${response.statusText}`);

    const toastId = toast("Processing...", { autoClose: false });

    const dataResponse: any = await response.json();

    const taskId = dataResponse.task_id;

    let taskCompleted = false;
    let taskResult = false;

    let taskMessage = "";
    let taskMessagePrev = "-1";

    let itterCounter = 0;
    let messageIndex = 0;

    let finalMessage = "";

    while (!taskCompleted) {
        itterCounter++;
        await wait(2000);
        const chkResp = await fetchTaskStatus(taskId);
        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            taskResult = chkResp.status == "success" || chkResp.status == "partial" ? true : false;
            taskMessage = chkResp.message;
            finalMessage = `${chkResp.message}`;
            taskCompleted = true;
        } else {
            if (itterCounter % 10 === 0) if (messageIndex < messageArr.length - 1) messageIndex++;

            if (chkResp.fileCount !== undefined) {
                taskMessage = chkResp.message;
                if (taskMessage.toLowerCase().includes("error")) {
                    toast.update(toastId, { render: `${taskMessage}`, type: "error" });
                } else {
                    toast.update(toastId, { render: `${taskMessage}` });
                }
            }
            if (itterCounter % 10 === 0 || itterCounter % 10 === 5 || itterCounter < 5) if (progressCallback) progressCallback();
        }
        if (taskMessagePrev !== taskMessage) if (progressCallback) progressCallback();

        taskMessagePrev = taskMessage;
    }
    if (taskMessage.toLowerCase().includes("error")) {
        toast.update(toastId, {
            render: `${taskMessage}`,
            type: "error",
            autoClose: 10000
        });
    } else {
        toast.update(toastId, {
            render: `${taskMessage}`,
            autoClose: 7000
        });
    }

    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}
export async function dataExtractionApi(props: greindexparams, progressCallback?: () => void): Promise<IUploadResponse> {
    const response = await fetch("/invoice_extraction", {
        method: "POST",
        headers: props.header,
        body: JSON.stringify({
            filestring: props.fileString,
            company: props.company,
            userid: props.userId,
            filespace: props.selectedGroup.selectionId,
            sectionoverlap: props.selectedGroup.sectionoverlap,
            maxsectionlength: props.selectedGroup.maxsectionlength,
            sentencesearchlimit: props.selectedGroup.sentencesearchlimit,
            modstring: props.modString,
            idstring: props.idString,
            usedocintel: props.selectedGroup.usedocintel,
            extractionfieldlist: props.selectedGroup.extractionfieldlist,
            assistanttype: props.selectedGroup.assistanttype
        })
    });

    if (!response.ok) {
        throw new Error(`Extract Invoice Details Failed: ${response.statusText}`);
    }
    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("pldFlAp¦Init_Response3", dataResponse);

    const taskId = dataResponse.task_id;

    let itterCounter = 0;

    let taskCompleted = false;
    let taskResult = false;

    let taskMessage = "";
    let taskMessagePrev = "-1";

    const toastId = toast("Extracting Invoice Details...", { autoClose: false });

    if (progressCallback) {
        progressCallback();
    }

    while (!taskCompleted) {
        itterCounter++;
        // invoiceExtractionApi
        await wait(2000);
        const chkResp = await fetchTaskStatus(taskId);
        import.meta.env.DEV === true && console.log("pldFlAp¦Next_Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 7000
            });
        } else {
            import.meta.env.DEV === true && console.log("Toast", `Extracting Invoice Details...`);
            if (chkResp.fileCount !== undefined) {
                try {
                    let progress = chkResp.progressCount / chkResp.fileCount;
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Extracting Invoice Details... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                } catch {
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Extracting Invoice Details... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                }
            }

            if (itterCounter % 10 === 0 || itterCounter % 10 === 5 || itterCounter < 5) {
                if (progressCallback) {
                    progressCallback();
                }
            }
        }

        if (taskMessagePrev !== taskMessage) {
            if (progressCallback) {
                progressCallback();
            }
        }
        taskMessagePrev = taskMessage;
    }

    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}
export async function saveTranscriptionDraft(
    selectedGroup: gselectedgroup,
    loggedInUser: gloggedinuser,
    transcript: gtranscriptentry[],
    transcriptKey: string,
    transcriptname: string
) {
    await fetch(`/qSaveTranscriptionDraft`, {
        method: "POST",
        headers: getHeaderG(loggedInUser),
        body: JSON.stringify({
            groupid: selectedGroup.selectionId,
            userid: loggedInUser.userId,
            transcriptKey: transcriptKey,
            transcript: transcript,
            transcriptname: transcriptname
        })
    });
}
export async function qUploadCCAConvo(trnscptPbls: qconvoupload, progressCallback?: () => void) {
    const toastId = toast("Adding Conversation to Knowledge..", { autoClose: false });
    setTimeout(() => {
        if (!isRequestFinished) if (progressCallback) progressCallback();
    }, 2000);

    const response = await fetch(`/kgnf_upload_conversation`, {
        method: "POST",
        headers: getHeaderG(trnscptPbls.loggedInUser),
        body: JSON.stringify({
            content: trnscptPbls.convo,
            containername: trnscptPbls.convoName,
            userid: trnscptPbls.loggedInUser.userId,
            groupid: trnscptPbls.selectedGroup.selectionId,
            companyid: trnscptPbls.selectedGroup.companyid,
            sectionoverlap: trnscptPbls.selectedGroup.sectionoverlap,
            maxsectionlength: trnscptPbls.selectedGroup.maxsectionlength,
            sentencesearchlimit: trnscptPbls.selectedGroup.sentencesearchlimit,
            invoicesgroup: trnscptPbls.selectedGroup.invoicesgroup,
            usedocintel: trnscptPbls.selectedGroup.usedocintel,
            forceocr: trnscptPbls.selectedGroup.forceocr,
            assitanttype: trnscptPbls.selectedGroup.assistanttype,
            contenttype_config: trnscptPbls.selectedGroup.contenttype_config
        })
    });
    let isRequestFinished = false;

    if (!response.ok) {
        throw new Error(`Uploading_Files_Failed: ${response.statusText}`);
    }
    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("pldFlAp¦Init_Response4", dataResponse);

    const taskId = dataResponse.task_id;
    let taskCompleted = false;
    let taskResult = false;
    let taskMessage = "";
    let taskMessagePrev = "-1";
    let itterCounter = 0;

    while (!taskCompleted) {
        itterCounter++;
        // publishTranscriptDraft
        await wait(8000);
        const chkResp = await fetchTaskStatus(taskId);

        import.meta.env.DEV === true && console.log("pldFlAp¦Next_Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 7000
            });
        } else {
            if (chkResp.filecount !== undefined) {
                try {
                    let progress = chkResp.progresscount / chkResp.filecount;
                    taskMessage = `Processing Files... ${chkResp.progresscount} of ${chkResp.filecount} Done`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                } catch {
                    taskMessage = `Processing Files... ${chkResp.progresscount} of ${chkResp.filecount} Done`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                }
            }

            if (itterCounter % 2 === 0 || itterCounter % 10 === 5 || itterCounter < 5) if (progressCallback) progressCallback();
        }
        if (taskMessagePrev !== taskMessage) if (progressCallback) progressCallback();

        taskMessagePrev = taskMessage;
    }
    removeTaskIdFromLocal(taskId);
    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
}
export async function publishTranscriptDraft(trnscptPbls: gtranscriptpublish, progressCallback?: () => void) {
    const toastId = toast("Uploading Files...", { autoClose: false });
    setTimeout(() => {
        if (!isRequestFinished) {
            if (progressCallback) {
                progressCallback();
            }
        }
    }, 2000);

    const response = await fetch(`/qPublishTranscriptDraft`, {
        method: "POST",
        headers: getHeaderG(trnscptPbls.loggedInUser),
        body: JSON.stringify({
            content: trnscptPbls.transcript,
            containername: trnscptPbls.transcriptName,
            userid: trnscptPbls.loggedInUser.userId,
            transcriptkey: trnscptPbls.transcriptKey,
            groupid: trnscptPbls.selectedGroup.selectionId,
            companyid: trnscptPbls.selectedGroup.companyid,
            sectionoverlap: trnscptPbls.selectedGroup.sectionoverlap,
            maxsectionlength: trnscptPbls.selectedGroup.maxsectionlength,
            sentencesearchlimit: trnscptPbls.selectedGroup.sentencesearchlimit,
            invoicesgroup: trnscptPbls.selectedGroup.invoicesgroup,
            usedocintel: trnscptPbls.selectedGroup.usedocintel,
            forceocr: trnscptPbls.selectedGroup.forceocr,
            assitanttype: trnscptPbls.selectedGroup.assistanttype,
            contenttype_config: trnscptPbls.selectedGroup.contenttype_config
        })
    });
    let isRequestFinished = false;

    if (!response.ok) {
        throw new Error(`Uploading_Files_Failed: ${response.statusText}`);
    }
    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("pldFlAp¦Init_Response4", dataResponse);

    const taskId = dataResponse.task_id;
    let taskCompleted = false;
    let taskResult = false;
    let taskMessage = "";
    let taskMessagePrev = "-1";
    let itterCounter = 0;

    while (!taskCompleted) {
        itterCounter++;
        // publishTranscriptDraft
        await wait(8000);
        const chkResp = await fetchTaskStatus(taskId);

        import.meta.env.DEV === true && console.log("pldFlAp¦Next_Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 7000
            });
        } else {
            if (chkResp.filecount !== undefined) {
                try {
                    let progress = chkResp.progresscount / chkResp.filecount;
                    taskMessage = `Processing Files... ${chkResp.progresscount} of ${chkResp.filecount} Done`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                } catch {
                    taskMessage = `Processing Files... ${chkResp.progresscount} of ${chkResp.filecount} Done`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                }
            }

            if (itterCounter % 2 === 0 || itterCounter % 10 === 5 || itterCounter < 5) {
                if (progressCallback) {
                    progressCallback();
                }
            }
        }
        if (taskMessagePrev !== taskMessage) {
            if (progressCallback) {
                progressCallback();
            }
        }
        taskMessagePrev = taskMessage;
    }
    removeTaskIdFromLocal(taskId);
    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
}
export async function getTranscriptionDrafts(selectedGroup: gselectedgroup, loggedInUser: gloggedinuser, transcriptkey?: string): Promise<gtranscriptobject[]> {
    const params = new URLSearchParams({
        groupid: selectedGroup.selectionId,
        userid: loggedInUser.userId,
        transcriptkey: transcriptkey ? transcriptkey : ""
    });
    const url = "/qGetTranscriptionDrafts" + `?${params.toString()}`;

    const response = await fetch(url, {
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw Error("Failed to Validate Mail");
    }
    let userCheck = await response.json();
    return userCheck;
}
export async function deleteTranscriptionDraft(selectedGroup: gselectedgroup, loggedInUser: gloggedinuser, transcriptKey: string) {
    await fetch(`/qDeleteTranscriptionDraft`, {
        method: "POST",
        headers: getHeaderG(loggedInUser),
        body: JSON.stringify({
            groupid: selectedGroup.selectionId,
            userid: loggedInUser.userId,
            transcriptKey: transcriptKey
        })
    });
}
export async function ai_comparison(
    fid1: string,
    fid2: string,
    loggedInUser: gloggedinuser,
    compid: string,
    mod1: string,
    mod2: string,
    ownerid1: string,
    ownerid2: string,
    progressCallback?: () => void
) {
    const toastId = toast.loading("Processing...", { autoClose: false });
    setTimeout(() => {
        if (progressCallback) {
            if (progressCallback) progressCallback();
        }
    }, 1000);

    const response = await fetch("/ai_compare", {
        method: "POST",
        headers: getHeaderG(loggedInUser),
        body: JSON.stringify({
            fid1: fid1,
            fid2: fid2,
            compid: compid,
            mod1: mod1,
            mod2: mod2,
            ownerid1: ownerid1,
            ownerid2: ownerid2
        })
    });
    toast.update(toastId, { render: "Comparing...", autoClose: false });
    const dataResponse: any = await response.json();
    const taskId = dataResponse.task_id;
    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const diffTaskStatus = async (taskId: string) => {
        const statusResponse = await fetch(`/task-status/${taskId}`);
        if (!statusResponse.ok) {
            throw new Error(`Fetching Task Status Failed: ${statusResponse.statusText}`);
        }
        return await statusResponse.json();
    };
    let taskCompleted = false;
    let taskResult = false;
    let taskMessage = "";

    while (!taskCompleted) {
        await wait(3000);
        const chkResp = await diffTaskStatus(taskId);
        import.meta.env.DEV === true && console.log("diffApi¦Next Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
                if (progressCallback) progressCallback();
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                type: "success",
                autoClose: 2000,
                isLoading: false
            });
        } else {
            import.meta.env.DEV === true && console.log("Toast", `Deleting File...`);
            if (progressCallback) progressCallback();

            toast.update(toastId, {
                render: chkResp.message,
                isLoading: false
            });
        }
    }

    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}
export async function getAiDiff(fid1: string, fid2: string, loggedInUser: gloggedinuser): Promise<gdiffresponse[]> {
    const params = new URLSearchParams({
        fid1: fid1,
        fid2: fid2,
        userid: loggedInUser.userId
    });
    const url = "/qGetAIDiff" + `?${params.toString()}`;
    const response = await fetch(url, {
        headers: getHeaderG(loggedInUser)
    });
    if (!response.ok) throw Error("Failed to Get AI Diff");
    let aiDiff = await response.json();
    return aiDiff;
}
export const downloadPDF = (url: string, filename: string, companyId: string, loggedInUser: gloggedinuser) => {
    console.log("Download PDF", url, filename, companyId, loggedInUser);
    fetch(`/content/${companyId}/${url}`, { method: "GET", headers: getHeaderG(loggedInUser) })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error("Download failed:", error));
};
export const getThumb = async (of: string, fid: string, company_id: string, loggedInUser: gloggedinuser) => {
    const params = new URLSearchParams({
        of: of,
        fid: fid,
        company_id: company_id
    });
    const url = "/thumb" + `?${params.toString()}`;
    fetch(url, { method: "GET", headers: getHeaderG(loggedInUser) })
        .then(response => response.blob())
        .then(blob => {})
        .catch(error => console.error("Download failed:", error));
};
export const qGetChatLog = async (groupid: string, headers: any) => {
    const params = new URLSearchParams({ groupid: groupid });
    const url = "/qGetChatLog" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("qGetChatLog¦Failed to Get Log");
    let sChatLog: gChatlogItem[] = await response.json();
    return sChatLog;
};
export const qHistGetByKey = async (userid: string, key: string, headers: any) => {
    const params = new URLSearchParams({ userid: userid, key: key });
    const url = "/qHistGetByKey" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("Failed to Get Latest History");
    let keyConvo = await response.json();
    return keyConvo;
};
export const qHistGetRecent = async (userid: string, company: string, headers: any) => {
    const params = new URLSearchParams({ userid: userid, company: company });
    const url = "/qHistGetRecent" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("Failed to Get Latest History");
    let recentConvosUserWide = await response.json();
    return recentConvosUserWide;
};
export const qGetSuggestedQuestions = async (fLst: gfile[], headers: any) => {
    const params = new URLSearchParams({
        fileids: fLst
            .map(f => f.fileid)
            .sort(() => 0.5 - Math.random())
            .slice(0, 3)
            .join(",")
    });
    const url = "/qGetSuggestedQuestions" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("Failed to Get Suggested Questions");
    let suggestedQuestions = await response.json();
    return suggestedQuestions;
};
export const qHistGetLatest = async (userid: string, groupid: string, companyid: string, topn: number, headers: any) => {
    const params = new URLSearchParams({
        userid: userid,
        groupid: groupid,
        companyid: companyid,
        topn: topn.toString()
    });
    const url = "/qHistGetLatest" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("Failed to Get Latest History");
    let latestConvo = await response.json();
    return latestConvo;
};
export const qCodeChk = async (userCode: string, userMail: string, mE: string, convCode?: string) => {
    const params = new URLSearchParams({
        userCode: userCode,
        userMail: userMail,
        convCode: convCode || "",
        mE: mE
    });
    const url = "/qCodeChk" + `?${params.toString()}`;
    const response = await fetch(url, { headers: { "Content-Type": "application/json" } });
    if (!response.ok) throw Error("Failed to Validate Mail");
    let userCheck = await response.json();
    return userCheck;
};
export const qUSp = async (n: string, v: string, headers: any) => {
    try {
        let subpaths = window.location.hash.split("/").filter(Boolean);
        let groupPath = decodeURIComponent(subpaths[2]);
        fetch("/qUSp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ grpi: groupPath, n: n, v: v })
        }).then(Response => {
            window.location.href = `${window.location.href.replace("chat", "library")}`;
            window.location.reload();
        });
    } catch {
        import.meta.env.DEV === true && console.log("errr_qusp");
    }
};
export const qLi = async (cat: string, det: string, headers: any, usr?: string) => {
    try {
        fetch("/qLi", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                usr: usr,
                cat: cat,
                det: det,
                loc: window.location.hash?.replace(/[^a-zA-Z0-9]/g, " ")
            })
        });
    } catch {}
};
export const qGetTiers = async (headers: any) => {
    const url = "/qGetTiers";
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("Failed to Get Latest History");
    let tiers = await response.json();
    return tiers;
};
export const qHistGetList = async (userid: string, groupid: string, companyid: string, topn: number, headers: any) => {
    const params = new URLSearchParams({
        userid: userid,
        groupid: groupid,
        companyid: companyid,
        topn: topn.toString()
    });
    const url = "/qHistGetList" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("Failed_to_Get_Latest_History");
    let convos = await response.json();
    return convos;
};
export const qHistAdd = async (key: number, userid: string, groupid: string, companyid: string, name: string, headers: any, answrs: any) => {
    fetch("/qHistAdd", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            key: key,
            userid: userid,
            groupid: groupid,
            companyid: companyid,
            content: answrs,
            name: name
        })
    });
};
export const qGetGroupsMemberships = async (companyid: string, headers: any) => {
    const params = new URLSearchParams({ compd: companyid });
    const url = "/qGetGroupsMemberships" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("Failed to get Groups");
    let mbrshpsResp = await response.json();
    return mbrshpsResp;
};
export const qGetCompanyUsers = async (companyid: string, headers: any) => {
    const params = new URLSearchParams({ compd: companyid });
    const url = "/qGetCompanyUsers" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("Failed to Get User Info");
    let compUsers = await response.json();
    return compUsers;
};
export const qGetAllUsers = async (headers: any) => {
    const response = await fetch("/qGetAllUsers", { headers: headers });
    if (!response.ok) throw Error("Failed to Get User Info");
    let allUsers = await response.json();
    return allUsers;
};
export const qGetFileParagraps = async (fid: string, loggedInUser: gloggedinuser): Promise<gautoparagraphresult[]> => {
    console.log("qget_para", fid);
    const params = new URLSearchParams({ fid: fid });
    const url = "/qGetFileParagraps" + `?${params.toString()}`;
    const response = await fetch(url, { headers: getHeaderG(loggedInUser) });
    if (!response.ok) throw Error("QGETPRGPS¦Failed to get Paragraphs");

    let paragrps = await response.json();
    if (paragrps && paragrps.length > 0) paragrps = paragrps;
    return paragrps;
};
export const qGetGroupLastModifiedFileDate = async (groupid: string, headers: any) => {
    const params = new URLSearchParams({ groupid: groupid });
    const url = "/qGetLatestModified" + `?${params.toString()}`;
    const response = await fetch(url, { headers: headers });
    if (!response.ok) throw Error("QGETGRPS¦Failed to Groups");
    let modresp = await response.json();
    return modresp;
};
export const qAddMember = async (usermail: string, groupid: string, compid: string, headers: any) => {
    const response = await fetch("/qAddMember", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            usermail: usermail,
            level: 1,
            groupid: groupid,
            companyid: compid
        })
    });
    return response;
};
export const qHistEditName = async (key: string, newText: string, userid: string, headers: any) => {
    const response = await fetch("/qHistEditName", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            key: key,
            userid: userid,
            name: newText
        })
    });
    return response;
};
export const qHistDelete = async (key: string, userid: string, headers: any) => {
    const response = await fetch("/qHistDelete", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            key: key,
            userid: userid
        })
    });
    return response;
};
export const qGetSentenceState = async (sentence: string, prev: string, selectedGroud: gselectedgroup, conf_name: string) => {
    const params = new URLSearchParams({ user_msg: sentence, prev_msg: prev, cust_inst: selectedGroud.docextractionconfig, conf_name: conf_name });
    const url = "/kgnf_sentence_state" + `?${params.toString()}`;
    const response = await fetch(url, {
        headers: {
            space_id: selectedGroud.selectionId,
            company_id: selectedGroud.companyid
        }
    });
    if (!response.ok) throw Error("Failed to Get Sentence State");
    let sentence_state = await response.json();
    return sentence_state;
};
export const qGetMatching = async (sentence: string, prev: string, option_values: string, option_config: gjsonprop, selectedGroup: gselectedgroup) => {
    const params = new URLSearchParams({
        user_msg: sentence,
        prev_msg: prev,
        option_values: option_values,
        option_config_name: option_config.name,
        option_config_desc: option_config.description
    });
    const url = "/kgnf_ai_matching" + `?${params.toString()}`;
    const response = await fetch(url, {
        headers: {
            space_id: selectedGroup.selectionId,
            company_id: selectedGroup.companyid
        }
    });
    if (!response.ok) throw Error("Failed to Get Sentence State");
    let sentence_state = await response.json();
    return sentence_state;
};
export const qTopicChange = async (
    sentence: string,
    prev: string,
    prev_topic: string,
    question_and_answers_list: gqasimplified[],
    selectedGroup: gselectedgroup,
    conf_details: gjsonprop
) => {
    const params = new URLSearchParams({
        user_msg: sentence,
        prev_msg: prev,
        prev_topic: prev_topic,
        qa_list: JSON.stringify(question_and_answers_list),
        conf_details: JSON.stringify(conf_details)
    });
    const url = "/kgnf_ai_topic_change" + `?${params.toString()}`;
    const response = await fetch(url, {
        headers: {
            space_id: selectedGroup.selectionId,
            company_id: selectedGroup.companyid
        }
    });

    if (!response.ok) throw Error("Failed to Get Sentence State");
    let sentence_state = await response.json();
    console.log("sentence_state", sentence_state);
    return sentence_state;
};
export const qReadify = async (text: string, selectedGroup: gselectedgroup) => {
    const params = new URLSearchParams({ text: text });
    const url = "/kgnf_readify" + `?${params.toString()}`;
    const response = await fetch(url, {
        headers: {
            space_id: selectedGroup.selectionId,
            company_id: selectedGroup.companyid
        }
    });

    if (!response.ok) throw Error("Failed to Get Sentence State");
    let readify = await response.json();
    console.log("readify", readify);
    console.log("readify", readify.result);
    if (readify && readify.result && readify.result.reply) {
        console.log("returns", readify.result.reply);
        return readify.result.reply;
    } else {
        return text;
    }
};
export const qJsonAnalysis = async (json_arr: gfanalyse[], headers: any) => {
    const response = await fetch("/kgnf_json_analysis", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            json_arr: json_arr
        })
    });
    if (!response.ok)
        return {
            success: false,
            response: "Failed to Analyse JSON"
        };
    return await response.json();
};
const fetchTaskStatus = async (taskId: string) => {
    try {
        const statusResponse = await fetch(`/task-status/${taskId}`);
        if (!statusResponse.ok) return {};

        return await statusResponse.json();
    } catch (error) {
        return {};
    }
};
const removeTaskIdFromLocal = (taskId: string) => {
    let taskIds = localStorage.getItem("taskIds")?.split(";");
    taskIds = taskIds?.filter(id => id !== taskId);
    localStorage.setItem("taskIds", taskIds ? taskIds.join(";") : "");
};
const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
